import React, { Component } from 'react';
import { Field, FieldArray } from 'formik';
import {
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Image,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Alert,
} from 'react-bootstrap';
import FormInput from 'components/FormInputs';
import TabActions from 'components/TabActions';
import DropZone from 'components/DropZone';
import Sketch from 'components/Sketch';

import {
  ServiceTypes,
  Shapes,
  ShapeOptions,
  GlassTypes,
  SetTypes,
  GlassTypesFields,
} from 'constants/glassPieces';
import { isRequired } from 'utils/rules';
import { extraValues } from 'constants/glassTypeOptions';
import GlassRectangle from './GlassRectangle';
import GlassRemindersView from './GlassRemindersView';
import GlassMaterialsView from './GlassMaterialsView';
import FabricationView from './FabricationView';

/* const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
}; */

const DETAILS = {
  // A
  Adlo: '',
  A_dlo: '',
  Ago: '',
  Anotes: '',
  // B
  Bdlo: '',
  B_dlo: '',
  Bgo: '',
  Bnotes: '',
  // C
  Cdlo: '',
  C_dlo: '',
  Cgo: '',
  Cnotes: '',
  // D
  Ddlo: '',
  D_dlo: '',
  Dgo: '',
  Dnotes: '',
  // E
  Edlo: '',
  E_dlo: '',
  Ego: '',
  Enotes: '',
  // F
  Fdlo: '',
  F_dlo: '',
  Fgo: '',
  Fnotes: '',
  // G
  Gdlo: '',
  G_dlo: '',
  Ggo: '',
  Gnotes: '',
  // H
  Hdlo: '',
  H_dlo: '',
  Hgo: '',
  Hnotes: '',
};

const INITIAL_VALUE = {
  glassReminders: {
    glass_elevator: 'yes',
    solar_film: 'no',
    solar_film_option_sag: null,
    wet_seal: 'no',
    wet_seal_option: null,
    furniture: 'no',
    walls_cielings: 'no',
    walls_cielings_option: null,
    blind_remove: 'no',
    color_waiver: 'no',
    damage_waiver: 'no',
    disclaimers: 'no',
    lift_inside: 'no',
    lift_outside: 'no',
  },
  glassMaterials: {
    caulks: [{ amount: 0, value: '', notes: '' }],
    types: [{ amount: 0, value: '', notes: '' }],
    equipaments: [{ amount: 0, value: '', notes: '' }],
    channels: [{ amount: 0, value: '', notes: '' }],
    miscellaneous: [{ amount: 0, value: '', notes: '' }],
  },
  details: DETAILS,
  glassTypeDetails: {},
  fabrication: 'no',
  fabrication_fields: {
    polished_edges_avaliable: false,
    pattern_avaliable: false,
    hole_avaliable: false,
    grid_avaliable: false,
    offset_avaliable: false,
    holes: [],
    offsets: [],
  },
  rectangle_fields: {
    extra_width_doubt: false,
    dlo_avaliable: true,
    go_avaliable: true,
    dlo_height: '',
    dlo_width: '',
    extra_height: 0,
    extra_width: 0,
  },
  glass_pricing: 'SAG',
  images: [],
  sketchs: [],
  shape_type: 'rectangular_square',
  service_type: 'replace',
  quantity: 1,
};

export default class GlassPiecesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportedPaths: null,
      current: 0,
      showEraseAlert: false,
    };
  }

  getBoolVal = doubtfulValue => {
    if (typeof doubtfulValue === 'string') {
      return doubtfulValue === '1';
    }
    return !!doubtfulValue;
  };

  addValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) + 1);
  };

  substractValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) - 1);
  };

  erase = remove => {
    const { current } = this.state;
    remove(current);
    this.setState({ showEraseAlert: false, current: current - 1 });
  };

  viewItem = current => {
    this.setState({ current });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderGlassPiece = (glassPiece, key, form, push, remove, errors = {}, touched = {}) => {
    const { handleChange, handleBlur, setFieldValue, token } = this.props;

    return (
      <Row className="mb-4 ml-0 mr-0 pt-5" style={{ background: '#fff' }}>
        <Col xs={12} sm={12} md={12} xl={7}>
          <Form.Group controlId={`glassPieces.${key}.service_type`}>
            <Form.Label className="mt-3 input-text">Service Type</Form.Label>
            <Field
              as="select"
              className={
                errors.service_type && touched.service_type
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`glassPieces.${key}.service_type`}
              value={glassPiece.service_type}
              onChange={e => {
                handleChange(e);
                // setFieldValue(`glassPieces.${key}.service_type`, {});
              }}
              onBlur={handleBlur}
            >
              {ServiceTypes.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.service_type && touched.service_type && errors.service_type}
            </Form.Control.Feedback>
          </Form.Group>
          {['rrdd'].includes(glassPiece.service_type) && (
            <Form.Group controlId={`glassPieces.${key}.quote_number`}>
              <Form.Label className="mt-3 input-text">Quote number</Form.Label>
              <Form.Control
                as={Field}
                name={`glassPieces.${key}.quote_number`}
                type="number"
                placeholder="Quote number"
                value={glassPiece.quote_number || ''}
                isInvalid={!!errors.quote_number && touched.quote_number}
                onChange={e => {
                  handleChange(e);
                  // setFieldValue(`glassPieces.${key}.quote_number`, {});
                }}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.quote_number && touched.quote_number && errors.quote_number}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group controlId={`glassPieces.${key}.location`}>
            <Form.Label className="mt-3 input-text">Location</Form.Label>
            <Form.Control
              as={Field}
              name={`glassPieces.${key}.location`}
              type="text"
              placeholder="Location"
              value={glassPiece.location || ''}
              isInvalid={!!errors.location && touched.location}
              onChange={e => {
                handleChange(e);
                // setFieldValue(`glassPieces.${key}.location`, {});
              }}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {errors.location && touched.location && errors.location}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={`glassPieces.${key}.quantity`}>
            <Form.Label className="mt-3 input-text">Quantity</Form.Label>
            <InputGroup className="4" md="4">
              <InputGroup.Prepend>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    this.substractValue(`glassPieces.${key}.quantity`, glassPiece.quantity)
                  }
                >
                  -
                </Button>
              </InputGroup.Prepend>
              <Form.Control
                as={Field}
                type="number"
                name={`glassPieces.${key}.quantity`}
                value={glassPiece.quantity}
                isInvalid={!!errors.quantity && touched.quantity}
                onChange={e => {
                  handleChange(e);
                  // setFieldValue(`glassPieces.${key}.quantity`, {});
                }}
                placeholder="Quantity"
                onBlur={handleBlur}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => this.addValue(`glassPieces.${key}.quantity`, glassPiece.quantity)}
                >
                  +
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {errors.quantity && touched.quantity && errors.quantity}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={`glassPieces.${key}.shape_type`}>
            <Form.Label className="mt-3 input-text">Shape Type</Form.Label>
            <Field
              as="select"
              className={
                errors.shape_type && touched.shape_type
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`glassPieces.${key}.shape_type`}
              value={glassPiece.shape_type}
              onChange={e => {
                handleChange(e);
                if (e.target.value !== 'rectangular_square') {
                  setFieldValue(`glassPieces.${key}.rectangle_fields`, {
                    extra_width_doubt: false,
                    dlo_avaliable: false,
                    go_avaliable: false,
                    dlo_height: '',
                    dlo_width: '',
                    extra_height: 0,
                    extra_width: 0,
                  });
                } else {
                  setFieldValue(`glassPieces.${key}.rectangle_fields`, {
                    extra_width_doubt: false,
                    dlo_avaliable: true,
                    go_avaliable: true,
                    dlo_height: '',
                    dlo_width: '',
                    extra_height: 0,
                    extra_width: 0,
                  });
                }
                setFieldValue(`glassPieces.${key}.details`, DETAILS);
              }}
              onBlur={handleBlur}
            >
              {Shapes.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.shape_type && touched.shape_type && errors.shape_type}
            </Form.Control.Feedback>
          </Form.Group>

          {ShapeOptions[glassPiece.shape_type] && (
            <Row>
              <Col xs={12} sm={12} md={5} xl={5}>
                <Image src={ShapeOptions[glassPiece.shape_type].picture} thumbnail />
              </Col>
              <Col xs={12} sm={12} md={7} xl={7}>
                {ShapeOptions[glassPiece.shape_type].fields.map(
                  (
                    {
                      name,
                      name2,
                      name3,
                      name4,
                      type,
                      label,
                      details,
                      options,
                      glasCheckName,
                      glasCheck2Name,
                    },
                    index,
                  ) => (
                    <FormInput
                      name={`glassPieces.${key}.details.${name}`}
                      name2={`glassPieces.${key}.details.${name2}`}
                      name3={`glassPieces.${key}.details.${name3}`}
                      name4={`glassPieces.${key}.details.${name4}`}
                      glasCheckName={`glassPieces.${key}.details.${glasCheckName}`}
                      glasCheck2Name={`glassPieces.${key}.details.${glasCheck2Name}`}
                      type={type}
                      label={label}
                      details={details}
                      options={options}
                      value={glassPiece.details[name] || ''}
                      value2={glassPiece.details[name2] || ''}
                      value3={glassPiece.details[name3] || ''}
                      value4={glassPiece.details[name4] || ''}
                      glasCheck={glassPiece.details[glasCheckName] || false}
                      glasCheck2={glassPiece.details[glasCheck2Name] || false}
                      isInvalid={
                        errors.details && touched.details
                          ? !!errors.details[name] && touched.details[name]
                          : false
                      }
                      isInvalidDetails={
                        errors.details && touched.details
                          ? !!errors.details[`${name}_detail`] && touched.details[`${name}_detail`]
                          : false
                      }
                      detailName={`glassPieces.${key}.details.${name}_detail`}
                      detailValue={glassPiece.details[`${name}_detail`]}
                      error={errors.details ? errors.details[name] : null}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      key={index}
                    />
                  ),
                )}
              </Col>
            </Row>
          )}

          <Form.Group controlId={`glassPieces.${key}.glass_type`}>
            <Form.Label className="mt-3 input-text">Glass Type</Form.Label>
            <Field
              as="select"
              className={
                errors.glass_type && touched.glass_type
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`glassPieces.${key}.glass_type`}
              value={glassPiece.glass_type || ''}
              onChange={e => {
                handleChange(e);
                setFieldValue(`glassPieces.${key}.glassTypeDetails`, {});
              }}
              onBlur={handleBlur}
            >
              <option value="">Choose a glass type</option>
              {GlassTypes.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.glass_type && touched.glass_type && errors.glass_type}
            </Form.Control.Feedback>
          </Form.Group>
          {glassPiece.glass_type && (
            <Row>
              {GlassTypesFields[glassPiece.glass_type].fields.map(
                ({ name, type, label, details, options, colSize, isDoubtful }, index) => (
                  <Col key={index} md={colSize}>
                    <FormInput
                      singleName={name}
                      glassType={glassPiece.glass_type}
                      glassTypeDetail={`glassPieces.${key}.glassTypeDetails.`}
                      name={`glassPieces.${key}.glassTypeDetails.${name}`}
                      type={type}
                      label={label}
                      details={details}
                      options={options}
                      value={glassPiece.glassTypeDetails[name] || ''}
                      isInvalid={
                        errors.glassTypeDetails && touched.glassTypeDetails
                          ? errors.glassTypeDetails[name] && touched.glassTypeDetails[name]
                          : false
                      }
                      isInvalidDetails={
                        errors.glassTypeDetails && touched.glassTypeDetails
                          ? !!errors.glassTypeDetails[`${name}_detail`] &&
                            touched.glassTypeDetails[`${name}_detail`]
                          : false
                      }
                      detailName={`glassPieces.${key}.glassTypeDetails.${name}_detail`}
                      detailValue={glassPiece.glassTypeDetails[`${name}_detail`]}
                      error={errors.glassTypeDetails ? errors.glassTypeDetails[name] : null}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      key={index}
                      isDoubtful={isDoubtful}
                      doubtfulValue={this.getBoolVal(glassPiece.glassTypeDetails[`${name}_doubt`])}
                      isDoubtfulName={`glassPieces.${key}.glassTypeDetails.${name}_doubt`}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                ),
              )}
            </Row>
          )}

          <Form.Group controlId={`glassPieces.${key}.glass_set`}>
            <Form.Label className="mt-3 input-text">Set</Form.Label>
            <InputGroup.Prepend
              className={
                !glassPiece.glass_set_doubt || glassPiece.glass_set_doubt == ''
                  ? 'background-deactivated'
                  : 'background-activated'
              }
            >
              <InputGroup.Checkbox
                onChange={evt => {
                  const { checked } = evt.target;
                  setFieldValue(`glassPieces.${key}.glass_set_doubt`, checked);
                }}
                name={`glassPieces.${key}.glass_set_doubt`}
                checked={glassPiece.glass_set_doubt || false}
                id={`glassPieces.${key}.glass_set_doubt`}
              />
              <Field
                as="select"
                className={
                  errors.glass_set && touched.glass_set
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`glassPieces.${key}.glass_set`}
                value={glassPiece.glass_set || ''}
                // onChange={handleChange}
                onChange={evt => {
                  if (evt.target.value !== '') {
                    setFieldValue(`glassPieces.${key}.glass_set_doubt`, true);
                  } else {
                    setFieldValue(`glassPieces.${key}.glass_set_doubt`, false);
                  }
                  handleChange(evt);
                  setFieldValue(
                    `glassPieces.${key}.rectangle_fields.extra_width`,
                    extraValues[evt.target.value].width,
                  );
                  setFieldValue(
                    `glassPieces.${key}.rectangle_fields.extra_height`,
                    extraValues[evt.target.value].height,
                  );
                  setFieldValue(`glassPieces.${key}.rectangle_fields.extra_width_doubt`, true);
                  setFieldValue(`glassPieces.${key}.rectangle_fields.extra_height_doubt`, true);
                }}
                onBlur={handleBlur}
              >
                <option value="">Choose a set</option>
                {SetTypes.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            </InputGroup.Prepend>

            <Form.Control.Feedback type="invalid">
              {errors.glass_set && touched.glass_set && errors.glass_set}
            </Form.Control.Feedback>
          </Form.Group>

          {['rectangular_square'].includes(glassPiece.shape_type) && (
            <GlassRectangle
              glass_set={glassPiece.glass_set}
              prefix={`glassPieces.${key}.rectangle_fields`}
              values={glassPiece.rectangle_fields || {}}
              errors={errors.rectangle_fields || {}}
              handleChange={handleChange}
              touched={touched.rectangle_fields || {}}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          )}

          <Form.Group controlId={`glassPieces.${key}.fabrication`}>
            <Form.Label className="mt-3 input-text">Fabrication?</Form.Label>
            <ButtonToolbar>
              <ToggleButtonGroup
                type="radio"
                name={`glassPieces.${key}.fabrication`}
                defaultValue={glassPiece.fabrication}
                onChange={(_, event) => {
                  handleChange(event);
                  if (event.target.value == 'no') {
                    setFieldValue(`glassPieces.${key}.fabrication_fields`, {
                      polished_edges_avaliable: false,
                      pattern_avaliable: false,
                      hole_avaliable: false,
                      grid_avaliable: false,
                      offset_avaliable: false,
                      holes: [],
                      offsets: [],
                    });
                  }
                }}
                style={{ width: '100%' }}
              >
                <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                  No
                </ToggleButton>
                <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            {glassPiece.fabrication === 'yes' && (
              <FabricationView
                prefix={`glassPieces.${key}.fabrication_fields`}
                values={glassPiece.fabrication_fields || {}}
                errors={errors.fabrication_fields || {}}
                handleChange={handleChange}
                touched={touched.fabrication_fields || {}}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
            )}
          </Form.Group>

          <Form.Group controlId={`glassPieces.${key}.glass_pricing`}>
            <Form.Label className="mt-3 input-text">Glass pricing</Form.Label>
            <ButtonToolbar>
              <ToggleButtonGroup
                type="radio"
                name={`glassPieces.${key}.glass_pricing`}
                defaultValue={glassPiece.glass_pricing}
                onChange={(_, event) => {
                  handleChange(event);
                  if (event.target.value == 'SAG') {
                    setFieldValue(`glassPieces.${key}.manufacturer`, null);
                  }
                }}
                style={{ width: '100%' }}
              >
                <ToggleButton
                  className="form-control pt-3"
                  variant="outline-primary"
                  value="Glazier"
                >
                  Glazier
                </ToggleButton>
                <ToggleButton className="form-control pt-3" variant="outline-primary" value="quote">
                  Vendor Pricing
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            {glassPiece.glass_pricing === 'quote' && (
              <Form.Group controlId={`glassPieces.${key}.manufacturer`}>
                <Form.Label className="mt-3 input-text">Manufacturer</Form.Label>
                <Form.Control
                  as={Field}
                  name={`glassPieces.${key}.manufacturer`}
                  type="text"
                  placeholder="Manufacturer"
                  value={glassPiece.manufacturer || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            )}
          </Form.Group>
          <Form.Group controlId="instructions">
            <Form.Label className="mt-3 input-text">Instructions</Form.Label>
            <Field
              as="textarea"
              className="textarea"
              name={`glassPieces.${key}.instructions`}
              value={glassPiece.instructions || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={5}>
          {/* GLASS REMINDERS */}
          <GlassRemindersView
            prefix={`glassPieces.${key}.glassReminders`}
            values={glassPiece.glassReminders}
            errors={errors.glassReminders || {}}
            handleChange={handleChange}
            touched={touched.glassReminders || {}}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Col>
        {/* GLASS MATERIALS */}
        <Col xs={12} sm={12} md={12} xl={12}>
          <GlassMaterialsView
            prefix={`glassPieces.${key}.glassMaterials`}
            values={glassPiece.glassMaterials}
            errors={errors.glassMaterials || {}}
            handleChange={handleChange}
            touched={touched.glassMaterials || {}}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Col>

        <Col xs={12} sm={12} md={12} xl={12} className="p-0">
          <h3>Images</h3>
          <DropZone
            height={300}
            token={token}
            folder="glass-pieces-uploads"
            initialFiles={glassPiece.images}
            handleSuccess={({ url }, position) => {
              const images = [...glassPiece.images];
              const pos = Math.min(position, glassPiece.images.length);
              images.splice(pos, 0, url);
              setFieldValue(`glassPieces.${key}.images`, images);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `glassPieces.${key}.images`,
                glassPiece.images.filter(u => u !== url),
              )
            }
            handlePosition={(fromIndex, toIndex) => {
              const images = [...glassPiece.images];
              const element = images[fromIndex];
              images.splice(fromIndex, 1);
              images.splice(toIndex, 0, element);

              setFieldValue(`glassPieces.${key}.images`, images);
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} className="mt-5 pt-3" style={{ cursor: 'cell' }}>
          <h3>Sketches</h3>
          <Form.Label>Click below to sketch </Form.Label>
          <Sketch
            token={token}
            folder="glass-pieces-sketchs"
            sketchs={glassPiece.sketchs}
            handleSuccess={({ url }) => {
              setFieldValue(`glassPieces.${key}.sketchs`, [...glassPiece.sketchs, url]);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `glassPieces.${key}.sketchs`,
                glassPiece.sketchs.filter(u => u !== url),
              )
            }
          />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      values: { glassPieces },
      errors,
      touched,
    } = this.props;
    const { current, showEraseAlert } = this.state;
    const glassPiecesCount = glassPieces.length;
    const glassPiece = glassPiecesCount > 0 ? glassPieces[current] : null;

    return (
      <div>
        <FieldArray
          name="glassPieces"
          render={({ form, push, remove }) => (
            <div style={{ marginBottom: '40px' }} key="glassPieces">
              <TabActions
                name="Glass Piece"
                count={glassPiecesCount}
                current={current}
                add={() => {
                  this.setState({ current: glassPiecesCount }, () => {
                    push(INITIAL_VALUE);
                  });
                }}
                ss={() => {}}
                s={() => {
                  this.setState({ current: glassPiecesCount }, () => {
                    push({
                      ...INITIAL_VALUE,
                      details: glassPiece.details,
                      shape_type: glassPiece.shape_type,
                    });
                  });
                }}
                copy={() => {
                  this.setState({ current: glassPiecesCount }, () => {
                    push({ ...glassPiece });
                  });
                }}
                erase={() => this.setState({ showEraseAlert: true })}
                viewItem={this.viewItem}
              />
              {glassPiece ? (
                this.renderGlassPiece(
                  glassPiece,
                  current,
                  form,
                  push,
                  remove,
                  errors && errors.glassPieces ? errors.glassPieces[current] : {},
                  touched && touched.glassPieces ? touched.glassPieces[current] : {},
                )
              ) : (
                <Alert variant="light" style={{ paddingTop: 20, background: '#F8FBFF' }}>
                  <Alert.Heading style={{ textAlign: 'center' }}>
                    {' There are currently no Glass pieces, '}
                    <Alert.Link
                      onClick={() => {
                        this.setState({ current: glassPiecesCount }, () => {
                          push(INITIAL_VALUE);
                        });
                      }}
                    >
                      Click here
                    </Alert.Link>
                    {' to create a new'}
                  </Alert.Heading>
                </Alert>
              )}
              {/* Alerts */}
              {this.renderAlert(
                'Remove glass piece',
                'Are you sure that want remove the glass piece?',
                showEraseAlert,
                () => this.setState({ showEraseAlert: false }),
                'Remove',
                () => this.erase(remove),
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
