/* ALL GLASS DOOR - REPAIR*/
const type_a_single =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/A+Single+-Left+-+(TOP+%26+BOTTOM+PATCH).jpg';
const A_Stile_Single_Door_Opp =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/A+Single+-Right+-+(TOP+%26+BOTTOM+PATCH).jpg';
const type_a_pair =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/A+Pair+-+(TOP+%26+BOTTOM+PATCH).jpg';
const type_f_single =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/F+Single+-+Left+-+(TOP%2BBOTTOM+PATCH+with+BOTTOM+PATCH+LOCK).jpg';
const F_Stile_Single_Door_Opp =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/F+Single+-+Right+-+(TOP%2BBOTTOM+PATCH+with+BOTTOM+PATCH+LOCK).jpg';
const type_f_pair =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/F+Pair+-+(TOP%2BBOTTOM+PATCH+with+BOTTOM+PATCH+LOCK).jpg';
const type_bp_single =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/BP+Single+-+Left+-+(BOTTOM+RAIL+%26+TOP+PATCH).jpg';
const BP_Stile_Single_Door_Opp =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/BP+Single+-+Right+-+(BOTTOM+RAIL+%26+TOP+PATCH).jpg';
const type_bp_pair =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/BP+Pair+-+(BOTTOM+RAIL+%26+TOP+PATCH).jpg';
const type_p_single =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/P+Single+-Left+-+(TOP+%26+BOTTOM+RAILS).jpg';
const P_Stile_Single_Door_Opp =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/P+Single+-+Right+-+(TOP+%26+BOTTOM+RAILS).jpg';
const type_p_pair =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/P+Pair+-+(TOP+%26+BOTTOM+RAILS).jpg';
const type_wp_single =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/WP+Single+-+Left+-+(TOP+%26+BOTTOM+RAILS+with+Side+Rails).jpg';
const type_wp_single_r =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/WP+Single+-+Right+-+(TOP+%26+BOTTOM+RAILS+with+Side+Rails).jpg';
const type_wp_pair =
  'https://sag-assets-media.s3.amazonaws.com/Glass+Door+Part+Repair/WP+Pair+-+(TOP+%26+BOTTOM+RAILS+with+Side+Rails).jpg';
/* ALL GLASS DOOR - BROKEN GLASS*/
const a_left = 'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/a_left.png';
const a_right =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/a_right.png';
const f_left = 'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/f_left.png';
const f_right =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/f_right.png';
const bp_left =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/bp_left.png';
const bp_right =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/bp_right.png';
const P_LEFTorRIGHT =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/P_LEFTorRIGHT.png';
const WP_LEFTorRIGHT =
  'https://sag-assets-media.s3.amazonaws.com/ALL+GLASS+DOOR+-+BROKEN+GLASS/WP_LEFTorRIGHT.png';
/* STOREFRONT DOOR - PARTS REPAIR */
const sd_type_center_hung_single =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Center+Hung+-+Single+Left.jpg';
const sd_SFDoor_Center_Hung_opp =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Center+Hung+-+Single+Right.jpg';
const sd_SfDoor_Center_Hung_Pair =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Center+Hung+Pair.jpg';
const sd_type_offset_pivots_single =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Offset+Pivots.Butt+Hinge+-+Single+Left.jpg';
const sd_SFDoor_Offset_Pivot_opp =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Offset+Pivots.Butt+Hinge+-+Single+Right.jpg';
const sd_SfDoor_Center_Hung_Pair_two =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Offset+Pivots.Butt+Hinge+-+Pair.jpg';
const sd_type_continuous_hinge_single =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Continuous+Hinge+-+Single+Left.jpg';
const sd_SFDoor_Continuous_Hinge_opp =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Continuous+Hinge+-+Single+Right.jpg';
const sd_SFDoor_Continuous_Hinge_Pair =
  'https://sag-assets-media.s3.amazonaws.com/STOREFRONT+DOOR+-+Parts+Repair/SFA+-+Continuous+Hinge+-+Pair.jpg';
/* HOLLOW METAL DOOR - PARTS REPAIR */
const hmd_type_continuous_hinges_single =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+Continuous+Hinge+-+Single+Left.jpg';
const hmd_Door_Panic_opp_Cont =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+Continuous+Hinge+-+Single+Right.jpg';
const hmd_Door_Panic_Pair_cont_Hinge =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+Continuous+Hinge+-+Pair.jpg';
const hmd_type_butt_hinges_single =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+-+Butt+Hinge+-Single+Left.jpg';
const hmd_Door_Panic_opp_Offet_Pivot =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+-+Butt+Hinge+-Single+Right.jpg';
const hmd_Door_Panic_opp =
  'https://sag-assets-media.s3.amazonaws.com/Hollow/HMW+-+Butt+Hinge+-+Pair.jpg';

/* SHOWER DOOR - BROKEN GLASS */
const shd_Shower_Door_left_2_Hinges =
  'https://sag-assets-media.s3.amazonaws.com/Shower+Door+-+Broken+Glass/Shower+Style+Door+Only+-+2+HINGES+-+A.png';
const shd_Shower_Door_Right_2_Hinges =
  'https://sag-assets-media.s3.amazonaws.com/Shower+Door+-+Broken+Glass/Shower+Style+Door+Only+-+2+HINGES+-+B.png';
const shd_Shower_Door_Left_3_Hinges =
  'https://sag-assets-media.s3.amazonaws.com/Shower+Door+-+Broken+Glass/Shower+Style+Door+Only+-+3+HINGES+-+A.png';
const shd_Shower_Door_Right_3_Hinges =
  'https://sag-assets-media.s3.amazonaws.com/Shower+Door+-+Broken+Glass/Shower+Style+Door+Only+-+3+HINGES+-+B.png';
/* HANDLES */
import Handle_1 from 'assets/media/handles/Handle_1.png';
import Handle_1_opp from 'assets/media/handles/Handle_1_opp.png';
import Handle_2 from 'assets/media/handles/Handle_2.png';
import Handle_2_opp from 'assets/media/handles/Handle_2_opp.png';
import Handle_3 from 'assets/media/handles/Handle_3.png';
import Handle_3_opp from 'assets/media/handles/Handle_3_opp.png';
import Handle_4 from 'assets/media/handles/Handle_4.png';
import Handle_4_opp from 'assets/media/handles/Handle_4_opp.png';
import Handle_5 from 'assets/media/handles/Handle_5.png';
import Handle_5_opp from 'assets/media/handles/Handle_5_opp.png';
import Handle_6 from 'assets/media/handles/Handle_6.png';
import Handle_6_opp from 'assets/media/handles/Handle_6_opp.png';
import Handle_7 from 'assets/media/handles/Handle_7.png';
import Handle_7_opp from 'assets/media/handles/Handle_7_opp.png';
const TYPE_A_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AJ+1.jpg';
const TYPE_A_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AJ+2.jpg';
const TYPE_A_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AK+1.jpg';
const TYPE_A_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AK+2.jpg';
const TYPE_A_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+C+1.jpg';
const TYPE_A_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+C+2.jpg';
const TYPE_A_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+D+1.jpg';
const TYPE_A_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+D+2.jpg';
const TYPE_A_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+F+1.jpg';
const TYPE_A_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+F+2.jpg';
const TYPE_BP_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AJ++2.jpg';
const TYPE_BP_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AJ+1.jpg';
const TYPE_BP_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AK++1.jpg';
const TYPE_BP_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AK+2.jpg';
const TYPE_BP_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+C+1.jpg';
const TYPE_BP_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+C+2.jpg';
const TYPE_BP_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+D+1.jpg';
const TYPE_BP_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+D+2.jpg';
const TYPE_BP_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+F++2.jpg';
const TYPE_BP_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+F+1.jpg';
const TYPE_P_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AJ+1.jpg';
const TYPE_P_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AJ+2.jpg';
const TYPE_P_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AK+1.jpg';
const TYPE_P_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AK+2.jpg';
const TYPE_P_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+C+1.jpg';
const TYPE_P_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+C+2.jpg';
const TYPE_P_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+D+1.jpg';
const TYPE_P_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+D+2.jpg';
const TYPE_P_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+F+1.jpg';
const TYPE_P_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+F+2.jpg';

import {
  locationDoor,
  finishA,
  doorCloserB,
  doorCloserBNotes,
  topPivotC,
  topPivotCNotes,
  topPivotArmC,
  topPivotArmCNotes,
  patchFillingD,
  patchFillingDNotes,
  topInsertE,
  topInsertENotes,
  topInsertArmE,
  topInsertArmENotes,
  headerDoorStopF,
  headerDoorStopFNotes,
  typeOfGlassG,
  typeOfGlassGNotes,
  handlesH,
  handlesHNotes,
  panicDeviceI,
  panicDeviceINotes,
  edgeSealWeatherstripJ,
  edgeSealWeatherstripJNotes,
  bottomPivotArmK,
  bottomPivotArmKNotes,
  bottomInsertK,
  bottomPivotL,
  floorDoorCloserM,
  thresholdNStoreFront,
  thresholdNNotesStoreFront,
  thresholdN,
  bottomRailO,
  topRailO,
  patchLockP,
  bottomArmQ,
  bottomRailR,
  sideRailsS,
  widthGenA,
  heightGen,
  brandA,
  cocCloser,
  topPivotFrameCOne,
  topPivotDoorCTwo,
  surfaceMountedCloserD,
  offsetArmE,
  dropPlateF,
  bottompivotKStoreFront,
  bottomPivotKNotesStoreFront,
  spindleL,
  typeOfBeadsT,
  thumbturnU,
  keyCylinderV,
  lockW,
  pushPaddleX,
  topFlushBoltYOne,
  bottomFlushBoltYTwo,
  topPivotOffsetZOne,
  intermediatePivotZTwo,
  bottomPivotOffsetZThree,
  continuousHingeZ,
  bottomPivotLTopBottomRails,
  floorDoorCloserMTopBottomRails,
  typeOfGlassGStorefront,
  handlesHStorefront,
  panicDeviceIStorefront,
  weatherStrippingJ,
  offsetArmEPivotButt,
  offsetArmEContinuousHinge,
  partRepairExtraOne,
  partRepairExtraOneNote,
  partRepairExtraTwo,
  partRepairExtraTwoNote,
  partRepairExtraThree,
  partRepairExtraThreeNote,
  partRepairExtraFour,
  partRepairExtraFourNote,
  partRepairExtraFive,
  partRepairExtraFiveNote,
  /* HMD */
  hmdA,
  hmdANotes,
  hmdB,
  hmdBNotes,
  hmdD,
  hmdDNotes,
  hmdE,
  hmdENotes,
  hmdI,
  hmdINotes,
  hmdIOne,
  hmdIOneNotes,
  hmdJ,
  hmdJNotes,
  hmdL,
  hmdLNotes,
  hmdM,
  hmdMNotes,
  hmdN,
  hmdNNotes,
  hmdW,
  hmdWNotes,
  hmdWOne,
  hmdWOneNotes,
  hmdWTwo,
  hmdWTwoNotes,
  hmdYOne,
  hmdYOneNotes,
  hmdYTwo,
  hmdYTwoNotes,
  hmdZ,
  hmdZNotes,
  /* OP 4-6 */
  hmdZOne,
  hmdZTwo,
  hmdZThree,

  /* HANDLES */
  rdHandleA,
  rdHandleB,
  rdHandleC,
  rdHandleD,
  rdHandleE,
  rdHandleF,
  rdHandleG,
  rdHandleH,
  rdHandleI,
  //NEW ALL GLASS DOOR - BROKEN GLASS
  BGA,
  BGB,
  BGC,
  BGD,
  BGE,
  BGF,
  BGG,
  BGH,
  BGI,
  BGJ,
  BGK,
  BGL,
  BGLOCATION,
  BGType,
  BGCLOATING,
  BGMetal,
  BGPatchF,
  BGPatchL,
  typeSet,
  BGHO,
  BGWS,
  hmldLNotes,
  hmdZTwoNotes,
  hmdZOneNotes,
  hmdZThreeNotes,
  finishANotes,
  bottomInsertKNotes,
  bottomPivotLNotes,
  floorDoorCloserMNotes,
  thresholdNNotes,
  patchLockPNotes,
  bottomRailONotes,
  bottomArmQNotes,
  topRailONotes,
  bottomRailRNotes,
  bottomPivotLTopBottomRailsNotes,
  floorDoorCloserMTopBottomRailsNotes,
  sideRailsSNotes,
  weatherStrippingJNotes,
  brandASF,
  brandANotesSF,
  spindleLNotes,
  cocCloserNotes,
  topPivotFrameCOneNotes,
  topPivotDoorCTwoNotes,
  surfaceMountedCloserDNotes,
  thumbturnUNotes,
  offsetArmENotes,
  keyCylinderVNotes,
  dropPlateFNotes,
  lockWNotes,
  typeOfGlassGStorefrontNotes,
  pushPaddleXNotes,
  handlesHStorefrontNotes,
  topFlushBoltYOneNotes,
  panicDeviceIStorefrontNotes,
  bottomFlushBoltYTwoNotes,
  offsetArmEPivotButtNotes,
  topPivotOffsetZOneNotes,
  intermediatePivotZTwoNotes,
  bottomPivotOffsetZThreeNotes,
  offsetArmEContinuousHingeNotes,
  continuousHingeZNotes,
  partFieldEmpty,
  floorcloserMStorefront,
  floorcloserMStorefrontNotes,
  typeOfBeadsTSF,
  typeOfBeadsTNotesSF,
  thresholdNAllGlass,
  thresholdNAllGlassNotes,
} from './inputs';

/* -----------------------------REPAIR DOORS - FIELDS */

export const DoorCategory = [
  { value: 'gdr', label: 'All Glass Door - Parts Repair' },
  { value: 'gdn', label: 'All Glass Door - Broken Glass' },
  { value: 'sfd', label: 'Storefront Door - Parts Repair' },
  { value: 'wmd', label: 'Hollow Metal/Wood Door - Parts Repair' },
  { value: 'sdbg', label: 'Shower Door - Broken Glass' },
];

/* ALL GLASS DOOR - PARTS REPAIR */
export const allGlassRepair = [
  { value: 'a-single', label: 'A Single, Left - (TOP & BOTTOM PATCH)' },
  { value: 'a-single-opp', label: 'A Single, Right - (TOP & BOTTOM PATCH)' },
  { value: 'a-pair', label: 'A Pair - (TOP & BOTTOM PATCH)' },
  { value: 'f-single', label: 'F Single, Left - (TOP/BOTTOM PATCH with BOTTOM PATCH LOCK)' },
  { value: 'f-single-opp', label: 'F Single, Right - (TOP/BOTTOM PATCH with BOTTOM PATCH LOCK)' },
  { value: 'f-pair', label: 'F Pair – (TOP/BOTTOM PATCH with BOTTOM PATCH LOCK)' },
  { value: 'bp-single', label: 'BP Single, Left - (BOTTOM RAIL & TOP PATCH)' },
  { value: 'bp-single-opp', label: 'BP Single, Right - (BOTTOM RAIL & TOP PATCH)' },
  { value: 'bp-pair', label: 'BP Pair - (BOTTOM RAIL & TOP PATCH)' },
  { value: 'p-single', label: 'P Single, Left - (TOP & BOTTOM RAILS)' },
  { value: 'p-single-opp', label: 'P Single, Right - (TOP & BOTTOM RAILS)' },
  { value: 'p-pair', label: 'P Pair - (TOP & BOTTOM RAILS)' },
  { value: 'wp-single', label: 'WP Single, Left – (TOP & BOTTOM RAILS with Side Rails)' },
  { value: 'wp-single-opp', label: 'WP Single, Right - (TOP & BOTTOM RAILS with Side Rails) ' },
  { value: 'wp-pair', label: 'WP Pair - (TOP & BOTTOM RAILS with Side Rails)' },
];

export const allGlassRepairFields = {
  'a-single': {
    picture: type_a_single,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'a-single-opp': {
    picture: A_Stile_Single_Door_Opp,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'a-pair': {
    picture: type_a_pair,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'f-single': {
    picture: type_f_single,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      patchLockP,
      patchLockPNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'f-single-opp': {
    picture: F_Stile_Single_Door_Opp,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      patchLockP,
      patchLockPNotes,
      partFieldEmpty,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'f-pair': {
    picture: type_f_pair,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      patchLockP,
      patchLockPNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'bp-single': {
    picture: type_bp_single,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      bottomRailO,
      bottomRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'bp-single-opp': {
    picture: BP_Stile_Single_Door_Opp,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      bottomRailO,
      bottomRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'bp-pair': {
    picture: type_bp_pair,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotC,
      topPivotCNotes,
      patchFillingD,
      patchFillingDNotes,
      topInsertE,
      topInsertENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomInsertK,
      bottomInsertKNotes,
      bottomPivotL,
      bottomPivotLNotes,
      floorDoorCloserM,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      bottomRailO,
      bottomRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'p-single': {
    picture: type_p_single,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'p-single-opp': {
    picture: P_Stile_Single_Door_Opp,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'p-pair': {
    picture: type_p_pair,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      edgeSealWeatherstripJ,
      edgeSealWeatherstripJNotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'wp-single': {
    picture: type_wp_single,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLTopBottomRailsNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMTopBottomRailsNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      sideRailsS,
      sideRailsSNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'wp-single-opp': {
    picture: type_wp_single_r,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLTopBottomRailsNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMTopBottomRailsNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      sideRailsS,
      sideRailsSNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'wp-pair': {
    picture: type_wp_pair,
    fields: [
      locationDoor,
      widthGenA,
      finishA,
      finishANotes,
      doorCloserB,
      doorCloserBNotes,
      topPivotArmC,
      topPivotArmCNotes,
      topInsertArmE,
      topInsertArmENotes,
      headerDoorStopF,
      headerDoorStopFNotes,
      typeOfGlassG,
      typeOfGlassGNotes,
      handlesH,
      handlesHNotes,
      panicDeviceI,
      panicDeviceINotes,
      bottomPivotArmK,
      bottomPivotArmKNotes,
      bottomPivotLTopBottomRails,
      bottomPivotLTopBottomRailsNotes,
      floorDoorCloserMTopBottomRails,
      floorDoorCloserMTopBottomRailsNotes,
      thresholdNAllGlass,
      thresholdNAllGlassNotes,
      topRailO,
      topRailONotes,
      patchLockP,
      patchLockPNotes,
      bottomArmQ,
      bottomArmQNotes,
      bottomRailR,
      bottomRailRNotes,
      sideRailsS,
      sideRailsSNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
};

/* ALL GLASS DOOR - BROKEN GLASS  */
export const allGlassBroken = [
  { value: 'a-left', label: 'A - Left' },
  { value: 'a-right', label: 'A - Right' },
  { value: 'f-left', label: 'F - Left' },
  { value: 'f-right', label: 'F - Right' },
  { value: 'bp-left', label: 'BP - Left' },
  { value: 'bp-right', label: 'BP - Right' },
  { value: 'p-left', label: 'P - Left' },
  { value: 'p-right', label: 'P - Right' },
  { value: 'wp-left', label: 'WP - Left' },
  { value: 'wp-right', label: 'WP - Right' },
];

export const allGlassBrokenFields = {
  'a-left': {
    picture: a_left,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'a-right': {
    picture: a_right,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'f-left': {
    picture: f_left,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'f-right': {
    picture: f_right,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'bp-left': {
    picture: bp_left,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'bp-right': {
    picture: bp_right,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'p-left': {
    picture: P_LEFTorRIGHT,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'p-right': {
    picture: P_LEFTorRIGHT,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'wp-left': {
    picture: WP_LEFTorRIGHT,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
  'wp-right': {
    picture: WP_LEFTorRIGHT,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGMetal,
      BGPatchF,
      BGPatchL,
      typeSet,
      BGHO,
      BGWS,
    ],
  },
};

/* STOREFRONT DOOR - PARTS REPAIR */
export const storefrontRepair = [
  { value: 'center-hung', label: 'Center Hung-Single Left' },
  { value: 'center-hung-opp', label: 'Center Hung-Single Right' },
  { value: 'center-hung-pair', label: 'Center Hung Pair' },
  { value: 'offset-pivots', label: 'Offset Pivots/Butt Hinge-Single Left' },
  { value: 'offset-pivots-opp', label: 'Offset Pivots/Butt Hinge-Single Right' },
  { value: 'offset-pivots-pair', label: 'Offset Pivots/Butt Hinge-Single pair' },
  { value: 'continuous-hinge', label: 'Continuous Hinge-Single Left' },
  { value: 'continuous-hinge-opp', label: 'Continuous Hinge-Single Right' },
  { value: 'continuous-hinge-pair', label: 'Continuous pair' },
];

export const storefrontRepairFields = {
  'center-hung': {
    picture: sd_type_center_hung_single,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      topPivotFrameCOne,
      topPivotFrameCOneNotes,
      topPivotDoorCTwo,
      topPivotDoorCTwoNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmE,
      offsetArmENotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      bottompivotKStoreFront,
      bottomPivotKNotesStoreFront,
      spindleL,
      spindleLNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'center-hung-opp': {
    picture: sd_SFDoor_Center_Hung_opp,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      topPivotFrameCOne,
      topPivotFrameCOneNotes,
      topPivotDoorCTwo,
      topPivotDoorCTwoNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmE,
      offsetArmENotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      bottompivotKStoreFront,
      bottomPivotKNotesStoreFront,
      spindleL,
      spindleLNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'center-hung-pair': {
    picture: sd_SfDoor_Center_Hung_Pair,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      topPivotFrameCOne,
      topPivotFrameCOneNotes,
      topPivotDoorCTwo,
      topPivotDoorCTwoNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmE,
      offsetArmENotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      bottompivotKStoreFront,
      bottomPivotKNotesStoreFront,
      spindleL,
      spindleLNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'offset-pivots': {
    picture: sd_type_offset_pivots_single,
    fields: [
      locationDoor,
      widthGenA,
      heightGen,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEPivotButt,
      offsetArmEPivotButtNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      topPivotOffsetZOne,
      topPivotOffsetZOneNotes,
      intermediatePivotZTwo,
      intermediatePivotZTwoNotes,
      bottomPivotOffsetZThree,
      bottomPivotOffsetZThreeNotes,

      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'offset-pivots-opp': {
    picture: sd_SFDoor_Offset_Pivot_opp,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEPivotButt,
      offsetArmEPivotButtNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      topPivotOffsetZOne,
      topPivotOffsetZOneNotes,
      intermediatePivotZTwo,
      intermediatePivotZTwoNotes,
      bottomPivotOffsetZThree,
      bottomPivotOffsetZThreeNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'offset-pivots-pair': {
    picture: sd_SfDoor_Center_Hung_Pair_two,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEPivotButt,
      offsetArmEPivotButtNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      topPivotOffsetZOne,
      topPivotOffsetZOneNotes,
      intermediatePivotZTwo,
      intermediatePivotZTwoNotes,
      bottomPivotOffsetZThree,
      bottomPivotOffsetZThreeNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'continuous-hinge': {
    picture: sd_type_continuous_hinge_single,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEContinuousHinge,
      offsetArmEContinuousHingeNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      continuousHingeZ,
      continuousHingeZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'continuous-hinge-opp': {
    picture: sd_SFDoor_Continuous_Hinge_opp,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEContinuousHinge,
      offsetArmEContinuousHingeNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      continuousHingeZ,
      continuousHingeZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'continuous-hinge-pair': {
    picture: sd_SFDoor_Continuous_Hinge_Pair,
    fields: [
      locationDoor,
      widthGenA,
      brandASF,
      brandANotesSF,
      cocCloser,
      cocCloserNotes,
      surfaceMountedCloserD,
      surfaceMountedCloserDNotes,
      offsetArmEContinuousHinge,
      offsetArmEContinuousHingeNotes,
      dropPlateF,
      dropPlateFNotes,
      typeOfGlassGStorefront,
      typeOfGlassGStorefrontNotes,
      handlesHStorefront,
      handlesHStorefrontNotes,
      panicDeviceIStorefront,
      panicDeviceIStorefrontNotes,
      weatherStrippingJ,
      weatherStrippingJNotes,
      floorcloserMStorefront,
      floorcloserMStorefrontNotes,
      thresholdNStoreFront,
      thresholdNNotesStoreFront,
      typeOfBeadsTSF,
      typeOfBeadsTNotesSF,
      thumbturnU,
      thumbturnUNotes,
      keyCylinderV,
      keyCylinderVNotes,
      lockW,
      lockWNotes,
      pushPaddleX,
      pushPaddleXNotes,
      topFlushBoltYOne,
      topFlushBoltYOneNotes,
      bottomFlushBoltYTwo,
      bottomFlushBoltYTwoNotes,
      continuousHingeZ,
      continuousHingeZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
};

/* HOLLOW METAL DOOR - PARTS REPAIR */

export const hollowMetalDoorRepair = [
  { value: 'continuous-hinge-left', label: 'Continuous Hinge-Single Left' },
  { value: 'continuous-hinge-right', label: 'Continuous Hinge-Single Right' },
  { value: 'continuous-hinge-pair', label: 'Continuous Hinge-Pair' },
  { value: 'butt-hinge-left', label: 'Butt Hinge-Single Left' },
  { value: 'butt-hinge-opp', label: 'Butt Hinge-Single Right' },
  { value: 'butt-hinge-pair', label: 'Butt Hinge-Pair' },
];

export const hollowMetalDoorRepairFields = {
  'continuous-hinge-left': {
    picture: hmd_type_continuous_hinges_single,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'continuous-hinge-right': {
    picture: hmd_Door_Panic_opp_Cont,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'continuous-hinge-pair': {
    picture: hmd_Door_Panic_Pair_cont_Hinge,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'butt-hinge-left': {
    picture: hmd_type_butt_hinges_single,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      hmdZOne,
      hmdZOneNotes,
      hmdZTwo,
      hmdZTwoNotes,
      /* OP 4-6 */
      hmdZThree,
      hmdZThreeNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'butt-hinge-opp': {
    picture: hmd_Door_Panic_opp_Offet_Pivot,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      hmdZOne,
      hmdZOneNotes,
      hmdZTwo,
      hmdZTwoNotes,
      /* OP 4-6 */
      hmdZThree,
      hmdZThreeNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
  'butt-hinge-pair': {
    picture: hmd_Door_Panic_opp,
    fields: [
      locationDoor,
      widthGenA,
      hmdA,
      hmdANotes,
      hmdB,
      hmdBNotes,
      hmdD,
      hmdDNotes,
      hmdE,
      hmdENotes,
      hmdI,
      hmdINotes,
      hmdIOne,
      hmdIOneNotes,
      hmdJ,
      hmdJNotes,
      hmdL,
      hmdLNotes,
      hmdM,
      hmdMNotes,
      hmdN,
      hmdNNotes,
      hmdW,
      hmdWNotes,
      hmdWOne,
      hmdWOneNotes,
      hmdWTwo,
      hmdWTwoNotes,
      hmdYOne,
      hmdYOneNotes,
      hmdYTwo,
      hmdYTwoNotes,
      hmdZ,
      hmdZNotes,
      hmdZOne,
      hmdZOneNotes,
      hmdZTwo,
      hmdZTwoNotes,
      /* OP 4-6 */
      hmdZThree,
      hmdZThreeNotes,
      partFieldEmpty,
      partRepairExtraOne,
      partRepairExtraOneNote,
      partRepairExtraTwo,
      partRepairExtraTwoNote,
      partRepairExtraThree,
      partRepairExtraThreeNote,
      partRepairExtraFour,
      partRepairExtraFourNote,
      partRepairExtraFive,
      partRepairExtraFiveNote,
    ],
  },
};

/* SHOWER DOOR - BROKEN GLASS */

export const showerDoorBroken = [
  { value: 'left-2-hing', label: 'LEFT (2 Hinge)' },
  { value: 'right-2-hing', label: 'RIGHT (2 Hinge)' },
  { value: 'left-3-hing', label: 'LEFT (3 Hinge)' },
  { value: 'right-3-hing', label: 'RIGHT (3 Hinge)' },
];

export const showerDoorBrokenFields = {
  'left-2-hing': {
    picture: shd_Shower_Door_left_2_Hinges,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGCLOATING,
      BGHO,
      BGWS,
    ],
  },
  'right-2-hing': {
    picture: shd_Shower_Door_Right_2_Hinges,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGCLOATING,
      BGHO,
      BGWS,
    ],
  },
  'left-3-hing': {
    picture: shd_Shower_Door_Left_3_Hinges,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGCLOATING,
      BGHO,
      BGWS,
    ],
  },
  'right-3-hing': {
    picture: shd_Shower_Door_Right_3_Hinges,
    fields: [
      BGA,
      BGB,
      BGC,
      BGD,
      BGE,
      BGF,
      BGG,
      BGH,
      BGI,
      BGJ,
      BGK,
      BGL,
      BGLOCATION,
      BGType,
      BGCLOATING,
      BGHO,
      BGWS,
    ],
  },
};

/* HANDLES */
export const RepairDoorsHandle = [
  {
    value: 'pull-handle-up-push-bar-3-holes',
    label: 'A Pull Handle, UP &  Push Bar (3 Holes)',
  },
  {
    value: 'pull-handle-up-push-bar-3-holes-opp',
    label: 'B Pull Handle, UP &  Push Bar (3 Holes)',
  },
  {
    value: 'pull-handle-down-push-bar-3-holes',
    label: 'A Pull Handle, DOWN &  Push Bar (3 Holes)',
  },
  {
    value: 'pull-handle-down-push-bar-3-holes-opp',
    label: 'B Pull Handle, DOWN &  Push Bar (3 Holes)',
  },
  { value: 'pull-handle-2-holes', label: 'A Pull Handle (2 Holes)' },
  { value: 'pull-handle-2-holes-opp', label: 'B Pull Handle (2 Holes)' },
  { value: 'pull-bar-2-holes', label: 'A Push Bar (2 Holes)' },
  { value: 'pull-bar-2-holes-opp', label: 'B Push Bar (2 Holes)' },
  { value: 'ladder-pull-3-holes', label: 'A Ladder Pull (3 Holes)' },
  { value: 'ladder-pull-3-holes-opp', label: 'B Ladder Pull (3 Holes)' },
  { value: 'ladder-pull-2-holes', label: 'A Ladder Pull (2 Holes)' },
  { value: 'ladder-pull-2-holes-opp', label: 'B Ladder Pull (2 Holes)' },
  { value: 'single-pull-1-hole', label: 'A Single Pull (1 Hole)' },
  { value: 'single-pull-1-hole-opp', label: 'B Single Pull (1 Hole)' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AJ-1', label: 'PanicDeadbolt TYPE A Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AJ-2', label: 'PanicDeadbolt TYPE A Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AK-1', label: 'PanicDeadbolt TYPE A Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AK-2', label: 'PanicDeadbolt TYPE A Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-C-1', label: 'PanicDeadbolt TYPE A Handle C 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-C-2', label: 'PanicDeadbolt TYPE A Handle C 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-D-1', label: 'PanicDeadbolt TYPE A Handle D 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-D-2', label: 'PanicDeadbolt TYPE A Handle D 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-F-1', label: 'PanicDeadbolt TYPE A Handle F 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-F-2', label: 'PanicDeadbolt TYPE A Handle F 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AJ-1', label: 'PanicDeadbolt TYPE BP Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AJ-2', label: 'PanicDeadbolt TYPE BP Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AK-1', label: 'PanicDeadbolt TYPE BP Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AK-2', label: 'PanicDeadbolt TYPE BP Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-C-1', label: 'PanicDeadbolt TYPE BP Handle C 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-C-2', label: 'PanicDeadbolt TYPE BP Handle C 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-D-1', label: 'PanicDeadbolt TYPE BP Handle D 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-D-2', label: 'PanicDeadbolt TYPE BP Handle D 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-F-1', label: 'PanicDeadbolt TYPE BP Handle F 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-F-2', label: 'PanicDeadbolt TYPE BP Handle F 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AJ-1', label: 'PanicDeadbolt TYPE P Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AJ-2', label: 'PanicDeadbolt TYPE P Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AK-1', label: 'PanicDeadbolt TYPE P Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AK-2', label: 'PanicDeadbolt TYPE P Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-C-1', label: 'PanicDeadbolt TYPE P Handle C 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-C-2', label: 'PanicDeadbolt TYPE P Handle C 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-D-1', label: 'PanicDeadbolt TYPE P Handle D 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-D-2', label: 'PanicDeadbolt TYPE P Handle D 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-F-1', label: 'PanicDeadbolt TYPE P Handle F 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-F-2', label: 'PanicDeadbolt TYPE P Handle F 2' },
];

export const RepairDoorsHandleFields = {
  'pull-handle-up-push-bar-3-holes': {
    picture: Handle_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-handle-up-push-bar-3-holes-opp': {
    picture: Handle_1_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-handle-down-push-bar-3-holes': {
    picture: Handle_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-handle-down-push-bar-3-holes-opp': {
    picture: Handle_2_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-handle-2-holes': {
    picture: Handle_3,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-handle-2-holes-opp': {
    picture: Handle_3_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-bar-2-holes': {
    picture: Handle_4,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'pull-bar-2-holes-opp': {
    picture: Handle_4_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'ladder-pull-3-holes': {
    picture: Handle_5,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'ladder-pull-3-holes-opp': {
    picture: Handle_5_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'ladder-pull-2-holes': {
    picture: Handle_6,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'ladder-pull-2-holes-opp': {
    picture: Handle_6_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'single-pull-1-hole': {
    picture: Handle_7,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'single-pull-1-hole-opp': {
    picture: Handle_7_opp,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AJ-1': {
    picture: TYPE_A_Handle_AJ_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AJ-2': {
    picture: TYPE_A_Handle_AJ_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AK-1': {
    picture: TYPE_A_Handle_AK_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AK-2': {
    picture: TYPE_A_Handle_AK_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-C-1': {
    picture: TYPE_A_Handle_C_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-C-2': {
    picture: TYPE_A_Handle_C_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-D-1': {
    picture: TYPE_A_Handle_D_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-D-2': {
    picture: TYPE_A_Handle_D_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-F-1': {
    picture: TYPE_A_Handle_F_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-F-2': {
    picture: TYPE_A_Handle_F_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AJ-1': {
    picture: TYPE_BP_Handle_AJ_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AJ-2': {
    picture: TYPE_BP_Handle_AJ_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AK-1': {
    picture: TYPE_BP_Handle_AK_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AK-2': {
    picture: TYPE_BP_Handle_AK_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-C-1': {
    picture: TYPE_BP_Handle_C_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-C-2': {
    picture: TYPE_BP_Handle_C_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-D-1': {
    picture: TYPE_BP_Handle_D_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-D-2': {
    picture: TYPE_BP_Handle_D_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-F-1': {
    picture: TYPE_BP_Handle_F_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-F-2': {
    picture: TYPE_BP_Handle_F_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AJ-1': {
    picture: TYPE_P_Handle_AJ_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AJ-2': {
    picture: TYPE_P_Handle_AJ_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AK-1': {
    picture: TYPE_P_Handle_AK_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AK-2': {
    picture: TYPE_P_Handle_AK_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-C-1': {
    picture: TYPE_P_Handle_C_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-C-2': {
    picture: TYPE_P_Handle_C_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-D-1': {
    picture: TYPE_P_Handle_D_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-D-2': {
    picture: TYPE_P_Handle_D_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-F-1': {
    picture: TYPE_P_Handle_F_1,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-F-2': {
    picture: TYPE_P_Handle_F_2,
    fields: [
      rdHandleA,
      rdHandleB,
      rdHandleC,
      rdHandleD,
      rdHandleE,
      rdHandleF,
      rdHandleG,
      rdHandleH,
      rdHandleI,
    ],
  },
};

export const DoorCategoryTypes = {
  gdr: { types: allGlassRepair, fields: allGlassRepairFields },
  gdn: { types: allGlassBroken, fields: allGlassBrokenFields },
  sfd: { types: storefrontRepair, fields: storefrontRepairFields },
  wmd: { types: hollowMetalDoorRepair, fields: hollowMetalDoorRepairFields },
  sdbg: { types: showerDoorBroken, fields: showerDoorBrokenFields },
};
