/* -------------------------------------DOOR TYPE OPTIONS */
export const detailsOnly = [
  { value: 'info-only', label: 'Info Only ' },
  { value: 'adjust-align', label: 'Adjust/Align' },
  { value: 'replace', label: 'Replace' },
];

export const paintFinish = [
  { value: 'Aluminum', label: 'Aluminum' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Custom', label: 'Custom' },
  { value: 'Clear Anodized (Standard  .4 mils)', label: 'Clear Anodized (Standard  .4 mils)' },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Polished Chrome (US26)', label: 'Polished Chrome (US26)' },
  { value: 'Satin Chrome (US26D)', label: 'Satin Chrome (US26D)' },
  { value: 'Polished Stainless Steel (US32)', label: 'Polished Stainless Steel (US32)' },
  { value: 'Satin Stainless Steel (US32D)', label: 'Satin Stainless Steel (US32D)' },
  { value: 'Satin Nickel (US15)', label: 'Satin Nickel (US15)' },
  { value: 'Oxidized Satin Nickel (US15A)', label: 'Oxidized Satin Nickel (US15A)' },
  { value: 'Polished Brass (US3)', label: 'Polished Brass (US3)' },
  { value: 'Satin Brass (US4)', label: 'Satin Brass (US4)' },
  { value: 'Oxidized Satin Brass (US5)', label: 'Oxidized Satin Brass (US5)' },
  { value: 'Polished Bronze (US9)', label: 'Polished Bronze (US9)' },
  { value: 'Satin Bronze (US10)', label: 'Satin Bronze (US10)' },
  { value: 'Oil Rubbed Bronze (US10B)', label: 'Oil Rubbed Bronze (US10B)' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const doorCloser = [
  { value: 'REARCH PART', label: 'REARCH PART' },
  { value: 'REFER TO INSTRUCTION', label: 'REFER TO INSTRUCTION' },
  { value: 'Rixson 800 90 HO COC Closer', label: 'Rixson 800 90 HO COC Closer' },
  { value: 'Rixson 800 90 NHO', label: 'Rixson 800 90 NHO' },
  { value: 'Rixson 800 105 NHO', label: 'Rixson 800 105 NHO' },
  { value: 'Dorma RTS88 105 NHO SZ3', label: 'Dorma RTS88 105 NHO SZ3' },
  { value: 'Dorma RTS88 90 NHO SZ3', label: 'Dorma RTS88 90 NHO SZ3' },
  { value: 'Dorma RTS88 105 HO SZ3', label: 'Dorma RTS88 105 HO SZ3' },
  { value: 'Dorma RTS88 90 HO SZ3', label: 'Dorma RTS88 90 HO SZ3' },
  {
    value: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
    label: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
  },
  {
    value: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
  },
  { value: 'Dorma RTS88 105 NHO BFI (Interior) ', label: 'Dorma RTS88 105 NHO BFI (Interior) ' },
  { value: 'Dorma RTS88 105 HO BFI (Interior) ', label: 'Dorma RTS88 105 HO BFI (Interior) ' },
  { value: 'Dorma RTS88 105 NHO BFE (Exterior) ', label: 'Dorma RTS88 105 NHO BFE (Exterior) ' },
  { value: 'Dorma RTS88 105 HO BFE (Exterior) ', label: 'Dorma RTS88 105 HO BFE (Exterior) ' },
  {
    value: 'Dorma RTS88 105 NHO BFI (Interior) (Extended Spindle)',
    label: 'Dorma RTS88 105 NHO BFI (Interior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO BFI (Interior) (Extended Spindle)',
    label: 'Dorma RTS88 105 HO BFI (Interior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 NHO BFE (Exterior) (Extended Spindle)',
    label: 'Dorma RTS88 105 NHO BFE (Exterior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO BFE (Exterior) (Extended Spindle) ',
    label: 'Dorma RTS88 105 HO BFE (Exterior) (Extended Spindle) ',
  },
  { value: 'Jackson 105 NHO', label: 'Jackson 105 NHO' },
  { value: 'Jackson 90 NHO', label: 'Jackson 90 NHO' },
  { value: 'Jackson 105 HO', label: 'Jackson 105 HO' },
  { value: 'Jackson 90 HO', label: 'Jackson 90 HO' },
  { value: 'Jackson 105 NHO Extended Spindle', label: 'Jackson 105 NHO Extended Spindle' },
  { value: 'Jackson 90 NHO Extended Spindle', label: 'Jackson 90 NHO Extended Spindle' },
  { value: 'Jackson 105 HO Extended Spindle', label: 'Jackson 105 HO Extended Spindle' },
  { value: 'Jackson 90 HO Extended Spindle', label: 'Jackson 90 HO Extended Spindle' },
  {
    value: 'Jackson 105 NHO 3 Valve with Back Check',
    label: 'Jackson 105 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson 90 NHO 3 Valve with Back Check',
    label: 'Jackson 90 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
  },
  {
    value: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
  },
  { value: 'International 230 105 HO', label: 'International 230 105 HO' },
  { value: 'International 231 105 NHO', label: 'International 231 105 NHO' },
  { value: 'International 232 90 HO', label: 'International 232 90 HO' },
  { value: 'International 233 90 HO', label: 'International 233 90 HO' },
  { value: 'Sentinel 3031 105 NHO', label: 'Sentinel 3031 105 NHO' },
  { value: 'Sentinel 3030 105 HO', label: 'Sentinel 3030 105 HO' },
  { value: 'Sentinel 3033 90 NHO', label: 'Sentinel 3033 90 NHO' },
  { value: 'Sentinel 3032 90 HO', label: 'Sentinel 3032 90 HO' },
  { value: 'Global TC7031 105 NHO', label: 'Global TC7031 105 NHO' },
  { value: 'Global TC7030 105 HO', label: 'Global TC7030 105 HO' },
  { value: 'Global TC7033 90 NHO', label: 'Global TC7033 90 NHO' },
  { value: 'Global TC7032 90 HO', label: 'Global TC7032 90 HO' },
  { value: 'LCN 2030 Series R/H Aluminum', label: 'LCN 2030 Series R/H Aluminum' },
  { value: 'LCN 2030 Series R/H Bronze', label: 'LCN 2030 Series R/H Bronze' },
  { value: 'LCN 2030 Series L/H Aluminum', label: 'LCN 2030 Series L/H Aluminum' },
  { value: 'LCN 2030 Series L/H Bronze', label: 'LCN 2030 Series L/H Bronze' },
  { value: 'LCN 2010 Series R/H Aluminum', label: 'LCN 2010 Series R/H Aluminum' },
  { value: 'LCN 2010 Series R/H Bronze', label: 'LCN 2010 Series R/H Bronze' },
  { value: 'LCN 2010 Series L/H Aluminum', label: 'LCN 2010 Series L/H Aluminum' },
  { value: 'LCN 2010 Series L/H Bronze', label: 'LCN 2010 Series L/H Bronze' },
  { value: 'LCN 3031 Series R/H Aluminum', label: 'LCN 3031 Series R/H Aluminum' },
  { value: 'LCN 3031 Series R/H Bronze', label: 'LCN 3031 Series R/H Bronze' },
  { value: 'LCN 3031 Series L/H Aluminum', label: 'LCN 3031 Series L/H Aluminum' },
  { value: 'LCN 3031 Series L/H Bronze', label: 'LCN 3031 Series L/H Bronze' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const topPivot = [
  { value: 'Rixson Walking Beam Pivot', label: 'Rixson Walking Beam Pivot' },
  { value: 'Dorma Walking Bean Pivot 5/8"', label: 'Dorma Walking Bean Pivot 5/8"' },
  { value: 'Dorma Walking Bean Pivot 15mm', label: 'Dorma Walking Bean Pivot 15mm' },
  { value: 'International Walking Beam Pivot', label: 'International Walking Beam Pivot' },
  {
    value: 'Dorma patch fitting insert for top Pivot Male',
    label: 'Dorma patch fitting insert for top Pivot Male',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const patchFilling = [
  { value: 'Dorma Patch Fitting Set for Door', label: 'Dorma Patch Fitting Set for Door' },
  { value: 'Dorma Patch Fitting Covers For Door', label: 'Dorma Patch Fitting Covers For Door' },
  { value: 'Casma Patch Fitting Set for Door', label: 'Casma Patch Fitting Set for Door' },
  { value: 'Casma Patch Fitting Covers For Door', label: 'Casma Patch Fitting Covers For Door' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const topInsert = [
  {
    value: 'Dorma patch fitting insert for RTS Closer',
    label: 'Dorma patch fitting insert for RTS Closer',
  },
  {
    value: 'Dorma patch fitting insert for top Pivot Female',
    label: 'Dorma patch fitting insert for top Pivot Female',
  },
  {
    value: 'Casma patch fitting insert for COC Closer',
    label: 'Casma patch fitting insert for COC Closer',
  },
  {
    value: 'Casma patch fitting insert for top Pivot Female',
    label: 'Casma patch fitting insert for top Pivot Female',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const headerDoorStop = [
  { value: '3" Square Door Stop', label: '3" Square Door Stop' },
  { value: '6" Square Door Stop', label: '6" Square Door Stop' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const doorGlassType = [
  { value: '3/8" Clear Tempered Glass', label: '3/8" Clear Tempered Glass' },
  { value: '1/2" Clear Tempered Glass', label: '1/2" Clear Tempered Glass' },
  { value: '3/4" Clear Tempered Glass', label: '3/4" Clear Tempered Glass' },
  { value: '3/8" Low Iron Tempered Glass', label: '3/8" Low Iron Tempered Glass' },
  { value: '1/2" Low Iron Tempered Glass', label: '1/2" Low Iron Tempered Glass' },
  { value: '3/4" Low Iron Tempered Glass', label: '3/4" Low Iron Tempered Glass' },
  { value: '3/8" Bronze Tempered Glass', label: '3/8" Bronze Tempered Glass' },
  { value: '1/2" Bronze Tempered Glass', label: '1/2" Bronze Tempered Glass' },
  { value: '3/4" Bronze Tempered Glass', label: '3/4" Bronze Tempered Glass' },
  {
    value: '3/8" Clear Laminated Tempered Glass',
    label: '3/8" Clear Laminated Tempered Glass',
  },
  {
    value: '1/2" Clear Laminated Tempered Glass',
    label: '1/2" Clear Laminated Tempered Glass',
  },
  {
    value: '3/4" Clear Laminated Tempered Glass',
    label: '3/4" Clear Laminated Tempered Glass',
  },
  {
    value: '3/8" Low Iron Laminated Tempered Glass',
    label: '3/8" Low Iron Laminated Tempered Glass',
  },
  {
    value: '1/2" Low Iron Laminated Tempered Glass',
    label: '1/2" Low Iron Laminated Tempered Glass',
  },
  {
    value: '3/4" Low Iron Laminated Tempered Glass',
    label: '3/4" Low Iron Laminated Tempered Glass',
  },
  {
    value: '3/8" Bronze Laminated Tempered Glass',
    label: '3/8" Bronze Laminated Tempered Glass',
  },
  {
    value: '1/2" Bronze Laminated Tempered Glass',
    label: '1/2" Bronze Laminated Tempered Glass',
  },
  {
    value: '3/4" Bronze Laminated Tempered Glass',
    label: '3/4" Bronze Laminated Tempered Glass',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const doorHandles = [
  {
    value: 'Pull Handle, UP & Push Bar (3 Holes)',
    label: 'Pull Handle, UP & Push Bar (3 Holes)',
  },
  {
    value: 'Pull Handle, DOWN & Push Bar (3 Holes)',
    label: 'Pull Handle, Down & Push Bar (3 Holes)',
  },
  { value: 'Pull Handle (2 Holes)', label: 'Pull Handle (2 Holes)' },
  { value: 'Push Bar (2 Holes)', label: 'Push Bar (2 Holes)' },
  { value: 'Ladder Pull (3 Holes)', label: 'Ladder Pull (3 Holes)' },
  { value: 'Ladder Pull (2 Holes)', label: 'Ladder Pull (2 Holes)' },
  { value: 'Single Pull (1 Hole)', label: 'Single Pull (1 Hole)' },

  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const panicDevice = [
  { value: 'Blumcraft Exit Device', label: 'Blumcraft Exit Device' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const edgeSealWeatherstrip = [
  { value: 'Edgeseal for 1/2" Glass', label: 'Edgeseal for 1/2" Glass' },
  { value: 'Edgeseal for 3/4" Glass', label: 'Edgeseal for 3/4" Glass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomInsert = [
  {
    value: 'Dorma patch fitting insert for Adjustable Bottom Pivot',
    label: 'Dorma patch fitting insert for Adjustable Bottom Pivot',
  },
  { value: 'Dorma patch fitting insert for BTS', label: 'Dorma patch fitting insert for BTS' },
  {
    value: 'Casma Stainless Steel Bottom Pivot Adjustable',
    label: 'Casma Stainless Steel Bottom Pivot Adjustable',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomPivot = [
  {
    value: 'Casma Stainless Steel Bottom Pivot Adjustable',
    label: 'Casma Stainless Steel Bottom Pivot Adjustable',
  },
  { value: 'Dorma Floor Pivot BTS Spindle', label: 'Dorma Floor Pivot BTS Spindle' },
  { value: 'Dorma Adjustable Floor Pivot', label: 'Dorma Adjustable Floor Pivot' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const floorCloser = [
  { value: 'Rixson 30 105 NHO', label: 'Rixson 30 105 NHO' },
  { value: 'Rixson 30 90 NHO', label: 'Rixson 30 90 NHO' },
  { value: 'Rixson 40 105 NHO', label: 'Rixson 40 105 NHO' },
  { value: 'Rixson 40 90 NHO', label: 'Rixson 40 90 NHO' },
  { value: 'Rixson 28 L/H 105 NHO', label: 'Rixson 28 L/H 105 NHO' },
  { value: 'Rixson 28 R/H 105 NHO', label: 'Rixson 28 R/H 105 NHO' },
  { value: 'Rixson 28 L/H 90 NHO', label: 'Rixson 28 L/H 90 NHO' },
  { value: 'Rixson 28 R/H 90 NHO', label: 'Rixson 28 R/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle L/H 90 NHO', label: 'Rixson 28 Square Spindle L/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle R/H 90 NHO', label: 'Rixson 28 Square Spindle R/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle L/H 105 NHO', label: 'Rixson 28 Square Spindle L/H 105 NHO' },
  { value: 'Rixson 28 Square Spindle R/H 105 NHO', label: 'Rixson 28 Square Spindle R/H 105 NHO' },
  { value: 'Rixson 30 105 HO', label: 'Rixson 30 105 HO' },
  { value: 'Rixson 30 90 HO', label: 'Rixson 30 90 HO' },
  { value: 'Rixson 40 105 HO', label: 'Rixson 40 105 HO' },
  { value: 'Rixson 40 90 HO', label: 'Rixson 40 90 HO' },
  { value: 'Rixson 28 L/H 105 HO', label: 'Rixson 28 L/H 105 HO' },
  { value: 'Rixson 28 R/H 105 HO', label: 'Rixson 28 R/H 105 HO' },
  { value: 'Rixson 28 L/H 90 HO', label: 'Rixson 28 L/H 90 HO' },
  { value: 'Rixson 28 R/H 90 HO', label: 'Rixson 28 R/H 90 HO' },
  { value: 'Rixson 28 Square Spindle L/H 90 HO', label: 'Rixson 28 Square Spindle L/H 90 HO' },
  { value: 'Rixson 28 Square Spindle R/H 90 HO', label: 'Rixson 28 Square Spindle R/H 90 HO' },
  { value: 'Rixson 28 Square Spindle L/H 105 HO', label: 'Rixson 28 Square Spindle L/H 105 HO' },
  { value: 'Rixson 28 Square Spindle R/H 105 HO', label: 'Rixson 28 Square Spindle R/H 105 HO' },
  {
    value: 'Rixson Plastic Cement Case for Rixson 27 and 28',
    label: 'Rixson Plastic Cement Case for Rixson 27 and 28',
  },
  {
    value: 'Rixson Metal Cement Case for Rixson 30',
    label: 'Rixson Metal Cement Case for Rixson 30',
  },
  {
    value: 'Rixson Metal Cement Case for Rixson 40',
    label: 'Rixson Metal Cement Case for Rixson 40',
  },
  { value: 'Dorma BTS80 Floor Door Closer', label: 'Dorma BTS80 Floor Door Closer' },
  { value: 'Dorma BTS75 Floor Door Closer', label: 'Dorma BTS75 Floor Door Closer' },
  { value: 'Rixson 5013 L/H 90 HO', label: 'Rixson 5013 L/H 90 HO' },
  { value: 'Rixson 5013 R/H 90 HO', label: 'Rixson 5013 R/H 90 HO' },
  { value: 'Rixson 5013 L/H 105 HO', label: 'Rixson 5013 L/H 105 HO' },
  { value: 'Rixson 5013 R/H 105 HO', label: 'Rixson 5013 R/H 105 HO' },
  { value: 'Rixson 5013 L/H 90 NHO', label: 'Rixson 5013 L/H 90 NHO' },
  { value: 'Rixson 5013 R/H 90 NHO', label: 'Rixson 5013 R/H 90 NHO' },
  { value: 'Rixson 5013 L/H 105 NHO', label: 'Rixson 5013 L/H 105 NHO' },
  { value: 'Rixson 5013 R/H 105 NHO', label: 'Rixson 5013 R/H 105 NHO' },
  { value: 'Rixson 5014 L/H 90 HO', label: 'Rixson 5014 L/H 90 HO' },
  { value: 'Rixson 5014 R/H 90 HO', label: 'Rixson 5014 R/H 90 HO' },
  { value: 'Rixson 5014 L/H 105 HO', label: 'Rixson 5014 L/H 105 HO' },
  { value: 'Rixson 5014 R/H 105 HO', label: 'Rixson 5014 R/H 105 HO' },
  { value: 'Rixson 5014 L/H 90 NHO', label: 'Rixson 5014 L/H 90 NHO' },
  { value: 'Rixson 5014 R/H 90 NHO', label: 'Rixson 5014 R/H 90 NHO' },
  { value: 'Rixson 5014 L/H 105 NHO', label: 'Rixson 5014 L/H 105 NHO' },
  { value: 'Rixson 5014 R/H 105 NHO', label: 'Rixson 5014 R/H 105 NHO' },
  { value: 'Rixson 5015 L/H 90 HO', label: 'Rixson 5015 L/H 90 HO' },
  { value: 'Rixson 5015 R/H 90 HO', label: 'Rixson 5015 R/H 90 HO' },
  { value: 'Rixson 5015 L/H 105 HO', label: 'Rixson 5015 L/H 105 HO' },
  { value: 'Rixson 5015 R/H 105 HO', label: 'Rixson 5015 R/H 105 HO' },
  { value: 'Rixson 5015 L/H 90 NHO', label: 'Rixson 5015 L/H 90 NHO' },
  { value: 'Rixson 5015 R/H 90 NHO', label: 'Rixson 5015 R/H 90 NHO' },
  { value: 'Rixson 5015 L/H 105 NHO', label: 'Rixson 5015 L/H 105 NHO' },
  { value: 'Rixson 5015 R/H 105 NHO', label: 'Rixson 5015 R/H 105 NHO' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const threshold = [
  {
    value: '4" Aluminum Threshold 36" long 1/2" rise',
    label: '4" Aluminum Threshold 36" long 1/2" rise',
  },
  {
    value: '4" Aluminum Threshold 72" long 1/2" rise',
    label: '4" Aluminum Threshold 72" long 1/2" rise',
  },
  {
    value: '4" Aluminum Threshold 36" long 1/4" rise',
    label: '4" Aluminum Threshold 36" long 1/4" rise',
  },
  {
    value: '4" Aluminum Threshold 72" long 1/4" rise',
    label: '4" Aluminum Threshold 72" long 1/4" rise',
  },
  {
    value: '5" Aluminum Threshold 36" long 1/2" rise',
    label: '5" Aluminum Threshold 36" long 1/2" rise',
  },
  {
    value: '5" Aluminum Threshold 72" long 1/2" rise',
    label: '5" Aluminum Threshold 72" long 1/2" rise',
  },
  {
    value: '5" Aluminum Threshold 36" long 1/4" rise',
    label: '5" Aluminum Threshold 36" long 1/4" rise',
  },
  {
    value: '5" Aluminum Threshold 72" long 1/4" rise',
    label: '5" Aluminum Threshold 72" long 1/4" rise',
  },
  {
    value: '6" Aluminum Threshold 36" long 1/2" rise',
    label: '6" Aluminum Threshold 36" long 1/2" rise',
  },
  {
    value: '6" Aluminum Threshold 72" long 1/2" rise',
    label: '6" Aluminum Threshold 72" long 1/2" rise',
  },
  {
    value: '6" Aluminum Threshold 36" long 1/4" rise',
    label: '6" Aluminum Threshold 36" long 1/4" rise',
  },
  {
    value: '6" Aluminum Threshold 72" long 1/4" rise',
    label: '6" Aluminum Threshold 72" long 1/4" rise',
  },
  {
    value: '7" Aluminum Threshold 36" long 1/2" rise',
    label: '7" Aluminum Threshold 36" long 1/2" rise',
  },
  {
    value: '7" Aluminum Threshold 72" long 1/2" rise',
    label: '7" Aluminum Threshold 72" long 1/2" rise',
  },
  {
    value: '7" Aluminum Threshold 36" long 1/4" rise',
    label: '7" Aluminum Threshold 36" long 1/4" rise',
  },
  {
    value: '7" Aluminum Threshold 72" long 1/4" rise',
    label: '7" Aluminum Threshold 72" long 1/4" rise',
  },
  {
    value: '8" Aluminum Threshold 36" long 1/2" rise',
    label: '8" Aluminum Threshold 36" long 1/2" rise',
  },
  {
    value: '8" Aluminum Threshold 72" long 1/2" rise',
    label: '8" Aluminum Threshold 72" long 1/2" rise',
  },
  {
    value: '8" Aluminum Threshold 36" long 1/4" rise',
    label: '8" Aluminum Threshold 36" long 1/4" rise',
  },
  {
    value: '8" Aluminum Threshold 72" long 1/4" rise',
    label: '8" Aluminum Threshold 72" long 1/4" rise',
  },
  {
    value: '4" Brass Threshold 36" long 1/2" rise',
    label: '4" Brass Threshold 36" long 1/2" rise',
  },
  {
    value: '4" Brass Threshold 72" long 1/2" rise',
    label: '4" Brass Threshold 72" long 1/2" rise',
  },
  {
    value: '4" Brass Threshold 36" long 1/4" rise',
    label: '4" Brass Threshold 36" long 1/4" rise',
  },
  {
    value: '4" Brass Threshold 72" long 1/4" rise',
    label: '4" Brass Threshold 72" long 1/4" rise',
  },
  {
    value: '5" Brass Threshold 36" long 1/2" rise',
    label: '5" Brass Threshold 36" long 1/2" rise',
  },
  {
    value: '5" Brass Threshold 72" long 1/2" rise',
    label: '5" Brass Threshold 72" long 1/2" rise',
  },
  {
    value: '5" Brass Threshold 36" long 1/4" rise',
    label: '5" Brass Threshold 36" long 1/4" rise',
  },
  {
    value: '5" Brass Threshold 72" long 1/4" rise',
    label: '5" Brass Threshold 72" long 1/4" rise',
  },
  {
    value: '6" Brass Threshold 36" long 1/2" rise',
    label: '6" Brass Threshold 36" long 1/2" rise',
  },
  {
    value: '6" Brass Threshold 72" long 1/2" rise',
    label: '6" Brass Threshold 72" long 1/2" rise',
  },
  {
    value: '6" Brass Threshold 36" long 1/4" rise',
    label: '6" Brass Threshold 36" long 1/4" rise',
  },
  {
    value: '6" Brass Threshold 72" long 1/4" rise',
    label: '6" Brass Threshold 72" long 1/4" rise',
  },
  {
    value: '7" Brass Threshold 36" long 1/2" rise',
    label: '7" Brass Threshold 36" long 1/2" rise',
  },
  {
    value: '7" Brass Threshold 72" long 1/2" rise',
    label: '7" Brass Threshold 72" long 1/2" rise',
  },
  {
    value: '7" Brass Threshold 36" long 1/4" rise',
    label: '7" Brass Threshold 36" long 1/4" rise',
  },
  {
    value: '7" Brass Threshold 72" long 1/4" rise',
    label: '7" Brass Threshold 72" long 1/4" rise',
  },
  {
    value: '8" Brass Threshold 36" long 1/2" rise',
    label: '8" Brass Threshold 36" long 1/2" rise',
  },
  {
    value: '8" Brass Threshold 72" long 1/2" rise',
    label: '8" Brass Threshold 72" long 1/2" rise',
  },
  {
    value: '8" Brass Threshold 36" long 1/4" rise',
    label: '8" Brass Threshold 36" long 1/4" rise',
  },
  {
    value: '8" Brass Threshold 72" long 1/4" rise',
    label: '8" Brass Threshold 72" long 1/4" rise',
  },
  {
    value: '4" Bronze Threshold 36" long 1/2" rise',
    label: '4" Bronze Threshold 36" long 1/2" rise',
  },
  {
    value: '4" Bronze Threshold 72" long 1/2" rise',
    label: '4" Bronze Threshold 72" long 1/2" rise',
  },
  {
    value: '4" Bronze Threshold 36" long 1/4" rise',
    label: '4" Bronze Threshold 36" long 1/4" rise',
  },
  {
    value: '4" Bronze Threshold 72" long 1/4" rise',
    label: '4" Bronze Threshold 72" long 1/4" rise',
  },
  {
    value: '5" Bronze Threshold 36" long 1/2" rise',
    label: '5" Bronze Threshold 36" long 1/2" rise',
  },
  {
    value: '5" Bronze Threshold 72" long 1/2" rise',
    label: '5" Bronze Threshold 72" long 1/2" rise',
  },
  {
    value: '5" Bronze Threshold 36" long 1/4" rise',
    label: '5" Bronze Threshold 36" long 1/4" rise',
  },
  {
    value: '5" Bronze Threshold 72" long 1/4" rise',
    label: '5" Bronze Threshold 72" long 1/4" rise',
  },
  {
    value: '6" Bronze Threshold 36" long 1/2" rise',
    label: '6" Bronze Threshold 36" long 1/2" rise',
  },
  {
    value: '6" Bronze Threshold 72" long 1/2" rise',
    label: '6" Bronze Threshold 72" long 1/2" rise',
  },
  {
    value: '6" Bronze Threshold 36" long 1/4" rise',
    label: '6" Bronze Threshold 36" long 1/4" rise',
  },
  {
    value: '6" Bronze Threshold 72" long 1/4" rise',
    label: '6" Bronze Threshold 72" long 1/4" rise',
  },
  {
    value: '7" Bronze Threshold 36" long 1/2" rise',
    label: '7" Bronze Threshold 36" long 1/2" rise',
  },
  {
    value: '7" Bronze Threshold 72" long 1/2" rise',
    label: '7" Bronze Threshold 72" long 1/2" rise',
  },
  {
    value: '7" Bronze Threshold 36" long 1/4" rise',
    label: '7" Bronze Threshold 36" long 1/4" rise',
  },
  {
    value: '7" Bronze Threshold 72" long 1/4" rise',
    label: '7" Bronze Threshold 72" long 1/4" rise',
  },
  {
    value: '8" Bronze Threshold 36" long 1/2" rise',
    label: '8" Bronze Threshold 36" long 1/2" rise',
  },
  {
    value: '8" Bronze Threshold 72" long 1/2" rise',
    label: '8" Bronze Threshold 72" long 1/2" rise',
  },
  {
    value: '8" Bronze Threshold 36" long 1/4" rise',
    label: '8" Bronze Threshold 36" long 1/4" rise',
  },
  {
    value: '8" Bronze Threshold 72" long 1/4" rise',
    label: '8" Bronze Threshold 72" long 1/4" rise',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const patchLock = [
  {
    value: 'Dorma Bottom Rail Lock Round Bolt 1 15/16 high',
    label: 'Dorma Bottom Rail Lock Round Bolt 1 15/16 high',
  },
  {
    value: 'Dorma Bottom Rail Lock Round Bolt 1 9/16 high',
    label: 'Dorma Bottom Rail Lock Round Bolt 1 9/16 high',
  },
  { value: 'Thumb Turn Cylinder Bronze', label: 'Thumb Turn Cylinder Bronze' },
  { value: 'Thumb Turn Cylinder Aluminum', label: 'Thumb Turn Cylinder Aluminum' },
  { value: 'Thumb Turn Cylinder Brass', label: 'Thumb Turn Cylinder Brass' },
  { value: 'Key Cylinder Bronze', label: 'Key Cylinder Bronze' },
  { value: 'Key Cylinder Aluminum', label: 'Key Cylinder Aluminum' },
  { value: 'Key Cylinder Brass', label: 'Key Cylinder Brass' },
  { value: 'Dummy Cylinder Bronze', label: 'Dummy Cylinder Bronze' },
  { value: 'Dummy Cylinder Aluminum', label: 'Dummy Cylinder Aluminum' },
  { value: 'Dummy Cylinder Brass', label: 'Dummy Cylinder Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomRail = [
  {
    value: '4" Square Bottom Rail Polished Stainless Steel Finish 4"',
    label: 'Square Bottom Rail Polished Stainless Steel Finish',
  },
  {
    value: '4" Square Bottom Rail Brush Stainless Steel Finish 4"',
    label: 'Square Bottom Rail Brush Stainless Steel Finish',
  },
  {
    value: '3 5/8" Square Bottom Rail Polished Stainless Steel Finish 3 5/8"',
    label: 'Square Bottom Rail Polished Stainless Steel Finish',
  },
  {
    value: '3 5/8" Square Bottom Rail Brush Stainless Steel Finish 3 5/8"',
    label: 'Square Bottom Rail Brush Stainless Steel Finish',
  },
  {
    value: '4" Tapered Bottom Rail Polished Stainless Steel Finish 4"',
    label: 'Tapered Bottom Rail Polished Stainless Steel Finish',
  },
  {
    value: '4" Tapered Bottom Rail Brush Stainless Steel Finish 4"',
    label: 'Tapered Bottom Rail Brush Stainless Steel Finish',
  },
  {
    value: '3 5/8" Tapered Bottom Rail Polished Stainless Steel Finish 3 5/8"',
    label: 'Tapered Bottom Rail Polished Stainless Steel Finish',
  },
  {
    value: '3 5/8" Tapered Bottom Rail Brush Stainless Steel Finish 3 5/8"',
    label: 'Tapered Bottom Rail Brush Stainless Steel Finish',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomArm = [
  { value: 'Rixson Bottom Center Hung Arm', label: 'Rixson Bottom Center Hung Arm' },
  { value: 'Rixson Bottom Flat Center hung Arm', label: 'Rixson Bottom Flat Center hung Arm' },
  {
    value: 'Rixson Bottom Offset Arm L/H (Rixson 27)',
    label: 'Rixson Bottom Offset Arm L/H (Rixson 27)',
  },
  {
    value: 'Rixson Bottom Offset Arm R/H (Rixson 27)',
    label: 'Rixson Bottom Offset Arm R/H (Rixson 27)',
  },
  { value: 'Dorma Center Hung Bottom Arm', label: 'Dorma Center Hung Bottom Arm' },
  { value: 'Dorma Offset Bottom Arm', label: 'Dorma Offset Bottom Arm' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const topPivotArm = [
  { value: 'Rixson Walking Beam Pivot', label: 'Rixson Walking Beam Pivot' },
  { value: 'Dorma Walking Bean Pivot 5/8"', label: 'Dorma Walking Bean Pivot 5/8"' },
  { value: 'Dorma Walking Bean Pivot 15mm', label: 'Dorma Walking Bean Pivot 15mm' },
  { value: 'International Walking Beam Pivot', label: 'International Walking Beam Pivot' },
  { value: 'Jackson Offset Arm', label: 'Jackson Offset Arm' },
  { value: 'Dorma Offset Arm', label: 'Dorma Offset Arm' },
  {
    value: 'Dorma patch fitting insert for top Pivot Male',
    label: 'Dorma patch fitting insert for top Pivot Male',
  },
  { value: 'Rixson top Offset Pivot', label: 'Rixson top Offset Pivot' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const topInsertArm = [
  {
    value: 'Dorma patch fitting insert for RTS Closer',
    label: 'Dorma patch fitting insert for RTS Closer',
  },
  {
    value: 'Dorma patch fitting insert for top Pivot Female',
    label: 'Dorma patch fitting insert for top Pivot Female',
  },
  {
    value: 'Casma patch fitting insert for COC Closer',
    label: 'Casma patch fitting insert for COC Closer',
  },
  {
    value: 'Casma patch fitting insert for top Pivot Female',
    label: 'Casma patch fitting insert for top Pivot Female',
  },
  {
    value: 'Rixson Top Center Hung Arm end load (Rixson 800)',
    label: 'Rixson Top Center Hung Arm end load (Rixson 800)',
  },
  { value: 'Dorma Adjustable End Load Arm', label: 'Dorma Adjustable End Load Arm' },
  { value: 'Dorma Top End Load 1"', label: 'Dorma Top End Load 1"' },
  { value: 'Dorma Top End Load 3/4"', label: 'Dorma Top End Load 3/4"' },
  { value: 'Dorma Top End Load 5/8"', label: 'Dorma Top End Load 5/8"' },
  { value: 'Dorma Top Rail Side Load Arm', label: 'Dorma Top Rail Side Load Arm' },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
  },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
  },
  { value: 'Jackson End Load Arm', label: 'Jackson End Load Arm' },
  { value: 'Jackson Side Load Arm', label: 'Jackson Side Load Arm' },
  { value: 'LCN 3034 All Track Rollers', label: ' LCN 3034 All Track Rollers' },
  { value: 'LCN Aluminum Standard Arm for 2610', label: 'LCN Aluminum Standard Arm for 2610' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomPivotArm = [
  { value: 'Rixson Bottom Offset Pivot L/H', label: 'Rixson Bottom Offset Pivot L/H' },
  { value: 'Rixson Bottom Offset Pivot R/H', label: 'Rixson Bottom Offset Pivot R/H' },
  {
    value: 'Dorma patch fitting insert for Adjustable Bottom Pivot',
    label: 'Dorma patch fitting insert for Adjustable Bottom Pivot',
  },
  { value: 'Dorma patch fitting insert for BTS', label: 'Dorma patch fitting insert for BTS' },
  { value: 'Dorma Bottom Pivot 7/8" Web Depth', label: 'Dorma Bottom Pivot 7/8" Web Depth' },
  {
    value: 'Dorma Bottom Pivot 1-9/16" Web Depth',
    label: 'Dorma Bottom Pivot 1-9/16" Web Depth',
  },
  {
    value: 'International Center hung bottom pivot',
    label: 'International Center hung bottom pivot',
  },
  {
    value: 'Jackson Center Hung Bottom pivot  "UP" 1-9/16"',
    label: 'Jackson Center Hung Bottom pivot "UP" 1-9/16"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot  "BV" 1"',
    label: 'Jackson Center Hung Bottom pivot "BV" 1"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
    label: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
  },
  {
    value: 'Casma Stainless Steel Bottom Pivot Adjustable',
    label: 'Casma Stainless Steel Bottom Pivot Adjustable',
  },
  {
    value: 'Rixson 370 Bottom Center Hung Pivot Set',
    label: 'Rixson 370 Bottom Center Hung Pivot Set',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const bottomPivotTopBottomRails = [
  { value: 'Rixson Bottom Offset Pivot L/H', label: 'Rixson Bottom Offset Pivot L/H' },
  { value: 'Rixson Bottom Offset Pivot R/H', label: 'Rixson Bottom Offset Pivot R/H' },
  { value: 'Dorma Bottom Pivot 7/8" Web Depth', label: 'Dorma Bottom Pivot 7/8" Web Depth' },
  {
    value: 'Dorma Bottom Pivot 1-9/16" Web Depth',
    label: 'Dorma Bottom Pivot 1-9/16" Web Depth',
  },
  {
    value: 'International Center hung bottom pivot',
    label: 'International Center hung bottom pivot',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "UP" 1-9/16"',
    label: 'Jackson Center Hung Bottom pivot "UP" 1-9/16"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "BV" 1"',
    label: 'Jackson Center Hung Bottom pivot "BV" 1"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
    label: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
  },
  {
    value: 'Casma Stainless Steel Bottom Pivot Adjustable',
    label: 'Casma Stainless Steel Bottom Pivot Adjustable',
  },
  {
    value: 'Rixson 370 Bottom Center Hung Pivot Set',
    label: 'Rixson 370 Bottom Center Hung Pivot Set',
  },
  { value: 'Dorma Floor Pivot BTS Spindle', label: 'Dorma Floor Pivot BTS Spindle' },
  { value: 'Dorma Adjustable Floor Pivot', label: 'Dorma Adjustable Floor Pivot' },
  {
    value: 'Kawneer Adjustable Bottom Pivot Center Hung',
    label: 'Kawneer Adjustable Bottom Pivot Center Hung',
  },
  {
    value: 'Ives 7253 Complete Center Hung Pivot Set',
    label: 'Ives 7253 Complete Center Hung Pivot Set',
  },
];

export const floorCloserTopBottomRails = [
  { value: 'Rixson 30 105 NHO', label: 'Rixson 30 105 NHO' },
  { value: 'Rixson 30 90 NHO', label: 'Rixson 30 90 NHO' },
  { value: 'Rixson 40 105 NHO', label: 'Rixson 40 105 NHO' },
  { value: 'Rixson 40 90 NHO', label: 'Rixson 40 90 NHO' },
  { value: 'Rixson 28 L/H 105 NHO', label: 'Rixson 28 L/H 105 NHO' },
  { value: 'Rixson 28 R/H 105 NHO', label: 'Rixson 28 R/H 105 NHO' },
  { value: 'Rixson 28 L/H 90 NHO', label: 'Rixson 28 L/H 90 NHO' },
  { value: 'Rixson 28 R/H 90 NHO', label: 'Rixson 28 R/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle L/H 90 NHO', label: 'Rixson 28 Square Spindle L/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle R/H 90 NHO', label: 'Rixson 28 Square Spindle R/H 90 NHO' },
  { value: 'Rixson 28 Square Spindle L/H 105 NHO', label: 'Rixson 28 Square Spindle L/H 105 NHO' },
  { value: 'Rixson 28 Square Spindle R/H 105 NHO', label: 'Rixson 28 Square Spindle R/H 105 NHO' },
  { value: 'Rixson 27 L/H 105 NHO', label: 'Rixson 27 L/H 105 NHO' },
  { value: 'Rixson 27 R/H 105 NHO', label: 'Rixson 27 R/H 105 NHO' },
  { value: 'Rixson 27 L/H 90 NHO', label: 'Rixson 27 L/H 90 NHO' },
  { value: 'Rixson 27 R/H 90 NHO', label: 'Rixson 27 R/H 90 NHO' },

  { value: 'Rixson 30 105 HO', label: 'Rixson 30 105 HO' },
  { value: 'Rixson 30 90 HO', label: 'Rixson 30 90 HO' },
  { value: 'Rixson 40 105 HO', label: 'Rixson 40 105 HO' },
  { value: 'Rixson 40 90 HO', label: 'Rixson 40 90 HO' },
  { value: 'Rixson 28 L/H 105 HO', label: 'Rixson 28 L/H 105 HO' },
  { value: 'Rixson 28 R/H 105 HO', label: 'Rixson 28 R/H 105 HO' },
  { value: 'Rixson 28 L/H 90 HO', label: 'Rixson 28 L/H 90 HO' },
  { value: 'Rixson 28 R/H 90 HO', label: 'Rixson 28 R/H 90 HO' },
  { value: 'Rixson 28 Square Spindle L/H 90 HO', label: 'Rixson 28 Square Spindle L/H 90 HO' },
  { value: 'Rixson 28 Square Spindle R/H 90 HO', label: 'Rixson 28 Square Spindle R/H 90 HO' },
  { value: 'Rixson 28 Square Spindle L/H 105 HO', label: 'Rixson 28 Square Spindle L/H 105 HO' },
  { value: 'Rixson 28 Square Spindle R/H 105 HO', label: 'Rixson 28 Square Spindle R/H 105 HO' },
  { value: 'Rixson 27 L/H 105 HO', label: 'Rixson 27 L/H 105 HO' },
  { value: 'Rixson 27 R/H 105 HO', label: 'Rixson 27 R/H 105 HO' },
  { value: 'Rixson 27 L/H 90 HO', label: 'Rixson 27 L/H 90 HO' },
  { value: 'Rixson 27 R/H 90 HO', label: 'Rixson 27 R/H 90 HO' },
  { value: 'Dorma BTS80 Floor Door Closer', label: 'Dorma BTS80 Floor Door Closer' },
  { value: 'Dorma BTS75 Floor Door Closer', label: 'Dorma BTS75 Floor Door Closer' },
  { value: 'Jackson Floor Door Closer', label: 'Jackson Floor Door Closer' },
  { value: 'CRL Floor Door Closer', label: 'CRL Floor Door Closer' },
  { value: 'Rixson 5013 L/H 90 HO', label: 'Rixson 5013 L/H 90 HO' },
  { value: 'Rixson 5013 R/H 90 HO', label: 'Rixson 5013 R/H 90 HO' },
  { value: 'Rixson 5013 L/H 105 HO', label: 'Rixson 5013 L/H 105 HO' },
  { value: 'Rixson 5013 R/H 105 HO', label: 'Rixson 5013 R/H 105 HO' },
  { value: 'Rixson 5013 L/H 90 NHO', label: 'Rixson 5013 L/H 90 NHO' },
  { value: 'Rixson 5013 R/H 90 NHO', label: 'Rixson 5013 R/H 90 NHO' },
  { value: 'Rixson 5013 L/H 105 NHO', label: 'Rixson 5013 L/H 105 NHO' },
  { value: 'Rixson 5013 R/H 105 NHO', label: 'Rixson 5013 R/H 105 NHO' },
  { value: 'Rixson 5014 L/H 90 HO', label: 'Rixson 5014 L/H 90 HO' },
  { value: 'Rixson 5014 R/H 90 HO', label: 'Rixson 5014 R/H 90 HO' },
  { value: 'Rixson 5014 L/H 105 HO', label: 'Rixson 5014 L/H 105 HO' },
  { value: 'Rixson 5014 R/H 105 HO', label: 'Rixson 5014 R/H 105 HO' },
  { value: 'Rixson 5014 L/H 90 NHO', label: 'Rixson 5014 L/H 90 NHO' },
  { value: 'Rixson 5014 R/H 90 NHO', label: 'Rixson 5014 R/H 90 NHO' },
  { value: 'Rixson 5014 L/H 105 NHO', label: 'Rixson 5014 L/H 105 NHO' },
  { value: 'Rixson 5014 R/H 105 NHO', label: 'Rixson 5014 R/H 105 NHO' },
  { value: 'Rixson 5015 L/H 90 HO', label: 'Rixson 5015 L/H 90 HO' },
  { value: 'Rixson 5015 R/H 90 HO', label: 'Rixson 5015 R/H 90 HO' },
  { value: 'Rixson 5015 L/H 105 HO', label: 'Rixson 5015 L/H 105 HO' },
  { value: 'Rixson 5015 R/H 105 HO', label: 'Rixson 5015 R/H 105 HO' },
  { value: 'Rixson 5015 L/H 90 NHO', label: 'Rixson 5015 L/H 90 NHO' },
  { value: 'Rixson 5015 R/H 90 NHO', label: 'Rixson 5015 R/H 90 NHO' },
  { value: 'Rixson 5015 L/H 105 NHO', label: 'Rixson 5015 L/H 105 NHO' },
  { value: 'Rixson 5015 R/H 105 NHO', label: 'Rixson 5015 R/H 105 NHO' },
];

export const topRail = [
  {
    value: '4" Square Top Rail Polished Stainless Steel Finish',
    label: '4" Square Top Rail Polished Stainless Steel Finish',
  },
  {
    value: '4" Square Top Rail Brush Stainless Steel Finish',
    label: '4" Square Top Rail Brush Stainless Steel Finish',
  },
  {
    value: '3 5/8" Square Top Rail Polished Stainless Steel Finish',
    label: '3 5/8" Square Top Rail Polished Stainless Steel Finish',
  },
  {
    value: '3 5/8" Square Top Rail Brush Stainless Steel Finish',
    label: '3 5/8" Square Top Rail Brush Stainless Steel Finish',
  },
  {
    value: '4" Tapered top Rail Polished Stainless Steel Finish',
    label: '4" Tapered top Rail Polished Stainless Steel Finish',
  },
  {
    value: '4" Tapered top Rail Brush Stainless Steel Finish',
    label: '4" Tapered top Rail Brush Stainless Steel Finish',
  },
  {
    value: '3 5/8" Tapered top Rail Polished Stainless Steel Finish',
    label: '3 5/8" Tapered top Rail Polished Stainless Steel Finish',
  },
  {
    value: '3 5/8" Tapered top Rail Brush Stainless Steel Finish',
    label: '3 5/8" Tapered top Rail Brush Stainless Steel Finish',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const sideRails = [
  {
    value: '1" Rail Polished Stainless Steel Finish',
    label: '1" Rail Polished Stainless Steel Finish',
  },
  {
    value: '1" Rail Brush Stainless Steel Finish',
    label: '1" Rail Brush Stainless Steel Finish',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

/* ALL GLASS DOOR - BROKEN GLASS  */

export const glassColorThicknessBrokenGlass = [
  { value: '3/8-Clear', label: '3/8" Clear' },
  { value: '3/8-Bronze', label: '3/8" Bronze' },
  { value: '3/8-Gray', label: '3/8" Gray' },
  { value: '3/8-Lami Clear', label: '3/8" Lami Clear' },
  { value: '3/8-Lami Bronze', label: '3/8" Lami Bronze' },
  { value: '3/8-Lami Gray', label: '3/8" Lami Gray' },
  { value: '3/8-Acid Etched', label: '3/8" Acid Etched' },
  { value: '3/8-Low Iron', label: '3/8" Low Iron' },
  { value: '1/2-Clear', label: '1/2" Clear ' },
  { value: '1/2-Bronze', label: '1/2" Bronze ' },
  { value: '1/2-Gray', label: '1/2" Gray' },
  { value: '1/2-Lami Clear', label: '1/2" Lami Clear' },
  { value: '1/2-Lami Bronze', label: '1/2" Lami Bronze' },
  { value: '1/2-Lami Gray', label: '1/2" Lami Gray' },
  { value: '1/2-Acid Etched', label: '1/2" Acid Etched' },
  { value: '1/2-Low Iron', label: '1/2" Low Iron' },
  { value: '5/8-Clear', label: '5/8" Clear' },
  { value: '5/8-Bronze', label: '5/8" Bronze ' },
  { value: '5/8-Gray', label: '5/8" Gray' },
  { value: '5/8-Lami Clear', label: '5/8" Lami Clear' },
  { value: '5/8-Lami Bronze', label: '5/8" Lami Bronze' },
  { value: '5/8-Lami Clear', label: '5/8" Lami Gray' },
  { value: '5/8-Acid Etched', label: '5/8" Acid Etched' },
  { value: '5/8-Low Iron', label: '5/8" Low Iron ' },
  { value: '3/4-Clear', label: '3/4" Clear' },
  { value: '3/4-Bronze', label: '3/4" Bronze' },
  { value: '3/4-Gray', label: '3/4" Gray' },
  { value: '3/4-Lami Clear', label: '3/4" Lami Clear' },
  { value: '3/4-Lami Bronze', label: '3/4" Lami Bronze' },
  { value: '3/4-Lami Gray', label: '3/4" Lami Gray' },
  { value: '3/4-Acid Etched', label: '3/4" Acid Etched' },
  { value: '3/4-Low Iron ', label: '3/4" Low Iron ' },
  { value: '1-Clear', label: '1" Clear' },
  { value: '1-Bronze', label: '1" Bronze' },
  { value: '1-Gray', label: '1" Gray' },
  { value: '1-Lami Clear', label: '1" Lami Clear' },
  { value: '1-Lami Bronze', label: '1" Lami Bronze' },
  { value: '1-Lami Gray', label: '1" Lami Gray' },
  { value: '1-Acid Etched', label: '1" Acid Etched' },
  { value: '1-Low Iron', label: '1" Low Iron' },
];

export const typeOfSet = [
  { value: 'Dry', label: 'Dry' },
  { value: 'Rockite', label: 'Rockite' },
  { value: 'Epoxy', label: 'Epoxy' },
  { value: 'Silicone', label: 'Silicone' },
];

export const holeLocation = [{ value: 'optionOne', label: 'Option 1' }];

export const holeSize = [
  { value: '1/2"', label: '1/2"' },
  { value: '5/8"', label: '5/8"' },
  { value: '3/4"', label: '3/4"' },
  { value: '1"', label: '1"' },
  { value: 'Custom', label: 'Custom' },
];

export const patchFittingManufacturer = [
  { value: 'Dorma', label: 'Dorma' },
  { value: 'Casma', label: 'Casma' },
  { value: 'CRL', label: 'CRL' },
];

export const bottomPatchLockManufacturer = [
  { value: 'Adams Rite', label: 'Adams Rite' },
  { value: 'Dorma', label: 'Dorma' },
  { value: 'CRL', label: 'CRL' },
];

export const topInsertArmBrokenGlass = [
  { value: 'LCN 3034 All Track Rollers', label: ' LCN 3034 All Track Rollers' },
  { value: 'LCN Aluminum Standard Arm for 2610', label: 'LCN Aluminum Standard Arm for 2610' },
];

/* STOREFRONT DOOR - PARTS REPAIR */
export const brandStorefront = [
  { value: 'Vistawall', label: 'Vistawall' },
  { value: 'Kawneer', label: 'Kawneer' },
  { value: 'YKK', label: 'YKK' },
  { value: 'US Aluminum', label: 'US Aluminum' },
  { value: 'EFCO', label: 'EFCO' },
  { value: 'Other', label: 'Other' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const cocCloserStorefront = [
  { value: 'Rixson 800 90 NHO', label: 'Rixson 800 90 NHO' },
  { value: 'Rixson 800 105 NHO', label: 'Rixson 800 105 NHO' },
  { value: 'Rixson 800 90 HO COC Closer', label: 'Rixson 800 90 HO COC Closer' },
  { value: 'Rixson 800 105 HO COC Closer', label: 'Rixson 800 105 HO COC Closer' },
  { value: 'Dorma RTS88 105 NHO SZ3', label: 'Dorma RTS88 105 NHO SZ3' },
  { value: 'Dorma RTS88 90 NHO SZ3', label: 'Dorma RTS88 90 NHO SZ3' },
  { value: 'Dorma RTS88 105 HO SZ3', label: 'Dorma RTS88 105 HO SZ3' },
  { value: 'Dorma RTS88 90 HO SZ3', label: 'Dorma RTS88 90 HO SZ3' },
  {
    value: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
    label: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
  },
  {
    value: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
  },
  { value: 'Dorma RTS88 105 NHO BFI (Interior) ', label: 'Dorma RTS88 105 NHO BFI (Interior) ' },
  { value: 'Dorma RTS88 105 HO BFI (Interior) ', label: 'Dorma RTS88 105 HO BFI (Interior) ' },
  { value: 'Dorma RTS88 105 NHO BFE (Exterior) ', label: 'Dorma RTS88 105 NHO BFE (Exterior) ' },
  { value: 'Dorma RTS88 105 HO BFE (Exterior) ', label: 'Dorma RTS88 105 HO BFE (Exterior) ' },
  {
    value: 'Dorma RTS88 105 NHO BFI (Interior) (Extended Spindle)',
    label: 'Dorma RTS88 105 NHO BFI (Interior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO BFI (Interior) (Extended Spindle)',
    label: 'Dorma RTS88 105 HO BFI (Interior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 NHO BFE (Exterior) (Extended Spindle)',
    label: 'Dorma RTS88 105 NHO BFE (Exterior) (Extended Spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO BFE (Exterior) (Extended Spindle) ',
    label: 'Dorma RTS88 105 HO BFE (Exterior) (Extended Spindle) ',
  },

  { value: 'Jackson 105 NHO', label: 'Jackson 105 NHO' },
  { value: 'Jackson 90 NHO', label: 'Jackson 90 NHO' },
  { value: 'Jackson 105 HO', label: 'Jackson 105 HO' },
  { value: 'Jackson 90 HO', label: 'Jackson 90 HO' },
  { value: 'Jackson 105 NHO Extended Spindle', label: 'Jackson 105 NHO Extended Spindle' },
  { value: 'Jackson 90 NHO Extended Spindle', label: 'Jackson 90 NHO Extended Spindle' },
  { value: 'Jackson 105 HO Extended Spindle', label: 'Jackson 105 HO Extended Spindle' },
  { value: 'Jackson 90 HO Extended Spindle', label: 'Jackson 90 HO Extended Spindle' },
  {
    value: 'Jackson 105 NHO 3 Valve with Back Check',
    label: 'Jackson 105 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson 90 NHO 3 Valve with Back Check',
    label: 'Jackson 90 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
  },
  {
    value: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
  },
  { value: 'International 230 105 HO', label: 'International 230 105 HO' },
  { value: 'International 231 105 NHO', label: 'International 231 105 NHO' },
  { value: 'International 232 90 HO', label: 'International 232 90 HO' },
  { value: 'International 233 90 HO', label: 'International 233 90 HO' },
  { value: 'Sentinel 3031 105 NHO', label: 'Sentinel 3031 105 NHO' },
  { value: 'Sentinel 3030 105 HO', label: 'Sentinel 3030 105 HO' },
  { value: 'Sentinel 3033 90 NHO', label: 'Sentinel 3033 90 NHO' },
  { value: 'Sentinel 3032 90 HO', label: 'Sentinel 3032 90 HO' },
  { value: 'Global TC7031 105 NHO', label: 'Global TC7031 105 NHO' },
  { value: 'Global TC7030 105 HO', label: 'Global TC7030 105 HO' },
  { value: 'Global TC7033 90 NHO', label: 'Global TC7033 90 NHO' },
  { value: 'Global TC7032 90 HO', label: 'Global TC7032 90 HO' },
  { value: 'LCN 2030 Series R/H Aluminum', label: 'LCN 2030 Series R/H Aluminum' },
  { value: 'LCN 2030 Series R/H Bronze', label: 'LCN 2030 Series R/H Bronze' },
  { value: 'LCN 2030 Series L/H Aluminum', label: 'LCN 2030 Series L/H Aluminum' },
  { value: 'LCN 2030 Series L/H Bronze', label: 'LCN 2030 Series L/H Bronze' },
  { value: 'LCN 2010 Series R/H Aluminum', label: 'LCN 2010 Series R/H Aluminum' },
  { value: 'LCN 2010 Series R/H Bronze', label: 'LCN 2010 Series R/H Bronze' },
  { value: 'LCN 2010 Series L/H Aluminum', label: 'LCN 2010 Series L/H Aluminum' },
  { value: 'LCN 2010 Series L/H Bronze', label: 'LCN 2010 Series L/H Bronze' },
  { value: 'LCN 3031 Series R/H Aluminum', label: 'LCN 3031 Series R/H Aluminum' },
  { value: 'LCN 3031 Series R/H Bronze', label: 'LCN 3031 Series R/H Bronze' },
  { value: 'LCN 3031 Series L/H Aluminum', label: 'LCN 3031 Series L/H Aluminum' },
  { value: 'LCN 3031 Series L/H Bronze', label: 'LCN 3031 Series L/H Bronze' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const topPivotFrame = [
  { value: 'Rixson Walking Beam Pivot', label: 'Rixson Walking Beam Pivot' },
  { value: 'Dorma Walking Bean Pivot 5/8"', label: 'Dorma Walking Bean Pivot 5/8"' },
  { value: 'Dorma Walking Bean Pivot 15mm', label: 'Dorma Walking Bean Pivot 15mm' },
  { value: 'Ives top pivot', label: 'Ives top pivot' },
  { value: 'International Walking Bean Pivot', label: 'International Walking Bean Pivot' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const topPivotDoor = [
  {
    value: 'Rixson Walking Beam Pivot Door Portion',
    label: 'Rixson Walking Beam Pivot Door Portion',
  },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
  },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
  },
  {
    value: 'Dorma Walking Bean Door Portion Adjustable',
    label: 'Dorma Walking Bean Door Portion Adjustable',
  },
  { value: 'Ives top pivot', label: 'Ives top pivot' },
  { value: 'International Walking Bean Pivot', label: 'International Walking Bean Pivot' },
  {
    value: 'Rixson Top Center Hung Arm end load (Rixson 800)',
    label: 'Rixson Top Center Hung Arm end load (Rixson 800)',
  },
  { value: 'Dorma Adjustable End Load Arm', label: 'Dorma Adjustable End Load Arm' },
  { value: 'Dorma Top End Load 1"', label: 'Dorma Top End Load 1"' },
  { value: 'Dorma Top End Load 3/4"', label: 'Dorma Top End Load 3/4"' },
  { value: 'Dorma Top End Load 5/8"', label: 'Dorma Top End Load 5/8"' },
  { value: 'Dorma Top Rail Side Load Arm', label: 'Dorma Top Rail Side Load Arm' },
];
export const surfaceMountedCloser = [
  { value: 'Norton 1601 Surface Closer Bronze', label: 'Norton 1601 Surface Closer Bronze' },
  { value: 'Norton 1601 Surface Closer Aluminum', label: 'Norton 1601 Surface Closer Aluminum' },
  { value: 'Norton Hold Open Arm for 1601', label: 'Norton Hold Open Arm for 1601' },
  { value: 'LCN 4040XP Surface Closer Bronze', label: 'LCN 4040XP Surface Closer Bronze' },
  { value: 'LCN 4040XP Surface Closer Aluminum', label: 'LCN 4040XP Surface Closer Aluminum' },
  { value: 'LCN Hold Open Arm For 4040XP', label: 'LCN Hold Open Arm For 4040XP' },
  { value: 'Dorma 7414 Surface Closer Bronze', label: 'Dorma 7414 Surface Closer Bronze' },
  { value: 'Dorma 7414 Surface Closer Aluminum', label: 'Dorma 7414 Surface Closer Aluminum' },
  { value: 'Dorma 8616 Surface Closer Bronze', label: 'Dorma 8616 Surface Closer Bronze' },
  { value: 'Dorma 8616 Surface Closer Aluminum', label: 'Dorma 8616 Surface Closer Aluminum' },
  {
    value: 'Dorma FH-Friction Hold Open Arm Bronze',
    label: 'Dorma FH-Friction Hold Open Arm Bronze',
  },
  {
    value: 'Dorma FH-Friction Hold Open Arm Aluminum',
    label: 'Dorma FH-Friction Hold Open Arm Aluminum',
  },
];
export const offsetArmCenterHung = [
  { value: 'Dorma Offset Top Arm', label: 'Dorma Offset Top Arm' },
  { value: 'Jackson Offset Arm 20-1312', label: 'Jackson Offset Arm 20-1312' },
  { value: 'Jackson Offset Arm 20-900', label: 'Jackson Offset Arm 20-900' },
  { value: 'Husky Offset Arm', label: 'Husky Offset Arm' },
  { value: 'Sam 2 kit Offset Arm', label: 'Sam 2 kit Offset Arm' },
  {
    value: 'Rixson Top Center Hung Arm end load (Rixson 800)',
    label: 'Rixson Top Center Hung Arm end load (Rixson 800)',
  },
  { value: 'Dorma Adjustable End Load Arm', label: 'Dorma Adjustable End Load Arm' },
  { value: 'Dorma Top End Load 1"', label: 'Dorma Top End Load 1"' },
  { value: 'Dorma Top End Load 3/4"', label: 'Dorma Top End Load 3/4"' },
  { value: 'Dorma Top End Load 5/8"', label: 'Dorma Top End Load 5/8"' },
  { value: 'Dorma Top Rail Side Load Arm ', label: 'Dorma Top Rail Side Load Arm ' },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 5/8" Pin',
  },
  {
    value: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
    label: 'Dorma top Walking Bean Pivot Door Portion 15mm Pin',
  },
  { value: 'Jackson End Load Arm 1"', label: 'Jackson End Load Arm 1"' },
  { value: 'Jackson End Load Arm 5/8"', label: 'Jackson End Load Arm 5/8"' },
  { value: 'Jackson Side Load Arm', label: 'Jackson Side Load Arm' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const offsetArmPivotsButt = [
  { value: 'Dorma Offset Top Arm', label: 'Dorma Offset Top Arm' },
  { value: 'Jackson Offset Arm 20-1312', label: 'Jackson Offset Arm 20-1312' },
  { value: 'Jackson Offset Arm 20-900', label: 'Jackson Offset Arm 20-900' },
  { value: 'Husky Offset Arm', label: 'Husky Offset Arm' },
  { value: 'Sam 2 kit Offset Arm', label: 'Sam 2 kit Offset Arm' },
  { value: 'Rixson 27 Bottom Arm', label: 'Rixson 27 Bottom Arm' },
];
export const offsetArmContinuousHinge = [
  { value: 'Dorma Offset Top Arm', label: 'Dorma Offset Top Arm' },
  { value: 'Jackson Offset Arm 20-1312', label: 'Jackson Offset Arm 20-1312' },
  { value: 'Jackson Offset Arm 20-900', label: 'Jackson Offset Arm 20-900' },
  { value: 'Husky Offset Arm', label: 'Husky Offset Arm' },
  { value: 'Sam 2 kit Offset Arm', label: 'Sam 2 kit Offset Arm' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const dropPlate = [
  { value: 'Norton Drop Plate for Header Bronze', label: 'Norton Drop Plate for Header Bronze' },
  {
    value: 'Norton Drop Plate for Header Aluminum',
    label: 'Norton Drop Plate for Header Aluminum',
  },
  { value: 'Norton Drop Plate for Door Bronze', label: 'Norton Drop Plate for Door Bronze' },
  { value: 'Norton Drop Plate for Door Aluminum', label: 'Norton Drop Plate for Door Aluminum' },
  { value: 'LCN Drop Plate For Header Bronze', label: 'LCN Drop Plate For Header Bronze' },
  { value: 'LCN Drop Plate For Header Aluminum', label: 'LCN Drop Plate For Header Aluminum' },
  { value: 'LCN Drop Plate For Door Bronze', label: 'LCN Drop Plate For Door Bronze' },
  { value: 'LCN Drop Plate For Door Aluminum', label: 'LCN Drop Plate For Door Aluminum' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const typeOfGlass = [
  { value: '1/4" Clear Laminated', label: '1/4" Clear Laminated' },
  { value: '1/4" Clear Tempered', label: '1/4" Clear Tempered' },
  { value: '1/4" Bronze Laminated', label: '1/4" Bronze Laminated' },
  { value: '1/4" Bronze Tempered', label: '1/4" Bronze Tempered' },
  { value: '1/4" Grey Laminated', label: '1/4" Grey Laminated' },
  { value: '1/4" Grey Tempered', label: '1/4" Grey Tempered' },
  { value: '3/8" Clear Laminated', label: '3/8" Clear Laminated' },
  { value: '3/8" Clear Tempered', label: '3/8" Clear Tempered' },
  { value: '3/8" Bronze Laminated', label: '3/8" Bronze Laminated' },
  { value: '3/8" Bronze Tempered', label: '3/8" Bronze Tempered' },
  { value: '3/8" Grey Laminated', label: '3/8" Grey Laminated' },
  { value: '3/8" Grey Tempered', label: '3/8" Grey Tempered' },
  { value: '1/2" Clear Laminated', label: '1/2" Clear Laminated' },
  { value: '1/2" Clear Tempered', label: '1/2" Clear Tempered' },
  { value: '1/2" Bronze Laminated', label: '1/2" Bronze Laminated' },
  { value: '1/2" Bronze Tempered', label: '1/2" Bronze Tempered' },
  { value: '1/2" Grey Laminated', label: '1/2" Grey Laminated' },
  { value: '1/2" Grey Tempered', label: '1/2" Grey Tempered' },
  { value: '1" IG Clear Tempered 1/4" lites', label: '1" IG Clear Tempered 1/4" lites' },
  {
    value: '1" IG Clear Tempered 3/16" lites',
    label: '1" IG Clear Tempered 3/16" lites',
  },
  {
    value: '1" IG Bronze Tempered 1/4" lites',
    label: '1" IG Bronze Tempered 1/4" lites',
  },
  {
    value: '1" IG Bronze Tempered 3/16" lites',
    label: '1" IG Bronze Tempered 3/16" lites',
  },
  { value: '1" IG Grey Tempered 1/4" lites', label: '1" IG Grey Tempered 1/4" lites' },
  { value: '1" IG Grey Tempered 3/16" lites', label: '1" IG Grey Tempered 3/16" lites' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const handlesStorefront = [
  {
    value: 'Standard Push Pull with Pull 8" CTC Aluminum Finish',
    label: 'Standard Push Pull with Pull 8" CTC Aluminum Finish',
  },
  {
    value: 'Standard Push Pull with Pull 9" CTC Aluminum Finish',
    label: 'Standard Push Pull with Pull 9" CTC Aluminum Finish',
  },
  {
    value: 'Standard Push Pull with Pull 12" CTC Aluminum Finish',
    label: 'Standard Push Pull with Pull 12" CTC Aluminum Finish',
  },
  {
    value: 'Standard Push Pull with Pull 8" CTC Bronze Finish',
    label: 'Standard Push Pull with Pull 8" CTC Bronze Finish',
  },
  {
    value: 'Standard Push Pull with Pull 9" CTC Bronze Finish',
    label: 'Standard Push Pull with Pull 9" CTC Bronze Finish',
  },
  {
    value: 'Standard Push Pull with Pull 12" CTC Bronze Finish',
    label: 'Standard Push Pull with Pull 12" CTC Bronze Finish',
  },
  {
    value: 'Standard Push Pull with Pull 8" CTC Black Finish',
    label: 'Standard Push Pull with Pull 8" CTC Black Finish',
  },
  {
    value: 'Standard Push Pull with Pull 9" CTC Black Finish',
    label: 'Standard Push Pull with Pull 9" CTC Black Finish',
  },
  {
    value: 'Standard Push Pull with Pull 12" CTC Black Finish',
    label: 'Standard Push Pull with Pull 12" CTC Black Finish',
  },
  {
    value: 'Back to back Pulls 8" CTC Aluminum Finish',
    label: 'Back to back Pulls 8" CTC Aluminum Finish',
  },
  {
    value: 'Back to Back Pulls 8" CTC Bronze Finish',
    label: 'Back to Back Pulls 8" CTC Bronze Finish',
  },
  {
    value: 'Back to Back Pulls 8" CTC Black Finish',
    label: 'Back to Back Pulls 8" CTC Black Finish',
  },
  {
    value: 'Back to back Pulls 9" CTC Aluminum Finish',
    label: 'Back to back Pulls 9" CTC Aluminum Finish',
  },
  {
    value: 'Back to Back Pulls 9" CTC Bronze Finish',
    label: 'Back to Back Pulls 9" CTC Bronze Finish',
  },
  {
    value: 'Back to Back Pulls 9" CTC Black Finish',
    label: 'Back to Back Pulls 9" CTC Black Finish',
  },
  {
    value: 'Back to back Pulls 12" CTC Aluminum Finish',
    label: 'Back to back Pulls 12" CTC Aluminum Finish',
  },
  {
    value: 'Back to Back Pulls 12" CTC Bronze Finish',
    label: 'Back to Back Pulls 12" CTC Bronze Finish',
  },
  {
    value: 'Back to Back Pulls 12" CTC Black Finish',
    label: 'Back to Back Pulls 12" CTC Black Finish',
  },
  { value: 'Pull Only 8" CTC Aluminum Finish', label: 'Pull Only 8" CTC Aluminum Finish' },
  { value: 'Pull Only 8" CTC Bronze Finish', label: 'Pull Only 8" CTC Bronze Finish' },
  { value: 'Pull Only 8" CTC Black Finish', label: 'Pull Only 8" CTC Black Finish' },
  { value: 'Pull Only 9" CTC Aluminum Finish', label: 'Pull Only 9" CTC Aluminum Finish' },
  { value: 'Pull Only 9" CTC Bronze Finish', label: 'Pull Only 9" CTC Bronze Finish' },
  { value: 'Pull Only 9" CTC Black Finish', label: 'Pull Only 9" CTC Black Finish' },
  { value: 'Pull Only 12" CTC Aluminum Finish', label: 'Pull Only 12" CTC Aluminum Finish' },
  { value: 'Pull Only 12" CTC Bronze Finish', label: 'Pull Only 12" CTC Bronze Finish' },
  { value: 'Pull Only 12" CTC Black Finish', label: 'Pull Only 12" CTC Black Finish' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const panicDeviceStorefront = [
  { value: 'Rim Panic device', label: 'Rim Panic device' },
  {
    value: 'Panic Device with Concealed Vertical Rods',
    label: 'Panic Device with Concealed Vertical Rods',
  },
  {
    value: 'Panic Device with Surface Mounted Vertical Rods',
    label: 'Panic Device with Surface Mounted Vertical Rods',
  },
  { value: 'Blumcraft Panic device', label: 'Blumcraft Panic device' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const weatherStrippingStorefront = [
  { value: 'Kawneer Astragal', label: 'Kawneer Astragal' },
  {
    value: 'WS125360GR Tall Gray Weatherstripping',
    label: 'WS125360GR Tall Gray Weatherstripping',
  },
  {
    value: 'WS125500BL Tall Black Weatherstripping',
    label: 'WS125500BL Tall Black Weatherstripping',
  },
  { value: 'WS187187BL Black Weatherstripping', label: 'WS187187BL Black Weatherstripping' },
  {
    value: 'WS187250GRF Tall Gray Weatherstripping W/FIN',
    label: 'WS187250GRF Tall Gray Weatherstripping W/FIN',
  },
  { value: 'WS281250GR Gray Weatherstripping', label: 'WS281250GR Gray Weatherstripping' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const floorCloserStorefront = [
  { value: 'Rixson 27 L/H 105 NHO', label: 'Rixson 27 L/H 105 NHO' },
  { value: 'Rixson 27 R/H 105 NHO', label: 'Rixson 27 R/H 105 NHO' },
  { value: 'Rixson 27 L/H 90 NHO', label: 'Rixson 27 L/H 90 NHO' },
  { value: 'Rixson 27 R/H 90 NHO', label: 'Rixson 27 R/H 90 NHO' },
  { value: 'Rixson 27 L/H 105 HO', label: 'Rixson 27 L/H 105 HO' },
  { value: 'Rixson 27 R/H 105 HO', label: 'Rixson 27 R/H 105 HO' },
  { value: 'Rixson 27 L/H 90 HO', label: 'Rixson 27 L/H 90 HO' },
  { value: 'Rixson 27 R/H 90 HO', label: 'Rixson 27 R/H 90 HO' },
  { value: 'Dorma BTS80 Floor Door Closer', label: 'Dorma BTS80 Floor Door Closer' },
  { value: 'Dorma BTS75 Floor Door Closer', label: 'Dorma BTS75 Floor Door Closer' },
  {
    value: 'Rixson Bottom Offset Arm L/H (Rixson 27)',
    label: 'Rixson Bottom Offset Arm L/H (Rixson 27)',
  },
  {
    value: 'Rixson Bottom Offset Arm R/H (Rixson 27)',
    label: 'Rixson Bottom Offset Arm R/H (Rixson 27)',
  },
  { value: 'Dorma Offset Bottom Arm', label: 'Dorma Offset Bottom Arm' },
];

export const bottomPivotStorefront = [
  { value: 'Rixson Bottom Center Hung Arm', label: 'Rixson Bottom Center Hung Arm' },
  { value: 'Rixson Bottom Flat Center hung Arm', label: 'Rixson Bottom Flat Center hung Arm' },
  { value: 'Dorma Center Hung Bottom Arm', label: 'Dorma Center Hung Bottom Arm' },
  { value: 'Dorma Bottom Pivot 7/8" Web Depth', label: 'Dorma Bottom Pivot 7/8" Web Depth' },
  {
    value: 'Dorma Bottom Pivot 1-9/16" Web Depth',
    label: 'Dorma Bottom Pivot 1-9/16" Web Depth',
  },
  { value: 'Dorma Floor Pivot BTS Spindle', label: 'Dorma Floor Pivot BTS Spindle' },
  { value: 'Dorma Adjustable Floor Pivot', label: 'Dorma Adjustable Floor Pivot' },
  {
    value: 'International Center hung bottom pivot',
    label: 'International Center hung bottom pivot',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "UP" 1-9/16"',
    label: 'Jackson Center Hung Bottom pivot "UP" 1-9/16"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "BV" 1"',
    label: 'Jackson Center Hung Bottom pivot "BV" 1"',
  },
  {
    value: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
    label: 'Jackson Center Hung Bottom pivot "G" 1-1/2"',
  },
  {
    value: 'Kawneer Adjustable Bottom Pivot Center Hung',
    label: 'Kawneer Adjustable Bottom Pivot Center Hung',
  },
  {
    value: 'Ives 7253 Complete Center Hung Pivot Set',
    label: 'Ives 7253 Complete Center Hung Pivot Set',
  },
  {
    value: 'Casma Stainless Steel Bottom Pivot Adjustable',
    label: 'Casma Stainless Steel Bottom Pivot Adjustable',
  },
  {
    value: 'Rixson 370 Bottom Center Hung Pivot Set',
    label: 'Rixson 370 Bottom Center Hung Pivot Set',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const spindleStorefront = [
  {
    value: 'Dorma Spindle for Dorma BTS80 1/8" Non Handed',
    label: 'Dorma Spindle for Dorma BTS80 1/8" Non Handed',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/8" L/H',
    label: 'Dorma Spindle for Dorma BTS80 1/8" L/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/8" R/H',
    label: 'Dorma Spindle for Dorma BTS80 1/8" R/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/4" Non Handed',
    label: 'Dorma Spindle for Dorma BTS80 1/4" Non Handed',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/4" L/H',
    label: 'Dorma Spindle for Dorma BTS80 1/4" L/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/4" R/H',
    label: 'Dorma Spindle for Dorma BTS80 1/4" R/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 3/8" Non Handed',
    label: 'Dorma Spindle for Dorma BTS80 3/8" Non Handed',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 3/8" L/H',
    label: 'Dorma Spindle for Dorma BTS80 3/8" L/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 3/8" R/H',
    label: 'Dorma Spindle for Dorma BTS80 3/8" R/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/2" Non Handed',
    label: 'Dorma Spindle for Dorma BTS80 1/2" Non Handed',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/2" L/H',
    label: 'Dorma Spindle for Dorma BTS80 1/2" L/H',
  },
  {
    value: 'Dorma Spindle for Dorma BTS80 1/2" R/H',
    label: 'Dorma Spindle for Dorma BTS80 1/2" R/H',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const typeofBeads = [
  {
    value: 'Square Beads For 1/4" glass, Aluminum Finish',
    label: 'Square Beads For 1/4" glass, Aluminum Finish',
  },
  {
    value: 'Square Beads For 3/8" glass, Aluminum Finish',
    label: 'Square Beads For 3/8" glass, Aluminum Finish',
  },
  {
    value: 'Square Beads For 1/2" glass, Aluminum Finish',
    label: 'Square Beads For 1/2" glass, Aluminum Finish',
  },
  {
    value: 'Square Beads For 1" glass, Aluminum Finish',
    label: 'Square Beads For 1" glass, Aluminum Finish',
  },
  {
    value: 'Square Beads For 1/4" glass, Bronze Finish',
    label: 'Square Beads For 1/4" glass, Bronze Finish',
  },
  {
    value: 'Square Beads For 3/8" glass, Bronze Finish',
    label: 'Square Beads For 3/8" glass, Bronze Finish',
  },
  {
    value: 'Square Beads For 1/2" glass, Bronze Finish',
    label: 'Square Beads For 1/2" glass, Bronze Finish',
  },
  {
    value: 'Square Beads For 1" glass, Bronze Finish',
    label: 'Square Beads For 1" glass, Bronze Finish',
  },
  {
    value: 'Square Beads For 1/4" glass, Black Finish',
    label: 'Square Beads For 1/4" glass, Black Finish',
  },
  {
    value: 'Square Beads For 3/8" glass, Black Finish',
    label: 'Square Beads For 3/8" glass, Black Finish',
  },
  {
    value: 'Square Beads For 1/2" glass, Black Finish',
    label: 'Square Beads For 1/2" glass, Black Finish',
  },
  {
    value: 'Square Beads For 1" glass, Black Finish',
    label: 'Square Beads For 1" glass, Black Finish',
  },
  {
    value: 'Bevel Beads For 1/4" glass, Aluminum Finish',
    label: 'Bevel Beads For 1/4" glass, Aluminum Finish',
  },
  {
    value: 'Bevel Beads For 1/4" glass, Bronze Finish',
    label: 'Bevel Beads For 1/4" glass, Bronze Finish',
  },
  {
    value: 'Bevel Beads For 1/4" glass, Black Finish',
    label: 'Bevel Beads For 1/4" glass, Black Finish',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const thumbturn = [
  { value: 'Thumb Turn Cylinder Bronze', label: 'Thumb Turn Cylinder Bronze' },
  { value: 'Thumb Turn Cylinder Aluminum', label: 'Thumb Turn Cylinder Aluminum' },
  { value: 'Thumb Turn Cylinder Brass', label: 'Thumb Turn Cylinder Brass' },
  { value: 'Key Cylinder Bronze', label: 'Key Cylinder Bronze' },
  { value: 'Key Cylinder Aluminum', label: 'Key Cylinder Aluminum' },
  { value: 'Key Cylinder Brass', label: 'Key Cylinder Brass' },
  { value: 'Dummy Cylinder Bronze', label: 'Dummy Cylinder Bronze' },
  { value: 'Dummy Cylinder Aluminum', label: 'Dummy Cylinder Aluminum' },
  { value: 'Dummy Cylinder Brass', label: 'Dummy Cylinder Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const keyCylinder = [
  { value: 'Key Cylinder Bronze', label: 'Key Cylinder Bronze' },
  { value: 'Key Cylinder Aluminum', label: 'Key Cylinder Aluminum' },
  { value: 'Key Cylinder Brass', label: 'Key Cylinder Brass' },
  { value: 'Dummy Cylinder Bronze', label: 'Dummy Cylinder Bronze' },
  { value: 'Dummy Cylinder Aluminum', label: 'Dummy Cylinder Aluminum' },
  { value: 'Dummy Cylinder Brass', label: 'Dummy Cylinder Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const lockStorefront = [
  {
    value: 'Adams Rite Dead Latch lock 4511 1 1/8 back set',
    label: 'Adams Rite Dead Latch lock 4511 1 1/8 back set',
  },
  {
    value: 'Adams Rite Dead Latch lock 4511 1 1/2 back set',
    label: 'Adams Rite Dead Latch lock 4511 1 1/2 back set',
  },
  {
    value: 'Adams Rite Dead Latch lock 4511 31/32 back set',
    label: 'Adams Rite Dead Latch lock 4511 31/32 back set',
  },
  {
    value: 'Adams Rite Dead Latch lock 4511 7/8 Back set',
    label: 'Adams Rite Dead Latch lock 4511 7/8 Back set',
  },
  {
    value: 'Adams Rite Dead Bolt lock 31/32 back set',
    label: 'Adams Rite Dead Bolt lock 31/32 back set',
  },
  {
    value: 'Adams Rite Dead Bolt lock 1 1/8 back set',
    label: 'Adams Rite Dead Bolt lock 1 1/8 back set',
  },
  {
    value: 'Adams Rite Dead Bolt lock 1 1/2 back set',
    label: 'Adams Rite Dead Bolt lock 1 1/2 back set',
  },
  {
    value: 'Adams Rite Hook Dead Bolt lock 31/32 back set',
    label: 'Adams Rite Hook Dead Bolt lock 31/32 back set',
  },
  {
    value: 'Adams Rite Hook Dead Bolt lock 1 1/8 back set',
    label: 'Adams Rite Hook Dead Bolt lock 1 1/8 back set',
  },
  {
    value: 'Adams Rite Hook Dead Bolt lock 1 1/2 back set',
    label: 'Adams Rite Hook Dead Bolt lock 1 1/2 back set',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pushPaddle = [
  { value: 'Adams Rite Push Paddle L/H Bronze', label: 'Adams Rite Push Paddle L/H Bronze' },
  { value: 'Adams Rite Push Paddle L/H Aluminum', label: 'Adams Rite Push Paddle L/H Aluminum' },
  { value: 'Adams Rite Push Paddle R/H Bronze', label: 'Adams Rite Push Paddle R/H Bronze' },
  { value: 'Adams Rite Push Paddle R/H Aluminum', label: 'Adams Rite Push Paddle R/H Aluminum' },
  {
    value: 'Adams Rite Lever handle for dead latch lock Bronze',
    label: 'Adams Rite Lever handle for dead latch lock Bronze',
  },
  {
    value: 'Adams Rite Lever handle for dead latch lock Aluminum',
    label: 'Adams Rite Lever handle for dead latch lock Aluminum',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const topFlushBolt = [
  { value: 'Flush Bolt Aluminum', label: 'Flush Bolt Aluminum' },
  { value: 'Flush Bolt Bronze', label: 'Flush Bolt Bronze' },
  { value: 'Flush Bolt Brass', label: 'Flush Bolt Brass' },
  { value: 'Sliding Bolt', label: 'Sliding Bolt' },
];
export const bottomFlushBolt = [
  { value: 'Flush Bolt Aluminum', label: 'Flush Bolt Aluminum' },
  { value: 'Flush Bolt Bronze', label: 'Flush Bolt Bronze' },
  { value: 'Flush Bolt Brass', label: 'Flush Bolt Brass' },
  { value: 'Sliding Bolt', label: 'Sliding Bolt' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const topPivotOffset = [
  { value: 'Rixson top Offset Pivot', label: 'Rixson top Offset Pivot' },
  { value: 'Vistawall Offset Top pivot Bronze', label: 'Vistawall Offset Top pivot Bronze' },
  { value: 'Vistawall Offset Top pivot Aluminum', label: 'Vistawall Offset Top pivot Aluminum' },
  { value: 'Kawneer Offset Top pivot Bronze', label: 'Kawneer Offset Top pivot Bronze' },
  { value: 'Kawneer Offset Top pivot Aluminum', label: 'Kawneer Offset Top pivot Aluminum' },
  { value: 'YKK Offset Top pivot Bronze', label: 'YKK Offset Top pivot Bronze' },
  { value: 'YKK Offset Top pivot Aluminum', label: 'YKK Offset Top pivot Aluminum' },
  { value: 'US Aluminum Offset Top pivot Bronze', label: 'US Aluminum Offset Top pivot Bronze' },
  {
    value: 'US Aluminum Offset Top pivot Aluminum',
    label: 'US Aluminum Offset Top pivot Aluminum',
  },
  {
    value: 'Architectural Design Offset Top pivot Bronze',
    label: 'Architectural Design Offset Top pivot Bronze',
  },
  {
    value: 'Architectural Design Offset Top pivot Aluminum',
    label: 'Architectural Design Offset Top pivot Aluminum',
  },
];
export const intermediatePivot = [
  { value: 'Rixson Intermediate pivot L/H', label: 'Rixson Intermediate pivot L/H' },
  { value: 'Rixson Intermediate pivot R/H', label: 'Rixson Intermediate pivot R/H' },
  {
    value: 'Vistawall Intermediate pivot L/H Bronze',
    label: 'Vistawall Intermediate pivot L/H Bronze',
  },
  {
    value: 'Vistawall Intermediate pivot R/H Bronze',
    label: 'Vistawall Intermediate pivot R/H Bronze',
  },
  {
    value: 'Vistawall Intermediate pivot L/H Aluminum',
    label: 'Vistawall Intermediate pivot L/H Aluminum',
  },
  {
    value: 'Vistawall Intermediate pivot R/H aluminum',
    label: 'Vistawall Intermediate pivot R/H aluminum',
  },
  {
    value: 'Kawneer Intermediate pivot L/H Bronze',
    label: 'Kawneer Intermediate pivot L/H Bronze',
  },
  {
    value: 'Kawneer Intermediate pivot R/H Bronze',
    label: 'Kawneer Intermediate pivot R/H Bronze',
  },
  {
    value: 'Kawneer Intermediate pivot L/H Aluminum',
    label: 'Kawneer Intermediate pivot L/H Aluminum',
  },
  {
    value: 'Kawneer Intermediate pivot R/H aluminum',
    label: 'Kawneer Intermediate pivot R/H aluminum',
  },
  { value: 'YKK Intermediate pivot L/H Bronze', label: 'YKK Intermediate pivot L/H Bronze' },
  { value: 'YKK Intermediate pivot R/H Bronze', label: 'YKK Intermediate pivot R/H Bronze' },
  { value: 'YKK Intermediate pivot L/H Aluminum', label: 'YKK Intermediate pivot L/H Aluminum' },
  { value: 'YKK Intermediate pivot R/H aluminum', label: 'YKK Intermediate pivot R/H aluminum' },
  {
    value: 'US Aluminum Intermediate pivot L/H Bronze',
    label: 'US Aluminum Intermediate pivot L/H Bronze',
  },
  {
    value: 'US Aluminum Intermediate pivot R/H Bronze',
    label: 'US Aluminum Intermediate pivot R/H Bronze',
  },
  {
    value: 'US Aluminum Intermediate pivot L/H Aluminum',
    label: 'US Aluminum Intermediate pivot L/H Aluminum',
  },
  {
    value: 'US Aluminum Intermediate pivot R/H aluminum',
    label: 'US Aluminum Intermediate pivot R/H aluminum',
  },
  {
    value: 'Architectural Design Intermediate pivot L/H Bronze',
    label: 'Architectural Design Intermediate pivot L/H Bronze',
  },
  {
    value: 'Architectural Design Intermediate pivot R/H Bronze',
    label: 'Architectural Design Intermediate pivot R/H Bronze',
  },
  {
    value: 'Architectural Design Intermediate pivot L/H Aluminum',
    label: 'Architectural Design Intermediate pivot L/H Aluminum',
  },
];
export const bottomPivootOffset = [
  { value: 'Rixson Bottom Offset Pivot L/H', label: 'Rixson Bottom Offset Pivot L/H' },
  { value: 'Rixson Bottom Offset Pivot R/H', label: 'Rixson Bottom Offset Pivot R/H' },
  {
    value: 'Vistawall Offset Bottom pivot L/H Bronze',
    label: 'Vistawall Offset Bottom pivot L/H Bronze',
  },
  {
    value: 'Vistawall Offset Bottom pivot R/H Bronze',
    label: 'Vistawall Offset Bottom pivot R/H Bronze',
  },
  {
    value: 'Vistawall Offset Bottom pivot L/H Aluminum',
    label: 'Vistawall Offset Bottom pivot L/H Aluminum',
  },
  {
    value: 'Vistawall Offset Bottom pivot R/H Aluminum',
    label: 'Vistawall Offset Bottom pivot R/H Aluminum',
  },
  {
    value: 'kawneer Offset Bottom pivot L/H Bronze',
    label: 'kawneer Offset Bottom pivot L/H Bronze',
  },
  {
    value: 'Kawneer Offset Bottom pivot R/H Bronze',
    label: 'Kawneer Offset Bottom pivot R/H Bronze',
  },
  {
    value: 'Kawneer Offset Bottom pivot L/H Aluminum',
    label: 'Kawneer Offset Bottom pivot L/H Aluminum',
  },
  {
    value: 'Kawneer Offset Bottom pivot R/H Aluminum',
    label: 'Kawneer Offset Bottom pivot R/H Aluminum',
  },
  { value: 'YKK Offset Bottom pivot L/H Bronze', label: 'YKK Offset Bottom pivot L/H Bronze' },
  { value: 'YKK Offset Bottom pivot R/H Bronze', label: 'YKK Offset Bottom pivot R/H Bronze' },
  {
    value: 'US Aluminum Offset Bottom pivot L/H Bronze',
    label: 'US Aluminum Offset Bottom pivot L/H Bronze',
  },
  {
    value: 'US Aluminum Offset Bottom pivot R/H Bronze',
    label: 'US Aluminum Offset Bottom pivot R/H Bronze',
  },
  {
    value: 'US Aluminum Offset Bottom pivot L/H Aluminum',
    label: 'US Aluminum Offset Bottom pivot L/H Aluminum',
  },
  {
    value: 'US Aluminum Offset Bottom pivot R/H Aluminum',
    label: 'US Aluminum Offset Bottom pivot R/H Aluminum',
  },
  {
    value: 'Architectural Design Offset Bottom pivot L/H Bronze',
    label: 'Architectural Design Offset Bottom pivot L/H Bronze',
  },
  {
    value: 'Architectural Design Offset Bottom pivot R/H Bronze',
    label: 'Architectural Design Offset Bottom pivot R/H Bronze',
  },
  {
    value: 'Architectural Design Offset Bottom pivot L/H Aluminum',
    label: 'Architectural Design Offset Bottom pivot L/H Aluminum',
  },
  {
    value: 'Architectural Design Offset Bottom pivot R/H Aluminum',
    label: 'Architectural Design Offset Bottom pivot R/H Aluminum',
  },
];
export const continuousHinge = [
  {
    value: 'Full Surface Continuous Hinge 84" Bronze',
    label: 'Full Surface Continuous Hinge 84" Bronze',
  },
  {
    value: 'Full Surface Continuous Hinge 84" Aluminum',
    label: 'Full Surface Continuous Hinge 84" Aluminum',
  },
  {
    value: 'Full Surface Continuous Hinge 96" Bronze',
    label: 'Full Surface Continuous Hinge 96" Bronze',
  },
  {
    value: 'Full Surface Continuous Hinge 96" Aluminum',
    label: 'Full Surface Continuous Hinge 96" Aluminum',
  },
  {
    value: 'Full  Mortise Continuous Hinge 84" Bronze',
    label: 'Full  Mortise Continuous Hinge 84" Bronze',
  },
  {
    value: 'Full  Mortise Continuous Hinge 84" Aluminum',
    label: 'Full  Mortise Continuous Hinge 84" Aluminum',
  },
  {
    value: 'Full  Mortise Continuous Hinge 96" Bronze',
    label: 'Full  Mortise Continuous Hinge 96" Bronze',
  },
  {
    value: 'Full  Mortise Continuous Hinge 96" Aluminum',
    label: 'Full  Mortise Continuous Hinge 96" Aluminum',
  },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Bronze', label: 'Butt Hinges 4 1/2 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum', label: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Brass', label: 'Butt Hinges 4 1/2 x 4 1/2 Brass' },
  { value: 'Butt Hinges 4 x 4 1/2 Bronze', label: 'Butt Hinges 4 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 x 4 1/2 Aluminum', label: 'Butt Hinges 4 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 x 4 1/2 Brass', label: 'Butt Hinges 4 x 4 1/2 Brass' },
];

/* HOLLOW METAL DOOR PARTS REPAIR */
export const hmdBrand = [
  { value: 'American Door', label: 'American Door' },
  { value: 'Atlantic Builders', label: 'Atlantic Builders' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdCocCloser = [
  { value: 'Rixson 800 90 NHO', label: 'Rixson 800 90 NHO' },
  { value: 'Rixson 800 105 NHO', label: 'Rixson 800 105 NHO' },
  { value: 'Rixson 800 90 HO COC Closer', label: 'Rixson 800 90 HO COC Closer' },
  { value: 'Rixson 800 105 HO COC Closer', label: 'Rixson 800 105 HO COC Closer' },
  { value: 'Dorma RTS88 105 NHO SZ3', label: 'Dorma RTS88 105 NHO SZ3' },
  { value: 'Dorma RTS88 90 NHO SZ3', label: 'Dorma RTS88 90 NHO SZ3' },
  { value: 'Dorma RTS88 105 HO SZ3', label: 'Dorma RTS88 105 HO SZ3' },
  { value: 'Dorma RTS88 90 HO SZ3', label: 'Dorma RTS88 90 HO SZ3' },
  {
    value: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
    label: 'Dorma RTS88 105 NHO SZ3 5MM (extended Spindle)',
  },
  {
    value: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 NHO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 105 HO SZ3 5MM (extended spindle)',
  },
  {
    value: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
    label: 'Dorma RTS88 90 HO SZ3 5MM (extended spindle)',
  },
  { value: 'Jackson 105 NHO', label: 'Jackson 105 NHO' },
  { value: 'Jackson 90 NHO', label: 'Jackson 90 NHO' },
  { value: 'Jackson 105 HO', label: 'Jackson 105 HO' },
  { value: 'Jackson 90 HO', label: 'Jackson 90 HO' },
  { value: 'Jackson 105 NHO Extended Spindle', label: 'Jackson 105 NHO Extended Spindle' },
  { value: 'Jackson 90 NHO Extended Spindle', label: 'Jackson 90 NHO Extended Spindle' },
  { value: 'Jackson 105 HO Extended Spindle', label: 'Jackson 105 HO Extended Spindle' },
  { value: 'Jackson 90 HO Extended Spindle', label: 'Jackson 90 HO Extended Spindle' },
  {
    value: 'Jackson 105 NHO 3 Valve with Back Check',
    label: 'Jackson 105 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson 90 NHO 3 Valve with Back Check',
    label: 'Jackson 90 NHO 3 Valve with Back Check',
  },
  {
    value: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4" frame (Old Husky)',
  },
  {
    value: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
    label: 'Jackson Retro Fit Kit for 4 1/2" frame (Old Husky)',
  },
  { value: 'Sentinel 3031 105 NHO', label: 'Sentinel 3031 105 NHO' },
  { value: 'Sentinel 3030 105 HO', label: 'Sentinel 3030 105 HO' },
  { value: 'Sentinel 3033 90 NHO', label: 'Sentinel 3033 90 NHO' },
  { value: 'Sentinel 3032 90 HO', label: 'Sentinel 3032 90 HO' },
  { value: 'Global TC7031 105 NHO', label: 'Global TC7031 105 NHO' },
  { value: 'Global TC7030 105 HO', label: 'Global TC7030 105 HO' },
  { value: 'Global TC7033 90 NHO', label: 'Global TC7033 90 NHO' },
  { value: 'Global TC7032 90 HO', label: 'Global TC7032 90 HO' },
  { value: 'LCN 2030 Series R/H Aluminum', label: 'LCN 2030 Series R/H Aluminum' },
  { value: 'LCN 2030 Series R/H Bronze', label: 'LCN 2030 Series R/H Bronze' },
  { value: 'LCN 2030 Series L/H Aluminum', label: 'LCN 2030 Series L/H Aluminum' },
  { value: 'LCN 2030 Series L/H Bronze', label: 'LCN 2030 Series L/H Bronze' },
  { value: 'LCN 2010 Series R/H Aluminum', label: 'LCN 2010 Series R/H Aluminum' },
  { value: 'LCN 2010 Series R/H Bronze', label: 'LCN 2010 Series R/H Bronze' },
  { value: 'LCN 2010 Series L/H Aluminum', label: 'LCN 2010 Series L/H Aluminum' },
  { value: 'LCN 2010 Series L/H Bronze', label: 'LCN 2010 Series L/H Bronze' },
  { value: 'LCN 3031 Series R/H Aluminum', label: 'LCN 3031 Series R/H Aluminum' },
  { value: 'LCN 3031 Series R/H Bronze', label: 'LCN 3031 Series R/H Bronze' },
  { value: 'LCN 3031 Series L/H Aluminum', label: 'LCN 3031 Series L/H Aluminum' },
  { value: 'LCN 3031 Series L/H Bronze', label: 'LCN 3031 Series L/H Bronze' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdSurfaceMountedCloser = [
  { value: 'Norton 1601 Surface Closer Bronze', label: 'Norton 1601 Surface Closer Bronze' },
  { value: 'Norton 1601 Surface Closer Aluminum', label: 'Norton 1601 Surface Closer Aluminum' },
  { value: 'Norton Hold Open Arm for 1601', label: 'Norton Hold Open Arm for 1601' },
  { value: 'LCN 4040XP Surface Closer Bronze', label: 'LCN 4040XP Surface Closer Bronze' },
  { value: 'LCN 4040XP Surface Closer Aluminum', label: 'LCN 4040XP Surface Closer Aluminum' },
  { value: 'LCN Hold Open Arm For 4040XP', label: 'LCN Hold Open Arm For 4040XP' },
  { value: 'Dorma 7414 Surface Closer Bronze', label: 'Dorma 7414 Surface Closer Bronze' },
  { value: 'Dorma 7414 Surface Closer Aluminum', label: 'Dorma 7414 Surface Closer Aluminum' },
  { value: 'Dorma 8616 Surface Closer Bronze', label: 'Dorma 8616 Surface Closer Bronze' },
  { value: 'Dorma 8616 Surface Closer Aluminum', label: 'Dorma 8616 Surface Closer Aluminum' },
  {
    value: 'Dorma FH-Friction Hold Open Arm Bronze',
    label: 'Dorma FH-Friction Hold Open Arm Bronze',
  },
  {
    value: 'Dorma FH-Friction Hold Open Arm Aluminum',
    label: 'Dorma FH-Friction Hold Open Arm Aluminum',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdOffsetArm = [
  { value: 'Dorma Offset Top Arm', label: 'Dorma Offset Top Arm' },
  { value: 'Jackson Offset Arm 20-1312', label: 'Jackson Offset Arm 20-1312' },
  { value: 'Jackson Offset Arm 20-900', label: 'Jackson Offset Arm 20-900' },
  { value: 'Husky Offset Arm', label: 'Husky Offset Arm' },
  { value: 'Sam 2 kit Offset Arm', label: 'Sam 2 kit Offset Arm' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdPanicDeviceRim = [
  { value: 'Von Duprin Rim Panic Device', label: 'Von Duprin Rim Panic Device' },
  { value: 'Yale Rim Panic Device', label: 'Yale Rim Panic Device' },
  { value: 'Monarch Rim Panic Device', label: 'Monarch Rim Panic Device' },
  { value: 'Jackson Rim Panic Device', label: 'Jackson Rim Panic Device' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdPanicDeviceVerticalRods = [
  {
    value: 'Von Duprin Panic Device with Concealed Vertical Rods',
    label: 'Von Duprin Panic Device with Concealed Vertical Rods',
  },
  {
    value: 'Yale Panic Device with Concealed Vertical Rods',
    label: 'Yale Panic Device with Concealed Vertical Rods',
  },
  {
    value: 'Monarch Panic Device with Concealed Vertical Rods',
    label: 'Monarch Panic Device with Concealed Vertical Rods',
  },
  {
    value: 'Jackson Panic Device with Concealed Vertical Rods',
    label: 'Jackson Panic Device with Concealed Vertical Rods',
  },
  {
    value: 'Von Duprin Panic Device with Surface Mounted Vertical Rods',
    label: 'Von Duprin Panic Device with Surface Mounted Vertical Rods',
  },
  {
    value: 'Yale Panic Device with Surface Mounted Vertical Rods',
    label: 'Yale Panic Device with Surface Mounted Vertical Rods',
  },
  {
    value: 'Monarch Panic Device with Surface Mounted Vertical Rods',
    label: 'Monarch Panic Device with Surface Mounted Vertical Rods',
  },
  {
    value: 'Jackson Panic Device with Surface Mounted Vertical Rods',
    label: 'Jackson Panic Device with Surface Mounted Vertical Rods',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdWeatherStripping = [
  { value: 'Door Sweep', label: 'Door Sweep' },
  { value: 'Corner Seal', label: 'Corner Seal' },
  { value: 'Jamb Seal', label: 'Jamb Seal' },
  { value: 'Perimeter Gasket', label: 'Perimeter Gasket' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdBottomArm = [
  {
    value: 'Rixson Bottom Offset Arm (Rixson 800)',
    label: 'Rixson Bottom Offset Arm (Rixson 800)',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdFloorCloser = [
  {
    value: 'Rixson 800 90 NHO Floor Closer W/Spindle',
    label: 'Rixson 800 90 NHO Floor Closer W/Spindle',
  },
  { value: 'Rixson 800 105 NHO Closer W/Spindle', label: 'Rixson 800 105 NHO Closer W/Spindle' },
  { value: 'Rixson 800 90 HO Closer W/Spindle', label: 'Rixson 800 90 HO Closer W/Spindle' },
  { value: 'Rixson 800 105 HO Closer W/Spindle', label: 'Rixson 800 105 HO Closer W/Spindle' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdThreshold = [
  {
    value: '4" x 36" x 1/4" Aluminum Threshold',
    label: '4" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/4" Aluminum Threshold',
    label: '5" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/4" Aluminum Threshold',
    label: '7" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/4" Aluminum Threshold',
    label: '8" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/4" Aluminum Threshold',
    label: '4" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/4" Aluminum Threshold',
    label: '5" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/4" Aluminum Threshold',
    label: '7" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/4" Aluminum Threshold',
    label: '8" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/4" Aluminum Threshold',
    label: '4" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/4" Aluminum Threshold',
    label: '5" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/4" Aluminum Threshold',
    label: '7" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/4" Aluminum Threshold',
    label: '8" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/2" Aluminum Threshold',
    label: '4" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/2" Aluminum Threshold',
    label: '5" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/2" Aluminum Threshold',
    label: '7" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/2" Aluminum Threshold',
    label: '8" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/2" Aluminum Threshold',
    label: '4" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/2" Aluminum Threshold',
    label: '5" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/2" Aluminum Threshold',
    label: '7" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/2" Aluminum Threshold',
    label: '8" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/2" Aluminum Threshold',
    label: '4" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/2" Aluminum Threshold',
    label: '5" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/2" Aluminum Threshold',
    label: '7" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/2" Aluminum Threshold',
    label: '8" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/4" Bronze Threshold',
    label: '4" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/4" Bronze Threshold',
    label: '5" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/4" Bronze Threshold',
    label: '7" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/4" Bronze Threshold',
    label: '8" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/4" Bronze Threshold',
    label: '4" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/4" Bronze Threshold',
    label: '5" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/4" Bronze Threshold',
    label: '7" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/4" Bronze Threshold',
    label: '8" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/4" Bronze Threshold',
    label: '4" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/4" Bronze Threshold',
    label: '5" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/4" Bronze Threshold',
    label: '7" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/4" Bronze Threshold',
    label: '8" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/2" Bronze Threshold',
    label: '4" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/2" Bronze Threshold',
    label: '5" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/2" Bronze Threshold',
    label: '7" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/2" Bronze Threshold',
    label: '8" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/2" Bronze Threshold',
    label: '4" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/2" Bronze Threshold',
    label: '5" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/2" Bronze Threshold',
    label: '7" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/2" Bronze Threshold',
    label: '8" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/2" Bronze Threshold',
    label: '4" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/2" Bronze Threshold',
    label: '5" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/2" Bronze Threshold',
    label: '7" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/2" Bronze Threshold',
    label: '8" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/4" Brass Threshold',
    label: '4" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/4" Brass Threshold',
    label: '5" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/4" Brass Threshold',
    label: '7" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/4" Brass Threshold',
    label: '8" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/4" Brass Threshold',
    label: '4" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/4" Brass Threshold',
    label: '5" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/4" Brass Threshold',
    label: '7" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/4" Brass Threshold',
    label: '8" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/4" Brass Threshold',
    label: '4" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/4" Brass Threshold',
    label: '5" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/4" Brass Threshold',
    label: '7" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/4" Brass Threshold',
    label: '8" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 36" x 1/2" Brass Threshold',
    label: '4" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/2" Brass Threshold',
    label: '5" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/2" Brass Threshold',
    label: '7" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/2" Brass Threshold',
    label: '8" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/2" Brass Threshold',
    label: '4" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/2" Brass Threshold',
    label: '5" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/2" Brass Threshold',
    label: '7" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/2" Brass Threshold',
    label: '8" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/2" Brass Threshold',
    label: '4" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/2" Brass Threshold',
    label: '5" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/2" Brass Threshold',
    label: '7" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/2" Brass Threshold',
    label: '8" x 72" x 1/2" Brass Threshold',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdLock = [
  { value: 'Mortise Lock', label: 'Mortise Lock' },
  { value: 'Lockset', label: 'Lockset' },
  { value: 'Dead Bolt', label: 'Dead Bolt' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdKnobLock = [
  { value: 'Aluminum Finish', label: 'Aluminum Finish' },
  { value: 'Satin Stainless Steel', label: 'Satin Stainless Steel' },
  { value: 'Polished Stainless Steel', label: 'Polished Stainless Steel' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdLeverLock = [
  { value: 'Aluminum Finish', label: 'Aluminum Finish' },
  { value: 'Satin Stainless Steel', label: 'Satin Stainless Steel' },
  { value: 'Polished Stainless Steel', label: 'Polished Stainless Steel' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdTopFlushBolt = [
  { value: 'Aluminum Finish', label: 'Aluminum Finish' },
  { value: 'Satin Stainless Steel', label: 'Satin Stainless Steel' },
  { value: 'Polished Stainless Steel', label: 'Polished Stainless Steel' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdBottomFlushBolt = [
  { value: 'Aluminum Finish', label: 'Aluminum Finish' },
  { value: 'Satin Stainless Steel', label: 'Satin Stainless Steel' },
  { value: 'Polished Stainless Steel', label: 'Polished Stainless Steel' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdContinuousHinge = [
  {
    value: 'Full Surface Continuous Hinge 84" Bronze',
    label: 'Full Surface Continuous Hinge 84" Bronze',
  },
  {
    value: 'Full Surface Continuous Hinge 84" Aluminum',
    label: 'Full Surface Continuous Hinge 84" Aluminum',
  },
  {
    value: 'Full Surface Continuous Hinge 96" Bronze',
    label: 'Full Surface Continuous Hinge 96" Bronze',
  },
  {
    value: 'Full Surface Continuous Hinge 96" Aluminum',
    label: 'Full Surface Continuous Hinge 96" Aluminum',
  },
  {
    value: 'Full  Mortise Continuous Hinge 84" Bronze',
    label: 'Full  Mortise Continuous Hinge 84" Bronze',
  },
  {
    value: 'Full  Mortise Continuous Hinge 84" Aluminum',
    label: 'Full  Mortise Continuous Hinge 84" Aluminum',
  },
  {
    value: 'Full  Mortise Continuous Hinge 96" Bronze',
    label: 'Full  Mortise Continuous Hinge 96" Bronze',
  },
  {
    value: 'Full  Mortise Continuous Hinge 96" Aluminum',
    label: 'Full  Mortise Continuous Hinge 96" Aluminum',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
/* ButtHinge */
export const hmdTopHinge = [
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Bronze', label: 'Butt Hinges 4 1/2 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum', label: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Brass', label: 'Butt Hinges 4 1/2 x 4 1/2 Brass' },
  { value: 'Butt Hinges 4 x 4 1/2 Bronze', label: 'Butt Hinges 4 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 x 4 1/2 Aluminum', label: 'Butt Hinges 4 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 x 4 1/2 Brass', label: 'Butt Hinges 4 x 4 1/2 Brass' },
  {
    value: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
    label: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdIntermediateHinge = [
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Bronze', label: 'Butt Hinges 4 1/2 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum', label: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Brass', label: 'Butt Hinges 4 1/2 x 4 1/2 Brass' },
  { value: 'Butt Hinges 4 x 4 1/2 Bronze', label: 'Butt Hinges 4 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 x 4 1/2 Aluminum', label: 'Butt Hinges 4 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 x 4 1/2 Brass', label: 'Butt Hinges 4 x 4 1/2 Brass' },
  {
    value: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
    label: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const hmdBottomHinge = [
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Bronze', label: 'Butt Hinges 4 1/2 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum', label: 'Butt Hinges 4 1/2 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 1/2 x 4 1/2 Brass', label: 'Butt Hinges 4 1/2 x 4 1/2 Brass' },
  { value: 'Butt Hinges 4 x 4 1/2 Bronze', label: 'Butt Hinges 4 x 4 1/2 Bronze' },
  { value: 'Butt Hinges 4 x 4 1/2 Aluminum', label: 'Butt Hinges 4 x 4 1/2 Aluminum' },
  { value: 'Butt Hinges 4 x 4 1/2 Brass', label: 'Butt Hinges 4 x 4 1/2 Brass' },
  {
    value: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
    label: '4 1/2 x 4 1/2 SINGLE ACTING SPRING HINGE',
  },
  { value: 'Research', label: 'Research' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

/* SHOWER DOOR - BROKEN GLASS */

export const sdGlassColorThickness = [
  { value: '3/8" Clear', label: '3/8" Clear' },
  { value: '3/8" Bronze', label: '3/8" Bronze' },
  { value: '3/8" Gray', label: '3/8" Gray' },
  { value: '3/8" Lami Clear', label: '3/8" Lami Clear' },
  { value: '3/8" Lami Bronze', label: '3/8" Lami Bronze' },
  { value: '3/8" Lami Gray', label: '3/8" Lami Gray' },
  { value: '3/8" Acid Etched', label: '3/8" Acid Etched' },
  { value: '3/8" Low Iron', label: '3/8" Low Iron' },

  { value: '1/2" Clear', label: '1/2" Clear ' },
  { value: '1/2" Bronze', label: '1/2" Bronze ' },
  { value: '1/2" Gray', label: '1/2" Gray' },
  { value: '1/2" Lami Clear', label: '1/2" Lami Clear' },
  { value: '1/2" Lami Bronze', label: '1/2" Lami Bronze' },
  { value: '1/2" Lami Gray', label: '1/2" Lami Gray' },
  { value: '1/2" Acid Etched', label: '1/2" Acid Etched' },
  { value: '1/2" Low Iron', label: '1/2" Low Iron' },

  { value: '5/8" Clear', label: '5/8" Clear' },
  { value: '5/8" Bronze', label: '5/8" Bronze ' },
  { value: '5/8" Gray', label: '5/8" Gray' },
  { value: '5/8" Lami Clear', label: '5/8" Lami Clear' },
  { value: '5/8" Lami Bronze', label: '5/8" Lami Bronze' },
  { value: '5/8" Lami Clear', label: '5/8" Lami Gray' },
  { value: '5/8" Acid Etched', label: '5/8" Acid Etched' },
  { value: '5/8" Low Iron', label: '5/8" Low Iron ' },

  { value: '3/4" Clear', label: '3/4" Clear' },
  { value: '3/4" Bronze', label: '3/4" Bronze' },
  { value: '3/4" Gray', label: '3/4" Gray' },
  { value: '3/4" Lami Clear', label: '3/4" Lami Clear' },
  { value: '3/4" Lami Bronze', label: '3/4" Lami Bronze' },
  { value: '3/4" Lami Gray', label: '3/4" Lami Gray' },
  { value: '3/4" Acid Etched', label: '3/4" Acid Etched' },
  { value: '3/4" Low Iron ', label: '3/4" Low Iron ' },

  { value: '1" Clear', label: '1" Clear' },
  { value: '1" Bronze', label: '1" Bronze' },
  { value: '1" Gray', label: '1" Gray' },
  { value: '1" Lami Clear', label: '1" Lami Clear' },
  { value: '1" Lami Bronze', label: '1" Lami Bronze' },
  { value: '1" Lami Gray', label: '1" Lami Gray' },
  { value: '1" Acid Etched', label: '1" Acid Etched' },
  { value: '1" Low Iron', label: '1" Low Iron' },
];
export const sdPolishedEdges = [
  { value: 'all Clear', label: 'All' },
  { value: 'custom', label: 'custom' },
];
export const sdHoleSize = [
  { value: '1/2"', label: '1/2"' },
  { value: '5/8"', label: '5/8"' },
  { value: '3/4"', label: '3/4"' },
  { value: '1"', label: '1"' },
  { value: 'Custom', label: 'Custom' },
];

/* -------------------------------------------NEW DOORS */
/* GLASS DOORS */

export const ndgdDoorHeader = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Floor to Ceiling',
    label: 'Floor to Ceiling',
  },
  {
    value: 'Floating Top',
    label: 'Floating Top',
  },
  {
    value: 'Header on Top',
    label: 'Header on Top',
  },
  {
    value: 'Support Bar',
    label: 'Support Bar',
  },
  {
    value: 'Ceiling Bracket',
    label: 'Ceiling Bracket',
  },
];

export const ndgdDoorGlassCoating = [
  {
    value: 'ShowerGuard',
    label: 'ShowerGuard',
  },
  {
    value: 'EnduroShield',
    label: 'EnduroShield',
  },
  {
    value: 'ClearShield',
    label: 'ClearShield',
  },
  {
    value: 'DiamondFusion',
    label: 'DiamondFusion',
  },
  {
    value: 'Cardinal10',
    label: 'Cardinal10',
  },
];

export const h_onsite = [
  {
    value: 'YES',
    label: 'YES',
  },
  {
    value: 'PICK UP & BRING TO GLASS SHOP',
    label: 'PICK UP & BRING TO GLASS SHOP',
  },
  {
    value: 'ALREADY PICKED UP & BROUGHT BACK TO GLASS SHOP',
    label: 'ALREADY PICKED UP & BROUGHT BACK TO GLASS SHOP',
  },
  {
    value: 'NO, REPLACE',
    label: 'NO, REPLACE',
  },
];
export const ndgdGlassTypeShower = [
  {
    value: '3/8 CLEAR',
    label: '3/8 CLEAR',
  },
  {
    value: '3/8 Low Iron',
    label: '3/8 Low Iron',
  },
  {
    value: '3/8 Starfire',
    label: '3/8 Starfire',
  },
  {
    value: '3/8 Optiwhite',
    label: '3/8 Optiwhite',
  },
  {
    value: '3/8 UltraClear',
    label: '3/8 UltraClear',
  },
  {
    value: '3/8 ExtraClear',
    label: '3/8 ExtraClear',
  },
  {
    value: '3/8 BRONZE',
    label: '3/8 BRONZE',
  },
  {
    value: '3/8 GRAY',
    label: '3/8 GRAY',
  },
  {
    value: '3/8 ACID ETCH/SATIN',
    label: '3/8 ACID ETCH/SATIN',
  },
  {
    value: '3/8 RAIN',
    label: '3/8 RAIN',
  },
  {
    value: '3/8 AQUATEX',
    label: '3/8 AQUATEX',
  },
  {
    value: '3/8 OTHER',
    label: '3/8 OTHER',
  },
  {
    value: '1/2 CLEAR',
    label: '1/2 CLEAR',
  },
  {
    value: '1/2 Low Iron',
    label: '1/2 Low Iron',
  },
  {
    value: '1/2 Starfire',
    label: '1/2 Starfire',
  },
  {
    value: '1/2 Optiwhite',
    label: '1/2 Optiwhite',
  },
  {
    value: '1/2 UltraClear',
    label: '1/2 UltraClear',
  },
  {
    value: '1/2 ExtraClear',
    label: '1/2 ExtraClear',
  },
  {
    value: '1/2 BRONZE',
    label: '1/2 BRONZE',
  },
  {
    value: '1/2 GRAY',
    label: '1/2 GRAY',
  },
  {
    value: '1/2 ACID ETCH/SATIN',
    label: '1/2 ACID ETCH/SATIN',
  },
  {
    value: '1/2 RAIN',
    label: '1/2 RAIN',
  },
  {
    value: '1/2 AQUATEX',
    label: '1/2 AQUATEX',
  },
  {
    value: '1/2 OTHER',
    label: '1/2 OTHER',
  },
  {
    value: '1/4 CLEAR',
    label: '1/4 CLEAR',
  },
  {
    value: '1/4 Low Iron',
    label: '1/4 Low Iron',
  },
  {
    value: '1/4 Starfire',
    label: '1/4 Starfire',
  },
  {
    value: '1/4 Optiwhite',
    label: '1/4 Optiwhite',
  },
  {
    value: '1/4 UltraClear',
    label: '1/4 UltraClear',
  },
  {
    value: '1/4 ExtraClear',
    label: '1/4 ExtraClear',
  },
  {
    value: '1/4 BRONZE',
    label: '1/4 BRONZE',
  },
  {
    value: '1/4 GRAY',
    label: '1/4 GRAY',
  },
  {
    value: '1/4 AQUATEX',
    label: '1/4 AQUATEX',
  },
  {
    value: '1/4 RAIN',
    label: '1/4 RAIN',
  },

  {
    value: '1/4 AQUATEX',
    label: '1/4 AQUATEX',
  },
  {
    value: '1/4 OTHER',
    label: '1/4 OTHER',
  },

  {
    value: '3/16 CLEAR',
    label: '3/16 CLEAR',
  },
  {
    value: '3/16 Low Iron',
    label: '3/16 Low Iron',
  },

  {
    value: '3/16 Starfire',
    label: '3/16 Starfire',
  },
  {
    value: '3/16 Optiwhite',
    label: '3/16 Optiwhite',
  },

  {
    value: '3/16 UltraClear',
    label: '3/16 UltraClear',
  },
  {
    value: '3/16 ExtraClear',
    label: '3/16 ExtraClear',
  },
  {
    value: '3/16 BRONZE',
    label: '3/16 BRONZE',
  },
  {
    value: '3/16 GRAY',
    label: '3/16 GRAY',
  },
  {
    value: '3/16 ACID ETCH/SATIN',
    label: '3/16 ACID ETCH/SATIN',
  },
  {
    value: '3/16 RAIN',
    label: '3/16 RAIN',
  },

  {
    value: '3/16 AQUATEX',
    label: '3/16 AQUATEX',
  },
  {
    value: '3/16 OTHER',
    label: '3/16 OTHER',
  },

  {
    value: 'OTHER (see notes)',
    label: 'OTHER (see notes)',
  },
];

export const ndgdDoorTransom = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Fixed',
    label: 'Fixed',
  },
  {
    value: 'Operable',
    label: 'Operable',
  },
  {
    value: 'Gap',
    label: 'Gap',
  },
  {
    value: 'FIXED - ABOVE DOOR ONLY ',
    label: 'FIXED - ABOVE DOOR ONLY ',
  },
  {
    value: 'FIXED - FULL',
    label: 'FIXED - FULL',
  },
];

export const ndgdDoorFrameStyle = [
  {
    value: 'FRAMELESS',
    label: 'FRAMELESS',
  },
  {
    value: 'SEMI-FRAMLESS',
    label: 'SEMI-FRAMLESS',
  },
  {
    value: 'FRAMED',
    label: 'FRAMED',
  },
];
export const ndgdDoorAction = [
  { label: 'SWING (LEFT)', value: 'SWING (LEFT)' },
  { label: 'SWING (RIGHT)', value: 'SWING (RIGHT)' },
  { label: 'SWING (PAIR)', value: 'SWING (PAIR)' },
  { label: 'SWING 2 HINGES (LEFT)', value: 'SWING 2 HINGES (LEFT)' },
  { label: 'SWING 2 HINGES (RIGHT)', value: 'SWING 2 HINGES (RIGHT)' },
  { label: 'SWING 2 HINGES (PAIR)', value: 'SWING 2 HINGES (PAIR)' },
  { label: 'SWING 3 HINGES (LEFT)', value: 'SWING 3 HINGES (LEFT)' },
  { label: 'SWING 3 HINGES (RIGHT)', value: 'SWING 3 HINGES (RIGHT)' },
  { label: 'SWING 3 HINGES (PAIR)', value: 'SWING 3 HINGES (PAIR)' },
  { label: 'SWING 4 HINGES (LEFT)', value: 'SWING 4 HINGES (LEFT)' },
  { label: 'SWING 4 HINGES (RIGHT)', value: 'SWING 4 HINGES (RIGHT)' },
  { label: 'SWING 4 HINGES (PAIR)', value: 'SWING 4 HINGES (PAIR)' },
  { label: 'SWING (other see notes)', value: 'SWING (other see notes)' },
  { label: 'PIVOTS - TOP+BOTTOM (LEFT)', value: 'PIVOTS - TOP+BOTTOM (LEFT)' },
  { label: 'PIVOTS - TOP+BOTTOM (RIGHT)', value: 'PIVOTS - TOP+BOTTOM (RIGHT)' },
  { label: 'PIVOTS - TOP+BOTTOM (PAIR)', value: 'PIVOTS - TOP+BOTTOM (PAIR)' },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (LEFT)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (LEFT)',
  },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (RIGHT)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (RIGHT)',
  },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (PAIR)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (PAIR)',
  },
  { label: 'PIVOTS (other see notes)', value: 'PIVOTS (other see notes)' },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (LEFT)',
    value: 'CONTINOUS HINGE - Surface Mounted (LEFT)',
  },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (RIGHT)',
    value: 'CONTINOUS HINGE - Surface Mounted (RIGHT)',
  },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (PAIR)',
    value: 'CONTINOUS HINGE - Surface Mounted (PAIR)',
  },
  { label: 'CONTINOUS HINGE - Mortise (LEFT)', value: 'CONTINOUS HINGE - Mortise (LEFT)' },
  { label: 'CONTINOUS HINGE - Mortise (RIGHT)', value: 'CONTINOUS HINGE - Mortise (RIGHT)' },
  { label: 'CONTINOUS HINGE - Mortise (PAIR)', value: 'CONTINOUS HINGE - Mortise (PAIR)' },
  { label: 'CONTINOUS HINGE (other see notes)', value: 'CONTINOUS HINGE (other see notes)' },
  { label: 'SLIDING - SINGLE (LEFT)', value: 'SLIDING - SINGLE (LEFT)' },
  { label: 'SLIDING - SINGLE (RIGHT)', value: 'SLIDING - SINGLE (RIGHT)' },
  { label: 'SLIDING - BY PASS (PAIR)', value: 'SLIDING - BY PASS (PAIR)' },
  { label: 'SLIDING - BY PART (PAIR)', value: 'SLIDING - BY PART (PAIR)' },
  { label: 'SLIDING - STACKING', value: 'SLIDING - STACKING' },
  { label: 'SLIDING (other see notes)', value: 'SLIDING (other see notes)' },
  { label: 'BARN - SINGLE SLIDER (LEFT)', value: 'BARN - SINGLE SLIDER (LEFT)' },
  { label: 'BARN - SINGLE SLIDER (RIGHT)', value: 'BARN - SINGLE SLIDER (RIGHT)' },
  { label: 'BARN - BY PASS (PAIR)', value: 'BARN - BY PASS (PAIR)' },
  { label: 'BARN - BY PART (PAIR)', value: 'BARN - BY PART (PAIR)' },
  { label: 'BARN (other see notes)', value: 'BARN (other see notes)' },
  { label: 'GAP (NO DOOR)', value: 'GAP (NO DOOR)' },
  { label: 'Standard (per door type)', value: 'Standard (per door type)' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const ndgdMeasurements = [
  {
    value: 'Centerline',
    label: 'Centerline',
  },
  {
    value: 'Exterior  of Glass',
    label: 'Exterior  of Glass',
  },
  {
    value: 'Interior of Glass ',
    label: 'Interior of Glass ',
  },
  {
    value: 'Exterior of Metal ',
    label: 'Exterior of Metal ',
  },
  {
    value: 'Interior of Metal  ',
    label: 'Interior of Metal  ',
  },
  {
    value: 'Rough Opening',
    label: 'Rough Opening',
  },
  {
    value: 'Door Opening',
    label: 'Door Opening',
  },
  {
    value: 'Door Only',
    label: 'Door Only',
  },
];

export const ndgdDoorHardwareFinish = [
  {
    value: 'Antique Brushed Copper ',
    label: 'Antique Brushed Copper ',
  },
  {
    value: 'Black',
    label: 'Black',
  },
  {
    value: 'Black (MATTE)',
    label: 'Black (MATTE)',
  },
  {
    value: 'Black with Chrome Center Block',
    label: 'Black with Chrome Center Block',
  },
  {
    value: 'Brass',
    label: 'Brass',
  },
  {
    value: 'Bronze',
    label: 'Bronze',
  },
  {
    value: 'Brushed Bronze',
    label: 'Brushed Bronze',
  },
  {
    value: 'Brushed Copper',
    label: 'Brushed Copper',
  },
  {
    value: 'Brushed Nickel',
    label: 'Brushed Nickel',
  },
  {
    value: 'Brushed Nickel with Brass Accents',
    label: 'Brushed Nickel with Brass Accents',
  },
  {
    value: 'Brushed Satin Chrome',
    label: 'Brushed Satin Chrome',
  },
  {
    value: 'Chrome',
    label: 'Chrome',
  },
  {
    value: 'Chrome with Brass Accent',
    label: 'Chrome with Brass Accent',
  },
  {
    value: 'Gold Plated',
    label: 'Gold Plated',
  },
  {
    value: 'Gun Metal',
    label: 'Gun Metal',
  },
  {
    value: 'Oil Rubbed Bronze',
    label: 'Oil Rubbed Bronze',
  },
  {
    value: 'Polished Brass',
    label: 'Polished Brass',
  },
  {
    value: 'Polished Copper',
    label: 'Polished Copper',
  },
  {
    value: 'Polished Nickel',
    label: 'Polished Nickel',
  },
  {
    value: 'Satin Brass',
    label: 'Satin Brass',
  },
  {
    value: 'Satin Chrome',
    label: 'Satin Chrome',
  },
  {
    value: 'Satin Nickel',
    label: 'Satin Nickel',
  },
  {
    value: 'Ultra Brass',
    label: 'Ultra Brass',
  },
  {
    value: 'White with Chrome Center Block',
    label: 'White with Chrome Center Block',
  },
  {
    value: 'CUSTOM',
    label: 'CUSTOM',
  },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];

export const ndgdDoorHandle = [
  { value: 'Standard (per door type)', label: 'Standard (per door type)' },
  {
    value: 'Push Bar Interior + Pull Handle Exterior',
    label: 'Push Bar Interior + Pull Handle Exterior',
  },
  { value: 'Push Bar (Interior & Exterior)', label: 'Push Bar (Interior & Exterior)' },
  { value: 'Pull - Offset (Back-2-Back)', label: 'Pull - Offset (Back-2-Back)' },
  { value: 'Pull - Offset (exterior)', label: 'Pull - Offset (exterior)' },
  { value: 'Pull - Straight (Back-2-Back)', label: 'Pull - Straight (Back-2-Back)' },
  { value: 'Pull - Straight (exterior)', label: 'Pull - Straight (exterior)' },
  { value: 'Pull - Round (Back-2-Back)', label: 'Pull - Round (Back-2-Back)' },
  { value: 'Pull - Round (exterior)', label: 'Pull - Round (exterior)' },
  { value: 'Pull - Square (Back-2-Back)', label: 'Pull - Square (Back-2-Back)' },
  { value: 'Pull - Square (exterior)', label: 'Pull - Square (exterior)' },
  {
    value: 'Pull Only - Straight - Exterior (with Panic Device)',
    label: 'Pull Only - Straight - Exterior (with Panic Device)',
  },
  {
    value: 'Pull Only - Offset - Exterior (with Panic Device)',
    label: 'Pull Only - Offset - Exterior (with Panic Device)',
  },
  { value: 'Ladder Pull (Back-2-Back)', label: 'Ladder Pull (Back-2-Back)' },
  { value: 'Ladder Pull (exterior)', label: 'Ladder Pull (exterior)' },
  { value: 'Towel Bar(s)', label: 'Towel Bar(s)' },
  { value: 'Towel Bar with Handle', label: 'Towel Bar with Handle' },
  { value: 'Knob (round)', label: 'Knob (round)' },
  { value: 'Knob (square)', label: 'Knob (square)' },
  { value: 'All Glass Door Panic', label: 'All Glass Door Panic' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Prep Only (see notes)', label: 'Prep Only (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  {
    value: '6" Pull - Round - Back2back  (No Washers)',
    label: '6" Pull - Round - Back2back  (No Washers)',
  },
  { value: '6" Pull - Round - Single Sided', label: '6" Pull - Round - Single Sided' },
  {
    value: '6" Pull - Round - Single Sided (No Washers)',
    label: '6" Pull - Round - Single Sided (No Washers)',
  },
  { value: '8" Pull - Round - Back2back', label: '8" Pull - Round - Back2back' },
  {
    value: '8" Pull - Round - Back2back  (No Washers)',
    label: '8" Pull - Round - Back2back  (No Washers)',
  },
  { value: '8" Pull - Round - Single Sided', label: '8" Pull - Round - Single Sided' },
  {
    value: '8" Pull - Round - Single Sided (No Washers)',
    label: '8" Pull - Round - Single Sided (No Washers)',
  },
  {
    value: '9" Pull - Round - Back2back  (No Washers)',
    label: '9" Pull - Round - Back2back  (No Washers)',
  },
  { value: '9" Pull - Round - Single Sided', label: '9" Pull - Round - Single Sided' },
  {
    value: '9" Pull - Round - Single Sided (No Washers)',
    label: '9" Pull - Round - Single Sided (No Washers)',
  },
  { value: '12" Pull - Round - Back2back', label: '12" Pull - Round - Back2back' },
  {
    value: '12" Pull - Round - Back2back  (No Washers)',
    label: '12" Pull - Round - Back2back  (No Washers)',
  },
  { value: '12" Pull - Round - Single Sided', label: '12" Pull - Round - Single Sided' },
  {
    value: '12" Pull - Round - Single Sided (No Washers)',
    label: '12" Pull - Round - Single Sided (No Washers)',
  },
  {
    value: 'Pull - Round - Single Sided (See Notes For Size)',
    label: 'Pull - Round - Single Sided (See Notes For Size)',
  },
  {
    value: 'Pull - Round - Single Sided (No Washers) (See Notes For Size)',
    label: 'Pull - Round - Single Sided (No Washers) (See Notes For Size)',
  },
  { value: '6" Pull  - Square - Single Sided', label: '6" Pull  - Square - Single Sided' },
  { value: '6" Pull - Square - Back2back', label: '6" Pull - Square - Back2back' },
  { value: '8" Pull  - Square - Single Sided', label: '8" Pull  - Square - Single Sided' },
  { value: '8" Pull - Square - Back2back', label: '8" Pull - Square - Back2back' },
  { value: '9" Pull  - Square - Single Sided', label: '9" Pull  - Square - Single Sided' },
  { value: '9" Pull - Square - Back2back', label: '9" Pull - Square - Back2back' },
  { value: '12" Pull  - Square - Single Sided', label: '12" Pull  - Square - Single Sided' },
  { value: '12" Pull - Square - Back2back', label: '12" Pull - Square - Back2back' },
  {
    value: 'Pull  - Square - Single Sided (See Notes For Size)',
    label: 'Pull  - Square - Single Sided (See Notes For Size)',
  },
  {
    value: 'Pull - Square - Back2back (No Washers) (See Notes For Size)',
    label: 'Pull - Square - Back2back (No Washers) (See Notes For Size)',
  },
  { value: '6" Ladder Pull Back2back', label: '6" Ladder Pull Back2back' },
  { value: '8" Ladder Pull Back2back', label: '8" Ladder Pull Back2back' },
  { value: '24" Ladder Pull Back2back', label: '24" Ladder Pull Back2back' },
  { value: '36" Ladder Pull Back2back', label: '36" Ladder Pull Back2back' },
  { value: '48" Ladder Pull Back2back', label: '48" Ladder Pull Back2back' },
  { value: '60" Ladder Pull Back2back', label: '60" Ladder Pull Back2back' },
  { value: '84" Ladder Pull Back2back', label: '84" Ladder Pull Back2back' },
  {
    value: 'Ladder Pull Back2back (See Notes For Size)',
    label: 'Ladder Pull Back2back (See Notes For Size)',
  },
  {
    value: 'Full Height Ladder Pull Back2back (See Notes)',
    label: 'Full Height Ladder Pull Back2back (See Notes)',
  },
  {
    value: '12" Push Bar Back2back Round (No Washers)',
    label: '12" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '18" Push Bar Back2back Round (No Washers)',
    label: '18" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '24" Push Bar Back2back Round (No Washers)',
    label: '24" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '30" Push Bar Back2back Round (No Washers)',
    label: '30" Push Bar Back2back Round (No Washers)',
  },
  {
    value: 'Push Bar Back2back Round (No Washers) (See Notes For Size)',
    label: 'Push Bar Back2back Round (No Washers) (See Notes For Size)',
  },
  {
    value: '12" Push Bar Single Sided Round (No Washers)',
    label: '12" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '18" Push Bar Single Sided  Round (No Washers)',
    label: '18" Push Bar Single Sided  Round (No Washers)',
  },
  {
    value: '20" Push Bar Single Sided Round (No Washers)',
    label: '20" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '22" Push Bar Single Sided Round (No Washers)',
    label: '22" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '24" Push Bar Single Sided Round (No Washers)',
    label: '24" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '26" Push Bar Single Sided Round (No Washers)',
    label: '26" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '27" Push Bar Single Sided Round (No Washers)',
    label: '27" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '28" Push Bar Single Sided Round (No Washers)',
    label: '28" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '30" Push Bar Single Sided Round (No Washers)',
    label: '30" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: 'Push Bar Single Sided  Round (No Washers) (See Notes For Size)',
    label: 'Push Bar Single Sided  Round (No Washers) (See Notes For Size)',
  },
  { value: '12" Push Bar Single Sided Round', label: '12" Push Bar Single Sided Round' },
  { value: '18" Push Bar Single Sided  Round', label: '18" Push Bar Single Sided  Round' },
  { value: '20" Push Bar Single Sided Round', label: '20" Push Bar Single Sided Round' },
  { value: '22" Push Bar Single Sided Round', label: '22" Push Bar Single Sided Round' },
  { value: '24" Push Bar Single Sided Round', label: '24" Push Bar Single Sided Round' },
  { value: '26" Push Bar Single Sided Round', label: '26" Push Bar Single Sided Round' },
  { value: '27" Push Bar Single Sided Round', label: '27" Push Bar Single Sided Round' },
  { value: '28" Push Bar Single Sided Round', label: '28" Push Bar Single Sided Round' },
  { value: '30" Push Bar Single Sided Round', label: '30" Push Bar Single Sided Round' },
  {
    value: 'Push Bar Single Sided  Round (See Notes For Size)',
    label: 'Push Bar Single Sided  Round (See Notes For Size)',
  },
  { value: '6x18 Ladder Pull+ Push.Bar', label: '6x18 Ladder Pull+ Push.Bar' },
  { value: '6x24 Ladder Pull+  Push.Bar', label: '6x24 Ladder Pull+  Push.Bar' },
  { value: '8x18 Ladder Pull+ Push.Bar ', label: '8x18 Ladder Pull+ Push.Bar ' },
  { value: '8x24 Ladder Pull+ Push.Bar', label: '8x24 Ladder Pull+ Push.Bar' },
  {
    value: 'Ladder Pull+ Push.Bar (See Notes For Size)',
    label: 'Ladder Pull+ Push.Bar (See Notes For Size)',
  },
  {
    value: '6x12  Pull + Push.bar (No Washers)',
    label: '6x12  Pull + Push.bar (No Washers)',
  },
  {
    value: '6x18 Pull + Push.Bar  (No Washers)',
    label: '6x18 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '6x22 Pull + Push.Bar  (No Washers)',
    label: '6x22 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '6x24 Pull + Push.Bar  (No Washers)',
    label: '6x24 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '8x18  Pull + Push.Bar  (No Washers)',
    label: '8x18  Pull + Push.Bar  (No Washers)',
  },
  {
    value: '8x22  Pull + Push.Bar  (No Washers)',
    label: '8x22  Pull + Push.Bar  (No Washers)',
  },
  { value: '8x24 Pull + Push.Bar (No Washers)', label: '8x24 Pull + Push.Bar (No Washers)' },
  {
    value: 'Pull + Push.Bar  (No Washers) (See Notes For Size)',
    label: 'Pull + Push.Bar  (No Washers) (See Notes For Size)',
  },
  { value: '6x12  Pull + Push.Bar', label: '6x12  Pull + Push.Bar' },
  { value: '6x18 Pull + Push.Bar', label: '6x18 Pull + Push.Bar' },
  { value: '6x22 Pull + Push.Bar', label: '6x22 Pull + Push.Bar' },
  { value: '6x24 Pull + Push.Bar', label: '6x24 Pull + Push.Bar' },
  { value: '8x18  Pull + Push.Bar', label: '8x18  Pull + Push.Bar' },
  { value: '8x20  Pull + Push.Bar', label: '8x20  Pull + Push.Bar' },
  { value: '8x22  Pull + Push.Bar', label: '8x22  Pull + Push.Bar' },
  { value: '8x24  Pull + Push.Bar', label: '8x24  Pull + Push.Bar' },
  { value: '12x24  Pull + Push.Bar', label: '12x24  Pull + Push.Bar' },
  {
    value: 'Pull+ Push.bar (See Notes For Size)',
    label: 'Pull+ Push.bar (See Notes For Size)',
  },
  { value: '10" Sculptured Handle', label: '10" Sculptured Handle' },
  { value: '12" Towel Bar (Single Sided)', label: '12" Towel Bar (Single Sided)' },
  {
    value: '12" Towel Bar W/washer (Single Sided)',
    label: '12" Towel Bar W/washer (Single Sided)',
  },
  { value: '18" Towel Bar (Single Sided)', label: '18" Towel Bar (Single Sided)' },
  { value: '18" Towel Bar (Single Sided)', label: '18" Towel Bar (Single Sided)' },
  {
    value: '18" Towel Bar W/washer (Single Sided)',
    label: '18" Towel Bar W/washer (Single Sided)',
  },
  {
    value: '18" Towel Bar with Contemporary Knob',
    label: '18" Towel Bar with Contemporary Knob',
  },
  {
    value: '18" Towel Bar with Traditional Knob',
    label: '18" Towel Bar with Traditional Knob',
  },
  { value: '20" Towel Bar (Back-2-Back)', label: '20" Towel Bar (Back-2-Back)' },
  { value: '20" Towel Bar (Single Sided)', label: '20" Towel Bar (Single Sided)' },
  {
    value: '20" Towel Bar W/washer (Single Sided)',
    label: '20" Towel Bar W/washer (Single Sided)',
  },
  { value: '22" Towel Bar (Single Sided)', label: '22" Towel Bar (Single Sided)' },
  {
    value: '22" Towel Bar W/washer (Single Sided)',
    label: '22" Towel Bar W/washer (Single Sided)',
  },
  { value: '24" Towel Bar (Back-2-Back)', label: '24" Towel Bar (Back-2-Back)' },
  { value: '24" Towel Bar (Single Sided)', label: '24" Towel Bar (Single Sided)' },
  { value: '24" Towel Bar (Single Sided)', label: '24" Towel Bar (Single Sided)' },
  {
    value: '24" Towel Bar W/washers (Single Sided)',
    label: '24" Towel Bar W/washers (Single Sided)',
  },
  {
    value: '24" Towel Bar With Contemporary Knob',
    label: '24" Towel Bar With Contemporary Knob',
  },
  {
    value: '24" Towel Bar With Traditional Knob',
    label: '24" Towel Bar With Traditional Knob',
  },
  { value: '26" Towel Bar (Single Sided)', label: '26" Towel Bar (Single Sided)' },
  {
    value: '26" Towel Bar W/washers (Single Sided)',
    label: '26" Towel Bar W/washers (Single Sided)',
  },
  { value: '27" Towel Bar (Single Sided)', label: '27" Towel Bar (Single Sided)' },
  {
    value: '27" Towel Bar W/washers (Single Sided)',
    label: '27" Towel Bar W/washers (Single Sided)',
  },
  { value: '28" Towel Bar (Single Sided)', label: '28" Towel Bar (Single Sided)' },
  {
    value: '28" Towel Bar W/washers (Single Sided)',
    label: '28" Towel Bar W/washers (Single Sided)',
  },
  { value: '30" Towel Bar (Single Sided)', label: '30" Towel Bar (Single Sided)' },
  {
    value: '30" Towel Bar W/washers (Single Sided)',
    label: '30" Towel Bar W/washers (Single Sided)',
  },
  { value: '6" Crescent Pull', label: '6" Crescent Pull' },
  { value: '6" Pull 18" Towel Bar', label: '6" Pull 18" Towel Bar' },
  { value: '6" Pull 18" Towel Bar', label: '6" Pull 18" Towel Bar' },
  { value: '6" Pull 24" Towel Bar', label: '6" Pull 24" Towel Bar' },
  { value: '6" Pull 24" Towel Bar', label: '6" Pull 24" Towel Bar' },
  {
    value: '6" Pull To 12" Towel Bar (No Washers)',
    label: '6" Pull To 12" Towel Bar (No Washers)',
  },
  { value: '6" Pull To 18" Towel Bar', label: '6" Pull To 18" Towel Bar' },
  { value: '6" Pull To 18" Towel Bar', label: '6" Pull To 18" Towel Bar' },
  {
    value: '6" Pull To 18" Towel Bar (Acrylic, Smooth)',
    label: '6" Pull To 18" Towel Bar (Acrylic, Smooth)',
  },
  {
    value: '6" Pull To 18" Towel Bar (No Washers)',
    label: '6" Pull To 18" Towel Bar (No Washers)',
  },
  { value: '6" Pull To 22" Towel Bar', label: '6" Pull To 22" Towel Bar' },
  { value: '6" Pull To 22" Towel Bar', label: '6" Pull To 22" Towel Bar' },
  { value: '6" Pull To 24" Towel Bar', label: '6" Pull To 24" Towel Bar' },
  { value: '6" Pull To 24" Towel Bar', label: '6" Pull To 24" Towel Bar' },
  { value: '8" Crescent Pull', label: '8" Crescent Pull' },
  { value: '8" Pull 18" Towel Bar', label: '8" Pull 18" Towel Bar' },
  { value: '8" Pull 18" Towel Bar', label: '8" Pull 18" Towel Bar' },
  { value: '8" Pull 24" Towel Bar', label: '8" Pull 24" Towel Bar' },
  { value: '8" Pull 24" Towel Bar', label: '8" Pull 24" Towel Bar' },
  { value: '8" Pull To 18" Towel Bar', label: '8" Pull To 18" Towel Bar' },
  {
    value: '8" Pull To 18" Towel Bar (No Washers)',
    label: '8" Pull To 18" Towel Bar (No Washers)',
  },
  { value: '8" Pull To 22" Towel Bar', label: '8" Pull To 22" Towel Bar' },
  { value: '8" Pull To 22" Towel Bar', label: '8" Pull To 22" Towel Bar' },
  { value: '8" Pull To 24" Towel Bar', label: '8" Pull To 24" Towel Bar' },
  {
    value: '8" Pull To 24" Towel Bar (Acrylic, Smooth)',
    label: '8" Pull To 24" Towel Bar (Acrylic, Smooth)',
  },
  {
    value: '8" Pull To 24" Towel Bar (No Washers)',
    label: '8" Pull To 24" Towel Bar (No Washers)',
  },
  { value: '8" Tubular Pull (Back-2-Back)', label: '8" Tubular Pull (Back-2-Back)' },
  { value: 'Acrylic Regular Style Towel Bar', label: 'Acrylic Regular Style Towel Bar' },
  { value: 'Arcylic Regular Style', label: 'Arcylic Regular Style' },
  { value: 'Ball Style Knob', label: 'Ball Style Knob' },
  { value: 'Bow Tie Knob', label: 'Bow Tie Knob' },
  { value: 'Circular Door Pull', label: 'Circular Door Pull' },
  { value: 'Colonial', label: 'Colonial' },
  {
    value: 'Colonial 18" (Back-2-Back) Towel Bar',
    label: 'Colonial 18" (Back-2-Back) Towel Bar',
  },
  {
    value: 'Colonial 18" Towel Bar (Single Sided)',
    label: 'Colonial 18" Towel Bar (Single Sided)',
  },
  {
    value: 'Colonial 24" Towel Bar (Single Sided)',
    label: 'Colonial 24" Towel Bar (Single Sided)',
  },
  { value: 'Colonial 6" (Back-2-Back)', label: 'Colonial 6" (Back-2-Back)' },
  { value: 'Colonial 6" Pull To 24" Towel Bar', label: 'Colonial 6" Pull To 24" Towel Bar' },
  { value: 'Colonial 8" (Back-2-Back)', label: 'Colonial 8" (Back-2-Back)' },
  { value: 'Crescent Grip Knob', label: 'Crescent Grip Knob' },
  {
    value: 'Crescent Towel Bar 18" (Back-2-Back)',
    label: 'Crescent Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Crescent Towel Bar 24"  (Back-2-Back)',
    label: 'Crescent Towel Bar 24"  (Back-2-Back)',
  },
  { value: 'Crystal Knob', label: 'Crystal Knob' },
  { value: 'Crystal Knob Contemporary Style', label: 'Crystal Knob Contemporary Style' },
  { value: 'Cylinder Style Knob (Back-2-Back)', label: 'Cylinder Style Knob (Back-2-Back)' },
  {
    value: 'Cylinder Style Knob (Single Sided)',
    label: 'Cylinder Style Knob (Single Sided)',
  },
  {
    value: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
    label: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
  },
  {
    value: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
    label: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
  },
  {
    value: 'Double Acrylic Door Pull 6"(Back-2-Back)',
    label: 'Double Acrylic Door Pull 6"(Back-2-Back)',
  },
  {
    value: 'Double Acrylic Door Pull 8" (Back-2-Back)',
    label: 'Double Acrylic Door Pull 8" (Back-2-Back)',
  },
  {
    value: 'Double Acrylic Door Pull Twist 8" (Back-2-Back)',
    label: 'Double Acrylic Door Pull Twist 8" (Back-2-Back)',
  },
  { value: 'Double Sided Knob', label: 'Double Sided Knob' },
  { value: 'Euro Style Knob', label: 'Euro Style Knob' },
  { value: 'Flair Style Knob', label: 'Flair Style Knob' },
  { value: 'Glass Knob Back To Back', label: 'Glass Knob Back To Back' },
  { value: 'Junior Contemporary Style Knob', label: 'Junior Contemporary Style Knob' },
  {
    value: 'Knob - Contemporary (Single Sided)',
    label: 'Knob - Contemporary (Single Sided)',
  },
  { value: 'Knob - Traditional (Single Sided)', label: 'Knob - Traditional (Single Sided)' },
  { value: 'Knob (Back-2-Back)', label: 'Knob (Back-2-Back)' },
  { value: 'Knob Latch - Glass Strike Option', label: 'Knob Latch - Glass Strike Option' },
  { value: 'Knob Latch - Wall Strike Option', label: 'Knob Latch - Wall Strike Option' },
  { value: 'Ladder Pull 24" (Single Sided)', label: 'Ladder Pull 24" (Single Sided)' },
  { value: 'Ladder Pull 36" (Single Sided)', label: 'Ladder Pull 36" (Single Sided)' },
  { value: 'Ladder Pull 48" (Single Sided)', label: 'Ladder Pull 48" (Single Sided)' },
  { value: 'Ladder Pull 6" (Back-2-Back)', label: 'Ladder Pull 6" (Back-2-Back)' },
  { value: 'Ladder Pull 8" (Back-2-Back)', label: 'Ladder Pull 8" (Back-2-Back)' },
  {
    value: 'Ladder Towel Bar 18" (Back-2-Back)',
    label: 'Ladder Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Ladder Towel Bar 18" (Single Sided)',
    label: 'Ladder Towel Bar 18" (Single Sided)',
  },
  {
    value: 'Ladder Towel Bar/Pull Combination 8x24',
    label: 'Ladder Towel Bar/Pull Combination 8x24',
  },
  { value: 'Laguna Thru-glass Pull', label: 'Laguna Thru-glass Pull' },
  { value: 'Mini Bulb Knob', label: 'Mini Bulb Knob' },
  {
    value: 'Mitered 18" Towel Bar (Back-2-Back)',
    label: 'Mitered 18" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Mitered 18" Towel Bar (Single Sided)',
    label: 'Mitered 18" Towel Bar (Single Sided)',
  },
  {
    value: 'Mitered 24" Towel Bar (Back-2-Back)',
    label: 'Mitered 24" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Mitered 24" Towel Bar (Single Sided)',
    label: 'Mitered 24" Towel Bar (Single Sided)',
  },
  { value: 'Mitered 6" Pull', label: 'Mitered 6" Pull' },
  { value: 'Mitered 8" Pull', label: 'Mitered 8" Pull' },
  {
    value: 'Oval/round Handle 6" (Back-2-Back)',
    label: 'Oval/round Handle 6" (Back-2-Back)',
  },
  {
    value: 'Oval/round Handle 8" (Back-2-Back)',
    label: 'Oval/round Handle 8" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 18" (Back-2-Back)',
    label: 'Oval/round Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 18" (Single Sided)',
    label: 'Oval/round Towel Bar 18" (Single Sided)',
  },
  {
    value: 'Oval/round Towel Bar 24" (Back-2-Back)',
    label: 'Oval/round Towel Bar 24" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 24" (Single Sided)',
    label: 'Oval/round Towel Bar 24" (Single Sided)',
  },
  { value: 'Paddle Knob', label: 'Paddle Knob' },
  {
    value: 'Pull Handle/Towel Bar Combination 12" x 24"',
    label: 'Pull Handle/Towel Bar Combination 12" x 24"',
  },
  {
    value: 'Pull Handle/Towel Bar Combination 6" x 12"',
    label: 'Pull Handle/Towel Bar Combination 6" x 12"',
  },
  { value: 'Ribbed Bow Tie Knob', label: 'Ribbed Bow Tie Knob' },
  { value: 'RM Series Pull 6" (Back-2-Back)', label: 'RM Series Pull 6" (Back-2-Back)' },
  { value: 'RM Series Pull 8" (Back-2-Back)', label: 'RM Series Pull 8" (Back-2-Back)' },
  { value: 'Robe Hook', label: 'Robe Hook' },
  { value: 'Sculptured Solid 6" Pull', label: 'Sculptured Solid 6" Pull' },
  { value: 'Serenity Thru-glass Pull', label: 'Serenity Thru-glass Pull' },
  { value: 'Solid 1" Diameter 8" Pull', label: 'Solid 1" Diameter 8" Pull' },
  { value: 'Solid 6"  Pull (Single Sided)', label: 'Solid 6"  Pull (Single Sided)' },
  { value: 'Solid 6" Pull (Back-2-Back)', label: 'Solid 6" Pull (Back-2-Back)' },
  { value: 'Solid 6" Pull (Back-2-Back)', label: 'Solid 6" Pull (Back-2-Back)' },
  { value: 'Solid 6" Pull (Single Sided)', label: 'Solid 6" Pull (Single Sided)' },
  { value: 'Solid 8" Pull (Back-2-Back)', label: 'Solid 8" Pull (Back-2-Back)' },
  { value: 'Solid 8" Pull (Back-2-Back)', label: 'Solid 8" Pull (Back-2-Back)' },
  { value: 'Solid 8" Pull (Single Sided)', label: 'Solid 8" Pull (Single Sided)' },
  { value: 'Solid 8" Pull (Single Sided)', label: 'Solid 8" Pull (Single Sided)' },
  { value: 'Solid Towel Bar (Back-2-Back)', label: 'Solid Towel Bar (Back-2-Back)' },
  { value: 'Solid Towel Bar (Back-2-Back)', label: 'Solid Towel Bar (Back-2-Back)' },
  { value: 'Square 1" (Back-2-Back)', label: 'Square 1" (Back-2-Back)' },
  {
    value: 'Square 18" Towel Bar (Back-2-Back)',
    label: 'Square 18" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Square 24" Towel Bar (Back-2-Back)',
    label: 'Square 24" Towel Bar (Back-2-Back)',
  },
  { value: 'Square Finger Pull Knob', label: 'Square Finger Pull Knob' },
  { value: 'Square Handle 8"', label: 'Square Handle 8"' },
  { value: 'Square KNOB (Back-2-Back)', label: 'Square KNOB (Back-2-Back)' },
  { value: 'Square Ladder 6" (Back-2-Back)', label: 'Square Ladder 6" (Back-2-Back)' },
  {
    value: 'Square Ladder Pull 8" (Back-2-Back)',
    label: 'Square Ladder Pull 8" (Back-2-Back)',
  },
  { value: 'Square Pull 6"', label: 'Square Pull 6"' },
  { value: 'Thru-Glass Finger Pull', label: 'Thru-Glass Finger Pull' },
  { value: 'Towel Bar 12" (Back-2-Back)', label: 'Towel Bar 12" (Back-2-Back)' },
  { value: 'Towel Bar 18" (Back-2-Back)', label: 'Towel Bar 18" (Back-2-Back)' },
  { value: 'Towel Bar 24" (Back-2-Back)', label: 'Towel Bar 24" (Back-2-Back)' },
  { value: 'Towel Bar 30" (Back-2-Back)', label: 'Towel Bar 30" (Back-2-Back)' },
  {
    value: 'Towel Bar W/washer 12" (Back-2-Back)',
    label: 'Towel Bar W/washer 12" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 18" (Back-2-Back)',
    label: 'Towel Bar W/washer 18" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 24" (Back-2-Back)',
    label: 'Towel Bar W/washer 24" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 30" (Back-2-Back)',
    label: 'Towel Bar W/washer 30" (Back-2-Back)',
  },
  { value: 'Towel Bar/Pull Combination 8x20', label: 'Towel Bar/Pull Combination 8x20' },
  { value: 'Tubular 6" Pull (Back-2-Back)', label: 'Tubular 6" Pull (Back-2-Back)' },
  { value: 'Tubular 6" Pull (Back-2-Back)', label: 'Tubular 6" Pull (Back-2-Back)' },
  { value: 'Tubular 8" Pull (Back-2-Back)', label: 'Tubular 8" Pull (Back-2-Back)' },
  {
    value: 'Tubular Brass Back To Back Towel Bar',
    label: 'Tubular Brass Back To Back Towel Bar',
  },
  { value: 'Tubular Brass Construction 12"', label: 'Tubular Brass Construction 12"' },
  { value: 'Tubular Brass Handle 18"', label: 'Tubular Brass Handle 18"' },
  { value: 'Tubular Brass Handle 24"', label: 'Tubular Brass Handle 24"' },
  { value: 'Tubular Brass Handle 6"', label: 'Tubular Brass Handle 6"' },
  {
    value: 'Tubular Towel Bar 18" (Back-2-Back)',
    label: 'Tubular Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Victorian 18" Towel Bar (Single Sided)',
    label: 'Victorian 18" Towel Bar (Single Sided)',
  },
  { value: 'Victorian 6" Pull (Back-2-Back)', label: 'Victorian 6" Pull (Back-2-Back)' },
  {
    value: 'Victorian 6" Pull 18" Towel Bar (combo)',
    label: 'Victorian 6" Pull 18" Towel Bar (combo)',
  },
  { value: 'Victorian 8" Pull (Back-2-Back)', label: 'Victorian 8" Pull (Back-2-Back)' },
  { value: 'Victorian 8" Pull (Back-2-Back)', label: 'Victorian 8" Pull (Back-2-Back)' },
  {
    value: 'Victorian 8" Pull 18" Towel Bar (combo)',
    label: 'Victorian 8" Pull 18" Towel Bar (combo)',
  },
  {
    value: 'Victorian 8" Pull 24" Towel Bar (combo)',
    label: 'Victorian 8" Pull 24" Towel Bar (combo)',
  },
  {
    value: 'Victorian Towel Bar 12" (Back-2-Back)',
    label: 'Victorian Towel Bar 12" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 18" (Back-2-Back)',
    label: 'Victorian Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 24" (Back-2-Back)',
    label: 'Victorian Towel Bar 24" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 24" (Single Sided)',
    label: 'Victorian Towel Bar 24" (Single Sided)',
  },
];

export const ndgdFixedPanelSecuring = [
  { value: 'Clips', label: 'Clips' },
  { value: 'Brackets', label: 'Brackets' },
  { value: 'U-Channel', label: 'U-Channel' },
  { value: 'U-Channel (deep)', label: 'U-Channel (deep)' },
  { value: 'U-Channel (lift&drop)', label: 'U-Channel (lift&drop)' },
  { value: 'U-Channel (slide)', label: 'U-Channel (slide)' },
  { value: 'Combo - WALL CLIPS + BOTTOM CHANNEL', label: 'Combo - WALL CLIPS + BOTTOM CHANNEL' },
  {
    value: 'Combo - WALL CLIPS + BOTTOM CHANNEL + GLASS2GLASS.CLAMP',
    label: 'Combo - WALL CLIPS + BOTTOM CHANNEL + GLASS2GLASS.CLAMP',
  },
  {
    value: 'Combo - WALL CLIPS + BOTTOM CHANNEL + GLASS2GLASS.OVER.SLEEVE',
    label: 'Combo - WALL CLIPS + BOTTOM CHANNEL + GLASS2GLASS.OVER.SLEEVE',
  },
  {
    value: 'Combo - WALL BRACKETS + BOTTOM CHANNEL',
    label: 'Combo - WALL BRACKETS + BOTTOM CHANNEL',
  },
  { value: 'Combo - WALL BRACKETS + BOTTOM CLIPS', label: 'Combo - WALL BRACKETS + BOTTOM CLIPS' },
  {
    value: 'Combo - WALL BRACKETS & CLIPS + BOTTOM CHANNEL',
    label: 'Combo - WALL BRACKETS & CLIPS + BOTTOM CHANNEL',
  },
  {
    value: 'Combo - WALL BRACKETS + BOTTOM CHANNEL + GLASS2GLASS.CLAMP',
    label: 'Combo - WALL BRACKETS + BOTTOM CHANNEL + GLASS2GLASS.CLAMP',
  },
  {
    value: 'Combo - WALL BRACKETS + BOTTOM CHANNEL + GLASS2GLASS.OVER.SLEEVE',
    label: 'Combo - WALL BRACKETS + BOTTOM CHANNEL + GLASS2GLASS.OVER.SLEEVE',
  },
  { value: 'Combo – Other (see notes)', label: 'Combo – Other (see notes)' },
  { value: 'TBD', label: 'TBD' },
  { value: 'RESEARCH', label: 'RESEARCH' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];

export const ndgdCornerJoints = [
  {
    value: 'IN LINE',
    label: 'IN LINE',
  },
  {
    value: 'MITER',
    label: 'MITER',
  },
  {
    value: 'LEFT PANEL OVERLAPS',
    label: 'LEFT PANEL OVERLAPS',
  },
  {
    value: 'RIGHT PANEL OVERLAPS',
    label: 'RIGHT PANEL OVERLAPS',
  },
  {
    value: 'SQUARE (No Overlap)',
    label: 'SQUARE (No Overlap)',
  },
];

export const ndgdLevel = [
  {
    value: 'Out of Level - LEFT UP',
    label: 'Out of Level - LEFT UP',
  },
  {
    value: 'Out of Level - LEFT DOWN',
    label: 'Out of Level - LEFT DOWN',
  },
  {
    value: 'Out of Level - RIGHT UP',
    label: 'Out of Level - RIGHT UP',
  },
  {
    value: 'Out of Level - RIGHT DOWN',
    label: 'Out of Level - RIGHT DOWN',
  },
  {
    value: 'Out Of Plumb - TOP IN ',
    label: 'Out Of Plumb - TOP IN ',
  },
  {
    value: 'Out Of Plumb - TOP OUT ',
    label: 'Out Of Plumb - TOP OUT ',
  },
  {
    value: 'Out Of Plumb - BOTTOM IN ',
    label: 'Out Of Plumb - BOTTOM IN ',
  },
  {
    value: 'Out Of Plumb - BOTTOM OUT ',
    label: 'Out Of Plumb - BOTTOM OUT ',
  },
  {
    value: 'BOWED',
    label: 'BOWED',
  },
];

export const anglesOption = [
  { value: '90°', label: '90°' },
  { value: '135°', label: '135°' },
  { value: '180°', label: '180°' },
  { value: '1°', label: '1°' },
  { value: '2°', label: '2°' },
  { value: '3°', label: '3°' },
  { value: '4°', label: '4°' },
  { value: '5°', label: '5°' },
  { value: '6°', label: '6°' },
  { value: '7°', label: '7°' },
  { value: '8°', label: '8°' },
  { value: '9°', label: '9°' },
  { value: '10°', label: '10°' },
  { value: '11°', label: '11°' },
  { value: '12°', label: '12°' },
  { value: '13°', label: '13°' },
  { value: '14°', label: '14°' },
  { value: '15°', label: '15°' },
  { value: '16°', label: '16°' },
  { value: '17°', label: '17°' },
  { value: '18°', label: '18°' },
  { value: '19°', label: '19°' },
  { value: '20°', label: '20°' },
  { value: '21°', label: '21°' },
  { value: '22°', label: '22°' },
  { value: '23°', label: '23°' },
  { value: '24°', label: '24°' },
  { value: '25°', label: '25°' },
  { value: '26°', label: '26°' },
  { value: '27°', label: '27°' },
  { value: '28°', label: '28°' },
  { value: '29°', label: '29°' },
  { value: '30°', label: '30°' },
  { value: '31°', label: '31°' },
  { value: '32°', label: '32°' },
  { value: '33°', label: '33°' },
  { value: '34°', label: '34°' },
  { value: '35°', label: '35°' },
  { value: '36°', label: '36°' },
  { value: '37°', label: '37°' },
  { value: '38°', label: '38°' },
  { value: '39°', label: '39°' },
  { value: '40°', label: '40°' },
  { value: '41°', label: '41°' },
  { value: '42°', label: '42°' },
  { value: '43°', label: '43°' },
  { value: '44°', label: '44°' },
  { value: '45°', label: '45°' },
  { value: '46°', label: '46°' },
  { value: '47°', label: '47°' },
  { value: '48°', label: '48°' },
  { value: '49°', label: '49°' },
  { value: '50°', label: '50°' },
  { value: '51°', label: '51°' },
  { value: '52°', label: '52°' },
  { value: '53°', label: '53°' },
  { value: '54°', label: '54°' },
  { value: '55°', label: '55°' },
  { value: '56°', label: '56°' },
  { value: '57°', label: '57°' },
  { value: '58°', label: '58°' },
  { value: '59°', label: '59°' },
  { value: '60°', label: '60°' },
  { value: '61°', label: '61°' },
  { value: '62°', label: '62°' },
  { value: '63°', label: '63°' },
  { value: '64°', label: '64°' },
  { value: '65°', label: '65°' },
  { value: '66°', label: '66°' },
  { value: '67°', label: '67°' },
  { value: '68°', label: '68°' },
  { value: '69°', label: '69°' },
  { value: '70°', label: '70°' },
  { value: '71°', label: '71°' },
  { value: '72°', label: '72°' },
  { value: '73°', label: '73°' },
  { value: '74°', label: '74°' },
  { value: '75°', label: '75°' },
  { value: '76°', label: '76°' },
  { value: '77°', label: '77°' },
  { value: '78°', label: '78°' },
  { value: '79°', label: '79°' },
  { value: '80°', label: '80°' },
  { value: '81°', label: '81°' },
  { value: '82°', label: '82°' },
  { value: '83°', label: '83°' },
  { value: '84°', label: '84°' },
  { value: '85°', label: '85°' },
  { value: '86°', label: '86°' },
  { value: '87°', label: '87°' },
  { value: '88°', label: '88°' },
  { value: '89°', label: '89°' },
  { value: '90°', label: '90°' },
  { value: '91°', label: '91°' },
  { value: '92°', label: '92°' },
  { value: '93°', label: '93°' },
  { value: '94°', label: '94°' },
  { value: '95°', label: '95°' },
  { value: '96°', label: '96°' },
  { value: '97°', label: '97°' },
  { value: '98°', label: '98°' },
  { value: '99°', label: '99°' },
  { value: '100°', label: '100°' },
  { value: '101°', label: '101°' },
  { value: '102°', label: '102°' },
  { value: '103°', label: '103°' },
  { value: '104°', label: '104°' },
  { value: '105°', label: '105°' },
  { value: '106°', label: '106°' },
  { value: '107°', label: '107°' },
  { value: '108°', label: '108°' },
  { value: '109°', label: '109°' },
  { value: '110°', label: '110°' },
  { value: '111°', label: '111°' },
  { value: '112°', label: '112°' },
  { value: '113°', label: '113°' },
  { value: '114°', label: '114°' },
  { value: '115°', label: '115°' },
  { value: '116°', label: '116°' },
  { value: '117°', label: '117°' },
  { value: '118°', label: '118°' },
  { value: '119°', label: '119°' },
  { value: '120°', label: '120°' },
  { value: '121°', label: '121°' },
  { value: '122°', label: '122°' },
  { value: '123°', label: '123°' },
  { value: '124°', label: '124°' },
  { value: '125°', label: '125°' },
  { value: '126°', label: '126°' },
  { value: '127°', label: '127°' },
  { value: '128°', label: '128°' },
  { value: '129°', label: '129°' },
  { value: '130°', label: '130°' },
  { value: '131°', label: '131°' },
  { value: '132°', label: '132°' },
  { value: '133°', label: '133°' },
  { value: '134°', label: '134°' },
  { value: '135°', label: '135°' },
  { value: '136°', label: '136°' },
  { value: '137°', label: '137°' },
  { value: '138°', label: '138°' },
  { value: '139°', label: '139°' },
  { value: '140°', label: '140°' },
  { value: '141°', label: '141°' },
  { value: '142°', label: '142°' },
  { value: '143°', label: '143°' },
  { value: '144°', label: '144°' },
  { value: '145°', label: '145°' },
  { value: '146°', label: '146°' },
  { value: '147°', label: '147°' },
  { value: '148°', label: '148°' },
  { value: '149°', label: '149°' },
  { value: '150°', label: '150°' },
  { value: '151°', label: '151°' },
  { value: '152°', label: '152°' },
  { value: '153°', label: '153°' },
  { value: '154°', label: '154°' },
  { value: '155°', label: '155°' },
  { value: '156°', label: '156°' },
  { value: '157°', label: '157°' },
  { value: '158°', label: '158°' },
  { value: '159°', label: '159°' },
  { value: '160°', label: '160°' },
  { value: '161°', label: '161°' },
  { value: '162°', label: '162°' },
  { value: '163°', label: '163°' },
  { value: '164°', label: '164°' },
  { value: '165°', label: '165°' },
  { value: '166°', label: '166°' },
  { value: '167°', label: '167°' },
  { value: '168°', label: '168°' },
  { value: '169°', label: '169°' },
  { value: '170°', label: '170°' },
  { value: '171°', label: '171°' },
  { value: '172°', label: '172°' },
  { value: '173°', label: '173°' },
  { value: '174°', label: '174°' },
  { value: '175°', label: '175°' },
  { value: '176°', label: '176°' },
  { value: '177°', label: '177°' },
  { value: '178°', label: '178°' },
  { value: '179°', label: '179°' },
  { value: '180°', label: '180°' },
];

export const ndshowerLayout = [
  {
    label: 'DOOR - Single – Inline',
    value: 'shower_inline_single',
  },
  {
    label: 'DOOR - Single – Angled',
    value: 'shower_angled_single',
  },
  {
    label: 'DOOR - Single – Neo',
    value: 'shower_neo_single',
  },
  {
    label: 'DOOR - Double – Inline',
    value: 'showers_inline_double',
  },
  {
    label: 'DOOR - Double – Angled',
    value: 'showers_angled_double',
  },
  {
    label: 'DOOR - Double – Neo',
    value: 'showers_neo_double',
  },
  {
    label: 'WINDOWS',
    value: 'windows',
  },
  {
    label: 'DOOR - Patio Sliders',
    value: 'patio',
  },
  {
    label: 'DOOR ONLY',
    value: 'hmdoor',
  },
];

export const ndshowerLayoutOptions = {
  showers_angled_double: {
    values: [
      {
        label: 1,
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_55_24-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 1,
        option: 'showers_angled_double',
      },
      {
        label: 2,
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_54_54-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 2,
        option: 'showers_angled_double',
      },
      {
        label: 3,
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_54_24-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 3,
        option: 'showers_angled_double',
      },
      {
        label: 4,
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_53_38-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 4,
        option: 'showers_angled_double',
      },
      {
        label: 5,
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_49_39-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 5,
        option: 'showers_angled_double',
      },
      {
        label: '6',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_49_17-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 6,
        option: 'showers_angled_double',
      },
      {
        label: '7',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_48_18-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 7,
        option: 'showers_angled_double',
      },
      {
        label: '8',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_47_58-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 8,
        option: 'showers_angled_double',
      },
      {
        label: '9',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_47_31-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 9,
        option: 'showers_angled_double',
      },
      {
        label: '10',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_47_12-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 10,
        option: 'showers_angled_double',
      },
      {
        label: '11',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_46_55-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 11,
        option: 'showers_angled_double',
      },
      {
        label: '12',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_46_18-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 12,
        option: 'showers_angled_double',
      },
      {
        label: '13',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_45_53-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 13,
        option: 'showers_angled_double',
      },
      {
        label: '14',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_45_23-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 14,
        option: 'showers_angled_double',
      },
      {
        label: '15',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_44_44-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 15,
        option: 'showers_angled_double',
      },
      {
        label: '16',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_43_56-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 16,
        option: 'showers_angled_double',
      },
      {
        label: '17',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_43_20-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 17,
        option: 'showers_angled_double',
      },
      {
        label: '18',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_42_29-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 18,
        option: 'showers_angled_double',
      },
      {
        label: '19',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_41_30-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 19,
        option: 'showers_angled_double',
      },
      {
        label: '20',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_41_08-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 20,
        option: 'showers_angled_double',
      },
      {
        label: '21',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_40_42-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 21,
        option: 'showers_angled_double',
      },
      {
        label: '22',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_40_11-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 22,
        option: 'showers_angled_double',
      },
      {
        label: '23',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_39_27-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 23,
        option: 'showers_angled_double',
      },
      {
        label: '24',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_38_44-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 24,
        option: 'showers_angled_double',
      },
      {
        label: '25',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_38_17-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 25,
        option: 'showers_angled_double',
      },
      {
        label: '26',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_37_48-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 26,
        option: 'showers_angled_double',
      },
      {
        label: '27',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_37_17-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 27,
        option: 'showers_angled_double',
      },
      {
        label: '28',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_35_50-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 28,
        option: 'showers_angled_double',
      },
      {
        label: '29',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_35_12-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 29,
        option: 'showers_angled_double',
      },
      {
        label: '30',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_34_30-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 30,
        option: 'showers_angled_double',
      },
      {
        label: '31',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_34_06-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 31,
        option: 'showers_angled_double',
      },
      {
        label: '32',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_33_26-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 32,
        option: 'showers_angled_double',
      },
      {
        label: '33',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_33_01-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 33,
        option: 'showers_angled_double',
      },
      {
        label: '34',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_32_01-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 34,
        option: 'showers_angled_double',
      },
      {
        label: '35',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_31_44-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 35,
        option: 'showers_angled_double',
      },
      {
        label: '36',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_31_13-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 36,
        option: 'showers_angled_double',
      },
      {
        label: '37',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_30_49-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 37,
        option: 'showers_angled_double',
      },
      {
        label: '38',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_30_23-SHOWERS_ANGLED_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 38,
        option: 'showers_angled_double',
      },
      {
        label: '39',
        value:
          'https://showers-angled-double.s3.amazonaws.com/2021-04-27+14_29_15-SHOWERS_ANGLED_DOUBLE.pdf+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 39,
        option: 'showers_angled_double',
      },
      {
        label: '40',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/1.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 40,
      },
      {
        label: '41',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/2.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 41,
      },
      {
        label: '42',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/3.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 42,
      },
      {
        label: '43',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/4.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 43,
      },
      {
        label: '44',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/5.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 44,
      },
      {
        label: '45',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/6.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 45,
      },
      {
        label: '46',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/7.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 46,
      },
      {
        label: '47',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/8.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 47,
      },
      {
        label: '48',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/9.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 48,
      },
      {
        label: '49',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/10.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 49,
      },
      {
        label: '50',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/11.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 50,
      },
      {
        label: '51',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/12.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 51,
      },
      {
        label: '52',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/13.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 52,
      },
      {
        label: '53',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/14.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 53,
      },
      {
        label: '54',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/15.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 54,
      },
      {
        label: '55',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/16.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 55,
      },
      {
        label: '56',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/17.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 56,
      },
      {
        label: '57',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/18.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 57,
      },
      {
        label: '58',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/19.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 58,
      },
      {
        label: '59',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/20.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 59,
      },
      {
        label: '60',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/21.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 60,
      },
      {
        label: '61',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/22.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 61,
      },
      {
        label: '62',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/23.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 62,
      },
      {
        label: '63',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/24.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 63,
      },
      {
        label: '64',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/25.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 64,
      },
      {
        label: '65',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/26.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 65,
      },
      {
        label: '66',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/27.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 66,
      },
      {
        label: '67',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/28.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 67,
      },
      {
        label: '68',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/29.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 68,
      },
      {
        label: '69',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/30.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 69,
      },
      {
        label: '70',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/31.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 70,
      },
      {
        label: '71',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/32.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 71,
      },
      {
        label: '72',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/33.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 72,
      },
      {
        label: '73',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/34.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 73,
      },
      {
        label: '74',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/35.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 74,
      },
      {
        label: '75',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/36.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 75,
      },
      {
        label: '76',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/37.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 76,
      },
      {
        label: '77',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/38.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 77,
      },
      {
        label: '78',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/39.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 78,
      },
      {
        label: '79',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/40.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 79,
      },
      {
        label: '80',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/41.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 80,
      },
      {
        label: '81',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/42.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 81,
      },
      {
        label: '82',
        option: 'showers_angled_double',
        value: 'https://showers-angled-double.s3.amazonaws.com/43.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 82,
      },
    ],
  },
  shower_angled_single: {
    values: [
      {
        label: '1',
        value: 'https://showers-angled-single.s3.amazonaws.com/image001.png',
        int: 1,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '2',
        value: 'https://showers-angled-single.s3.amazonaws.com/image003.png',
        int: 2,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '3',
        value: 'https://showers-angled-single.s3.amazonaws.com/image005.png',
        int: 3,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '4',
        value: 'https://showers-angled-single.s3.amazonaws.com/image007.png',
        int: 4,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '5',
        value: 'https://showers-angled-single.s3.amazonaws.com/image009.png',
        int: 5,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '6',
        value: 'https://showers-angled-single.s3.amazonaws.com/image011.png',
        int: 6,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '7',
        value: 'https://showers-angled-single.s3.amazonaws.com/image013.png',
        int: 7,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '8',
        value: 'https://showers-angled-single.s3.amazonaws.com/image015.png',
        int: 8,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '9',
        value: 'https://showers-angled-single.s3.amazonaws.com/image017.png',
        int: 9,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '10',
        value: 'https://showers-angled-single.s3.amazonaws.com/image019.png',
        int: 10,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '11',
        value: 'https://showers-angled-single.s3.amazonaws.com/image021.png',
        int: 11,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '12',
        value: 'https://showers-angled-single.s3.amazonaws.com/image023.png',
        int: 12,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '13',
        value: 'https://showers-angled-single.s3.amazonaws.com/image025.png',
        int: 13,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '14',
        value: 'https://showers-angled-single.s3.amazonaws.com/image027.png',
        int: 14,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '15',
        value: 'https://showers-angled-single.s3.amazonaws.com/image029.png',
        int: 15,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '16',
        value: 'https://showers-angled-single.s3.amazonaws.com/image031.png',
        int: 16,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '17',
        value: 'https://showers-angled-single.s3.amazonaws.com/image033.png',
        int: 17,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '18',
        value: 'https://showers-angled-single.s3.amazonaws.com/image035.png',
        int: 18,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '19',
        value: 'https://showers-angled-single.s3.amazonaws.com/image037.png',
        int: 19,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '20',
        value: 'https://showers-angled-single.s3.amazonaws.com/image039.png',
        int: 20,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '21',
        value: 'https://showers-angled-single.s3.amazonaws.com/image041.png',
        int: 21,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '22',
        value: 'https://showers-angled-single.s3.amazonaws.com/image043.png',
        int: 22,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '23',
        value: 'https://showers-angled-single.s3.amazonaws.com/image045.png',
        int: 23,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '24',
        value: 'https://showers-angled-single.s3.amazonaws.com/image047.png',
        int: 24,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '25',
        value: 'https://showers-angled-single.s3.amazonaws.com/image049.png',
        int: 25,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '26',
        value: 'https://showers-angled-single.s3.amazonaws.com/image051.png',
        int: 26,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '27',
        value: 'https://showers-angled-single.s3.amazonaws.com/image053.png',
        int: 27,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '28',
        value: 'https://showers-angled-single.s3.amazonaws.com/image055.png',
        int: 28,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '29',
        value: 'https://showers-angled-single.s3.amazonaws.com/image057.png',
        int: 29,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '30',
        value: 'https://showers-angled-single.s3.amazonaws.com/image059.png',
        int: 30,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '31',
        value: 'https://showers-angled-single.s3.amazonaws.com/image061.png',
        int: 31,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '32',
        value: 'https://showers-angled-single.s3.amazonaws.com/image063.png',
        int: 32,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '33',
        value: 'https://showers-angled-single.s3.amazonaws.com/image065.png',
        int: 33,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '34',
        value: 'https://showers-angled-single.s3.amazonaws.com/image067.png',
        int: 34,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '35',
        value: 'https://showers-angled-single.s3.amazonaws.com/image069.png',
        int: 35,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '36',
        value: 'https://showers-angled-single.s3.amazonaws.com/image071.png',
        int: 36,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '37',
        value: 'https://showers-angled-single.s3.amazonaws.com/image073.png',
        int: 37,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '38',
        value: 'https://showers-angled-single.s3.amazonaws.com/image075.png',
        int: 38,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '39',
        value: 'https://showers-angled-single.s3.amazonaws.com/image077.png',
        int: 39,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '40',
        value: 'https://showers-angled-single.s3.amazonaws.com/image079.png',
        int: 40,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '41',
        value: 'https://showers-angled-single.s3.amazonaws.com/image081.png',
        int: 41,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '42',
        value: 'https://showers-angled-single.s3.amazonaws.com/image083.png',
        int: 42,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '43',
        value: 'https://showers-angled-single.s3.amazonaws.com/image085.png',
        int: 43,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '44',
        value: 'https://showers-angled-single.s3.amazonaws.com/image087.png',
        int: 44,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '45',
        value: 'https://showers-angled-single.s3.amazonaws.com/image089.png',
        int: 45,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '46',
        value: 'https://showers-angled-single.s3.amazonaws.com/image091.png',
        int: 46,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '47',
        value: 'https://showers-angled-single.s3.amazonaws.com/image093.png',
        int: 47,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '48',
        value: 'https://showers-angled-single.s3.amazonaws.com/image095.png',
        int: 48,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '49',
        value: 'https://showers-angled-single.s3.amazonaws.com/image097.png',
        int: 49,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '50',
        value: 'https://showers-angled-single.s3.amazonaws.com/image099.png',
        int: 50,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '51',
        value: 'https://showers-angled-single.s3.amazonaws.com/image101.png',
        int: 51,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '52',
        value: 'https://showers-angled-single.s3.amazonaws.com/image103.png',
        int: 52,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '53',
        value: 'https://showers-angled-single.s3.amazonaws.com/image105.png',
        int: 53,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '54',
        value: 'https://showers-angled-single.s3.amazonaws.com/image107.png',
        int: 54,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '55',
        value: 'https://showers-angled-single.s3.amazonaws.com/image109.png',
        int: 55,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '56',
        value: 'https://showers-angled-single.s3.amazonaws.com/image111.png',
        int: 56,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '57',
        value: 'https://showers-angled-single.s3.amazonaws.com/image113.png',
        int: 57,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '58',
        value: 'https://showers-angled-single.s3.amazonaws.com/image115.png',
        int: 58,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '59',
        value: 'https://showers-angled-single.s3.amazonaws.com/image117.png',
        int: 59,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '60',
        value: 'https://showers-angled-single.s3.amazonaws.com/image119.png',
        int: 60,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '61',
        value: 'https://showers-angled-single.s3.amazonaws.com/image121.png',
        int: 61,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '62',
        value: 'https://showers-angled-single.s3.amazonaws.com/image123.png',
        int: 62,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '63',
        value: 'https://showers-angled-single.s3.amazonaws.com/image125.png',
        int: 63,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '64',
        value: 'https://showers-angled-single.s3.amazonaws.com/image127.png',
        int: 64,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '65',
        value: 'https://showers-angled-single.s3.amazonaws.com/image129.png',
        int: 65,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '66',
        value: 'https://showers-angled-single.s3.amazonaws.com/image131.png',
        int: 66,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '67',
        value: 'https://showers-angled-single.s3.amazonaws.com/image133.png',
        int: 67,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '68',
        value: 'https://showers-angled-single.s3.amazonaws.com/1.png',
        int: 68,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '69',
        value: 'https://showers-angled-single.s3.amazonaws.com/2.png',
        int: 69,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '70',
        value: 'https://showers-angled-single.s3.amazonaws.com/3.png',
        int: 70,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '71',
        value: 'https://showers-angled-single.s3.amazonaws.com/4.png',
        int: 71,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '72',
        value: 'https://showers-angled-single.s3.amazonaws.com/5.png',
        int: 72,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '73',
        value: 'https://showers-angled-single.s3.amazonaws.com/6.png',
        int: 73,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '74',
        value: 'https://showers-angled-single.s3.amazonaws.com/7.png',
        int: 74,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '75',
        value: 'https://showers-angled-single.s3.amazonaws.com/8.png',
        int: 75,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '76',
        value: 'https://showers-angled-single.s3.amazonaws.com/9.png',
        int: 76,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '77',
        value: 'https://showers-angled-single.s3.amazonaws.com/10.png',
        int: 77,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '78',
        value: 'https://showers-angled-single.s3.amazonaws.com/11.png',
        int: 78,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '79',
        value: 'https://showers-angled-single.s3.amazonaws.com/12.png',
        int: 79,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '80',
        value: 'https://showers-angled-single.s3.amazonaws.com/13.png',
        int: 80,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '81',
        value: 'https://showers-angled-single.s3.amazonaws.com/14.png',
        int: 81,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '82',
        value: 'https://showers-angled-single.s3.amazonaws.com/15.png',
        int: 82,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '83',
        value: 'https://showers-angled-single.s3.amazonaws.com/16.png',
        int: 83,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '84',
        value: 'https://showers-angled-single.s3.amazonaws.com/17.png',
        int: 84,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '85',
        value: 'https://showers-angled-single.s3.amazonaws.com/18.png',
        int: 85,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '86',
        value: 'https://showers-angled-single.s3.amazonaws.com/19.png',
        int: 86,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '87',
        value: 'https://showers-angled-single.s3.amazonaws.com/20.png',
        int: 87,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '88',
        value: 'https://showers-angled-single.s3.amazonaws.com/21.png',
        int: 88,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '89',
        value: 'https://showers-angled-single.s3.amazonaws.com/22.png',
        int: 89,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '90',
        value: 'https://showers-angled-single.s3.amazonaws.com/23.png',
        int: 90,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '91',
        value: 'https://showers-angled-single.s3.amazonaws.com/24.png',
        int: 91,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '92',
        value: 'https://showers-angled-single.s3.amazonaws.com/25.png',
        int: 92,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '93',
        value: 'https://showers-angled-single.s3.amazonaws.com/26.png',
        int: 93,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '94',
        value: 'https://showers-angled-single.s3.amazonaws.com/27.png',
        int: 94,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '95',
        value: 'https://showers-angled-single.s3.amazonaws.com/28.png',
        int: 95,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '96',
        value: 'https://showers-angled-single.s3.amazonaws.com/29.png',
        int: 96,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '97',
        value: 'https://showers-angled-single.s3.amazonaws.com/30.png',
        int: 97,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '98',
        value: 'https://showers-angled-single.s3.amazonaws.com/31.png',
        int: 98,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '99',
        value: 'https://showers-angled-single.s3.amazonaws.com/32.png',
        int: 99,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '100',
        value: 'https://showers-angled-single.s3.amazonaws.com/33.png',
        int: 100,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '101',
        value: 'https://showers-angled-single.s3.amazonaws.com/34.png',
        int: 101,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '102',
        value: 'https://showers-angled-single.s3.amazonaws.com/35.png',
        int: 102,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '103',
        value: 'https://showers-angled-single.s3.amazonaws.com/36.png',
        int: 103,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '104',
        value: 'https://showers-angled-single.s3.amazonaws.com/37.png',
        int: 104,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '105',
        value: 'https://showers-angled-single.s3.amazonaws.com/38.png',
        int: 105,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '106',
        value: 'https://showers-angled-single.s3.amazonaws.com/39.png',
        int: 106,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '107',
        value: 'https://showers-angled-single.s3.amazonaws.com/40.png',
        int: 107,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '108',
        value: 'https://showers-angled-single.s3.amazonaws.com/41.png',
        int: 108,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '109',
        value: 'https://showers-angled-single.s3.amazonaws.com/42.png',
        int: 109,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '110',
        value: 'https://showers-angled-single.s3.amazonaws.com/43.png',
        int: 110,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '111',
        value: 'https://showers-angled-single.s3.amazonaws.com/44.png',
        int: 111,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '112',
        value: 'https://showers-angled-single.s3.amazonaws.com/45.png',
        int: 112,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '113',
        value: 'https://showers-angled-single.s3.amazonaws.com/46.png',
        int: 113,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '114',
        value: 'https://showers-angled-single.s3.amazonaws.com/47.png',
        int: 114,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '115',
        value: 'https://showers-angled-single.s3.amazonaws.com/48.png',
        int: 115,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '116',
        value: 'https://showers-angled-single.s3.amazonaws.com/49.png',
        int: 116,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '117',
        value: 'https://showers-angled-single.s3.amazonaws.com/50.png',
        int: 117,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '118',
        value: 'https://showers-angled-single.s3.amazonaws.com/51.png',
        int: 118,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '119',
        value: 'https://showers-angled-single.s3.amazonaws.com/52.png',
        int: 119,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '120',
        value: 'https://showers-angled-single.s3.amazonaws.com/53.png',
        int: 120,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '121',
        value: 'https://showers-angled-single.s3.amazonaws.com/54.png',
        int: 121,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '122',
        value: 'https://showers-angled-single.s3.amazonaws.com/55.png',
        int: 122,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '123',
        value: 'https://showers-angled-single.s3.amazonaws.com/56.png',
        int: 123,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '124',
        value: 'https://showers-angled-single.s3.amazonaws.com/57.png',
        int: 124,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '125',
        value: 'https://showers-angled-single.s3.amazonaws.com/58.png',
        int: 125,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '126',
        value: 'https://showers-angled-single.s3.amazonaws.com/59.png',
        int: 126,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '127',
        value: 'https://showers-angled-single.s3.amazonaws.com/60.png',
        int: 127,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '128',
        value: 'https://showers-angled-single.s3.amazonaws.com/61.png',
        int: 128,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '129',
        value: 'https://showers-angled-single.s3.amazonaws.com/62.png',
        int: 129,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '130',
        value: 'https://showers-angled-single.s3.amazonaws.com/63.png',
        int: 130,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '131',
        value: 'https://showers-angled-single.s3.amazonaws.com/64.png',
        int: 131,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '132',
        value: 'https://showers-angled-single.s3.amazonaws.com/65.png',
        int: 132,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '133',
        value: 'https://showers-angled-single.s3.amazonaws.com/66.png',
        int: 133,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
      {
        label: '134',
        value: 'https://showers-angled-single.s3.amazonaws.com/67.png',
        int: 134,
        option: 'showers_angled_single',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },
    ],
  },
  showers_inline_double: {
    values: [
      {
        label: '1',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_12_56-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 1,
        option: 'showers_inline_double',
      },
      {
        label: '2',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_13_42-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 2,
        option: 'showers_inline_double',
      },
      {
        label: '3',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_14_12-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 3,
        option: 'showers_inline_double',
      },
      {
        label: '4',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_14_49-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 4,
        option: 'showers_inline_double',
      },
      {
        label: '5',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_16_37-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 5,
        option: 'showers_inline_double',
      },
      {
        label: '6',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_17_10-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 6,
        option: 'showers_inline_double',
      },
      {
        label: '7',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_17_50-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 7,
        option: 'showers_inline_double',
      },
      {
        label: '8',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_18_11-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 8,
        option: 'showers_inline_double',
      },
      {
        label: '9',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_18_33-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 9,
        option: 'showers_inline_double',
      },
      {
        label: '10',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_18_53-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 10,
        option: 'showers_inline_double',
      },
      {
        label: '11',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_19_14-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 11,
        option: 'showers_inline_double',
      },
      {
        label: '12',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_19_37-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 12,
        option: 'showers_inline_double',
      },
      {
        label: '13',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_19_58-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 13,
        option: 'showers_inline_double',
      },
      {
        label: '14',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_20_23-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 14,
        option: 'showers_inline_double',
      },
      {
        label: '15',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_20_38-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 15,
        option: 'showers_inline_double',
      },
      {
        label: '16',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_21_00-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 16,
        option: 'showers_inline_double',
      },
      {
        label: '17',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_21_39-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 17,
        option: 'showers_inline_double',
      },
      {
        label: '18',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_22_00-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 18,
        option: 'showers_inline_double',
      },
      {
        label: '19',
        value:
          'https://showers-inline-double.s3.amazonaws.com/2021-04-27+14_22_15-SHOWERS_INLINE_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 19,
        option: 'showers_inline_double',
      },
      {
        label: '20',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/1.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 20,
      },
      {
        label: '21',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/2.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 21,
      },
      {
        label: '22',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/3.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 22,
      },
      {
        label: '23',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/4.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 23,
      },
      {
        label: '24',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/5.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 24,
      },
      {
        label: '25',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/6.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 25,
      },
      {
        label: '26',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/7.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 26,
      },
      {
        label: '27',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/8.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 27,
      },
      {
        label: '28',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/9.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 28,
      },
      {
        label: '29',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/10.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 29,
      },
      {
        label: '30',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/11.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 30,
      },
      {
        label: '31',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/12.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 31,
      },
      {
        label: '32',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/13.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 32,
      },
      {
        label: '33',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/14.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 33,
      },
      {
        label: '34',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/15.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 34,
      },
      {
        label: '35',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/16.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 35,
      },
      {
        label: '36',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/17.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 36,
      },
      {
        label: '37',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/18.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 37,
      },
      {
        label: '38',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/19.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 38,
      },
      {
        label: '39',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/20.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 39,
      },
      {
        label: '40',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/21.png',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 40,
      },
      {
        label: '41',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/22.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 41,
      },
      {
        label: '42',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/23.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 42,
      },
      {
        label: '43',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/24.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 43,
      },
      {
        label: '44',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/25.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 44,
      },
      {
        label: '45',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/26.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 45,
      },
      {
        label: '46',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/27.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 46,
      },
      {
        label: '47',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/28.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 47,
      },
      {
        label: '48',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/29.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 48,
      },
      {
        label: '49',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/30.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 49,
      },
      {
        label: '50',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/31.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 50,
      },
      {
        label: '51',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/32.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 51,
      },
      {
        label: '52',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/33.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 52,
      },
      {
        label: '53',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/34.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 53,
      },
      {
        label: '54',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/35.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 54,
      },
      {
        label: '55',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/36.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 55,
      },
      {
        label: '56',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/37.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 56,
      },
      {
        label: '57',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/38.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 57,
      },
      {
        label: '58',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/39.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 58,
      },
      {
        label: '59',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/40.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 59,
      },
      {
        label: '60',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/41.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 60,
      },
      {
        label: '61',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/42.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 61,
      },
      {
        label: '62',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/43.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 62,
      },
      {
        label: '63',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/44.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 63,
      },
      {
        label: '64',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/45.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 64,
      },
      {
        label: '65',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/46.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 65,
      },
      {
        label: '66',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/47.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 66,
      },
      {
        label: '67',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/48.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 67,
      },
      {
        label: '68',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/49.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 68,
      },
      {
        label: '69',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/50.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 69,
      },
      {
        label: '70',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/51.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 70,
      },
      {
        label: '71',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/52.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 71,
      },
      {
        label: '72',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/53.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 72,
      },
      {
        label: '73',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/54.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 73,
      },
      {
        label: '74',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/55.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 74,
      },
      {
        label: '75',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/56.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 75,
      },
      {
        label: '76',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/57.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 76,
      },
      {
        label: '77',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/58.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 77,
      },
      {
        label: '78',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/59.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 78,
      },
      {
        label: '79',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/60.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 79,
      },
      {
        label: '80',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/61.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 80,
      },
      {
        label: '81',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/62.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 81,
      },
      {
        label: '82',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/63.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 82,
      },
      {
        label: '83',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/64.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 83,
      },
      {
        label: '84',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/65.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 84,
      },
      {
        label: '85',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/66.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 85,
      },
      {
        label: '86',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/67.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 86,
      },
      {
        label: '87',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/68.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 87,
      },
      {
        label: '88',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/69.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 88,
      },
      {
        label: '89',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/70.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 89,
      },
      {
        label: '90',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/71.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 90,
      },
      {
        label: '91',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/72.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 91,
      },
      {
        label: '92',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/73.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 92,
      },
      {
        label: '93',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/74.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 93,
      },
      {
        label: '94',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/75.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 94,
      },
      {
        label: '95',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/76.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 95,
      },
      {
        label: '96',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/77.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 96,
      },
      {
        label: '97',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/78.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 97,
      },
      {
        label: '98',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/79.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 98,
      },
      {
        label: '99',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/80.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 99,
      },
      {
        label: '100',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/81.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 100,
      },
      {
        label: '101',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/82.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 101,
      },
      {
        label: '102',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/83.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 102,
      },
      {
        label: '103',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/84.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 103,
      },
      {
        label: '104',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/85.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 104,
      },
      {
        label: '105',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/86.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 105,
      },
      {
        label: '106',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/87.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 106,
      },
      {
        label: '107',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/88.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 107,
      },
      {
        label: '108',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/89.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 108,
      },
      {
        label: '109',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/90.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 109,
      },
      {
        label: '110',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/91.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 110,
      },
      {
        label: '111',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/92.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 111,
      },
      {
        label: '112',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/93.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 112,
      },
      {
        label: '113',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/94.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 113,
      },
      {
        label: '114',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/95.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 114,
      },
      {
        label: '115',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/96.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 115,
      },
      {
        label: '116',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/97.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 116,
      },
      {
        label: '117',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/98.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 117,
      },
      {
        label: '118',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/99.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 118,
      },
      {
        label: '119',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/100.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 119,
      },
      {
        label: '120',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/101.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 120,
      },
      {
        label: '121',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/102.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 121,
      },
      {
        label: '122',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/103.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 122,
      },
      {
        label: '123',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/104.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 123,
      },
      {
        label: '124',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/105.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 124,
      },
      {
        label: '125',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/106.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 125,
      },
      {
        label: '126',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/107.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 126,
      },
      {
        label: '127',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/108.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 127,
      },
      {
        label: '128',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/109.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 128,
      },
      {
        label: '129',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/110.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 129,
      },
      {
        label: '130',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/111.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 130,
      },
      {
        label: '131',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/112.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 131,
      },
      {
        label: '132',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/113.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 132,
      },
      {
        label: '133',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/114.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 133,
      },
      {
        label: '134',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/115.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 134,
      },
      {
        label: '135',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/116.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 135,
      },
      {
        label: '136',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/117.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 136,
      },
      {
        label: '137',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/118.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 137,
      },
      {
        label: '138',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/119.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 138,
      },
      {
        label: '139',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/120.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 139,
      },
      {
        label: '140',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/121.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 140,
      },
      {
        label: '141',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/122.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 141,
      },
      {
        label: '142',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/123.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 142,
      },
      {
        label: '143',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/124.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 143,
      },
      {
        label: '144',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/125.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 144,
      },
      {
        label: '145',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/126.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 145,
      },
      {
        label: '146',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/127.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 146,
      },
      {
        label: '147',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/128.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 147,
      },
      {
        label: '148',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/129.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 148,
      },
      {
        label: '149',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/130.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 149,
      },
      {
        label: '150',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/131.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 150,
      },
      {
        label: '151',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/132.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 151,
      },
      {
        label: '152',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/133.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 152,
      },
      {
        label: '153',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/134.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 153,
      },
      {
        label: '154',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/135.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 154,
      },
      {
        label: '155',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/136.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 155,
      },
      {
        label: '156',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/137.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 156,
      },
      {
        label: '157',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/138.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 157,
      },
      {
        label: '158',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/139.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 158,
      },
      {
        label: '159',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/140.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 159,
      },
      {
        label: '160',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/141.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 160,
      },
      {
        label: '161',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/142.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 161,
      },
      {
        label: '162',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/143.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 162,
      },
      {
        label: '163',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/144.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 163,
      },
      {
        label: '164',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/145.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 164,
      },
      {
        label: '165',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/146.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 165,
      },
      {
        label: '166',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/147.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 166,
      },
      {
        label: '167',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/148.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 167,
      },
      {
        label: '168',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/149.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 168,
      },
      {
        label: '169',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/150.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 169,
      },
      {
        label: '170',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/151.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 170,
      },
      {
        label: '171',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/152.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 171,
      },
      {
        label: '172',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/153.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 172,
      },
      {
        label: '173',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/154.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 173,
      },
      {
        label: '174',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/155.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 174,
      },
      {
        label: '175',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/156.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 175,
      },
      {
        label: '176',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/157.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 176,
      },
      {
        label: '177',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/158.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 177,
      },
      {
        label: '178',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/159.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 178,
      },
      {
        label: '179',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/160.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 179,
      },
      {
        label: '180',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/161.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 180,
      },
      {
        label: '181',
        option: 'showers_inline_double',
        value: 'https://showers-inline-double.s3.amazonaws.com/162.jpg',
        type: 'Single-Door-Sidelite-Rigt-new-door-container-glass-dooor-shower-enclosures',
        int: 181,
      },
    ],
  },
  shower_inline_single: {
    values: [
      {
        label: '1',
        value: 'https://showers-inline-single.s3.amazonaws.com/image001.png',
        int: 1,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '2',
        value: 'https://showers-inline-single.s3.amazonaws.com/image003.png',
        int: 2,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '3',
        value: 'https://showers-inline-single.s3.amazonaws.com/image005.png',
        int: 3,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '4',
        value: 'https://showers-inline-single.s3.amazonaws.com/image007.png',
        int: 4,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '5',
        value: 'https://showers-inline-single.s3.amazonaws.com/image009.png',
        int: 5,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '6',
        value: 'https://showers-inline-single.s3.amazonaws.com/image011.png',
        int: 6,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '7',
        value: 'https://showers-inline-single.s3.amazonaws.com/image013.png',
        int: 7,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '8',
        value: 'https://showers-inline-single.s3.amazonaws.com/image015.png',
        int: 8,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '9',
        value: 'https://showers-inline-single.s3.amazonaws.com/image017.png',
        int: 9,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '10',
        value: 'https://showers-inline-single.s3.amazonaws.com/image019.png',
        int: 10,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '11',
        value: 'https://showers-inline-single.s3.amazonaws.com/image021.png',
        int: 11,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '12',
        value: 'https://showers-inline-single.s3.amazonaws.com/image023.png',
        int: 12,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '13',
        value: 'https://showers-inline-single.s3.amazonaws.com/image025.png',
        int: 13,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '14',
        value: 'https://showers-inline-single.s3.amazonaws.com/image027.png',
        int: 14,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '15',
        value: 'https://showers-inline-single.s3.amazonaws.com/image029.png',
        int: 15,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '16',
        value: 'https://showers-inline-single.s3.amazonaws.com/image031.png',
        int: 16,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '17',
        value: 'https://showers-inline-single.s3.amazonaws.com/image033.png',
        int: 17,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '18',
        value: 'https://showers-inline-single.s3.amazonaws.com/image035.png',
        int: 18,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '19',
        value: 'https://showers-inline-single.s3.amazonaws.com/image036.jpg',
        int: 19,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '20',
        value: 'https://showers-inline-single.s3.amazonaws.com/image037.png',
        int: 20,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '21',
        value: 'https://showers-inline-single.s3.amazonaws.com/image039.png',
        int: 21,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '22',
        value: 'https://showers-inline-single.s3.amazonaws.com/image041.png',
        int: 22,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '23',
        value: 'https://showers-inline-single.s3.amazonaws.com/image043.png',
        int: 23,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '24',
        value: 'https://showers-inline-single.s3.amazonaws.com/image045.png',
        int: 24,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '25',
        value: 'https://showers-inline-single.s3.amazonaws.com/image047.png',
        int: 25,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '26',
        value: 'https://showers-inline-single.s3.amazonaws.com/image049.png',
        int: 26,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '27',
        value: 'https://showers-inline-single.s3.amazonaws.com/image051.png',
        int: 27,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '28',
        value: 'https://showers-inline-single.s3.amazonaws.com/image052.jpg',
        int: 28,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '29',
        value: 'https://showers-inline-single.s3.amazonaws.com/image053.png',
        int: 29,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },

      {
        label: '30',
        value: 'https://showers-inline-single.s3.amazonaws.com/image055.png',
        int: 30,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '31',
        value: 'https://showers-inline-single.s3.amazonaws.com/imaje31.jpg',
        int: 31,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '32',
        value: 'https://showers-inline-single.s3.amazonaws.com/2.jpg',
        int: 32,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '33',
        value: 'https://showers-inline-single.s3.amazonaws.com/3.jpg',
        int: 33,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '34',
        value: 'https://showers-inline-single.s3.amazonaws.com/4.jpg',
        int: 34,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '35',
        value: 'https://showers-inline-single.s3.amazonaws.com/5.jpg',
        int: 35,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '36',
        value: 'https://showers-inline-single.s3.amazonaws.com/6.jpg',
        int: 36,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '37',
        value: 'https://showers-inline-single.s3.amazonaws.com/7.jpg',
        int: 37,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '38',
        value: 'https://showers-inline-single.s3.amazonaws.com/8.jpg',
        int: 38,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '39',
        value: 'https://showers-inline-single.s3.amazonaws.com/9.jpg',
        int: 39,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '40',
        value: 'https://showers-inline-single.s3.amazonaws.com/10.jpg',
        int: 40,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '41',
        value: 'https://showers-inline-single.s3.amazonaws.com/11.jpg',
        int: 41,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '42',
        value: 'https://showers-inline-single.s3.amazonaws.com/12.jpg',
        int: 42,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '43',
        value: 'https://showers-inline-single.s3.amazonaws.com/13.jpg',
        int: 43,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '44',
        value: 'https://showers-inline-single.s3.amazonaws.com/14.jpg',
        int: 44,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '45',
        value: 'https://showers-inline-single.s3.amazonaws.com/15.jpg',
        int: 45,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '46',
        value: 'https://showers-inline-single.s3.amazonaws.com/16.jpg',
        int: 46,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '47',
        value: 'https://showers-inline-single.s3.amazonaws.com/17.jpg',
        int: 47,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '48',
        value: 'https://showers-inline-single.s3.amazonaws.com/18.jpg',
        int: 48,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '49',
        value: 'https://showers-inline-single.s3.amazonaws.com/19.jpg',
        int: 49,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '50',
        value: 'https://showers-inline-single.s3.amazonaws.com/20.jpg',
        int: 50,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '51',
        value: 'https://showers-inline-single.s3.amazonaws.com/21.jpg',
        int: 51,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '52',
        value: 'https://showers-inline-single.s3.amazonaws.com/22.jpg',
        int: 52,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '53',
        value: 'https://showers-inline-single.s3.amazonaws.com/23.jpg',
        int: 53,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '54',
        value: 'https://showers-inline-single.s3.amazonaws.com/24.jpg',
        int: 54,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '55',
        value: 'https://showers-inline-single.s3.amazonaws.com/25.jpg',
        int: 55,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '56',
        value: 'https://showers-inline-single.s3.amazonaws.com/26.jpg',
        int: 56,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '57',
        value: 'https://showers-inline-single.s3.amazonaws.com/27.jpg',
        int: 57,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '58',
        value: 'https://showers-inline-single.s3.amazonaws.com/28.jpg',
        int: 58,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '59',
        value: 'https://showers-inline-single.s3.amazonaws.com/29.jpg',
        int: 59,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        option: 'shower_inline_single',
      },
      {
        label: '60',
        value: 'https://showers-inline-single.s3.amazonaws.com/30.jpg',
        int: 60,
        option: 'shower_inline_single',
      },
      {
        label: '61',
        value: 'https://showers-inline-single.s3.amazonaws.com/31.jpg',
        int: 61,
        option: 'shower_inline_single',
      },
      {
        label: '62',
        value: 'https://showers-inline-single.s3.amazonaws.com/32.jpg',
        int: 62,
        option: 'shower_inline_single',
      },
      {
        label: '63',
        value: 'https://showers-inline-single.s3.amazonaws.com/33.jpg',
        int: 63,
        option: 'shower_inline_single',
      },
      {
        label: '64',
        value: 'https://showers-inline-single.s3.amazonaws.com/34.jpg',
        int: 64,
        option: 'shower_inline_single',
      },
      {
        label: '65',
        value: 'https://showers-inline-single.s3.amazonaws.com/35.jpg',
        int: 65,
        option: 'shower_inline_single',
      },
      {
        label: '66',
        value: 'https://showers-inline-single.s3.amazonaws.com/36.jpg',
        int: 66,
        option: 'shower_inline_single',
      },
      {
        label: '67',
        value: 'https://showers-inline-single.s3.amazonaws.com/37.jpg',
        int: 67,
        option: 'shower_inline_single',
      },
      {
        label: '68',
        value: 'https://showers-inline-single.s3.amazonaws.com/38.jpg',
        int: 68,
        option: 'shower_inline_single',
      },
      {
        label: '69',
        value: 'https://showers-inline-single.s3.amazonaws.com/39.jpg',
        int: 69,
        option: 'shower_inline_single',
      },
      {
        label: '70',
        value: 'https://showers-inline-single.s3.amazonaws.com/40.jpg',
        int: 70,
        option: 'shower_inline_single',
      },
      {
        label: '71',
        value: 'https://showers-inline-single.s3.amazonaws.com/41.jpg',
        int: 71,
        option: 'shower_inline_single',
      },
      {
        label: '72',
        value: 'https://showers-inline-single.s3.amazonaws.com/42.jpg',
        int: 72,
        option: 'shower_inline_single',
      },
      {
        label: '73',
        value: 'https://showers-inline-single.s3.amazonaws.com/43.jpg',
        int: 73,
        option: 'shower_inline_single',
      },
      {
        label: '74',
        value: 'https://showers-inline-single.s3.amazonaws.com/44.jpg',
        int: 74,
        option: 'shower_inline_single',
      },
      {
        label: '75',
        value: 'https://showers-inline-single.s3.amazonaws.com/45.jpg',
        int: 75,
        option: 'shower_inline_single',
      },
      {
        label: '76',
        value: 'https://showers-inline-single.s3.amazonaws.com/46.jpg',
        int: 76,
        option: 'shower_inline_single',
      },
      {
        label: '77',
        value: 'https://showers-inline-single.s3.amazonaws.com/47.jpg',
        int: 77,
        option: 'shower_inline_single',
      },
      {
        label: '78',
        value: 'https://showers-inline-single.s3.amazonaws.com/48.jpg',
        int: 78,
        option: 'shower_inline_single',
      },
      {
        label: '79',
        value: 'https://showers-inline-single.s3.amazonaws.com/49.jpg',
        int: 79,
        option: 'shower_inline_single',
      },
      {
        label: '81',
        value: 'https://showers-inline-single.s3.amazonaws.com/41.jpg',
        int: 81,
        option: 'shower_inline_single',
      },
      {
        label: '82',
        value: 'https://showers-inline-single.s3.amazonaws.com/42.jpg',
        int: 82,
        option: 'shower_inline_single',
      },
      {
        label: '83',
        value: 'https://showers-inline-single.s3.amazonaws.com/43.jpg',
        int: 83,
        option: 'shower_inline_single',
      },
      {
        label: '84',
        value: 'https://showers-inline-single.s3.amazonaws.com/44.jpg',
        int: 84,
        option: 'shower_inline_single',
      },
      {
        label: '85',
        value: 'https://showers-inline-single.s3.amazonaws.com/45.jpg',
        int: 85,
        option: 'shower_inline_single',
      },
      {
        label: '86',
        value: 'https://showers-inline-single.s3.amazonaws.com/46.jpg',
        int: 86,
        option: 'shower_inline_single',
      },
      {
        label: '87',
        value: 'https://showers-inline-single.s3.amazonaws.com/47.jpg',
        int: 87,
        option: 'shower_inline_single',
      },
      {
        label: '88',
        value: 'https://showers-inline-single.s3.amazonaws.com/48.jpg',
        int: 88,
        option: 'shower_inline_single',
      },
      {
        label: '89',
        value: 'https://showers-inline-single.s3.amazonaws.com/49.jpg',
        int: 89,
        option: 'shower_inline_single',
      },
      {
        label: '90',
        value: 'https://showers-inline-single.s3.amazonaws.com/50.jpg',
        int: 90,
        option: 'shower_inline_single',
      },
      {
        label: '91',
        value: 'https://showers-inline-single.s3.amazonaws.com/51.jpg',
        int: 91,
        option: 'shower_inline_single',
      },
      {
        label: '92',
        value: 'https://showers-inline-single.s3.amazonaws.com/52.jpg',
        int: 92,
        option: 'shower_inline_single',
      },
      {
        label: '93',
        value: 'https://showers-inline-single.s3.amazonaws.com/53.jpg',
        int: 93,
        option: 'shower_inline_single',
      },
      {
        label: '94',
        value: 'https://showers-inline-single.s3.amazonaws.com/54.jpg',
        int: 94,
        option: 'shower_inline_single',
      },
      {
        label: '95',
        value: 'https://showers-inline-single.s3.amazonaws.com/55.jpg',
        int: 95,
        option: 'shower_inline_single',
      },
      {
        label: '96',
        value: 'https://showers-inline-single.s3.amazonaws.com/56.jpg',
        int: 96,
        option: 'shower_inline_single',
      },
      {
        label: '97',
        value: 'https://showers-inline-single.s3.amazonaws.com/57.jpg',
        int: 97,
        option: 'shower_inline_single',
      },
      {
        label: '98',
        value: 'https://showers-inline-single.s3.amazonaws.com/58.jpg',
        int: 98,
        option: 'shower_inline_single',
      },
      {
        label: '99',
        value: 'https://showers-inline-single.s3.amazonaws.com/59.jpg',
        int: 99,
        option: 'shower_inline_single',
      },
      {
        label: '100',
        value: 'https://showers-inline-single.s3.amazonaws.com/60.jpg',
        int: 100,
        option: 'shower_inline_single',
      },
      {
        label: '101',
        value: 'https://showers-inline-single.s3.amazonaws.com/61.jpg',
        int: 101,
        option: 'shower_inline_single',
      },
      {
        label: '102',
        value: 'https://showers-inline-single.s3.amazonaws.com/62.jpg',
        int: 102,
        option: 'shower_inline_single',
      },
      {
        label: '103',
        value: 'https://showers-inline-single.s3.amazonaws.com/63.jpg',
        int: 103,
        option: 'shower_inline_single',
      },
      {
        label: '104',
        value: 'https://showers-inline-single.s3.amazonaws.com/64.jpg',
        int: 104,
        option: 'shower_inline_single',
      },
      {
        label: '105',
        value: 'https://showers-inline-single.s3.amazonaws.com/65.jpg',
        int: 105,
        option: 'shower_inline_single',
      },
      {
        label: '106',
        value: 'https://showers-inline-single.s3.amazonaws.com/66.jpg',
        int: 106,
        option: 'shower_inline_single',
      },
      {
        label: '107',
        value: 'https://showers-inline-single.s3.amazonaws.com/67.jpg',
        int: 107,
        option: 'shower_inline_single',
      },
      {
        label: '108',
        value: 'https://showers-inline-single.s3.amazonaws.com/68.jpg',
        int: 108,
        option: 'shower_inline_single',
      },
      {
        label: '109',
        value: 'https://showers-inline-single.s3.amazonaws.com/69.jpg',
        int: 109,
        option: 'shower_inline_single',
      },
      {
        label: '110',
        value: 'https://showers-inline-single.s3.amazonaws.com/70.jpg',
        int: 110,
        option: 'shower_inline_single',
      },
      {
        label: '111',
        value: 'https://showers-inline-single.s3.amazonaws.com/71.jpg',
        int: 111,
        option: 'shower_inline_single',
      },
      {
        label: '112',
        value: 'https://showers-inline-single.s3.amazonaws.com/72.jpg',
        int: 112,
        option: 'shower_inline_single',
      },
      {
        label: '113',
        value: 'https://showers-inline-single.s3.amazonaws.com/73.jpg',
        int: 113,
        option: 'shower_inline_single',
      },
      {
        label: '114',
        value: 'https://showers-inline-single.s3.amazonaws.com/74.jpg',
        int: 114,
        option: 'shower_inline_single',
      },
      {
        label: '115',
        value: 'https://showers-inline-single.s3.amazonaws.com/75.jpg',
        int: 115,
        option: 'shower_inline_single',
      },
      {
        label: '116',
        value: 'https://showers-inline-single.s3.amazonaws.com/76.jpg',
        int: 116,
        option: 'shower_inline_single',
      },
      {
        label: '117',
        value: 'https://showers-inline-single.s3.amazonaws.com/77.jpg',
        int: 117,
        option: 'shower_inline_single',
      },
      {
        label: '118',
        value: 'https://showers-inline-single.s3.amazonaws.com/78.jpg',
        int: 118,
        option: 'shower_inline_single',
      },
    ],
  },
  windows: {
    values: [
      {
        label: '1',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C1.jpg',
        type: 'windows',
        int: 1,
      },
      {
        label: '2',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C2.jpg',
        type: 'windows',
        int: 2,
      },
      {
        label: '3',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C3.jpg',
        type: 'windows',
        int: 3,
      },
      {
        label: '4',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C4.jpg',
        type: 'windows',
        int: 4,
      },
      {
        label: '5',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C5.jpg',
        type: 'windows',
        int: 5,
      },
      {
        label: '6',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C6.jpg',
        type: 'windows',
        int: 6,
      },
      {
        label: '7',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C7.jpg',
        type: 'windows',
        int: 7,
      },
      {
        label: '8',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C8.jpg',
        type: 'windows',
        int: 8,
      },
      {
        label: '9',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C9.jpg',
        type: 'windows',
        int: 9,
      },
      {
        label: '10',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C10.jpg',
        type: 'windows',
        int: 10,
      },
      {
        label: '11',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C11.jpg',
        type: 'windows',
        int: 11,
      },
      {
        label: '12',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C12.jpg',
        type: 'windows',
        int: 12,
      },
      {
        label: '13',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C13.jpg',
        type: 'windows',
        int: 13,
      },
      {
        label: '14',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C14.jpg',
        type: 'windows',
        int: 14,
      },
      {
        label: '15',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C15.jpg',
        type: 'windows',
        int: 15,
      },
      {
        label: '16',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C16.jpg',
        type: 'windows',
        int: 16,
      },
      {
        label: '17',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C17.jpg',
        type: 'windows',
        int: 17,
      },
      {
        label: '18',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C18.jpg',
        type: 'windows',
        int: 18,
      },
      {
        label: '19',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C19.jpg',
        type: 'windows',
        int: 19,
      },
      {
        label: '20',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C20.jpg',
        type: 'windows',
        int: 20,
      },
      {
        label: '21',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C21.jpg',
        type: 'windows',
        int: 21,
      },
      {
        label: '22',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C22.jpg',
        type: 'windows',
        int: 22,
      },
      {
        label: '23',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C23.jpg',
        type: 'windows',
        int: 23,
      },
      {
        label: '24',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C24.jpg',
        type: 'windows',
        int: 24,
      },
      {
        label: '25',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C25.jpg',
        type: 'windows',
        int: 25,
      },
      {
        label: '26',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C26.jpg',
        type: 'windows',
        int: 26,
      },
      {
        label: '27',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C27.jpg',
        type: 'windows',
        int: 27,
      },
      {
        label: '28',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C28.jpg',
        type: 'windows',
        int: 28,
      },
      {
        label: '29',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C29.jpg',
        type: 'windows',
        int: 29,
      },
      {
        label: '30',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C30.jpg',
        type: 'windows',
        int: 30,
      },
      {
        label: '31',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C31.jpg',
        type: 'windows',
        int: 31,
      },
      {
        label: '32',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C32.jpg',
        type: 'windows',
        int: 32,
      },
      {
        label: '33',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C33.jpg',
        type: 'windows',
        int: 33,
      },
      {
        label: '34',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C34.jpg',
        type: 'windows',
        int: 34,
      },
      {
        label: '35',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C35.jpg',
        type: 'windows',
        int: 35,
      },
      {
        label: '36',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C36.jpg',
        type: 'windows',
        int: 36,
      },
      {
        label: '37',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C37.jpg',
        type: 'windows',
        int: 37,
      },
      {
        label: '38',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C38.jpg',
        type: 'windows',
        int: 38,
      },

      {
        label: '39',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C40.jpg',
        type: 'windows',
        int: 39,
      },
      {
        label: '40',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C41.jpg',
        type: 'windows',
        int: 40,
      },
      {
        label: '41',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C42.jpg',
        type: 'windows',
        int: 41,
      },
      {
        label: '42',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C43.jpg',
        type: 'windows',
        int: 42,
      },
      {
        label: '43',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C44.jpg',
        type: 'windows',
        int: 43,
      },
      {
        label: '44',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C45.jpg',
        type: 'windows',
        int: 44,
      },
      {
        label: '45',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C46.jpg',
        type: 'windows',
        int: 45,
      },
      {
        label: '46',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C47.jpg',
        type: 'windows',
        int: 46,
      },
      {
        label: '47',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C48.jpg',
        type: 'windows',
        int: 47,
      },
      {
        label: '48',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C49.jpg',
        type: 'windows',
        int: 48,
      },
      {
        label: '49',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C50.jpg',
        type: 'windows',
        int: 49,
      },
      {
        label: '50',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C51.jpg',
        type: 'windows',
        int: 50,
      },
      {
        label: '51',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C52.jpg',
        type: 'windows',
        int: 51,
      },
      {
        label: '52',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C53.jpg',
        type: 'windows',
        int: 52,
      },
      {
        label: '53',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/C54.jpg',
        type: 'windows',
        int: 53,
      },
      {
        label: '54',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/DH1.jpg',
        type: 'windows',
        int: 54,
      },
      {
        label: '55',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/DH2.jpg',
        type: 'windows',
        int: 55,
      },
      {
        label: '56',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/DH3.jpg',
        type: 'windows',
        int: 56,
      },
      {
        label: '57',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F1.jpg',
        type: 'windows',
        int: 57,
      },
      {
        label: '58',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F2.jpg',
        type: 'windows',
        int: 58,
      },
      {
        label: '59',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F3.jpg',
        type: 'windows',
        int: 59,
      },
      {
        label: '60',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F4.jpg',
        type: 'windows',
        int: 60,
      },
      {
        label: '61',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F5.jpg',
        type: 'windows',
        int: 61,
      },
      {
        label: '62',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F6.jpg',
        type: 'windows',
        int: 62,
      },
      {
        label: '63',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F7.jpg',
        type: 'windows',
        int: 63,
      },
      {
        label: '64',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F8.jpg',
        type: 'windows',
        int: 64,
      },
      {
        label: '65',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F9.jpg',
        type: 'windows',
        int: 65,
      },
      {
        label: '66',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F10.jpg',
        type: 'windows',
        int: 66,
      },
      {
        label: '67',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F11.jpg',
        type: 'windows',
        int: 67,
      },
      {
        label: '68',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F12.jpg',
        type: 'windows',
        int: 68,
      },
      {
        label: '69',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F13.jpg',
        type: 'windows',
        int: 69,
      },
      {
        label: '70',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F14.jpg',
        type: 'windows',
        int: 70,
      },
      {
        label: '71',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F15.jpg',
        type: 'windows',
        int: 71,
      },
      {
        label: '72',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F16.jpg',
        type: 'windows',
        int: 72,
      },
      {
        label: '73',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F17.jpg',
        type: 'windows',
        int: 73,
      },
      {
        label: '74',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F18.jpg',
        type: 'windows',
        int: 74,
      },
      {
        label: '75',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F19.jpg',
        type: 'windows',
        int: 75,
      },
      {
        label: '76',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F20.jpg',
        type: 'windows',
        int: 76,
      },
      {
        label: '77',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/F21.jpg',
        type: 'windows',
        int: 77,
      },
      {
        label: '78',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/S1.jpg',
        type: 'windows',
        int: 78,
      },
      {
        label: '79',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/S2.jpg',
        type: 'windows',
        int: 79,
      },
      {
        label: '80',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/S3.jpg',
        type: 'windows',
        int: 80,
      },
      {
        label: '81',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/S4.jpg',
        type: 'windows',
        int: 81,
      },
      {
        label: '82',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/S5.jpg',
        type: 'windows',
        int: 82,
      },
      {
        label: '83',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/SH1.jpg',
        type: 'windows',
        int: 83,
      },
      {
        label: '84',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/SH2.jpg',
        type: 'windows',
        int: 84,
      },
      {
        label: '85',
        option: 'windows',
        value: 'https://windows-layouts.s3.amazonaws.com/SH3.jpg',
        type: 'windows',
        int: 85,
      },
    ],
  },
  showers_neo_double: {
    values: [
      {
        label: '1',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_39_47-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 1,
        option: 'showers_neo_double',
      },
      {
        label: '2',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_40_07-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 2,
        option: 'showers_neo_double',
      },
      {
        label: '3',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_40_21-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 3,
        option: 'showers_neo_double',
      },
      {
        label: '4',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_40_36-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 4,
        option: 'showers_neo_double',
      },
      {
        label: '5',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_41_00-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 5,
        option: 'showers_neo_double',
      },
      {
        label: '6',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_41_17-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 6,
        option: 'showers_neo_double',
      },
      {
        label: '7',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_41_35-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 7,
        option: 'showers_neo_double',
      },
      {
        label: '8',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_41_50-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 8,
        option: 'showers_neo_double',
      },
      {
        label: '9',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_42_04-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 9,
        option: 'showers_neo_double',
      },
      {
        label: '10',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_44_23-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 10,
        option: 'showers_neo_double',
      },
      {
        label: '11',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_44_56-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 11,
        option: 'showers_neo_double',
      },
      {
        label: '12',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_49_31-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 12,
        option: 'showers_neo_double',
      },
      {
        label: '13',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+08_55_26-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 13,
        option: 'showers_neo_double',
      },
      {
        label: '14',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_13_48-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 14,
        option: 'showers_neo_double',
      },
      {
        label: '15',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_14_08-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 15,
        option: 'showers_neo_double',
      },
      {
        label: '16',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_14_34-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 16,
        option: 'showers_neo_double',
      },
      {
        label: '17',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_16_37-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 17,
        option: 'showers_neo_double',
      },
      {
        label: '18',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_17_59-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 18,
        option: 'showers_neo_double',
      },
      {
        label: '19',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_18_20-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 19,
        option: 'showers_neo_double',
      },
      {
        label: '20',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_18_45-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 20,
        option: 'showers_neo_double',
      },
      {
        label: '21',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_19_13-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 21,
        option: 'showers_neo_double',
      },
      {
        label: '22',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_19_48-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 22,
        option: 'showers_neo_double',
      },
      {
        label: '23',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_20_48-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 23,
        option: 'showers_neo_double',
      },
      {
        label: '24',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_21_08-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 24,
        option: 'showers_neo_double',
      },
      {
        label: '25',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_21_35-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 25,
        option: 'showers_neo_double',
      },
      {
        label: '26',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_22_03-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 26,
        option: 'showers_neo_double',
      },
      {
        label: '27',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_22_30-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 27,
        option: 'showers_neo_double',
      },
      {
        label: '28',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_22_54-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 28,
        option: 'showers_neo_double',
      },
      {
        label: '29',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_23_11-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 29,
        option: 'showers_neo_double',
      },
      {
        label: '30',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_32_09-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 30,
        option: 'showers_neo_double',
      },
      {
        label: '31',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_33_06-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 31,
        option: 'showers_neo_double',
      },
      {
        label: '32',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_33_39-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 32,
        option: 'showers_neo_double',
      },
      {
        label: '33',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_33_48-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 33,
        option: 'showers_neo_double',
      },
      {
        label: '34',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_34_22-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 34,
        option: 'showers_neo_double',
      },
      {
        label: '35',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_34_46-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 35,
        option: 'showers_neo_double',
      },
      {
        label: '36',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_35_11-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 36,
        option: 'showers_neo_double',
      },
      {
        label: '37',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_35_38-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 37,
        option: 'showers_neo_double',
      },
      {
        label: '38',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_36_05-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 38,
        option: 'showers_neo_double',
      },
      {
        label: '39',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_36_29-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 39,
        option: 'showers_neo_double',
      },
      {
        label: '40',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_36_54-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 40,
        option: 'showers_neo_double',
      },
      {
        label: '41',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_37_26-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 41,
        option: 'showers_neo_double',
      },
      {
        label: '42',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_37_53-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 42,
        option: 'showers_neo_double',
      },
      {
        label: '43',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_38_20-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 43,
        option: 'showers_neo_double',
      },
      {
        label: '44',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_38_26-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 44,
        option: 'showers_neo_double',
      },
      {
        label: '45',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_39_03-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 45,
        option: 'showers_neo_double',
      },
      {
        label: '46',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_39_48-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 46,
        option: 'showers_neo_double',
      },
      {
        label: '47',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_40_29-SHOWERS_NEO_DOUBLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 47,
        option: 'showers_neo_double',
      },
      {
        label: '48',
        value:
          'https://showers-neo-double.s3.amazonaws.com/2021-04-28+11_41_37-SHOWERS_NEO_DOUBLE.pdf+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 48,
        option: 'showers_neo_double',
      },
      {
        label: '49',
        value: 'https://showers-neo-double.s3.amazonaws.com/1.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 49,
        option: 'showers_neo_double',
      },
      {
        label: '50',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/2.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 50,
      },
      {
        label: '51',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/3.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 51,
      },
      {
        label: '52',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/4.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 52,
      },
      {
        label: '53',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/5.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 53,
      },
      {
        label: '54',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/6.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 54,
      },
      {
        label: '55',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/7.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 55,
      },
      {
        label: '56',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/8.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 56,
      },
      {
        label: '57',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/9.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 57,
      },
      {
        label: '58',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/10.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 58,
      },
      {
        label: '59',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/11.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 59,
      },
      {
        label: '60',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/12.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 60,
      },
      {
        label: '61',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/13.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 61,
      },
      {
        label: '62',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/14.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 62,
      },
      {
        label: '63',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/15.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 63,
      },
      {
        label: '64',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/16.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 64,
      },
      {
        label: '65',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/17.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 65,
      },
      {
        label: '66',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/18.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 66,
      },
      {
        label: '67',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/19.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 67,
      },
      {
        label: '68',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/20.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 68,
      },
      {
        label: '69',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/21.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 69,
      },
      {
        label: '70',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/22.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 70,
      },
      {
        label: '71',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/23.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 71,
      },
      {
        label: '72',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/24.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 72,
      },
      {
        label: '73',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/25.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 73,
      },
      {
        label: '74',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/26.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 74,
      },
      {
        label: '75',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/27.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 75,
      },
      {
        label: '76',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/28.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 76,
      },
      {
        label: '77',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/29.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 77,
      },
      {
        label: '78',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/30.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 78,
      },
      {
        label: '79',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/31.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 79,
      },
      {
        label: '80',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/32.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 80,
      },
      {
        label: '81',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/33.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 81,
      },
      {
        label: '82',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/34.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 82,
      },
      {
        label: '83',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/35.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 83,
      },
      {
        label: '84',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/36.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 84,
      },
      {
        label: '85',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/37.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 85,
      },
      {
        label: '86',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/38.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 86,
      },
      {
        label: '87',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/39.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 87,
      },
      {
        label: '88',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/40.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 88,
      },
      {
        label: '89',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/41.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 89,
      },
      {
        label: '90',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/42.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 90,
      },
      {
        label: '91',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/43.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 91,
      },
      {
        label: '92',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/44.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 92,
      },
      {
        label: '93',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/45.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 93,
      },
      {
        label: '94',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/46.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 94,
      },
      {
        label: '95',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/47.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 95,
      },
      {
        label: '96',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/48.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 96,
      },
      {
        label: '97',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/49.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 97,
      },
      {
        label: '98',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/50.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 98,
      },
      {
        label: '99',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/51.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 99,
      },
      {
        label: '100',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/52.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 100,
      },
      {
        label: '101',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/53.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 101,
      },
      {
        label: '102',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/54.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 102,
      },
      {
        label: '103',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/55.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 103,
      },
      {
        label: '104',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/56.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 104,
      },
      {
        label: '105',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/57.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 105,
      },
      {
        label: '106',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/58.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 106,
      },
      {
        label: '107',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/59.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 107,
      },
      {
        label: '108',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/60.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 108,
      },
      {
        label: '109',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/61.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 109,
      },
      {
        label: '110',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/62.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 110,
      },
      {
        label: '111',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/63.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 111,
      },
      {
        label: '112',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/64.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 112,
      },
      {
        label: '113',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/65.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 113,
      },
      {
        label: '114',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/66.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 114,
      },
      {
        label: '115',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/67.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 115,
      },
      {
        label: '116',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/68.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 116,
      },
      {
        label: '117',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/69.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 117,
      },
      {
        label: '118',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/70.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 118,
      },
      {
        label: '119',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/71.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 119,
      },
      {
        label: '120',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/72.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 120,
      },
      {
        label: '121',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/73.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 121,
      },
      {
        label: '122',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/74.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 122,
      },
      {
        label: '123',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/75.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 123,
      },
      {
        label: '124',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/76.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 124,
      },
      {
        label: '125',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/77.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 125,
      },
      {
        label: '126',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/78.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 126,
      },
      {
        label: '127',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/79.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 127,
      },
      {
        label: '128',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/80.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 128,
      },
      {
        label: '129',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/81.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 129,
      },
      {
        label: '130',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/82.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 130,
      },
      {
        label: '131',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/83.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 131,
      },
      {
        label: '132',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/84.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 132,
      },
      {
        label: '133',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/85.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 133,
      },
      {
        label: '134',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/86.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 134,
      },
      {
        label: '135',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/87.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 135,
      },
      {
        label: '136',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/88.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 136,
      },
      {
        label: '137',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/89.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 137,
      },
      {
        label: '138',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/90.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 138,
      },
      {
        label: '139',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/91.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 139,
      },
      {
        label: '140',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/92.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 140,
      },
      {
        label: '141',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/93.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 141,
      },
      {
        label: '142',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/94.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 142,
      },
      {
        label: '143',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/95.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 143,
      },
      {
        label: '144',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/96.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 144,
      },
      {
        label: '145',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/97.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 145,
      },
      {
        label: '146',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/98.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 146,
      },
      {
        label: '147',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/99.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 147,
      },
      {
        label: '148',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/100.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 148,
      },
      {
        label: '149',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/101.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 149,
      },
      {
        label: '150',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/102.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 150,
      },
      {
        label: '151',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/103.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 151,
      },
      {
        label: '152',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/104.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 152,
      },
      {
        label: '153',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/105.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 153,
      },
      {
        label: '154',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/106.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 154,
      },
      {
        label: '155',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/107.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 155,
      },
      {
        label: '156',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/108.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 156,
      },
      {
        label: '157',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/109.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 157,
      },
      {
        label: '158',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/110.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 158,
      },
      {
        label: '159',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/111.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 159,
      },
      {
        label: '160',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/112.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 160,
      },
      {
        label: '161',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/113.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 161,
      },
      {
        label: '162',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/114.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 162,
      },
      {
        label: '163',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/115.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 163,
      },
      {
        label: '164',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/116.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 164,
      },
      {
        label: '165',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/117.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 165,
      },
      {
        label: '166',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/118.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 166,
      },
      {
        label: '167',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/119.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 167,
      },
      {
        label: '168',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/120.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 168,
      },
      {
        label: '169',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/121.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 169,
      },
      {
        label: '170',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/122.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 170,
      },
      {
        label: '171',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/123.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 171,
      },
      {
        label: '172',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/124.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 172,
      },
      {
        label: '173',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/125.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 173,
      },
      {
        label: '174',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/126.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 174,
      },
      {
        label: '175',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/127.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 175,
      },
      {
        label: '176',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/128.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 176,
      },
      {
        label: '177',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/129.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 177,
      },
      {
        label: '178',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/130.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 178,
      },
      {
        label: '179',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/131.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 179,
      },
      {
        label: '180',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/132.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 180,
      },
      {
        label: '181',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/133.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 181,
      },
      {
        label: '182',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/134.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 182,
      },
      {
        label: '183',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/135.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 183,
      },
      {
        label: '184',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/136.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 184,
      },
      {
        label: '185',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/137.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 185,
      },
      {
        label: '186',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/138.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 186,
      },
      {
        label: '187',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/139.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 187,
      },
      {
        label: '188',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/140.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 188,
      },
      {
        label: '189',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/141.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 189,
      },
      {
        label: '190',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/142.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 190,
      },
      {
        label: '191',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/143.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 191,
      },
      {
        label: '192',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/144.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 192,
      },
      {
        label: '193',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/145.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 193,
      },
      {
        label: '194',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/146.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 194,
      },
      {
        label: '195',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/147.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 195,
      },
      {
        label: '196',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/148.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 196,
      },
      {
        label: '197',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/149.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 197,
      },
      {
        label: '198',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/150.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 198,
      },
      {
        label: '199',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/151.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 199,
      },
      {
        label: '200',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/152.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 200,
      },
      {
        label: '201',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/153.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 201,
      },
      {
        label: '202',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/154.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 202,
      },
      {
        label: '203',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/155.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 203,
      },
      {
        label: '204',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/156.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 204,
      },
      {
        label: '205',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/157.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 205,
      },
      {
        label: '206',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/158.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 206,
      },
      {
        label: '207',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/159.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 207,
      },
      {
        label: '208',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/160.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 208,
      },
      {
        label: '209',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/161.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 209,
      },
      {
        label: '210',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/162.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 210,
      },
      {
        label: '211',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/163.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 211,
      },
      {
        label: '212',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/164.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 212,
      },
      {
        label: '213',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/165.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 213,
      },
      {
        label: '214',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/166.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 214,
      },
      {
        label: '215',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/167.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 215,
      },
      {
        label: '216',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/168.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 216,
      },
      {
        label: '217',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/169.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 217,
      },
      {
        label: '218',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/170.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 218,
      },
      {
        label: '219',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/171.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 219,
      },
      {
        label: '220',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/172.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 220,
      },
      {
        label: '221',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/173.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 221,
      },
      {
        label: '222',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/174.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 222,
      },
      {
        label: '223',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/175.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 223,
      },
      {
        label: '224',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/176.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 224,
      },
      {
        label: '225',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/177.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 225,
      },
      {
        label: '226',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/178.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 226,
      },
      {
        label: '227',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/179.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 227,
      },
      {
        label: '228',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/180.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 228,
      },
      {
        label: '229',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/181.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 229,
      },
      {
        label: '230',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/182.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 230,
      },
      {
        label: '231',
        option: 'showers_neo_double',
        value: 'https://showers-neo-double.s3.amazonaws.com/183.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 231,
      },
    ],
  },
  shower_neo_single: {
    values: [
      {
        label: '1',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+09_36_11-Window.png',
        int: 1,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '2',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+09_48_52-Window.png',
        int: 2,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '3',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+09_50_14-Window.png',
        int: 3,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '4',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+09_51_29-Window.png',
        int: 4,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '5',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+09_58_59-Window.png',
        int: 5,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '6',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_00_19-Window.png',
        int: 6,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '7',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_02_05-Window.png',
        int: 7,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '8',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_03_31-Window.png',
        int: 8,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '9',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_07_15-Window.png',
        int: 9,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '10',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_08_16-Window.png',
        int: 10,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '11',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_09_18-Window.png',
        int: 11,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '12',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_10_38-Window.png',
        int: 12,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '13',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_11_36-Window.png',
        int: 13,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '14',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_12_59-Window.png',
        int: 14,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '15',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_15_58-Window.png',
        int: 15,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '16',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_16_41-Window.png',
        int: 16,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '17',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_17_27-Window.png',
        int: 17,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '18',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_18_41-Window.png',
        int: 18,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '19',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_19_45-Window.png',
        int: 19,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '20',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_20_48-Window.png',
        int: 20,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '21',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_22_16-Window.png',
        int: 21,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '22',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_24_02-Window.png',
        int: 22,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '23',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_25_04-Window.png',
        int: 23,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '24',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_26_51-Window.png',
        int: 24,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '25',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_28_08-Window.png',
        int: 25,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '26',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_29_45-Window.png',
        int: 26,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '27',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_30_58-Window.png',
        int: 27,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '28',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_32_11-Window.png',
        int: 28,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '29',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_34_11-Window.png',
        int: 29,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '30',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+10_59_28-Window.png',
        int: 30,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '31',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_00_14-Window.png',
        int: 31,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '32',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_03_40-Window.png',
        int: 32,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '33',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_04_41-Window.png',
        int: 33,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '34',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_48_01-Window.png',
        int: 34,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '35',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_49_03-Window.png',
        int: 35,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '36',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_50_10-Window.png',
        int: 36,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '37',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_52_37-Window.png',
        int: 37,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '38',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_53_30-Window.png',
        int: 38,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '39',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_54_22-Window.png',
        int: 39,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '40',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+11_55_20-Window.png',
        int: 40,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '41',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_14_03-Window.png',
        int: 41,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '42',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_15_04-Window.png',
        int: 42,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '43',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_16_02-Window.png',
        int: 43,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '44',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_17_04-Window.png',
        int: 44,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '45',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_18_11-Window.png',
        int: 45,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '46',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_19_58-Window.png',
        int: 46,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '47',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_21_59-Window.png',
        int: 47,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '48',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_31_10-Window.png',
        int: 48,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '49',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_33_48-Window.png',
        int: 49,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '50',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_34_49-Window.png',
        int: 50,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '51',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_35_44-Window.png',
        int: 51,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '52',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_37_29-Window.png',
        int: 52,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '53',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_38_24-Window.png',
        int: 53,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '54',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_41_29-Window.png',
        int: 54,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '55',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_42_54-Window.png',
        int: 55,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '56',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+13_45_47-Window.png',
        int: 56,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '57',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_18_25-Window.png',
        int: 57,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '58',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_19_13-Window.png',
        int: 58,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '59',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_20_47-Window.png',
        int: 59,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '60',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_21_48-Window.png',
        int: 60,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '61',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_23_00-Window.png',
        int: 61,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '62',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_24_08-Window.png',
        int: 62,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '63',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_24_48-Window.png',
        int: 63,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '64',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_25_28-Window.png',
        int: 64,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '65',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_26_07-Window.png',
        int: 65,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '66',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_26_51-Window.png',
        int: 66,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '67',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_27_46-Window.png',
        int: 67,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '68',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2021-04-28+16_28_33-Window.png',
        int: 68,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '69',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/a1.2021-04-28+08_29_12-Window.png',
        int: 69,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '70',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/a2.2021-04-28+09_33_36-Window.png',
        int: 70,
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
      },

      {
        label: '71',
        option: 'shower_neo_single',
        value:
          'https://showers-neo-single.s3.amazonaws.com/a3.2021-04-29+13_44_44-SHOWERS_NEO_SINGLE.pdf+_+-+Foxit+PhantomPDF+-+__Remote.png',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 71,
      },
      {
        label: '72',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/1.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 72,
      },
      {
        label: '73',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/2.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 73,
      },
      {
        label: '74',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/3.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 74,
      },
      {
        label: '75',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/4.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 75,
      },
      {
        label: '76',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/5.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 76,
      },
      {
        label: '77',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/6.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 77,
      },
      {
        label: '78',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/7.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 78,
      },
      {
        label: '79',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/8.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 79,
      },
      {
        label: '80',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/9.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 80,
      },
      {
        label: '81',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/10.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 81,
      },
      {
        label: '82',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/11.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 82,
      },
      {
        label: '83',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/12.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 83,
      },
      {
        label: '84',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/13.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 84,
      },
      {
        label: '85',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/14.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 85,
      },
      {
        label: '86',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/15.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 86,
      },
      {
        label: '87',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/16.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 87,
      },
      {
        label: '88',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/17.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 88,
      },
      {
        label: '89',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/18.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 89,
      },
      {
        label: '90',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/19.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 90,
      },
      {
        label: '91',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/20.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 91,
      },
      {
        label: '92',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/21.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 92,
      },
      {
        label: '93',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/22.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 93,
      },
      {
        label: '94',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/23.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 94,
      },
      {
        label: '95',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/24.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 95,
      },
      {
        label: '96',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/25.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 96,
      },
      {
        label: '97',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/26.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 97,
      },
      {
        label: '98',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/27.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 98,
      },
      {
        label: '99',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/28.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 99,
      },
      {
        label: '100',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/29.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 100,
      },
      {
        label: '101',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/30.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 101,
      },
      {
        label: '102',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/31.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 102,
      },
      {
        label: '103',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/32.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 103,
      },
      {
        label: '104',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/33.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 104,
      },
      {
        label: '105',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/34.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 105,
      },
      {
        label: '106',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/35.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 106,
      },
      {
        label: '107',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/36.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 107,
      },
      {
        label: '108',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/37.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 108,
      },
      {
        label: '109',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/38.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 109,
      },
      {
        label: '110',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/39.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 110,
      },
      {
        label: '111',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/40.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 111,
      },
      {
        label: '112',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/41.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 112,
      },
      {
        label: '113',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/42.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 113,
      },
      {
        label: '114',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/43.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 114,
      },
      {
        label: '115',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/44.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 115,
      },
      {
        label: '116',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/45.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 116,
      },
      {
        label: '117',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/46.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 117,
      },
      {
        label: '118',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/47.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 118,
      },
      {
        label: '119',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/48.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 119,
      },
      {
        label: '120',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/49.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 120,
      },
      {
        label: '121',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/50.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 121,
      },
      {
        label: '122',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/51.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 122,
      },
      {
        label: '123',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/52.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 123,
      },
      {
        label: '124',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/53.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 124,
      },
      {
        label: '125',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/54.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 125,
      },
      {
        label: '126',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/55.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 126,
      },
      {
        label: '127',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/56.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 127,
      },
      {
        label: '128',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/57.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 128,
      },
      {
        label: '129',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/58.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 129,
      },
      {
        label: '130',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/59.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 130,
      },
      {
        label: '131',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/60.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 131,
      },
      {
        label: '132',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/61.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 132,
      },
      {
        label: '133',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/62.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 133,
      },
      {
        label: '134',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/63.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 134,
      },
      {
        label: '135',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/64.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 135,
      },
      {
        label: '136',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/65.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 136,
      },
      {
        label: '137',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/66.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 137,
      },
      {
        label: '138',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/67.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 138,
      },
      {
        label: '139',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/68.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 139,
      },
      {
        label: '140',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/69.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 140,
      },
      {
        label: '141',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/70.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 141,
      },
      {
        label: '142',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/71.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 142,
      },
      {
        label: '143',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/72.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 143,
      },
      {
        label: '144',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/73.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 144,
      },
      {
        label: '145',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/74.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 145,
      },
      {
        label: '146',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/75.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 146,
      },
      {
        label: '147',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/76.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 147,
      },
      {
        label: '148',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/77.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 148,
      },
      {
        label: '149',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/78.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 149,
      },
      {
        label: '150',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/79.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 150,
      },
      {
        label: '151',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/80.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 151,
      },
      {
        label: '152',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/81.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 152,
      },
      {
        label: '153',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/82.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 153,
      },
      {
        label: '154',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/83.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 154,
      },
      {
        label: '155',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/84.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 155,
      },
      {
        label: '156',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/85.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 156,
      },
      {
        label: '157',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/86.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 157,
      },
      {
        label: '158',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/87.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 158,
      },
      {
        label: '159',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/88.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 159,
      },
      {
        label: '160',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/89.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 160,
      },
      {
        label: '161',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/90.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 161,
      },
      {
        label: '162',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/91.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 162,
      },
      {
        label: '163',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/92.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 163,
      },
      {
        label: '164',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/93.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 164,
      },
      {
        label: '165',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/94.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 165,
      },
      {
        label: '166',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/95.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 166,
      },
      {
        label: '167',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/96.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 167,
      },
      {
        label: '168',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/97.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 168,
      },
      {
        label: '169',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/98.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 169,
      },
      {
        label: '170',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/99.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 170,
      },
      {
        label: '171',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/100.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 171,
      },
      {
        label: '172',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/101.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 172,
      },
      {
        label: '173',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/102.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 173,
      },
      {
        label: '174',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/103.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 174,
      },
      {
        label: '175',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/104.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 175,
      },
      {
        label: '176',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/105.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 176,
      },
      {
        label: '177',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/106.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 177,
      },
      {
        label: '178',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/107.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 178,
      },
      {
        label: '179',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/108.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 179,
      },
      {
        label: '180',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/109.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 180,
      },
      {
        label: '181',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/110.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 181,
      },
      {
        label: '182',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/111.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 182,
      },
      {
        label: '183',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/112.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 183,
      },
      {
        label: '184',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/113.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 184,
      },
      {
        label: '185',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/114.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 185,
      },
      {
        label: '186',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/115.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 186,
      },
      {
        label: '187',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/116.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 187,
      },
      {
        label: '188',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/117.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 188,
      },
      {
        label: '189',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/118.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 189,
      },
      {
        label: '190',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/119.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 190,
      },
      {
        label: '191',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/120.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 191,
      },
      {
        label: '192',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/121.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 192,
      },
      {
        label: '193',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/122.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 193,
      },
      {
        label: '194',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/123.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 194,
      },
      {
        label: '195',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/124.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 195,
      },
      {
        label: '196',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/125.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 196,
      },
      {
        label: '197',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/126.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 197,
      },
      {
        label: '198',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/127.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 198,
      },
      {
        label: '199',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/128.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 199,
      },
      {
        label: '200',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/129.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 200,
      },
      {
        label: '201',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/130.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 201,
      },
      {
        label: '202',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/131.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 202,
      },
      {
        label: '203',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/132.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 203,
      },
      {
        label: '204',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/133.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 204,
      },
      {
        label: '205',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/134.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 205,
      },
      {
        label: '206',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/135.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 206,
      },
      {
        label: '207',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/136.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 207,
      },
      {
        label: '208',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/137.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 208,
      },
      {
        label: '209',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/138.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 209,
      },
      {
        label: '210',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/139.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 210,
      },
      {
        label: '211',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/140.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 211,
      },
      {
        label: '212',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/141.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 212,
      },
      {
        label: '213',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/142.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 213,
      },
      {
        label: '214',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/143.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 214,
      },
      {
        label: '215',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/144.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 215,
      },
      {
        label: '216',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/145.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 216,
      },
      {
        label: '217',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/146.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 217,
      },
      {
        label: '218',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/147.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 218,
      },
      {
        label: '219',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/148.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 219,
      },
      {
        label: '220',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/149.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 220,
      },
      {
        label: '221',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/150.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 221,
      },
      {
        label: '222',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/151.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 222,
      },
      {
        label: '223',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/152.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 223,
      },
      {
        label: '224',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/153.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 224,
      },
      {
        label: '225',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/154.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 225,
      },
      {
        label: '226',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/155.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 226,
      },
      {
        label: '227',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/156.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 227,
      },
      {
        label: '228',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/157.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 228,
      },
      {
        label: '229',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/158.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 229,
      },
      {
        label: '230',
        option: 'shower_neo_single',
        value: 'https://showers-neo-single.s3.amazonaws.com/159.jpg',
        type: 'Single-Door-Sidelite-Right-new-door-container-glass-dooor-shower-enclosures',
        int: 230,
      },
    ],
  },
  patio: {
    values: [
      {
        label: 'OX',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_OX_Slider.jpg',
        int: 1,
        option: 'patio',
      },
      {
        label: 'XO',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_XO_Slider.jpg',
        int: 2,
        option: 'patio',
      },
      {
        label: 'OXO (SLIDE RIGHT)',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_OXO_Right_Slide.jpg',
        int: 3,
        option: 'patio',
      },
      {
        label: 'OXO (SLIDE LEFT)',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_OXO_Right_Slide.jpg',
        int: 4,
        option: 'patio',
      },
      {
        label: 'XOO',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_XOO_Slider.jpg',
        int: 5,
        option: 'patio',
      },
      {
        label: 'OOX',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_OOX_Slider.jpg',
        int: 6,
        option: 'patio',
      },
      {
        label: 'OXXO',
        value: 'https://sag-assets-media.s3.amazonaws.com/blueprints/pd_OXXO_Slider.jpg',
        int: 7,
        option: 'patio',
      },
    ],
  },
  hmdoor: {
    values: [
      {
        label: 1,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/HM.W+-+Door+Only+-+3+Hinges+-+A.png',
        int: 1,
        option: 'hmdoor',
      },
      {
        label: 2,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/HM.W+-+Door+Only+-+3+Hinges+-+B.png',
        int: 2,
        option: 'hmdoor',
      },
      {
        label: 3,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/HM.W+-+Door+Only+-+4+Hinges+-+A.png',
        int: 3,
        option: 'hmdoor',
      },
      {
        label: 4,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/HM.W+-+Door+Only+-+4+Hinges+-+B.png',
        int: 4,
        option: 'hmdoor',
      },
      {
        label: 5,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+BUTT+or+INT+HINGE++-+Single+A.png',
        int: 5,
        option: 'hmdoor',
      },
      {
        label: 6,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+BUTT+or+INT+HINGE++-+Single+B.png',
        int: 6,
        option: 'hmdoor',
      },
      {
        label: 7,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+BUTT+or+INT+Hinge+-+PAIR.png',
        int: 7,
        option: 'hmdoor',
      },
      {
        label: 8,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+INT+Hinge+-+PAIR.png',
        int: 8,
        option: 'hmdoor',
      },
      {
        label: 9,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+INT+Hinge+-+Single+A.png',
        int: 9,
        option: 'hmdoor',
      },
      {
        label: 10,
        value:
          'https://sag-assets-media.s3.amazonaws.com/DOOR+ONLY/SFA+-+Door+Only+-+INT+Hinge+-+Single+B.png',
        int: 10,
        option: 'hmdoor',
      },
    ],
  },
};

export const newdoorCategoryLayout = {
  Gdoor: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
  Aludoor: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
  hmdoor: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
  patio: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
  shower: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
  films: { types: ndshowerLayout, fields: ndshowerLayoutOptions },
};

// export const ndgdDoorLayout = [
//   {
//     value: nd_SE_1,
//     label: 'Option 1',
//     int: 0,
//   },
//   {
//     value: nd_SE_2,
//     label: 'Option 2',
//     int: 1,
//   },
//   {
//     value: nd_SE_3,
//     label: 'Option 3',
//     int: 2,
//   },
//   {
//     value: nd_SE_4,
//     label: 'Option 4',
//     int: 3,
//   },
//   {
//     value: nd_SE_5,
//     label: 'Option 5',
//     int: 4,
//   },
//   {
//     value: nd_SE_6,
//     label: 'Option 6',
//     int: 5,
//   },
//   {
//     value: nd_SE_7,
//     label: 'Option 7',
//     int: 6,
//   },
//   {
//     value: nd_SE_8,
//     label: 'Option 8',
//     int: 7,
//   },
//   {
//     value: nd_SE_9,
//     label: 'Option 9',
//     int: 8,
//   },
//   {
//     value: nd_SE_10,
//     label: 'Option 10',
//     int: 9,
//   },
// ];

export const ndgdDoorStyle = [
  { value: 'A (TOP & BOTTOM PATCH)', label: 'A (TOP & BOTTOM PATCH)' },
  { value: 'BP (BOTTOM RAIL + TOP PATCH)', label: 'BP (BOTTOM RAIL + TOP PATCH)' },
  { value: 'P (TOP & BOTTOM RAIL)', label: 'P (TOP & BOTTOM RAIL)' },
  {
    value: 'F (TOP & BOTTOM PATCH + BOTTOM PATCH LOCK)',
    label: 'F (TOP & BOTTOM PATCH + BOTTOM PATCH LOCK)',
  },
  {
    value: 'WP Type (TOP & BOTTOM RAILS + SIDE RAILS)',
    label: 'WP Type (TOP & BOTTOM RAILS + SIDE RAILS)',
  },
  { value: 'TBD', label: 'TBD' },
  { value: 'RESEARCH', label: 'RESEARCH' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
/* TYPE A/F */
export const ndgdTopPatch = [
  { value: 'CRL', label: 'CRL' },
  { value: 'Dorma', label: 'Dorma' },
  { value: 'Casma', label: 'Casma' },
];
export const ndgdBottomPatch = [
  { value: 'CRL', label: 'CRL' },
  { value: 'Dorma', label: 'Dorma' },
  { value: 'Catsma', label: 'Catsma' },
];
/* TYPE END */

export const ndgdDoorHand = [
  { value: 'Right Hand', label: 'Right Hand' },
  { value: 'Left Hand', label: 'Left Hand' },
  { value: 'Double Acting', label: 'Double Acting' },
];
export const ndgdGlassType = [
  { value: '3/8" Clear', label: '3/8" Clear' },
  { value: '3/8" Bronze', label: '3/8" Bronze' },
  { value: '3/8" Gray', label: '3/8" Gray' },
  { value: '3/8" Lami Clear', label: '3/8" Lami Clear' },
  { value: '3/8" Lami Bronze', label: '3/8" Lami Bronze' },
  { value: '3/8" Lami Gray', label: '3/8" Lami Gray' },
  { value: '3/8" Acid Etched', label: '3/8" Acid Etched' },
  { value: '3/8" Low Iron', label: '3/8" Low Iron' },
  { value: '1/2" Clear', label: '1/2" Clear' },
  { value: '1/2" Bronze', label: '1/2" Bronze' },
  { value: '1/2" Gray', label: '1/2" Gray' },
  { value: '1/2" Lami Clear', label: '1/2" Lami Clear' },
  { value: '1/2" Lami Bronze', label: '1/2" Lami Bronze' },
  { value: '1/2" Lami Gray', label: '1/2" Lami Gray' },
  { value: '1/2" Acid Etched', label: '1/2" Acid Etched' },
  { value: '1/2" Low Iron', label: '1/2" Low Iron' },
  { value: '5/8" Clear', label: '5/8" Clear' },
  { value: '5/8" Bronze', label: '5/8" Bronze' },
  { value: '5/8" Gray', label: '5/8" Gray' },
  { value: '5/8" Lami Clear', label: '5/8" Lami Clear' },
  { value: '5/8" Lami Bronze', label: '5/8" Lami Bronze' },
  { value: '5/8" Lami Gray', label: '5/8" Lami Gray' },
  { value: '5/8" Acid Etched', label: '5/8" Acid Etched' },
  { value: '5/8" Low Iron', label: '5/8" Low Iron' },
  { value: '3/4" Clear', label: '3/4" Clear' },
  { value: '3/4" Bronze', label: '3/4" Bronze' },
  { value: '3/4" Gray', label: '3/4" Gray' },
  { value: '3/4" Lami Clear', label: '3/4" Lami Clear' },
  { value: '3/4" Lami Bronze', label: '3/4" Lami Bronze' },
  { value: '3/4" Lami Gray', label: '3/4" Lami Gray' },
  { value: '3/4" Acid Etched', label: '3/4" Acid Etched' },
  { value: '3/4" Low Iron', label: '3/4" Low Iron' },
  { value: '1" Clear', label: '1" Clear' },
  { value: '1" Bronze', label: '1" Bronze' },
  { value: '1" Gray', label: '1" Gray' },
  { value: '1" Lami Clear', label: '1" Lami Clear' },
  { value: '1" Lami Bronze', label: '1" Lami Bronze' },
  { value: '1" Lami Gray', label: '1" Lami Gray' },
  { value: '1" Acid Etched', label: '1" Acid Etched' },
  { value: '1" Low Iron', label: '1" Low Iron' },
];
/* TYPE BP/P/WP */
export const ndgdRailType = [
  { value: 'SQUARE: STANDARD', label: 'SQUARE: STANDARD' },
  { value: 'SQUARE: 3/4" SLIM', label: 'SQUARE: 3/4" SLIM' },
  { value: 'SQUARE: 1 1/2" SLIM', label: 'SQUARE: 1 1/2" SLIM' },
  { value: 'SQUARE: 2 1/2" SLIM', label: 'SQUARE: 2 1/2" SLIM' },
  { value: 'SQUARE: 3 3/8"', label: 'SQUARE: 3 3/8"' },
  { value: 'SQUARE: 3 5/8"', label: 'SQUARE: 3 5/8"' },
  { value: 'SQUARE: 4"', label: 'SQUARE: 4"' },
  { value: 'SQUARE: 6"', label: 'SQUARE: 6"' },
  { value: 'SQUARE: 10”', label: 'SQUARE: 10”' },
  { value: 'TAPERED: STANDARD', label: 'TAPERED: STANDARD' },
  { value: 'TAPERED: 2 1/2" SLIM', label: 'TAPERED: 2 1/2" SLIM' },
  { value: 'TAPERED: 3 3/8"', label: 'TAPERED: 3 3/8"' },
  { value: 'TAPERED: 3 5/8"', label: 'TAPERED: 3 5/8"' },
  { value: 'TAPERED: 4"', label: 'TAPERED: 4"' },
  { value: 'TAPERED: 6"', label: 'TAPERED: 6"' },
  { value: 'TAPERED: 10”', label: 'TAPERED: 10”' },
  { value: 'OTHER', label: 'OTHER' },
];

export const ndgdTopRailType = [
  { value: 'Tapered', label: 'Tapered' },
  { value: 'Square', label: 'Square' },
];

export const ndgdBottomRailType = [
  { value: 'Tapered', label: 'Tapered' },
  { value: 'Square', label: 'Square' },
];
/* TYPE END */
export const ndgdMetalFinish = [
  { value: 'Clear Anodized', label: 'Clear Anodized' },
  { value: 'Bronze Anodized', label: 'Bronze Anodized' },
  { value: 'Polished Stainless Steel', label: 'Polished Stainless Steel' },
  { value: 'Satin Stainless', label: 'Satin Stainless' },
  { value: 'Polished Brass', label: 'Polished Brass' },
  { value: 'Satin Brass', label: 'Satin Brass' },
  { value: 'Clear Anodized (Standard  .4 mils)', label: 'Clear Anodized (Standard  .4 mils)' },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Polished Chrome (US26)', label: 'Polished Chrome (US26)' },
  { value: 'Satin Chrome (US26D)', label: 'Satin Chrome (US26D)' },
  { value: 'Polished Stainless Steel (US32)', label: 'Polished Stainless Steel (US32)' },
  { value: 'Satin Stainless Steel (US32D)', label: 'Satin Stainless Steel (US32D)' },
  { value: 'Satin Nickel (US15)', label: 'Satin Nickel (US15)' },
  { value: 'Oxidized Satin Nickel (US15A)', label: 'Oxidized Satin Nickel (US15A)' },
  { value: 'Polished Brass (US3)', label: 'Polished Brass (US3)' },
  { value: 'Satin Brass (US4)', label: 'Satin Brass (US4)' },
  { value: 'Oxidized Satin Brass (US5)', label: 'Oxidized Satin Brass (US5)' },
  { value: 'Polished Bronze (US9)', label: 'Polished Bronze (US9)' },
  { value: 'Satin Bronze (US10)', label: 'Satin Bronze (US10)' },
  { value: 'Oil Rubbed Bronze (US10B)', label: 'Oil Rubbed Bronze (US10B)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const ndgdClosers = [
  { label: 'Surface Mounted (standard)', value: 'Surface Mounted (standard)' },
  { label: 'Surface Mounted (heavy duty)', value: 'Surface Mounted (heavy duty)' },
  {
    label: 'Surface Mounted + Drop Plate (standard)',
    value: 'Surface Mounted + Drop Plate (standard)',
  },
  {
    label: 'Surface Mounted + Drop Plate (heavy duty)',
    value: 'Surface Mounted + Drop Plate (heavy duty)',
  },
  { label: 'Surface Mounted (other see notes)', value: 'Surface Mounted (other see notes)' },
  { label: 'Concealed Overhead (standard)', value: 'Concealed Overhead (standard)' },
  {
    label: 'Concealed Overhead 90 (No-Hold Open)',
    value: 'Concealed Overhead 90 (No-Hold Open)',
  },
  { label: 'Concealed Overhead 90 (Hold Open)', value: 'Concealed Overhead 90 (Hold Open)' },
  {
    label: 'Concealed Overhead 105 (No-Hold Open)',
    value: 'Concealed Overhead 105 (No-Hold Open)',
  },
  {
    label: 'Concealed Overhead 105 (Hold Open)',
    value: 'Concealed Overhead 105 (Hold Open)',
  },
  {
    label: 'Concealed Overhead (other see notes)',
    value: 'Concealed Overhead (other see notes)',
  },
  { label: 'Floor Closer (standard)', value: 'Floor Closer (standard)' },
  { label: 'Floor Closer (Non-Hold Open)', value: 'Floor Closer (Non-Hold Open)' },
  { label: 'Floor Closer (Hold Open)', value: 'Floor Closer (Hold Open)' },
  { label: 'Floor Closer (other see notes)', value: 'Floor Closer (other see notes)' },
  { label: 'Automatic - Concealed', value: 'Automatic - Concealed' },
  { label: 'Automatic - Surface Mounted', value: 'Automatic - Surface Mounted' },
  { label: 'Automatic - Floor', value: 'Automatic - Floor' },
  { label: 'Automatic - Slider (By Pass)', value: 'Automatic - Slider (By Pass)' },
  { label: 'Automatic - Slider (By Part)', value: 'Automatic - Slider (By Part)' },
  {
    label: 'Automatic - Left.Concealed + Right.Standard Closer',
    value: 'Automatic - Left.Concealed + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Concealed + Left.Standard Closer',
    value: 'Automatic - Right.Concealed + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
    value: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
    value: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Floor + Right.Standard Closer',
    value: 'Automatic - Left.Floor + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Floor + Left.Standard Closer',
    value: 'Automatic - Right.Floor + Left.Standard Closer',
  },
  { label: 'Automatic: Slider (By Pass)', value: 'Automatic: Slider (By Pass)' },
  { label: 'Automatic: Slider (By Part)', value: 'Automatic: Slider (By Part)' },
  { label: 'Automatic - PAIR - Concealed', value: 'Automatic - PAIR - Concealed' },
  {
    label: 'Automatic - PAIR - Surface Mounted',
    value: 'Automatic - PAIR - Surface Mounted',
  },
  { label: 'Automatic - PAIR - Floor', value: 'Automatic - PAIR - Floor' },
  {
    label: 'Automatic - PAIR - Slider (By Pass)',
    value: 'Automatic - PAIR - Slider (By Pass)',
  },
  {
    label: 'Automatic - PAIR - Slider (By Part)',
    value: 'Automatic - PAIR - Slider (By Part)',
  },
  { label: 'Automatic (other see notes)', value: 'Automatic (other see notes)' },
  { label: 'Standard (per door type)', value: 'Standard (per door type)' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
  { label: 'No Closer ', value: 'No Closer ' },
];
export const ndgdLocks = [
  { value: 'Bottom Rail Lock (P or Bp)', label: 'Bottom Rail Lock (P or Bp)' },
  { value: 'Dorma Patch Lock (F)', label: 'Dorma Patch Lock (F)' },
  { value: 'Mag Lock By Others', label: 'Mag Lock By Others' },
  { value: 'Mag Drop Lock By Others', label: 'Mag Drop Lock By Others' },
  { value: 'No Lock', label: 'No Lock' },
];
export const ndgdHandles = [
  { value: 'Standard (per door type)', label: 'Standard (per door type)' },
  {
    value: 'Push Bar Interior + Pull Handle Exterior',
    label: 'Push Bar Interior + Pull Handle Exterior',
  },
  { value: 'Push Bar (Interior & Exterior)', label: 'Push Bar (Interior & Exterior)' },
  { value: 'Pull - Offset (Back-2-Back)', label: 'Pull - Offset (Back-2-Back)' },
  { value: 'Pull - Offset (exterior)', label: 'Pull - Offset (exterior)' },
  { value: 'Pull - Straight (Back-2-Back)', label: 'Pull - Straight (Back-2-Back)' },
  { value: 'Pull - Straight (exterior)', label: 'Pull - Straight (exterior)' },
  { value: 'Pull - Round (Back-2-Back)', label: 'Pull - Round (Back-2-Back)' },
  { value: 'Pull - Round (exterior)', label: 'Pull - Round (exterior)' },
  { value: 'Pull - Square (Back-2-Back)', label: 'Pull - Square (Back-2-Back)' },
  { value: 'Pull - Square (exterior)', label: 'Pull - Square (exterior)' },
  {
    value: 'Pull Only - Straight - Exterior (with Panic Device)',
    label: 'Pull Only - Straight - Exterior (with Panic Device)',
  },
  {
    value: 'Pull Only - Offset - Exterior (with Panic Device)',
    label: 'Pull Only - Offset - Exterior (with Panic Device)',
  },
  { value: 'Ladder Pull (Back-2-Back)', label: 'Ladder Pull (Back-2-Back)' },
  { value: 'Ladder Pull (exterior)', label: 'Ladder Pull (exterior)' },
  { value: 'Towel Bar(s)', label: 'Towel Bar(s)' },
  { value: 'Towel Bar with Handle', label: 'Towel Bar with Handle' },
  { value: 'Knob (round)', label: 'Knob (round)' },
  { value: 'Knob (square)', label: 'Knob (square)' },
  { value: 'All Glass Door Panic', label: 'All Glass Door Panic' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Prep Only (see notes)', label: 'Prep Only (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  {
    value: '6" Pull - Round - Back2back  (No Washers)',
    label: '6" Pull - Round - Back2back  (No Washers)',
  },
  { value: '6" Pull - Round - Single Sided', label: '6" Pull - Round - Single Sided' },
  {
    value: '6" Pull - Round - Single Sided (No Washers)',
    label: '6" Pull - Round - Single Sided (No Washers)',
  },
  { value: '8" Pull - Round - Back2back', label: '8" Pull - Round - Back2back' },
  {
    value: '8" Pull - Round - Back2back  (No Washers)',
    label: '8" Pull - Round - Back2back  (No Washers)',
  },
  { value: '8" Pull - Round - Single Sided', label: '8" Pull - Round - Single Sided' },
  {
    value: '8" Pull - Round - Single Sided (No Washers)',
    label: '8" Pull - Round - Single Sided (No Washers)',
  },
  {
    value: '9" Pull - Round - Back2back  (No Washers)',
    label: '9" Pull - Round - Back2back  (No Washers)',
  },
  { value: '9" Pull - Round - Single Sided', label: '9" Pull - Round - Single Sided' },
  {
    value: '9" Pull - Round - Single Sided (No Washers)',
    label: '9" Pull - Round - Single Sided (No Washers)',
  },
  { value: '12" Pull - Round - Back2back', label: '12" Pull - Round - Back2back' },
  {
    value: '12" Pull - Round - Back2back  (No Washers)',
    label: '12" Pull - Round - Back2back  (No Washers)',
  },
  { value: '12" Pull - Round - Single Sided', label: '12" Pull - Round - Single Sided' },
  {
    value: '12" Pull - Round - Single Sided (No Washers)',
    label: '12" Pull - Round - Single Sided (No Washers)',
  },
  {
    value: 'Pull - Round - Single Sided (See Notes For Size)',
    label: 'Pull - Round - Single Sided (See Notes For Size)',
  },
  {
    value: 'Pull - Round - Single Sided (No Washers) (See Notes For Size)',
    label: 'Pull - Round - Single Sided (No Washers) (See Notes For Size)',
  },
  { value: '6" Pull  - Square - Single Sided', label: '6" Pull  - Square - Single Sided' },
  { value: '6" Pull - Square - Back2back', label: '6" Pull - Square - Back2back' },
  { value: '8" Pull  - Square - Single Sided', label: '8" Pull  - Square - Single Sided' },
  { value: '8" Pull - Square - Back2back', label: '8" Pull - Square - Back2back' },
  { value: '9" Pull  - Square - Single Sided', label: '9" Pull  - Square - Single Sided' },
  { value: '9" Pull - Square - Back2back', label: '9" Pull - Square - Back2back' },
  { value: '12" Pull  - Square - Single Sided', label: '12" Pull  - Square - Single Sided' },
  { value: '12" Pull - Square - Back2back', label: '12" Pull - Square - Back2back' },
  {
    value: 'Pull  - Square - Single Sided (See Notes For Size)',
    label: 'Pull  - Square - Single Sided (See Notes For Size)',
  },
  {
    value: 'Pull - Square - Back2back (No Washers) (See Notes For Size)',
    label: 'Pull - Square - Back2back (No Washers) (See Notes For Size)',
  },
  { value: '6" Ladder Pull Back2back', label: '6" Ladder Pull Back2back' },
  { value: '8" Ladder Pull Back2back', label: '8" Ladder Pull Back2back' },
  { value: '24" Ladder Pull Back2back', label: '24" Ladder Pull Back2back' },
  { value: '36" Ladder Pull Back2back', label: '36" Ladder Pull Back2back' },
  { value: '48" Ladder Pull Back2back', label: '48" Ladder Pull Back2back' },
  { value: '60" Ladder Pull Back2back', label: '60" Ladder Pull Back2back' },
  { value: '84" Ladder Pull Back2back', label: '84" Ladder Pull Back2back' },
  {
    value: 'Ladder Pull Back2back (See Notes For Size)',
    label: 'Ladder Pull Back2back (See Notes For Size)',
  },
  {
    value: 'Full Height Ladder Pull Back2back (See Notes)',
    label: 'Full Height Ladder Pull Back2back (See Notes)',
  },
  {
    value: '12" Push Bar Back2back Round (No Washers)',
    label: '12" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '18" Push Bar Back2back Round (No Washers)',
    label: '18" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '24" Push Bar Back2back Round (No Washers)',
    label: '24" Push Bar Back2back Round (No Washers)',
  },
  {
    value: '30" Push Bar Back2back Round (No Washers)',
    label: '30" Push Bar Back2back Round (No Washers)',
  },
  {
    value: 'Push Bar Back2back Round (No Washers) (See Notes For Size)',
    label: 'Push Bar Back2back Round (No Washers) (See Notes For Size)',
  },
  {
    value: '12" Push Bar Single Sided Round (No Washers)',
    label: '12" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '18" Push Bar Single Sided  Round (No Washers)',
    label: '18" Push Bar Single Sided  Round (No Washers)',
  },
  {
    value: '20" Push Bar Single Sided Round (No Washers)',
    label: '20" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '22" Push Bar Single Sided Round (No Washers)',
    label: '22" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '24" Push Bar Single Sided Round (No Washers)',
    label: '24" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '26" Push Bar Single Sided Round (No Washers)',
    label: '26" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '27" Push Bar Single Sided Round (No Washers)',
    label: '27" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '28" Push Bar Single Sided Round (No Washers)',
    label: '28" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: '30" Push Bar Single Sided Round (No Washers)',
    label: '30" Push Bar Single Sided Round (No Washers)',
  },
  {
    value: 'Push Bar Single Sided  Round (No Washers) (See Notes For Size)',
    label: 'Push Bar Single Sided  Round (No Washers) (See Notes For Size)',
  },
  { value: '12" Push Bar Single Sided Round', label: '12" Push Bar Single Sided Round' },
  { value: '18" Push Bar Single Sided  Round', label: '18" Push Bar Single Sided  Round' },
  { value: '20" Push Bar Single Sided Round', label: '20" Push Bar Single Sided Round' },
  { value: '22" Push Bar Single Sided Round', label: '22" Push Bar Single Sided Round' },
  { value: '24" Push Bar Single Sided Round', label: '24" Push Bar Single Sided Round' },
  { value: '26" Push Bar Single Sided Round', label: '26" Push Bar Single Sided Round' },
  { value: '27" Push Bar Single Sided Round', label: '27" Push Bar Single Sided Round' },
  { value: '28" Push Bar Single Sided Round', label: '28" Push Bar Single Sided Round' },
  { value: '30" Push Bar Single Sided Round', label: '30" Push Bar Single Sided Round' },
  {
    value: 'Push Bar Single Sided  Round (See Notes For Size)',
    label: 'Push Bar Single Sided  Round (See Notes For Size)',
  },
  { value: '6x18 Ladder Pull+ Push.Bar', label: '6x18 Ladder Pull+ Push.Bar' },
  { value: '6x24 Ladder Pull+  Push.Bar', label: '6x24 Ladder Pull+  Push.Bar' },
  { value: '8x18 Ladder Pull+ Push.Bar ', label: '8x18 Ladder Pull+ Push.Bar ' },
  { value: '8x24 Ladder Pull+ Push.Bar', label: '8x24 Ladder Pull+ Push.Bar' },
  {
    value: 'Ladder Pull+ Push.Bar (See Notes For Size)',
    label: 'Ladder Pull+ Push.Bar (See Notes For Size)',
  },
  {
    value: '6x12  Pull + Push.bar (No Washers)',
    label: '6x12  Pull + Push.bar (No Washers)',
  },
  {
    value: '6x18 Pull + Push.Bar  (No Washers)',
    label: '6x18 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '6x22 Pull + Push.Bar  (No Washers)',
    label: '6x22 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '6x24 Pull + Push.Bar  (No Washers)',
    label: '6x24 Pull + Push.Bar  (No Washers)',
  },
  {
    value: '8x18  Pull + Push.Bar  (No Washers)',
    label: '8x18  Pull + Push.Bar  (No Washers)',
  },
  {
    value: '8x22  Pull + Push.Bar  (No Washers)',
    label: '8x22  Pull + Push.Bar  (No Washers)',
  },
  { value: '8x24 Pull + Push.Bar (No Washers)', label: '8x24 Pull + Push.Bar (No Washers)' },
  {
    value: 'Pull + Push.Bar  (No Washers) (See Notes For Size)',
    label: 'Pull + Push.Bar  (No Washers) (See Notes For Size)',
  },
  { value: '6x12  Pull + Push.Bar', label: '6x12  Pull + Push.Bar' },
  { value: '6x18 Pull + Push.Bar', label: '6x18 Pull + Push.Bar' },
  { value: '6x22 Pull + Push.Bar', label: '6x22 Pull + Push.Bar' },
  { value: '6x24 Pull + Push.Bar', label: '6x24 Pull + Push.Bar' },
  { value: '8x18  Pull + Push.Bar', label: '8x18  Pull + Push.Bar' },
  { value: '8x20  Pull + Push.Bar', label: '8x20  Pull + Push.Bar' },
  { value: '8x22  Pull + Push.Bar', label: '8x22  Pull + Push.Bar' },
  { value: '8x24  Pull + Push.Bar', label: '8x24  Pull + Push.Bar' },
  { value: '12x24  Pull + Push.Bar', label: '12x24  Pull + Push.Bar' },
  {
    value: 'Pull+ Push.bar (See Notes For Size)',
    label: 'Pull+ Push.bar (See Notes For Size)',
  },
  { value: '10" Sculptured Handle', label: '10" Sculptured Handle' },
  { value: '12" Towel Bar (Single Sided)', label: '12" Towel Bar (Single Sided)' },
  {
    value: '12" Towel Bar W/washer (Single Sided)',
    label: '12" Towel Bar W/washer (Single Sided)',
  },
  { value: '18" Towel Bar (Single Sided)', label: '18" Towel Bar (Single Sided)' },
  { value: '18" Towel Bar (Single Sided)', label: '18" Towel Bar (Single Sided)' },
  {
    value: '18" Towel Bar W/washer (Single Sided)',
    label: '18" Towel Bar W/washer (Single Sided)',
  },
  {
    value: '18" Towel Bar with Contemporary Knob',
    label: '18" Towel Bar with Contemporary Knob',
  },
  {
    value: '18" Towel Bar with Traditional Knob',
    label: '18" Towel Bar with Traditional Knob',
  },
  { value: '20" Towel Bar (Back-2-Back)', label: '20" Towel Bar (Back-2-Back)' },
  { value: '20" Towel Bar (Single Sided)', label: '20" Towel Bar (Single Sided)' },
  {
    value: '20" Towel Bar W/washer (Single Sided)',
    label: '20" Towel Bar W/washer (Single Sided)',
  },
  { value: '22" Towel Bar (Single Sided)', label: '22" Towel Bar (Single Sided)' },
  {
    value: '22" Towel Bar W/washer (Single Sided)',
    label: '22" Towel Bar W/washer (Single Sided)',
  },
  { value: '24" Towel Bar (Back-2-Back)', label: '24" Towel Bar (Back-2-Back)' },
  { value: '24" Towel Bar (Single Sided)', label: '24" Towel Bar (Single Sided)' },
  { value: '24" Towel Bar (Single Sided)', label: '24" Towel Bar (Single Sided)' },
  {
    value: '24" Towel Bar W/washers (Single Sided)',
    label: '24" Towel Bar W/washers (Single Sided)',
  },
  {
    value: '24" Towel Bar With Contemporary Knob',
    label: '24" Towel Bar With Contemporary Knob',
  },
  {
    value: '24" Towel Bar With Traditional Knob',
    label: '24" Towel Bar With Traditional Knob',
  },
  { value: '26" Towel Bar (Single Sided)', label: '26" Towel Bar (Single Sided)' },
  {
    value: '26" Towel Bar W/washers (Single Sided)',
    label: '26" Towel Bar W/washers (Single Sided)',
  },
  { value: '27" Towel Bar (Single Sided)', label: '27" Towel Bar (Single Sided)' },
  {
    value: '27" Towel Bar W/washers (Single Sided)',
    label: '27" Towel Bar W/washers (Single Sided)',
  },
  { value: '28" Towel Bar (Single Sided)', label: '28" Towel Bar (Single Sided)' },
  {
    value: '28" Towel Bar W/washers (Single Sided)',
    label: '28" Towel Bar W/washers (Single Sided)',
  },
  { value: '30" Towel Bar (Single Sided)', label: '30" Towel Bar (Single Sided)' },
  {
    value: '30" Towel Bar W/washers (Single Sided)',
    label: '30" Towel Bar W/washers (Single Sided)',
  },
  { value: '6" Crescent Pull', label: '6" Crescent Pull' },
  { value: '6" Pull 18" Towel Bar', label: '6" Pull 18" Towel Bar' },
  { value: '6" Pull 18" Towel Bar', label: '6" Pull 18" Towel Bar' },
  { value: '6" Pull 24" Towel Bar', label: '6" Pull 24" Towel Bar' },
  { value: '6" Pull 24" Towel Bar', label: '6" Pull 24" Towel Bar' },
  {
    value: '6" Pull To 12" Towel Bar (No Washers)',
    label: '6" Pull To 12" Towel Bar (No Washers)',
  },
  { value: '6" Pull To 18" Towel Bar', label: '6" Pull To 18" Towel Bar' },
  { value: '6" Pull To 18" Towel Bar', label: '6" Pull To 18" Towel Bar' },
  {
    value: '6" Pull To 18" Towel Bar (Acrylic, Smooth)',
    label: '6" Pull To 18" Towel Bar (Acrylic, Smooth)',
  },
  {
    value: '6" Pull To 18" Towel Bar (No Washers)',
    label: '6" Pull To 18" Towel Bar (No Washers)',
  },
  { value: '6" Pull To 22" Towel Bar', label: '6" Pull To 22" Towel Bar' },
  { value: '6" Pull To 22" Towel Bar', label: '6" Pull To 22" Towel Bar' },
  { value: '6" Pull To 24" Towel Bar', label: '6" Pull To 24" Towel Bar' },
  { value: '6" Pull To 24" Towel Bar', label: '6" Pull To 24" Towel Bar' },
  { value: '8" Crescent Pull', label: '8" Crescent Pull' },
  { value: '8" Pull 18" Towel Bar', label: '8" Pull 18" Towel Bar' },
  { value: '8" Pull 18" Towel Bar', label: '8" Pull 18" Towel Bar' },
  { value: '8" Pull 24" Towel Bar', label: '8" Pull 24" Towel Bar' },
  { value: '8" Pull 24" Towel Bar', label: '8" Pull 24" Towel Bar' },
  { value: '8" Pull To 18" Towel Bar', label: '8" Pull To 18" Towel Bar' },
  {
    value: '8" Pull To 18" Towel Bar (No Washers)',
    label: '8" Pull To 18" Towel Bar (No Washers)',
  },
  { value: '8" Pull To 22" Towel Bar', label: '8" Pull To 22" Towel Bar' },
  { value: '8" Pull To 22" Towel Bar', label: '8" Pull To 22" Towel Bar' },
  { value: '8" Pull To 24" Towel Bar', label: '8" Pull To 24" Towel Bar' },
  {
    value: '8" Pull To 24" Towel Bar (Acrylic, Smooth)',
    label: '8" Pull To 24" Towel Bar (Acrylic, Smooth)',
  },
  {
    value: '8" Pull To 24" Towel Bar (No Washers)',
    label: '8" Pull To 24" Towel Bar (No Washers)',
  },
  { value: '8" Tubular Pull (Back-2-Back)', label: '8" Tubular Pull (Back-2-Back)' },
  { value: 'Acrylic Regular Style Towel Bar', label: 'Acrylic Regular Style Towel Bar' },
  { value: 'Arcylic Regular Style', label: 'Arcylic Regular Style' },
  { value: 'Ball Style Knob', label: 'Ball Style Knob' },
  { value: 'Bow Tie Knob', label: 'Bow Tie Knob' },
  { value: 'Circular Door Pull', label: 'Circular Door Pull' },
  { value: 'Colonial', label: 'Colonial' },
  {
    value: 'Colonial 18" (Back-2-Back) Towel Bar',
    label: 'Colonial 18" (Back-2-Back) Towel Bar',
  },
  {
    value: 'Colonial 18" Towel Bar (Single Sided)',
    label: 'Colonial 18" Towel Bar (Single Sided)',
  },
  {
    value: 'Colonial 24" Towel Bar (Single Sided)',
    label: 'Colonial 24" Towel Bar (Single Sided)',
  },
  { value: 'Colonial 6" (Back-2-Back)', label: 'Colonial 6" (Back-2-Back)' },
  { value: 'Colonial 6" Pull To 24" Towel Bar', label: 'Colonial 6" Pull To 24" Towel Bar' },
  { value: 'Colonial 8" (Back-2-Back)', label: 'Colonial 8" (Back-2-Back)' },
  { value: 'Crescent Grip Knob', label: 'Crescent Grip Knob' },
  {
    value: 'Crescent Towel Bar 18" (Back-2-Back)',
    label: 'Crescent Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Crescent Towel Bar 24"  (Back-2-Back)',
    label: 'Crescent Towel Bar 24"  (Back-2-Back)',
  },
  { value: 'Crystal Knob', label: 'Crystal Knob' },
  { value: 'Crystal Knob Contemporary Style', label: 'Crystal Knob Contemporary Style' },
  { value: 'Cylinder Style Knob (Back-2-Back)', label: 'Cylinder Style Knob (Back-2-Back)' },
  {
    value: 'Cylinder Style Knob (Single Sided)',
    label: 'Cylinder Style Knob (Single Sided)',
  },
  {
    value: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
    label: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
  },
  {
    value: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
    label: 'Cylinder Style Knob Back To Back - Plastic Sleeve',
  },
  {
    value: 'Double Acrylic Door Pull 6"(Back-2-Back)',
    label: 'Double Acrylic Door Pull 6"(Back-2-Back)',
  },
  {
    value: 'Double Acrylic Door Pull 8" (Back-2-Back)',
    label: 'Double Acrylic Door Pull 8" (Back-2-Back)',
  },
  {
    value: 'Double Acrylic Door Pull Twist 8" (Back-2-Back)',
    label: 'Double Acrylic Door Pull Twist 8" (Back-2-Back)',
  },
  { value: 'Double Sided Knob', label: 'Double Sided Knob' },
  { value: 'Euro Style Knob', label: 'Euro Style Knob' },
  { value: 'Flair Style Knob', label: 'Flair Style Knob' },
  { value: 'Glass Knob Back To Back', label: 'Glass Knob Back To Back' },
  { value: 'Junior Contemporary Style Knob', label: 'Junior Contemporary Style Knob' },
  {
    value: 'Knob - Contemporary (Single Sided)',
    label: 'Knob - Contemporary (Single Sided)',
  },
  { value: 'Knob - Traditional (Single Sided)', label: 'Knob - Traditional (Single Sided)' },
  { value: 'Knob (Back-2-Back)', label: 'Knob (Back-2-Back)' },
  { value: 'Knob Latch - Glass Strike Option', label: 'Knob Latch - Glass Strike Option' },
  { value: 'Knob Latch - Wall Strike Option', label: 'Knob Latch - Wall Strike Option' },
  { value: 'Ladder Pull 24" (Single Sided)', label: 'Ladder Pull 24" (Single Sided)' },
  { value: 'Ladder Pull 36" (Single Sided)', label: 'Ladder Pull 36" (Single Sided)' },
  { value: 'Ladder Pull 48" (Single Sided)', label: 'Ladder Pull 48" (Single Sided)' },
  { value: 'Ladder Pull 6" (Back-2-Back)', label: 'Ladder Pull 6" (Back-2-Back)' },
  { value: 'Ladder Pull 8" (Back-2-Back)', label: 'Ladder Pull 8" (Back-2-Back)' },
  {
    value: 'Ladder Towel Bar 18" (Back-2-Back)',
    label: 'Ladder Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Ladder Towel Bar 18" (Single Sided)',
    label: 'Ladder Towel Bar 18" (Single Sided)',
  },
  {
    value: 'Ladder Towel Bar/Pull Combination 8x24',
    label: 'Ladder Towel Bar/Pull Combination 8x24',
  },
  { value: 'Laguna Thru-glass Pull', label: 'Laguna Thru-glass Pull' },
  { value: 'Mini Bulb Knob', label: 'Mini Bulb Knob' },
  {
    value: 'Mitered 18" Towel Bar (Back-2-Back)',
    label: 'Mitered 18" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Mitered 18" Towel Bar (Single Sided)',
    label: 'Mitered 18" Towel Bar (Single Sided)',
  },
  {
    value: 'Mitered 24" Towel Bar (Back-2-Back)',
    label: 'Mitered 24" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Mitered 24" Towel Bar (Single Sided)',
    label: 'Mitered 24" Towel Bar (Single Sided)',
  },
  { value: 'Mitered 6" Pull', label: 'Mitered 6" Pull' },
  { value: 'Mitered 8" Pull', label: 'Mitered 8" Pull' },
  {
    value: 'Oval/round Handle 6" (Back-2-Back)',
    label: 'Oval/round Handle 6" (Back-2-Back)',
  },
  {
    value: 'Oval/round Handle 8" (Back-2-Back)',
    label: 'Oval/round Handle 8" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 18" (Back-2-Back)',
    label: 'Oval/round Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 18" (Single Sided)',
    label: 'Oval/round Towel Bar 18" (Single Sided)',
  },
  {
    value: 'Oval/round Towel Bar 24" (Back-2-Back)',
    label: 'Oval/round Towel Bar 24" (Back-2-Back)',
  },
  {
    value: 'Oval/round Towel Bar 24" (Single Sided)',
    label: 'Oval/round Towel Bar 24" (Single Sided)',
  },
  { value: 'Paddle Knob', label: 'Paddle Knob' },
  {
    value: 'Pull Handle/Towel Bar Combination 12" x 24"',
    label: 'Pull Handle/Towel Bar Combination 12" x 24"',
  },
  {
    value: 'Pull Handle/Towel Bar Combination 6" x 12"',
    label: 'Pull Handle/Towel Bar Combination 6" x 12"',
  },
  { value: 'Ribbed Bow Tie Knob', label: 'Ribbed Bow Tie Knob' },
  { value: 'RM Series Pull 6" (Back-2-Back)', label: 'RM Series Pull 6" (Back-2-Back)' },
  { value: 'RM Series Pull 8" (Back-2-Back)', label: 'RM Series Pull 8" (Back-2-Back)' },
  { value: 'Robe Hook', label: 'Robe Hook' },
  { value: 'Sculptured Solid 6" Pull', label: 'Sculptured Solid 6" Pull' },
  { value: 'Serenity Thru-glass Pull', label: 'Serenity Thru-glass Pull' },
  { value: 'Solid 1" Diameter 8" Pull', label: 'Solid 1" Diameter 8" Pull' },
  { value: 'Solid 6"  Pull (Single Sided)', label: 'Solid 6"  Pull (Single Sided)' },
  { value: 'Solid 6" Pull (Back-2-Back)', label: 'Solid 6" Pull (Back-2-Back)' },
  { value: 'Solid 6" Pull (Back-2-Back)', label: 'Solid 6" Pull (Back-2-Back)' },
  { value: 'Solid 6" Pull (Single Sided)', label: 'Solid 6" Pull (Single Sided)' },
  { value: 'Solid 8" Pull (Back-2-Back)', label: 'Solid 8" Pull (Back-2-Back)' },
  { value: 'Solid 8" Pull (Back-2-Back)', label: 'Solid 8" Pull (Back-2-Back)' },
  { value: 'Solid 8" Pull (Single Sided)', label: 'Solid 8" Pull (Single Sided)' },
  { value: 'Solid 8" Pull (Single Sided)', label: 'Solid 8" Pull (Single Sided)' },
  { value: 'Solid Towel Bar (Back-2-Back)', label: 'Solid Towel Bar (Back-2-Back)' },
  { value: 'Solid Towel Bar (Back-2-Back)', label: 'Solid Towel Bar (Back-2-Back)' },
  { value: 'Square 1" (Back-2-Back)', label: 'Square 1" (Back-2-Back)' },
  {
    value: 'Square 18" Towel Bar (Back-2-Back)',
    label: 'Square 18" Towel Bar (Back-2-Back)',
  },
  {
    value: 'Square 24" Towel Bar (Back-2-Back)',
    label: 'Square 24" Towel Bar (Back-2-Back)',
  },
  { value: 'Square Finger Pull Knob', label: 'Square Finger Pull Knob' },
  { value: 'Square Handle 8"', label: 'Square Handle 8"' },
  { value: 'Square KNOB (Back-2-Back)', label: 'Square KNOB (Back-2-Back)' },
  { value: 'Square Ladder 6" (Back-2-Back)', label: 'Square Ladder 6" (Back-2-Back)' },
  {
    value: 'Square Ladder Pull 8" (Back-2-Back)',
    label: 'Square Ladder Pull 8" (Back-2-Back)',
  },
  { value: 'Square Pull 6"', label: 'Square Pull 6"' },
  { value: 'Thru-Glass Finger Pull', label: 'Thru-Glass Finger Pull' },
  { value: 'Towel Bar 12" (Back-2-Back)', label: 'Towel Bar 12" (Back-2-Back)' },
  { value: 'Towel Bar 18" (Back-2-Back)', label: 'Towel Bar 18" (Back-2-Back)' },
  { value: 'Towel Bar 24" (Back-2-Back)', label: 'Towel Bar 24" (Back-2-Back)' },
  { value: 'Towel Bar 30" (Back-2-Back)', label: 'Towel Bar 30" (Back-2-Back)' },
  {
    value: 'Towel Bar W/washer 12" (Back-2-Back)',
    label: 'Towel Bar W/washer 12" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 18" (Back-2-Back)',
    label: 'Towel Bar W/washer 18" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 24" (Back-2-Back)',
    label: 'Towel Bar W/washer 24" (Back-2-Back)',
  },
  {
    value: 'Towel Bar W/washer 30" (Back-2-Back)',
    label: 'Towel Bar W/washer 30" (Back-2-Back)',
  },
  { value: 'Towel Bar/Pull Combination 8x20', label: 'Towel Bar/Pull Combination 8x20' },
  { value: 'Tubular 6" Pull (Back-2-Back)', label: 'Tubular 6" Pull (Back-2-Back)' },
  { value: 'Tubular 6" Pull (Back-2-Back)', label: 'Tubular 6" Pull (Back-2-Back)' },
  { value: 'Tubular 8" Pull (Back-2-Back)', label: 'Tubular 8" Pull (Back-2-Back)' },
  {
    value: 'Tubular Brass Back To Back Towel Bar',
    label: 'Tubular Brass Back To Back Towel Bar',
  },
  { value: 'Tubular Brass Construction 12"', label: 'Tubular Brass Construction 12"' },
  { value: 'Tubular Brass Handle 18"', label: 'Tubular Brass Handle 18"' },
  { value: 'Tubular Brass Handle 24"', label: 'Tubular Brass Handle 24"' },
  { value: 'Tubular Brass Handle 6"', label: 'Tubular Brass Handle 6"' },
  {
    value: 'Tubular Towel Bar 18" (Back-2-Back)',
    label: 'Tubular Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Victorian 18" Towel Bar (Single Sided)',
    label: 'Victorian 18" Towel Bar (Single Sided)',
  },
  { value: 'Victorian 6" Pull (Back-2-Back)', label: 'Victorian 6" Pull (Back-2-Back)' },
  {
    value: 'Victorian 6" Pull 18" Towel Bar (combo)',
    label: 'Victorian 6" Pull 18" Towel Bar (combo)',
  },
  { value: 'Victorian 8" Pull (Back-2-Back)', label: 'Victorian 8" Pull (Back-2-Back)' },
  { value: 'Victorian 8" Pull (Back-2-Back)', label: 'Victorian 8" Pull (Back-2-Back)' },
  {
    value: 'Victorian 8" Pull 18" Towel Bar (combo)',
    label: 'Victorian 8" Pull 18" Towel Bar (combo)',
  },
  {
    value: 'Victorian 8" Pull 24" Towel Bar (combo)',
    label: 'Victorian 8" Pull 24" Towel Bar (combo)',
  },
  {
    value: 'Victorian Towel Bar 12" (Back-2-Back)',
    label: 'Victorian Towel Bar 12" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 18" (Back-2-Back)',
    label: 'Victorian Towel Bar 18" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 24" (Back-2-Back)',
    label: 'Victorian Towel Bar 24" (Back-2-Back)',
  },
  {
    value: 'Victorian Towel Bar 24" (Single Sided)',
    label: 'Victorian Towel Bar 24" (Single Sided)',
  },
];
export const ndgdHeader = [
  { value: '1 3/4" x 4 1/2"', label: '1 3/4" x 4 1/2"' },
  { value: '1 3/4" x 4"', label: '1 3/4" x 4"' },
  { value: 'No Header', label: 'No Header' },
  { value: 'Other', label: 'Other' },
];
export const ndgdThreshold = [
  {
    value: 'Standard (per door type)',
    label: 'Standard (per door type)',
  },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  {
    value: '4" x 36" x 1/4" Aluminum Threshold',
    label: '4" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/4" Aluminum Threshold',
    label: '5" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/4" Aluminum Threshold',
    label: '7" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/4" Aluminum Threshold',
    label: '8" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/4" Aluminum Threshold',
    label: '4" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/4" Aluminum Threshold',
    label: '5" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/4" Aluminum Threshold',
    label: '7" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/4" Aluminum Threshold',
    label: '8" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/4" Aluminum Threshold',
    label: '4" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/4" Aluminum Threshold',
    label: '5" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/4" Aluminum Threshold',
    label: '7" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/4" Aluminum Threshold',
    label: '8" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/2" Aluminum Threshold',
    label: '4" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/2" Aluminum Threshold',
    label: '5" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/2" Aluminum Threshold',
    label: '7" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/2" Aluminum Threshold',
    label: '8" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/2" Aluminum Threshold',
    label: '4" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/2" Aluminum Threshold',
    label: '5" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/2" Aluminum Threshold',
    label: '7" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/2" Aluminum Threshold',
    label: '8" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/2" Aluminum Threshold',
    label: '4" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/2" Aluminum Threshold',
    label: '5" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/2" Aluminum Threshold',
    label: '7" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/2" Aluminum Threshold',
    label: '8" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/4" Bronze Threshold',
    label: '4" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/4" Bronze Threshold',
    label: '5" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/4" Bronze Threshold',
    label: '7" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/4" Bronze Threshold',
    label: '8" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/4" Bronze Threshold',
    label: '4" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/4" Bronze Threshold',
    label: '5" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/4" Bronze Threshold',
    label: '7" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/4" Bronze Threshold',
    label: '8" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/4" Bronze Threshold',
    label: '4" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/4" Bronze Threshold',
    label: '5" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/4" Bronze Threshold',
    label: '7" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/4" Bronze Threshold',
    label: '8" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/2" Bronze Threshold',
    label: '4" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/2" Bronze Threshold',
    label: '5" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/2" Bronze Threshold',
    label: '7" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/2" Bronze Threshold',
    label: '8" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/2" Bronze Threshold',
    label: '4" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/2" Bronze Threshold',
    label: '5" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/2" Bronze Threshold',
    label: '7" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/2" Bronze Threshold',
    label: '8" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/2" Bronze Threshold',
    label: '4" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/2" Bronze Threshold',
    label: '5" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/2" Bronze Threshold',
    label: '7" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/2" Bronze Threshold',
    label: '8" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/4" Brass Threshold',
    label: '4" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/4" Brass Threshold',
    label: '5" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/4" Brass Threshold',
    label: '7" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/4" Brass Threshold',
    label: '8" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/4" Brass Threshold',
    label: '4" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/4" Brass Threshold',
    label: '5" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/4" Brass Threshold',
    label: '7" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/4" Brass Threshold',
    label: '8" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/4" Brass Threshold',
    label: '4" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/4" Brass Threshold',
    label: '5" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/4" Brass Threshold',
    label: '7" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/4" Brass Threshold',
    label: '8" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 36" x 1/2" Brass Threshold',
    label: '4" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/2" Brass Threshold',
    label: '5" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/2" Brass Threshold',
    label: '7" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/2" Brass Threshold',
    label: '8" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/2" Brass Threshold',
    label: '4" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/2" Brass Threshold',
    label: '5" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/2" Brass Threshold',
    label: '7" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/2" Brass Threshold',
    label: '8" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/2" Brass Threshold',
    label: '4" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/2" Brass Threshold',
    label: '5" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/2" Brass Threshold',
    label: '7" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/2" Brass Threshold',
    label: '8" x 72" x 1/2" Brass Threshold',
  },
];
export const ndgdSideLightHeadCondition = [
  { value: 'Rail Under the Header', label: 'Rail Under the Header' },
  { value: 'Rail to the Ceiling', label: 'Rail to the Ceiling' },
  { value: 'Undercut Header', label: 'Undercut Header' },
  { value: '2" x 1" Channel', label: '2" x 1" Channel' },
];
export const ndgdSidelightSillCondition = [
  { value: 'Rail to the Floor', label: 'Rail to the Floor' },
  { value: 'Rail on Top of Threshold', label: 'Rail on Top of Threshold' },
  { value: '1" x 1" Channel', label: '1" x 1" Channel' },
];
export const ndgdSidelitesConfiguration = [
  { value: 'Sidelite on the Left', label: 'Sidelite on the Left' },
  { value: 'Sidelite on the Right', label: 'Sidelite on the Right' },
  { value: 'Sidelite Both Sides', label: 'Sidelite Both Sides' },
];

export const ndgdImageHandles = [
  {
    value:
      'pull_handle_up_push_bar_3_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-1.jpg" id="1',
    label: 'Pull Handle, UP"amp; Push Bar (3 Holes) ',
  },
  {
    value:
      'pull_handle_down_push_bar_3_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-2.jpg" id="2',
    label: 'Pull Handle, DOWN"amp; Push Bar (3 Holes)',
  },
  {
    value:
      'pull_handle_2_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-3.jpg" id="3',
    label: 'Pull Handle (2 Holes)',
  },
  {
    value:
      'pull_bar_2_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-4.jpg" id="4',
    label: 'Push Bar (2 Holes)',
  },
  {
    value:
      'ladder_pull_3_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-5.jpg" id="5',
    label: 'Ladder Pull (3 Holes)',
  },
  {
    value:
      'ladder_pull_2_holes" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-6.jpg" id="6',
    label: 'Ladder Pull (2 Holes)',
  },
  {
    value:
      'single_pull_1_hole" imgurl="https://dev.salbertglass.com/wp-content/plugins/jobs/img/doors/handle/Handle-7.jpg" id="7',
    label: 'Single Pull (1 Hole)',
  },
];
/* ALUMINUM DOORS */
export const ndadDoorHand = [
  { value: 'Hinge Left Swing In (Left Hand)', label: 'Hinge Left Swing In (Left Hand)' },
  { value: 'Hinge Right Swing In (Right Hand)', label: 'Hinge Right Swing In (Right Hand)' },
  { value: 'Hinge Left Swing Out (Right Hand)', label: 'Hinge Left Swing Out (Right Hand)' },
  { value: 'Hinge Left Swing Out (Right Hand)', label: 'Hinge Right Swing Out (Left Hand)' },
  { value: 'Double Acting', label: 'Double Acting' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const ndadOffsetArm = [
  { value: 'LCN 3034 All Track Rollers', label: 'LCN 3034 All Track Rollers' },
  { value: 'LCN Aluminum Standard Arm for 2610', label: 'LCN Aluminum Standard Arm for 2610' },
];
export const ndadDoorStyle = [
  { value: 'Narrow', label: 'Narrow' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Wide', label: 'Wide' },
  { value: 'Medium (High Traffic)', label: 'Medium (High Traffic)' },
  { value: 'Wide (High Traffic)', label: 'Wide (High Traffic)' },
  { value: 'I-Line', label: 'I-Line' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const ndadLine = [
  { value: '1/4"', label: '1/4"' },
  { value: '3/8"', label: '3/8"' },
  { value: '/2"', label: '1/2"' },
  { value: '1"', label: '1"' },
];
export const ndadFrameSystem = [
  {
    value: 'Door ONLY - No Frame',
    label: 'Door ONLY - No Frame',
  },
  { value: '1 3/4" x 4" Tubular', label: '1 3/4" x 4" Tubular' },
  { value: '1 3/4" x 4" Open Back', label: '1 3/4" x 4" Open Back' },
  { value: '1 3/4" x 4-1/2" Tubular', label: '1 3/4" x 4-1/2" Tubular' },
  { value: '1 3/4" x 4-1/2" Open Back', label: '1 3/4" x 4-1/2" Open Back' },
  { value: '2" x 4 1/2" Tubula', label: '2" x 4 1/2" Tubula' },
  { value: '2" x 4 1/2" Open Back', label: '2" x 4 1/2" Open Back' },
  { value: '1 3/4" x 4 1/2" Flush Front', label: '1 3/4" x 4 1/2" Flush Front' },
  { value: '2" x 4 1/2" Flush Front', label: '2" x 4 1/2" Flush Front' },
  { value: '1 3/4" x 6" Flush Front', label: '1 3/4" x 6" Flush Front' },
  { value: '2" x 6" Flush Front', label: '2" x 6" Flush Front' },
  { value: 'Subframe 1" x 4 1/2"', label: 'Subframe 1" x 4 1/2"' },
  {
    value: 'Subframe Curtainwall 1" x 4 1/2"',
    label: 'Subframe Curtainwall 1" x 4 1/2"',
  },
  {
    value: 'High Traffic 1 3/4" " x 4 1/2"',
    label: 'High Traffic 1 3/4" " x 4 1/2"',
  },
  { value: 'High Traffic 2" x 4 1/2"', label: 'High Traffic 2" x 4 1/2"' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const ndadFinish = [
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Custom', label: 'Custom' },
  { value: 'Clear Anodized (Standard  .4 mils)', label: 'Clear Anodized (Standard  .4 mils)' },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Almond (TW)', label: 'Almond (TW)' },
  { value: 'Antique White (EFCO)', label: 'Antique White (EFCO)' },
  {
    value: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
    label: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
  },
  { value: 'Colonial White (OBE, YKK)', label: 'Colonial White (OBE, YKK)' },
  { value: 'Cr�me (TW)', label: 'Cr�me (TW)' },
  { value: 'Herring Bone (EFCO)', label: 'Herring Bone (EFCO)' },
  { value: 'Milk White (KAW)', label: 'Milk White (KAW)' },
  { value: 'Stone White (USA)', label: 'Stone White (USA)' },
  { value: 'White (EFCO, TW)', label: 'White (EFCO, TW)' },
  { value: 'Beige (OBE, YKK)', label: 'Beige (OBE, YKK)' },
  { value: 'Seawolf Beige(YKK)', label: 'Seawolf Beige(YKK)' },
  { value: 'Black (OBE, YKK, KAW, EFCO, USA, TW)', label: 'Black (OBE, YKK, KAW, EFCO, USA, TW)' },
  { value: 'Deep Blue (KAW)', label: 'Deep Blue (KAW)' },
  { value: 'Blue Sky (EFCO)', label: 'Blue Sky (EFCO)' },
  { value: 'Legacy Blue (EFCO)', label: 'Legacy Blue (EFCO)' },
  { value: 'Medium Blue (OBE, KAW, TW)', label: 'Medium Blue (OBE, KAW, TW)' },
  { value: 'Midnight Blue (KAW, EFCO)', label: 'Midnight Blue (KAW, EFCO)' },
  { value: 'Military Blue (OBE, KAW, USA)', label: 'Military Blue (OBE, KAW, USA)' },
  { value: 'Regal Blue (OBE, USA)', label: 'Regal Blue (OBE, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Bronze (THW)', label: 'Bronze (THW)' },
  { value: 'Classic Bronze (YKK, KAW, USA)', label: 'Classic Bronze (YKK, KAW, USA)' },
  { value: 'Light Bronze (USA)', label: 'Light Bronze (USA)' },
  { value: 'Medium Bronze (KAW, USA, THW)', label: 'Medium Bronze (KAW, USA, THW)' },
  { value: 'Brown (OBE)', label: 'Brown (OBE)' },
  { value: 'Java Brown (KAW)', label: 'Java Brown (KAW)' },
  { value: 'Light Brown (EFCO)', label: 'Light Brown (EFCO)' },
  { value: 'Mesa Brown (EFCO)', label: 'Mesa Brown (EFCO)' },
  { value: 'Mineral Brown (EFCO)', label: 'Mineral Brown (EFCO)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Aged Copper (OBE, EFCO, USA)', label: 'Aged Copper (OBE, EFCO, USA)' },
  { value: 'Copper Penny (OBE)', label: 'Copper Penny (OBE)' },
  { value: 'Champagne (EFCO, USA)', label: 'Champagne (EFCO, USA)' },
  { value: 'Champagne Gold (OBE)', label: 'Champagne Gold (OBE)' },
  { value: 'Gold (USA)', label: 'Gold (USA)' },
  { value: 'Atlantic Gray (KAW)', label: 'Atlantic Gray (KAW)' },
  { value: 'Charcoal (YKK, KAW, USA)', label: 'Charcoal (YKK, KAW, USA)' },
  { value: 'Charcoal Gray (OBE)', label: 'Charcoal Gray (OBE)' },
  { value: 'Dark Gray (TW)', label: 'Dark Gray (TW)' },
  { value: 'Dove Gray (KAW)', label: 'Dove Gray (KAW)' },
  { value: 'Fashion Gray (OBE)', label: 'Fashion Gray (OBE)' },
  { value: 'Grey (EFCO)', label: 'Grey (EFCO)' },
  { value: 'Gun Barrel (EFCO)', label: 'Gun Barrel (EFCO)' },
  { value: 'Light Gray (KAW, TW)', label: 'Light Gray (KAW, TW)' },
  { value: 'Night Hawk Gray (KAW)', label: 'Night Hawk Gray (KAW)' },
  { value: 'Smoke Gray (KAW)', label: 'Smoke Gray (KAW)' },
  { value: 'Stone Gray (USA)', label: 'Stone Gray (USA)' },
  { value: 'Dark Green (KAW, TW)', label: 'Dark Green (KAW, TW)' },
  { value: 'Hartford Green (YKK, KAW, EFCO, USA)', label: 'Hartford Green (YKK, KAW, EFCO, USA)' },
  { value: 'Interstate Green (OBE, KAW)', label: 'Interstate Green (OBE, KAW)' },
  { value: 'Light Green (KAW, TW)', label: 'Light Green (KAW, TW)' },
  { value: 'Sea Spray (EFCO, USA)', label: 'Sea Spray (EFCO, USA)' },
  { value: 'Teal (USA)', label: 'Teal (USA)' },
  { value: 'Ivory (KAW)', label: 'Ivory (KAW)' },
  { value: 'Ivy (EFCO, USA)', label: 'Ivy (EFCO, USA)' },
  { value: 'Dark Ivy (KAW)', label: 'Dark Ivy (KAW)' },
  { value: 'Pewter (OBE)', label: 'Pewter (OBE)' },
  { value: 'Portland Stone (OBE)', label: 'Portland Stone (OBE)' },
  { value: 'Pueblo Tan (OBE, EFCO, USA)', label: 'Pueblo Tan (OBE, EFCO, USA)' },
  { value: 'Brick Red (KAW)', label: 'Brick Red (KAW)' },
  { value: 'Claret Red (KAW)', label: 'Claret Red (KAW)' },
  { value: 'Colonial Red (YKK)', label: 'Colonial Red (YKK)' },
  { value: 'Regal Red (EFCO)', label: 'Regal Red (EFCO)' },
  { value: 'Rust Red (TW)', label: 'Rust Red (TW)' },
  { value: 'Redwood (OBE, KAW, EFCO, USA)', label: 'Redwood (OBE, KAW, EFCO, USA)' },
  { value: 'Sandstone (OBE, YKK, KAW, USA, TW)', label: 'Sandstone (OBE, YKK, KAW, USA, TW)' },
  { value: 'Sea Wolf (KAW)', label: 'Sea Wolf (KAW)' },
  { value: 'Bright Silver (OBE)', label: 'Bright Silver (OBE)' },
  { value: 'Warm Silver (EFCO, USA)', label: 'Warm Silver (EFCO, USA)' },
  { value: 'Terra Cotta (OBE)', label: 'Terra Cotta (OBE)' },
  { value: 'Summer Yellow (KAW)', label: 'Summer Yellow (KAW)' },
  {
    value: 'Standard',
    label: 'Standard',
  },
  {
    value: 'Research (see notes)',
    label: 'Research (see notes)',
  },
  {
    value: 'Other (see notes)',
    label: 'Other (see notes)',
  },
];

export const ndadHangingHardware = [
  { value: '2 Butt Hinges', label: '2 Butt Hinges' },
  { value: '3 Butt Hinges', label: '3 Butt Hinges' },
  { value: 'Top & Bottom Offset Pivots', label: 'Top & Bottom Offset Pivots' },
  {
    value: 'Top, Bottom & Intermediate Offset Pivots',
    label: 'Top, Bottom & Intermediate Offset Pivots',
  },
  {
    value: 'Center Hung',
    label: 'Center Hung',
  },
  { value: 'Full Surface Continuous Hinges', label: 'Full Surface Continuous Hinges' },
  { value: 'Full Mortise Continuous Hinges', label: 'Full Mortise Continuous Hinges' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const ndadThreshold = [
  { value: '5" x 72" x 1/2', label: '5" x 72" x 1/2' },
  { value: '6" x 72" x 1/2', label: '6" x 72" x 1/2' },
  { value: '7" x 72" x 1/2', label: '7" x 72" x 1/2' },
  { value: '8" x 72" x 1/2', label: '8" x 72" x 1/2' },
  {
    value: '4" x 36" x 1/4" Aluminum Threshold',
    label: '4" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/4" Aluminum Threshold',
    label: '5" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/4" Aluminum Threshold',
    label: '7" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/4" Aluminum Threshold',
    label: '8" x 36" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/4" Aluminum Threshold',
    label: '4" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/4" Aluminum Threshold',
    label: '5" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/4" Aluminum Threshold',
    label: '7" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/4" Aluminum Threshold',
    label: '8" x 48" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/4" Aluminum Threshold',
    label: '4" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/4" Aluminum Threshold',
    label: '5" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/4" Aluminum Threshold',
    label: '7" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/4" Aluminum Threshold',
    label: '8" x 72" x 1/4" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/2" Aluminum Threshold',
    label: '4" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 36" x 1/2" Aluminum Threshold',
    label: '5" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 36" x 1/2" Aluminum Threshold',
    label: '7" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 36" x 1/2" Aluminum Threshold',
    label: '8" x 36" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 48" x 1/2" Aluminum Threshold',
    label: '4" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 48" x 1/2" Aluminum Threshold',
    label: '5" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 48" x 1/2" Aluminum Threshold',
    label: '7" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 48" x 1/2" Aluminum Threshold',
    label: '8" x 48" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 72" x 1/2" Aluminum Threshold',
    label: '4" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '5" x 72" x 1/2" Aluminum Threshold',
    label: '5" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '7" x 72" x 1/2" Aluminum Threshold',
    label: '7" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '8" x 72" x 1/2" Aluminum Threshold',
    label: '8" x 72" x 1/2" Aluminum Threshold',
  },
  {
    value: '4" x 36" x 1/4" Bronze Threshold',
    label: '4" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/4" Bronze Threshold',
    label: '5" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/4" Bronze Threshold',
    label: '7" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/4" Bronze Threshold',
    label: '8" x 36" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/4" Bronze Threshold',
    label: '4" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/4" Bronze Threshold',
    label: '5" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/4" Bronze Threshold',
    label: '7" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/4" Bronze Threshold',
    label: '8" x 48" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/4" Bronze Threshold',
    label: '4" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/4" Bronze Threshold',
    label: '5" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/4" Bronze Threshold',
    label: '7" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/4" Bronze Threshold',
    label: '8" x 72" x 1/4" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/2" Bronze Threshold',
    label: '4" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 36" x 1/2" Bronze Threshold',
    label: '5" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 36" x 1/2" Bronze Threshold',
    label: '7" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 36" x 1/2" Bronze Threshold',
    label: '8" x 36" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 48" x 1/2" Bronze Threshold',
    label: '4" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 48" x 1/2" Bronze Threshold',
    label: '5" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 48" x 1/2" Bronze Threshold',
    label: '7" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 48" x 1/2" Bronze Threshold',
    label: '8" x 48" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 72" x 1/2" Bronze Threshold',
    label: '4" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '5" x 72" x 1/2" Bronze Threshold',
    label: '5" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '7" x 72" x 1/2" Bronze Threshold',
    label: '7" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '8" x 72" x 1/2" Bronze Threshold',
    label: '8" x 72" x 1/2" Bronze Threshold',
  },
  {
    value: '4" x 36" x 1/4" Brass Threshold',
    label: '4" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/4" Brass Threshold',
    label: '5" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/4" Brass Threshold',
    label: '7" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/4" Brass Threshold',
    label: '8" x 36" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/4" Brass Threshold',
    label: '4" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/4" Brass Threshold',
    label: '5" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/4" Brass Threshold',
    label: '7" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/4" Brass Threshold',
    label: '8" x 48" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/4" Brass Threshold',
    label: '4" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/4" Brass Threshold',
    label: '5" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/4" Brass Threshold',
    label: '7" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/4" Brass Threshold',
    label: '8" x 72" x 1/4" Brass Threshold',
  },
  {
    value: '4" x 36" x 1/2" Brass Threshold',
    label: '4" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 36" x 1/2" Brass Threshold',
    label: '5" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 36" x 1/2" Brass Threshold',
    label: '7" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 36" x 1/2" Brass Threshold',
    label: '8" x 36" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 48" x 1/2" Brass Threshold',
    label: '4" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 48" x 1/2" Brass Threshold',
    label: '5" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 48" x 1/2" Brass Threshold',
    label: '7" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 48" x 1/2" Brass Threshold',
    label: '8" x 48" x 1/2" Brass Threshold',
  },
  {
    value: '4" x 72" x 1/2" Brass Threshold',
    label: '4" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '5" x 72" x 1/2" Brass Threshold',
    label: '5" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '7" x 72" x 1/2" Brass Threshold',
    label: '7" x 72" x 1/2" Brass Threshold',
  },
  {
    value: '8" x 72" x 1/2" Brass Threshold',
    label: '8" x 72" x 1/2" Brass Threshold',
  },
];
export const ndadDoorCloser = [
  { label: 'Surface Mounted (standard)', value: 'Surface Mounted (standard)' },
  { label: 'Surface Mounted (heavy duty)', value: 'Surface Mounted (heavy duty)' },
  {
    label: 'Surface Mounted + Drop Plate (standard)',
    value: 'Surface Mounted + Drop Plate (standard)',
  },
  {
    label: 'Surface Mounted + Drop Plate (heavy duty)',
    value: 'Surface Mounted + Drop Plate (heavy duty)',
  },
  { label: 'Surface Mounted (other see notes)', value: 'Surface Mounted (other see notes)' },
  { label: 'Concealed Overhead (standard)', value: 'Concealed Overhead (standard)' },
  {
    label: 'Concealed Overhead 90 (No-Hold Open)',
    value: 'Concealed Overhead 90 (No-Hold Open)',
  },
  { label: 'Concealed Overhead 90 (Hold Open)', value: 'Concealed Overhead 90 (Hold Open)' },
  {
    label: 'Concealed Overhead 105 (No-Hold Open)',
    value: 'Concealed Overhead 105 (No-Hold Open)',
  },
  {
    label: 'Concealed Overhead 105 (Hold Open)',
    value: 'Concealed Overhead 105 (Hold Open)',
  },
  {
    label: 'Concealed Overhead (other see notes)',
    value: 'Concealed Overhead (other see notes)',
  },
  { label: 'Floor Closer (standard)', value: 'Floor Closer (standard)' },
  { label: 'Floor Closer (Non-Hold Open)', value: 'Floor Closer (Non-Hold Open)' },
  { label: 'Floor Closer (Hold Open)', value: 'Floor Closer (Hold Open)' },
  { label: 'Floor Closer (other see notes)', value: 'Floor Closer (other see notes)' },
  { label: 'Automatic - Concealed', value: 'Automatic - Concealed' },
  { label: 'Automatic - Surface Mounted', value: 'Automatic - Surface Mounted' },
  { label: 'Automatic - Floor', value: 'Automatic - Floor' },
  { label: 'Automatic - Slider (By Pass)', value: 'Automatic - Slider (By Pass)' },
  { label: 'Automatic - Slider (By Part)', value: 'Automatic - Slider (By Part)' },
  {
    label: 'Automatic - Left.Concealed + Right.Standard Closer',
    value: 'Automatic - Left.Concealed + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Concealed + Left.Standard Closer',
    value: 'Automatic - Right.Concealed + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
    value: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
    value: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Floor + Right.Standard Closer',
    value: 'Automatic - Left.Floor + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Floor + Left.Standard Closer',
    value: 'Automatic - Right.Floor + Left.Standard Closer',
  },
  { label: 'Automatic: Slider (By Pass)', value: 'Automatic: Slider (By Pass)' },
  { label: 'Automatic: Slider (By Part)', value: 'Automatic: Slider (By Part)' },
  { label: 'Automatic - PAIR - Concealed', value: 'Automatic - PAIR - Concealed' },
  {
    label: 'Automatic - PAIR - Surface Mounted',
    value: 'Automatic - PAIR - Surface Mounted',
  },
  { label: 'Automatic - PAIR - Floor', value: 'Automatic - PAIR - Floor' },
  {
    label: 'Automatic - PAIR - Slider (By Pass)',
    value: 'Automatic - PAIR - Slider (By Pass)',
  },
  {
    label: 'Automatic - PAIR - Slider (By Part)',
    value: 'Automatic - PAIR - Slider (By Part)',
  },
  { label: 'Automatic (other see notes)', value: 'Automatic (other see notes)' },
  { label: 'Standard (per door type)', value: 'Standard (per door type)' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
  { label: 'No Closer ', value: 'No Closer ' },
];
export const ndadLock = [
  {
    value: 'MS Hookbolt With Two Cylinders (Standard)',
    label: 'MS Hookbolt With Two Cylinders (Standard)',
  },
  {
    value: 'MS Hookbolt Exterior with Cylinder Thumbturn Interior',
    label: 'MS Hookbolt Exterior with Cylinder Thumbturn Interior',
  },
  { value: 'Deadlock', label: 'Deadlock' },
  { value: 'Long Throw Deadlock', label: 'Long Throw Deadlock' },
  { value: 'Latchlock with Push Paddle', label: 'Latchlock with Push Paddle' },
  { value: 'Latchlock with Lever Handle', label: 'Latchlock with Lever Handle' },
  {
    value: 'Latchlock with Push Paddle (Electric Strike)',
    label: 'Latchlock with Push Paddle (Electric Strike)',
  },
  {
    value: 'Latchlock with Lever Handle (Electric Strike)',
    label: 'Latchlock with Lever Handle (Electric Strike)',
  },
  { value: 'Electric Strike', label: 'Electric Strike' },
  { value: 'No Locking Hardware', label: 'No Locking Hardware' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const ndadPanicDevice = [
  { value: 'RIM Panic (Touchbar)', label: 'RIM Panic (Touchbar)' },
  { value: 'RIM Panic- Electrified (Touchbar)', label: 'RIM Panic- Electrified (Touchbar)' },
  { value: 'CVR Panic (Touchbar)', label: 'CVR Panic (Touchbar)' },
  { value: 'CVR Panic- Electrified (Touchbar)', label: 'CVR Panic- Electrified (Touchbar)' },
  { value: 'RIM Panic (Crashbar)', label: 'RIM Panic (Crashbar)' },
  { value: 'CVR Panic (Crashbar)', label: 'CVR Panic (Crashbar)' },
  { value: 'CVR Panic Mid-Panel', label: 'CVR Panic Mid-Panel' },
  { value: 'Panic Device (Surface Applied)', label: 'Panic Device (Surface Applied)' },
  {
    value: 'Panic Device Electrified (Surface Applied)',
    label: 'Panic Device Electrified (Surface Applied)',
  },
  { value: 'Mortise Panic', label: 'Mortise Panic' },
  { value: 'CRL/Blumcraft Panic', label: 'CRL/Blumcraft Panic' },
  { value: 'Removable Mullion', label: 'Removable Mullion' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];

export const ndadHandles = [
  {
    value: 'Push Bar Interior /Pull Handle Exterior (standard)',
    label: 'Push Bar Interior /Pull Handle Exterior (standard)',
  },
  { value: 'Push Bar Interior /Push Bar Exterior', label: 'Push Bar Interior /Push Bar Exterior' },
  { value: 'Pull Handle Offset (Back-2-Back)', label: 'Pull Handle Offset (Back-2-Back)' },
  { value: 'Pull Handle Straight (Back-2-Back)', label: 'Pull Handle Straight (Back-2-Back)' },
  {
    value: 'Pull Only Exterior (with Panic Device)',
    label: 'Pull Only Exterior (with Panic Device)',
  },
  { value: 'Ladder Pull (Back-2-Back)', label: 'Ladder Pull (Back-2-Back)' },
  { value: 'Ladder Pull (exterior)', label: 'Ladder Pull (exterior)' },
];
export const ndadMidPanel = [
  { value: '5/16"', label: '5/16"' },
  { value: '1/2"', label: '1/2"' },
  { value: '5/8"', label: '5/8"' },
  { value: '3/4"', label: '3/4"' },
  { value: '7/8"', label: '7/8"' },
  { value: '1 7/8"', label: '1 7/8"' },
  { value: '2"', label: '2"' },
  { value: '2 1/4"', label: '2 1/4"' },
  { value: '3 7/16"', label: '3 7/16"' },
  { value: '3 1/2"', label: '3 1/2"' },
  { value: '4"', label: '4"' },
  { value: '4 1/8"', label: '4 1/8"' },
  { value: '5"', label: '5"' },
  { value: '6"', label: '6"' },
  { value: '6 3/16"', label: '6 3/16"' },
  { value: '6 1/2"', label: '6 1/2"' },
  { value: '7 1/2"', label: '7 1/2"' },
  { value: '7 15/16"', label: '7 15/16"' },
  { value: '8"', label: '8"' },
  { value: '8 1/4"', label: '8 1/4"' },
  { value: '9 1/2"', label: '9 1/2"' },
  { value: '10"', label: '10"' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndadBottomRail = [
  { value: '3 3/16"', label: '3 3/16"' },
  { value: '4"', label: '4"' },
  { value: '5 1/2"  ', label: '5 1/2"' },
  { value: '6 1/2"', label: '6 1/2"' },
  { value: '7 1/2"', label: '7 1/2"' },
  { value: '8"', label: '8"' },
  { value: '9 1/2"', label: '9 1/2"' },
  { value: '10"', label: '10"' },
  { value: '12" (stacked)', label: '12" (stacked)' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const ndadDoorSweeps = [
  { value: 'Bottom - Brush (exposed screws)', label: 'Bottom - Brush (exposed screws)' },
  { value: 'Bottom - Brush (concealed screws)', label: 'Bottom - Brush (concealed screws)' },
  { value: 'Bottom - Vinyl (exposed screws)', label: 'Bottom - Vinyl (exposed screws)' },
  { value: 'Bottom - Vinyl (concealed screws)', label: 'Bottom - Vinyl (concealed screws)' },
  { value: 'Bottom - Automatic (exposed screws)', label: 'Bottom - Automatic (exposed screws)' },
  {
    value: 'Bottom - Automatic (concealed screws)',
    label: 'Bottom - Automatic (concealed screws)',
  },
];

/* HOLLOW METAL DOORS */

export const ndhmdDoorTypeOptions = [
  { value: 'Metal', label: 'Metal' },
  { value: 'Wood', label: 'Wood' },
  { value: 'Steel', label: 'Steel' },
  { value: 'Vinyl', label: 'Vinyl' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdDoorActionOptions = [
  { label: 'SWING (LEFT)', value: 'SWING (LEFT)' },
  { label: 'SWING (RIGHT)', value: 'SWING (RIGHT)' },
  { label: 'SWING (PAIR)', value: 'SWING (PAIR)' },
  { label: 'SWING 2 HINGES (LEFT)', value: 'SWING 2 HINGES (LEFT)' },
  { label: 'SWING 2 HINGES (RIGHT)', value: 'SWING 2 HINGES (RIGHT)' },
  { label: 'SWING 2 HINGES (PAIR)', value: 'SWING 2 HINGES (PAIR)' },
  { label: 'SWING 3 HINGES (LEFT)', value: 'SWING 3 HINGES (LEFT)' },
  { label: 'SWING 3 HINGES (RIGHT)', value: 'SWING 3 HINGES (RIGHT)' },
  { label: 'SWING 3 HINGES (PAIR)', value: 'SWING 3 HINGES (PAIR)' },
  { label: 'SWING 4 HINGES (LEFT)', value: 'SWING 4 HINGES (LEFT)' },
  { label: 'SWING 4 HINGES (RIGHT)', value: 'SWING 4 HINGES (RIGHT)' },
  { label: 'SWING 4 HINGES (PAIR)', value: 'SWING 4 HINGES (PAIR)' },
  { label: 'SWING (other see notes)', value: 'SWING (other see notes)' },
  { label: 'PIVOTS - TOP+BOTTOM (LEFT)', value: 'PIVOTS - TOP+BOTTOM (LEFT)' },
  { label: 'PIVOTS - TOP+BOTTOM (RIGHT)', value: 'PIVOTS - TOP+BOTTOM (RIGHT)' },
  { label: 'PIVOTS - TOP+BOTTOM (PAIR)', value: 'PIVOTS - TOP+BOTTOM (PAIR)' },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (LEFT)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (LEFT)',
  },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (RIGHT)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (RIGHT)',
  },
  {
    label: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (PAIR)',
    value: 'PIVOTS - TOP+INTERMEDIATE+BOTTOM (PAIR)',
  },
  { label: 'PIVOTS (other see notes)', value: 'PIVOTS (other see notes)' },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (LEFT)',
    value: 'CONTINOUS HINGE - Surface Mounted (LEFT)',
  },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (RIGHT)',
    value: 'CONTINOUS HINGE - Surface Mounted (RIGHT)',
  },
  {
    label: 'CONTINOUS HINGE - Surface Mounted (PAIR)',
    value: 'CONTINOUS HINGE - Surface Mounted (PAIR)',
  },
  { label: 'CONTINOUS HINGE - Mortise (LEFT)', value: 'CONTINOUS HINGE - Mortise (LEFT)' },
  { label: 'CONTINOUS HINGE - Mortise (RIGHT)', value: 'CONTINOUS HINGE - Mortise (RIGHT)' },
  { label: 'CONTINOUS HINGE - Mortise (PAIR)', value: 'CONTINOUS HINGE - Mortise (PAIR)' },
  { label: 'CONTINOUS HINGE (other see notes)', value: 'CONTINOUS HINGE (other see notes)' },
  { label: 'SLIDING - SINGLE (LEFT)', value: 'SLIDING - SINGLE (LEFT)' },
  { label: 'SLIDING - SINGLE (RIGHT)', value: 'SLIDING - SINGLE (RIGHT)' },
  { label: 'SLIDING - BY PASS (PAIR)', value: 'SLIDING - BY PASS (PAIR)' },
  { label: 'SLIDING - BY PART (PAIR)', value: 'SLIDING - BY PART (PAIR)' },
  { label: 'SLIDING - STACKING', value: 'SLIDING - STACKING' },
  { label: 'SLIDING (other see notes)', value: 'SLIDING (other see notes)' },
  { label: 'BARN - SINGLE SLIDER (LEFT)', value: 'BARN - SINGLE SLIDER (LEFT)' },
  { label: 'BARN - SINGLE SLIDER (RIGHT)', value: 'BARN - SINGLE SLIDER (RIGHT)' },
  { label: 'BARN - BY PASS (PAIR)', value: 'BARN - BY PASS (PAIR)' },
  { label: 'BARN - BY PART (PAIR)', value: 'BARN - BY PART (PAIR)' },
  { label: 'BARN (other see notes)', value: 'BARN (other see notes)' },
  { label: 'GAP (NO DOOR)', value: 'GAP (NO DOOR)' },
  { label: 'Standard (per door type)', value: 'Standard (per door type)' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const ndhmdHingeHeight = [
  { value: '4"', label: '4"' },
  { value: '4 1/2"', label: '4 1/2"' },
];
export const ndhmdDoorThickness = [
  { value: 'Standard', label: 'Standard' },
  { value: '1 3/8"', label: '1 3/8"' },
  { value: '1 3/4"', label: '1 3/4"' },
  { value: '2"', label: '2"' },
  { value: 'LEVEL 1 - STANDARD DUTY - 1 3/8"', label: 'LEVEL 1 - STANDARD DUTY - 1 3/8"' },
  { value: 'LEVEL 1 - STANDARD DUTY - 1 3/4"', label: 'LEVEL 1 - STANDARD DUTY - 1 3/4"' },
  { value: 'LEVEL 2 - HEAVY DUTY - 1 3/4"', label: 'LEVEL 2 - HEAVY DUTY - 1 3/4"' },
  { value: 'LEVEL 3 - EXTRA HEAVY DUTY - 1 3/4"', label: 'LEVEL 3 - EXTRA HEAVY DUTY - 1 3/4"' },
  { value: 'LEVEL 4 - MAXIMUM  DUTY - 1 3/4"', label: 'LEVEL 4 - MAXIMUM  DUTY - 1 3/4"' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdDoorPanelOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'D - DUTCH DOOR', label: 'D - DUTCH DOOR' },
  { value: 'E2 - 2 PANEL EMBOSSED', label: 'E2 - 2 PANEL EMBOSSED' },
  { value: 'E4 - 4 PANEL EMBOSSED', label: 'E4 - 4 PANEL EMBOSSED' },
  { value: 'E6 - 6 PANEL EMBOSSED', label: 'E6 - 6 PANEL EMBOSSED' },
  { value: 'E8 - 8 PANEL EMBOSSED', label: 'E8 - 8 PANEL EMBOSSED' },
  { value: 'EG - EMBOSSED & HALF GLASS', label: 'EG - EMBOSSED & HALF GLASS' },
  { value: 'EWG - 6 PANEL EMBOSSED (wood grain)', label: 'EWG - 6 PANEL EMBOSSED (wood grain)' },
  { value: 'F - FLUSH', label: 'F - FLUSH' },
  { value: 'FG - FULL GLASS', label: 'FG - FULL GLASS' },
  { value: 'FG3 - FULL GLASS (3 EQUAL LITES)', label: 'FG3 - FULL GLASS (3 EQUAL LITES)' },
  { value: 'FL - FULL LOUVER', label: 'FL - FULL LOUVER' },
  { value: 'G - HALF GLASS', label: 'G - HALF GLASS' },
  {
    value: 'G2 - HALF GLASS: 2 LITES (horizontal) + LOUVER',
    label: 'G2 - HALF GLASS: 2 LITES (horizontal) + LOUVER',
  },
  {
    value: 'G3 - HALF GLASS: 3 EQUAL LITES (horizontal)',
    label: 'G3 - HALF GLASS: 3 EQUAL LITES (horizontal)',
  },
  {
    value: 'G3L - HALF GLASS: 3 EQUAL LITES (horizontal) + LOUVER',
    label: 'G3L - HALF GLASS: 3 EQUAL LITES (horizontal) + LOUVER',
  },
  { value: 'G4 - HALF GLASS: 4 EQUAL LITES (box)', label: 'G4 - HALF GLASS: 4 EQUAL LITES (box)' },
  { value: 'G6 - HALF GLASS: 6 EQUAL LITES (box)', label: 'G6 - HALF GLASS: 6 EQUAL LITES (box)' },
  { value: 'GL - HALF GLASS + LOUVER', label: 'GL - HALF GLASS + LOUVER' },
  { value: 'L - LOUVER - BOTTOM', label: 'L - LOUVER - BOTTOM' },
  { value: 'L -  TOP - LOUVER', label: 'L -  TOP - LOUVER' },
  { value: 'LL - TOP&BOTTOM', label: 'LL - TOP&BOTTOM' },
  { value: 'N - NARROW LITE', label: 'N - NARROW LITE' },
  { value: 'NL - NARROW LITE + LOUVER', label: 'NL - NARROW LITE + LOUVER' },
  { value: 'T - TEXTURED', label: 'T - TEXTURED' },
  { value: 'V - VISION LITE', label: 'V - VISION LITE' },
  { value: 'VL - VISION LITE + LOUVER', label: 'VL - VISION LITE + LOUVER' },
  { value: 'WG - WOOD GRAIN', label: 'WG - WOOD GRAIN' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdHingeSizeOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: '4"', label: '4"' },
  { value: '4 1/2"', label: '4 1/2"' },
  { value: '5"', label: '5"' },
  { value: '5 1/2"', label: '5 1/2"' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdLouverOptions = [
  {
    value: 'Standard (see *Door Panel Layout* Options for sizes)',
    label: 'Standard (see *Door Panel Layout* Options for sizes)',
  },
  { value: '12x12', label: '12x12' },
  { value: '12x24', label: '12x24' },
  { value: '24x24', label: '24x24' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdDoorFrameFinishOptions = [
  { value: 'Wood - Standard', label: 'Wood - Standard' },
  {
    value: 'Wood - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Wood - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Wood - Unknown', label: 'Wood - Unknown' },
  { value: 'Wood - Research (see notes)', label: 'Wood - Research (see notes)' },
  { value: 'Wood - Custom (see notes)', label: 'Wood - Custom (see notes)' },
  { value: 'Wood - other (see notes)', label: 'Wood - other (see notes)' },
  { value: 'Metal - Standard', label: 'Metal - Standard' },
  {
    value: 'Metal - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Metal - Unknown', label: 'Metal - Unknown' },
  { value: 'Metal - Research (see notes)', label: 'Metal - Research (see notes)' },
  { value: 'Metal - Custom (see notes)', label: 'Metal - Custom (see notes)' },
  { value: 'Metal - other (see notes)', label: 'Metal - other (see notes)' },
  { value: 'Steel - Standard', label: 'Steel - Standard' },
  {
    value: 'Steel - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
    label: 'Steel - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Steel - Unknown', label: 'Steel - Unknown' },
  { value: 'Steel - Research (see notes)', label: 'Steel - Research (see notes)' },
  { value: 'Steel - Custom (see notes)', label: 'Steel - Custom (see notes)' },
  { value: 'Steel - other (see notes)', label: 'Steel - other (see notes)' },
  { value: 'Vinyl - Standard', label: 'Vinyl - Standard' },
  {
    value: 'Vinyl - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
    label: 'Vinyl - PRIMED GRAY - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Vinyl - Unknown', label: 'Vinyl - Unknown' },
  { value: 'Vinyl - Research (see notes)', label: 'Vinyl - Research (see notes)' },
  { value: 'Vinyl - Custom (see notes)', label: 'Vinyl - Custom (see notes)' },
  { value: 'Vinyl - other (see notes)', label: 'Vinyl - other (see notes)' },
  { value: 'Custom - (see notes)', label: 'Custom - (see notes)' },
  { value: 'Other - (see notes)', label: 'Other - (see notes)' },
  {
    value: 'Metal - Galvanized - Standard Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized - Standard Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 40 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 40 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 40 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 40 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 40 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 40 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 60 - 12 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 60 - 12 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 60 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 60 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 60 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 60 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 60 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 60 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 90 - 12 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 90 - 12 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 90 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 90 - 14 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 90 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 90 - 16 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized 90 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized 90 - 18 Guage - Finish/Stain/Painting: BY OTHERS',
  },
  {
    value: 'Metal - Galvanized - Other - Finish/Stain/Painting: BY OTHERS',
    label: 'Metal - Galvanized - Other - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Birch Wood - Standard', label: 'Birch Wood - Standard' },
  {
    value: 'Birch Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Birch Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Birch Wood - Clear', label: 'Birch Wood - Clear' },
  { value: 'Birch Wood - Honey', label: 'Birch Wood - Honey' },
  { value: 'Birch Wood - Autumn', label: 'Birch Wood - Autumn' },
  { value: 'Birch Wood  - Cinnamon', label: 'Birch Wood  - Cinnamon' },
  { value: 'Birch Wood - Nutmeg', label: 'Birch Wood - Nutmeg' },
  { value: 'Birch Wood- Toast', label: 'Birch Wood- Toast' },
  { value: 'Birch Wood- Amber', label: 'Birch Wood- Amber' },
  { value: 'Birch Wood- Saffron', label: 'Birch Wood- Saffron' },
  { value: 'Birch Wood - Merlot', label: 'Birch Wood - Merlot' },
  { value: 'Birch Wood - Teak', label: 'Birch Wood - Teak' },
  { value: 'Birch Wood - Red', label: 'Birch Wood - Red' },
  { value: 'Birch Wood - Mahogany', label: 'Birch Wood - Mahogany' },
  { value: 'Birch Wood - Walnut', label: 'Birch Wood - Walnut' },
  { value: 'Birch Wood - Brown', label: 'Birch Wood - Brown' },
  { value: 'Birch Wood - Dark Brown', label: 'Birch Wood - Dark Brown' },
  { value: 'Birch Wood - UNKNOWN', label: 'Birch Wood - UNKNOWN' },
  { value: 'Birch Wood - Research (see notes)', label: 'Birch Wood - Research (see notes)' },
  { value: 'Birch Wood - Custom (see notes)', label: 'Birch Wood - Custom (see notes)' },
  { value: 'Birch Wood - other (see notes)', label: 'Birch Wood - other (see notes)' },
  { value: 'Red Oak Wood - Standard', label: 'Red Oak Wood - Standard' },
  {
    value: 'Red Oak Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Red Oak Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Red Oak Wood - Clear', label: 'Red Oak Wood - Clear' },
  { value: 'Red Oak Wood - Honey', label: 'Red Oak Wood - Honey' },
  { value: 'Red Oak Wood - Autumn', label: 'Red Oak Wood - Autumn' },
  { value: 'Red Oak Wood - Cinnamon', label: 'Red Oak Wood - Cinnamon' },
  { value: 'Red Oak Wood - Nutmeg', label: 'Red Oak Wood - Nutmeg' },
  { value: 'Red Oak Wood - Toast', label: 'Red Oak Wood - Toast' },
  { value: 'Red Oak Wood - Amber', label: 'Red Oak Wood - Amber' },
  { value: 'Red Oak Wood - Saffron', label: 'Red Oak Wood - Saffron' },
  { value: 'Red Oak Wood - Merlot', label: 'Red Oak Wood - Merlot' },
  { value: 'Red Oak Wood - Teak', label: 'Red Oak Wood - Teak' },
  { value: 'Red Oak Wood - Red', label: 'Red Oak Wood - Red' },
  { value: 'Red Oak Wood - Mahogany', label: 'Red Oak Wood - Mahogany' },
  { value: 'Red Oak Wood - Walnut', label: 'Red Oak Wood - Walnut' },
  { value: 'Red Oak Wood - Brown', label: 'Red Oak Wood - Brown' },
  { value: 'Red Oak Wood - Dark Brown', label: 'Red Oak Wood - Dark Brown' },
  { value: 'Red Oak Wood - UNKNOWN', label: 'Red Oak Wood - UNKNOWN' },
  { value: 'Red Oak Wood - Research (see notes)', label: 'Red Oak Wood - Research (see notes)' },
  { value: 'Red Oak Wood - Custom (see notes)', label: 'Red Oak Wood - Custom (see notes)' },
  { value: 'Red Oak Wood - other (see notes)', label: 'Red Oak Wood - other (see notes)' },
  { value: 'Maple Wood - Standard', label: 'Maple Wood - Standard' },
  {
    value: 'Maple Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Maple Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Maple Wood - Clear', label: 'Maple Wood - Clear' },
  { value: 'Maple Wood - Honey', label: 'Maple Wood - Honey' },
  { value: 'Maple Wood - Autumn', label: 'Maple Wood - Autumn' },
  { value: 'Maple Wood - Cinnamon', label: 'Maple Wood - Cinnamon' },
  { value: 'Maple Wood - Nutmeg', label: 'Maple Wood - Nutmeg' },
  { value: 'Maple Wood - Toast', label: 'Maple Wood - Toast' },
  { value: 'Maple Wood - Amber', label: 'Maple Wood - Amber' },
  { value: 'Maple Wood - Saffron', label: 'Maple Wood - Saffron' },
  { value: 'Maple Wood - Merlot', label: 'Maple Wood - Merlot' },
  { value: 'Maple Wood - Teak', label: 'Maple Wood - Teak' },
  { value: 'Maple Wood - Red', label: 'Maple Wood - Red' },
  { value: 'Maple Wood - Mahogany', label: 'Maple Wood - Mahogany' },
  { value: 'Maple Wood - Walnut', label: 'Maple Wood - Walnut' },
  { value: 'Maple Wood - Brown', label: 'Maple Wood - Brown' },
  { value: 'Maple Wood - Dark Brown', label: 'Maple Wood - Dark Brown' },
  { value: 'Maple Wood - UNKNOWN', label: 'Maple Wood - UNKNOWN' },
  { value: 'Maple Wood - Research (see notes)', label: 'Maple Wood - Research (see notes)' },
  { value: 'Maple Wood - Custom (see notes)', label: 'Maple Wood - Custom (see notes)' },
  { value: 'Maple Wood - other (see notes)', label: 'Maple Wood - other (see notes)' },
  { value: 'Cherry Wood - Standard', label: 'Cherry Wood - Standard' },
  {
    value: 'Cherry Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Cherry Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Cherry Wood - Clear', label: 'Cherry Wood - Clear' },
  { value: 'Cherry Wood - Honey', label: 'Cherry Wood - Honey' },
  { value: 'Cherry Wood - Autumn', label: 'Cherry Wood - Autumn' },
  { value: 'Cherry Wood - Cinnamon', label: 'Cherry Wood - Cinnamon' },
  { value: 'Cherry Wood - Nutmeg', label: 'Cherry Wood - Nutmeg' },
  { value: 'Cherry Wood - Toast', label: 'Cherry Wood - Toast' },
  { value: 'Cherry Wood - Amber', label: 'Cherry Wood - Amber' },
  { value: 'Cherry Wood - Saffron', label: 'Cherry Wood - Saffron' },
  { value: 'Cherry Wood - Merlot', label: 'Cherry Wood - Merlot' },
  { value: 'Cherry Wood - Teak', label: 'Cherry Wood - Teak' },
  { value: 'Cherry Wood - Red', label: 'Cherry Wood - Red' },
  { value: 'Cherry Wood - Mahogany', label: 'Cherry Wood - Mahogany' },
  { value: 'Cherry Wood - Walnut', label: 'Cherry Wood - Walnut' },
  { value: 'Cherry Wood - Brown', label: 'Cherry Wood - Brown' },
  { value: 'Cherry Wood - Dark Brown', label: 'Cherry Wood - Dark Brown' },
  { value: 'Cherry Wood - UNKNOWN', label: 'Cherry Wood - UNKNOWN' },
  { value: 'Cherry Wood - Research (see notes)', label: 'Cherry Wood - Research (see notes)' },
  { value: 'Cherry Wood - Custom (see notes)', label: 'Cherry Wood - Custom (see notes)' },
  { value: 'Cherry Wood - other (see notes)', label: 'Cherry Wood - other (see notes)' },
  { value: 'Mahogany Wood - Standard', label: 'Mahogany Wood - Standard' },
  {
    value: 'Mahogany Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Mahogany Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Mahogany Wood - Clear', label: 'Mahogany Wood - Clear' },
  { value: 'Mahogany Wood - Honey', label: 'Mahogany Wood - Honey' },
  { value: 'Mahogany Wood - Autumn', label: 'Mahogany Wood - Autumn' },
  { value: 'Mahogany Wood - Cinnamon', label: 'Mahogany Wood - Cinnamon' },
  { value: 'Mahogany Wood - Nutmeg', label: 'Mahogany Wood - Nutmeg' },
  { value: 'Mahogany Wood - Toast', label: 'Mahogany Wood - Toast' },
  { value: 'Mahogany Wood - Amber', label: 'Mahogany Wood - Amber' },
  { value: 'Mahogany Wood - Saffron', label: 'Mahogany Wood - Saffron' },
  { value: 'Mahogany Wood - Merlot', label: 'Mahogany Wood - Merlot' },
  { value: 'Mahogany Wood - Teak', label: 'Mahogany Wood - Teak' },
  { value: 'Mahogany Wood - Red', label: 'Mahogany Wood - Red' },
  { value: 'Mahogany Wood - Mahogany', label: 'Mahogany Wood - Mahogany' },
  { value: 'Mahogany Wood - Walnut', label: 'Mahogany Wood - Walnut' },
  { value: 'Mahogany Wood - Brown', label: 'Mahogany Wood - Brown' },
  { value: 'Mahogany Wood - Dark Brown', label: 'Mahogany Wood - Dark Brown' },
  { value: 'Mahogany Wood - UNKNOWN', label: 'Mahogany Wood - UNKNOWN' },
  { value: 'Mahogany Wood - Research (see notes)', label: 'Mahogany Wood - Research (see notes)' },
  { value: 'Mahogany Wood - Custom (see notes)', label: 'Mahogany Wood - Custom (see notes)' },
  { value: 'Mahogany Wood - other (see notes)', label: 'Mahogany Wood - other (see notes)' },
  { value: 'Walnut Wood - Standard', label: 'Walnut Wood - Standard' },
  {
    value: 'Walnut Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
    label: 'Walnut Wood - Standard - Untreated - Finish/Stain/Painting: BY OTHERS',
  },
  { value: 'Walnut Wood - Clear', label: 'Walnut Wood - Clear' },
  { value: 'Walnut Wood - Honey', label: 'Walnut Wood - Honey' },
  { value: 'Walnut Wood - Autumn', label: 'Walnut Wood - Autumn' },
  { value: 'Walnut Wood - Cinnamon', label: 'Walnut Wood - Cinnamon' },
  { value: 'Walnut Wood - Nutmeg', label: 'Walnut Wood - Nutmeg' },
  { value: 'Walnut Wood - Toast', label: 'Walnut Wood - Toast' },
  { value: 'Walnut Wood - Amber', label: 'Walnut Wood - Amber' },
  { value: 'Walnut Wood - Saffron', label: 'Walnut Wood - Saffron' },
  { value: 'Walnut Wood - Merlot', label: 'Walnut Wood - Merlot' },
  { value: 'Walnut Wood - Teak', label: 'Walnut Wood - Teak' },
  { value: 'Walnut Wood - Red', label: 'Walnut Wood - Red' },
  { value: 'Walnut Wood - Mahogany', label: 'Walnut Wood - Mahogany' },
  { value: 'Walnut Wood - Walnut', label: 'Walnut Wood - Walnut' },
  { value: 'Walnut Wood - Brown', label: 'Walnut Wood - Brown' },
  { value: 'Walnut Wood - Dark Brown', label: 'Walnut Wood - Dark Brown' },
  { value: 'Walnut Wood - UNKNOWN', label: 'Walnut Wood - UNKNOWN' },
  { value: 'Walnut Wood - Research (see notes)', label: 'Walnut Wood - Research (see notes)' },
  { value: 'Walnut Wood - Custom (see notes)', label: 'Walnut Wood - Custom (see notes)' },
  { value: 'Walnut Wood - other (see notes)', label: 'Walnut Wood - other (see notes)' },
];

export const ndhmdContinuousHinge = [
  { value: 'Full Surface', label: 'Full Surface' },
  { value: 'Full Mortise', label: 'Full Mortise' },
  { value: 'None', label: 'None' },
];
export const ndhmdDoorHand = [
  { value: 'Hinge Left Swing In (Left Hand)', label: 'Hinge Left Swing In (Left Hand)' },
  { value: 'Hinge Right Swing In (Right Hand)', label: 'Hinge Right Swing In (Right Hand)' },
  {
    value: 'Hinge Left Swing Out (Left Hand Reverse)',
    label: 'Hinge Left Swing Out (Left Hand Reverse)',
  },
  {
    value: 'Hinge Right Swing Out (Right Hand Reverse)',
    label: 'Hinge Right Swing Out (Right Hand Reverse)',
  },
];

export const ndhmdFireRating = [
  { value: 'None', label: 'None' },
  { value: '20 Minutes', label: '20 Minutes' },
  { value: '45 Minutes', label: '45 Minutes' },
  { value: '60 Minutes', label: '60 Minutes' },
  { value: '90 Minutes', label: '90 Minutes' },
  { value: '120 Minutes', label: '120 Minutes' },
  { value: '3 Hours', label: '3 Hours' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdVisionKit = [
  {
    value: 'Standard (see *Door Panel Layout* Options for sizes)',
    label: 'Standard (see *Door Panel Layout* Options for sizes)',
  },
  { value: '10x10', label: '10x10' },
  { value: '5x20', label: '5x20' },
  { value: '4x25', label: '4x25' },
  { value: '22x28 (Half Glass)', label: '22x28 (Half Glass)' },
  { value: 'Full Glass', label: 'Full Glass' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdLockset = [
  { value: 'Passage Set', label: 'Passage set' },
  { value: 'Exit Set', label: 'Exit Set' },
  { value: 'Privacy', label: 'Privacy' },
  { value: 'Dummy', label: 'Dummy' },
];
export const ndhmdPanicDevice = [
  { value: 'Standard', label: 'Standard' },
  { value: 'CVR Panic - Electrified (Touchbar)', label: 'CVR Panic - Electrified (Touchbar)' },
  { value: 'CVR Panic (Crashbar)', label: 'CVR Panic (Crashbar)' },
  { value: 'CVR Panic (Touchbar)', label: 'CVR Panic (Touchbar)' },
  { value: 'CVR Panic Mid-Panel', label: 'CVR Panic Mid-Panel' },
  { value: 'RIM Panic - Electrified (Touchbar)', label: 'RIM Panic - Electrified (Touchbar)' },
  { value: 'RIM Panic (Crashbar)', label: 'RIM Panic (Crashbar)' },
  { value: 'RIM Panic (Touchbar)', label: 'RIM Panic (Touchbar)' },
  { value: 'Panic Device (Surface Applied)', label: 'Panic Device (Surface Applied)' },
  {
    value: 'Panic Device Electrified (Surface Applied)',
    label: 'Panic Device Electrified (Surface Applied)',
  },
  { value: 'Mortise Panic', label: 'Mortise Panic' },
  { value: 'CRL/Blumcraft Panic', label: 'CRL/Blumcraft Panic' },
  { value: 'Removable Mullion', label: 'Removable Mullion' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdDoorCloser = [
  { value: 'Surface Mounted', label: 'Surface Mounted' },
  { value: 'Concealed Overhead', label: 'Concealed Overhead' },
  { value: 'Automatic Operator', label: 'Automatic Operator' },
];
export const ndhmdPullSet = [{ value: 'C Pull with Plate', label: 'C Pull with Plate' }];

export const ndhmdKickPlate = [
  { value: 'Standard', label: 'Standard' },
  { value: '6"', label: '6"' },
  { value: '10"', label: '10"' },
  { value: '12"', label: '12"' },
  { value: '22"', label: '22"' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

/* PATIO DOOR */
export const pdTypeOptions = [
  { value: 'Window - Double Hung', label: 'Window - Double Hung' },
  { value: 'Window - Single Hung ', label: 'Window - Single Hung ' },
  { value: 'Window - Fixed/Picture ', label: 'Window - Fixed/Picture ' },
  { value: 'Window - Casement ', label: 'Window - Casement ' },
  { value: 'Window - Casement (basement) ', label: 'Window - Casement (basement) ' },
  { value: 'Window - Slider ', label: 'Window - Slider ' },
  { value: 'Window - Hopper ', label: 'Window - Hopper ' },
  { value: 'Window - Awning ', label: 'Window - Awning ' },
  { value: 'Window - Garden', label: 'Window - Garden' },
  { value: 'Window - Bay ', label: 'Window - Bay ' },
  { value: 'Window - Bow', label: 'Window - Bow' },
  { value: 'Patio Door - Slider', label: 'Patio Door - Slider' },
  { value: 'Patio Door - Stacking Slider', label: 'Patio Door - Stacking Slider' },
  { value: 'Patio Door - Bi-Fold', label: 'Patio Door - Bi-Fold' },
  {
    value: 'Terrace Swing Door - Single (Left)',
    label: 'Terrace Swing Door - Single (Left)',
  },
  {
    value: 'Terrace Swing Door - Single (Right)',
    label: 'Terrace Swing Door - Single (Right)',
  },
  { value: 'Terrace Swing Door - Pair', label: 'Terrace Swing Door - Pair' },
  { value: 'Window - Custom (see notes)', label: 'Window - Custom (see notes)' },
  { value: 'Window - Research (see notes)', label: 'Window - Research (see notes)' },
  { value: 'Window - Other (see notes)', label: 'Window - Other (see notes)' },
  { value: 'Patio Door - Custom (see notes)', label: 'Patio Door - Custom (see notes)' },
  { value: 'Patio Door - Research (see notes)', label: 'Patio Door - Research (see notes)' },
  { value: 'Patio Door - Other (see notes)', label: 'Patio Door - Other (see notes)' },
  { value: 'Terrace Door - Custom (see notes)', label: 'Terrace Door - Custom (see notes)' },
  {
    value: 'Terrace Door - Research (see notes)',
    label: 'Terrace Door - Research (see notes)',
  },
  { value: 'Terrace Door - Other (see notes)', label: 'Terrace Door - Other (see notes)' },
];
export const pdFrameType = [
  { value: 'Aluminum', label: 'Aluminum' },
  { value: 'Vinyl', label: 'Vinyl' },
  { value: 'Fiberglass ', label: 'Fiberglass ' },
  { value: 'Wood ', label: 'Wood ' },
  { value: 'Steel ', label: 'Steel ' },
  { value: 'Wood-Clad (EXTERIOR CLAD ONLY)', label: 'Wood-Clad (EXTERIOR CLAD ONLY)' },
  { value: 'Wood-Clad (INTERIOR CLAD ONLY)', label: 'Wood-Clad (INTERIOR CLAD ONLY)' },
  { value: 'Wood-Clad ', label: 'Wood-Clad ' },
  { value: 'Aluminum-Clad', label: 'Aluminum-Clad' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdFramingDepth = [
  { value: '4 3/8', label: '4 3/8' },
  { value: '4 1/2', label: '4 1/2' },
];
export const pdThermallyBroken = [
  { value: 'YES', label: 'YES' },
  { value: 'NO', label: 'NO' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdFrame = [
  { value: 'New Construction', label: 'New Construction' },
  { value: 'Replacement', label: 'Replacement' },
  { value: 'Nail Fin 1 3/8" Setback ', label: 'Nail Fin 1 3/8" Setback ' },
  { value: 'Nail Fin 1" Setback ', label: 'Nail Fin 1" Setback ' },
  { value: 'Double Wall Flush Flange', label: 'Double Wall Flush Flange' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdFrameReceptor = [
  { value: 'None', label: 'None' },
  { value: 'Head (only)', label: 'Head (only)' },
  { value: 'Sill (only) ', label: 'Sill (only) ' },
  { value: 'Head & Jamb ', label: 'Head & Jamb ' },
  { value: 'Head, Jamb, & Sill', label: 'Head, Jamb, & Sill' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdAssembly = [
  { value: 'Standard', label: 'Standard' },
  { value: 'Knocked Down ', label: 'Knocked Down ' },
  { value: 'Assembled ', label: 'Assembled ' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdGridsColorOptions = [];
export const pdFinishA = [
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Custom', label: 'Custom' },
  { value: 'Clear Anodized (Standard  .4 mils)', label: 'Clear Anodized (Standard  .4 mils)' },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Almond (TW)', label: 'Almond (TW)' },
  { value: 'Antique White (EFCO)', label: 'Antique White (EFCO)' },
  {
    value: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
    label: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
  },
  { value: 'Colonial White (OBE, YKK)', label: 'Colonial White (OBE, YKK)' },
  { value: 'Crème (TW)', label: 'Crème (TW)' },
  { value: 'Herring Bone (EFCO)', label: 'Herring Bone (EFCO)' },
  { value: 'Milk White (KAW)', label: 'Milk White (KAW)' },
  { value: 'Stone White (USA)', label: 'Stone White (USA)' },
  { value: 'White (EFCO, TW)', label: 'White (EFCO, TW)' },
  { value: 'Beige (OBE, YKK)', label: 'Beige (OBE, YKK)' },
  { value: 'Seawolf Beige(YKK)', label: 'Seawolf Beige(YKK)' },
  { value: 'Black (OBE, YKK, KAW, EFCO, USA, TW)', label: 'Black (OBE, YKK, KAW, EFCO, USA, TW)' },
  { value: 'Deep Blue (KAW)', label: 'Deep Blue (KAW)' },
  { value: 'Blue Sky (EFCO)', label: 'Blue Sky (EFCO)' },
  { value: 'Legacy Blue (EFCO)', label: 'Legacy Blue (EFCO)' },
  { value: 'Medium Blue (OBE, KAW, TW)', label: 'Medium Blue (OBE, KAW, TW)' },
  { value: 'Midnight Blue (KAW, EFCO)', label: 'Midnight Blue (KAW, EFCO)' },
  { value: 'Military Blue (OBE, KAW, USA)', label: 'Military Blue (OBE, KAW, USA)' },
  { value: 'Regal Blue (OBE, USA)', label: 'Regal Blue (OBE, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Bronze (THW)', label: 'Bronze (THW)' },
  { value: 'Classic Bronze (YKK, KAW, USA)', label: 'Classic Bronze (YKK, KAW, USA)' },
  { value: 'Light Bronze (USA)', label: 'Light Bronze (USA)' },
  { value: 'Medium Bronze (KAW, USA, THW)', label: 'Medium Bronze (KAW, USA, THW)' },
  { value: 'Brown (OBE)', label: 'Brown (OBE)' },
  { value: 'Java Brown (KAW)', label: 'Java Brown (KAW)' },
  { value: 'Light Brown (EFCO)', label: 'Light Brown (EFCO)' },
  { value: 'Mesa Brown (EFCO)', label: 'Mesa Brown (EFCO)' },
  { value: 'Mineral Brown (EFCO)', label: 'Mineral Brown (EFCO)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Aged Copper (OBE, EFCO, USA)', label: 'Aged Copper (OBE, EFCO, USA)' },
  { value: 'Copper Penny (OBE)', label: 'Copper Penny (OBE)' },
  { value: 'Champagne (EFCO, USA)', label: 'Champagne (EFCO, USA)' },
  { value: 'Champagne Gold (OBE)', label: 'Champagne Gold (OBE)' },
  { value: 'Gold (USA)', label: 'Gold (USA)' },
  { value: 'Atlantic Gray (KAW)', label: 'Atlantic Gray (KAW)' },
  { value: 'Charcoal (YKK, KAW, USA)', label: 'Charcoal (YKK, KAW, USA)' },
  { value: 'Charcoal Gray (OBE)', label: 'Charcoal Gray (OBE)' },
  { value: 'Dark Gray (TW)', label: 'Dark Gray (TW)' },
  { value: 'Dove Gray (KAW)', label: 'Dove Gray (KAW)' },
  { value: 'Fashion Gray (OBE)', label: 'Fashion Gray (OBE)' },
  { value: 'Grey (EFCO)', label: 'Grey (EFCO)' },
  { value: 'Gun Barrel (EFCO)', label: 'Gun Barrel (EFCO)' },
  { value: 'Light Gray (KAW, TW)', label: 'Light Gray (KAW, TW)' },
  { value: 'Night Hawk Gray (KAW)', label: 'Night Hawk Gray (KAW)' },
  { value: 'Smoke Gray (KAW)', label: 'Smoke Gray (KAW)' },
  { value: 'Stone Gray (USA)', label: 'Stone Gray (USA)' },
  { value: 'Dark Green (KAW, TW)', label: 'Dark Green (KAW, TW)' },
  { value: 'Hartford Green (YKK, KAW, EFCO, USA)', label: 'Hartford Green (YKK, KAW, EFCO, USA)' },
  { value: 'Interstate Green (OBE, KAW)', label: 'Interstate Green (OBE, KAW)' },
  { value: 'Light Green (KAW, TW)', label: 'Light Green (KAW, TW)' },
  { value: 'Sea Spray (EFCO, USA)', label: 'Sea Spray (EFCO, USA)' },
  { value: 'Teal (USA)', label: 'Teal (USA)' },
  { value: 'Ivory (KAW)', label: 'Ivory (KAW)' },
  { value: 'Ivy (EFCO, USA)', label: 'Ivy (EFCO, USA)' },
  { value: 'Dark Ivy (KAW)', label: 'Dark Ivy (KAW)' },
  { value: 'Pewter (OBE)', label: 'Pewter (OBE)' },
  { value: 'Portland Stone (OBE)', label: 'Portland Stone (OBE)' },
  { value: 'Pueblo Tan (OBE, EFCO, USA)', label: 'Pueblo Tan (OBE, EFCO, USA)' },
  { value: 'Brick Red (KAW)', label: 'Brick Red (KAW)' },
  { value: 'Claret Red (KAW)', label: 'Claret Red (KAW)' },
  { value: 'Colonial Red (YKK)', label: 'Colonial Red (YKK)' },
  { value: 'Regal Red (EFCO)', label: 'Regal Red (EFCO)' },
  { value: 'Rust Red (TW)', label: 'Rust Red (TW)' },
  { value: 'Redwood (OBE, KAW, EFCO, USA)', label: 'Redwood (OBE, KAW, EFCO, USA)' },
  { value: 'Sandstone (OBE, YKK, KAW, USA, TW)', label: 'Sandstone (OBE, YKK, KAW, USA, TW)' },
  { value: 'Sea Wolf (KAW)', label: 'Sea Wolf (KAW)' },
  { value: 'Bright Silver (OBE)', label: 'Bright Silver (OBE)' },
  { value: 'Warm Silver (EFCO, USA)', label: 'Warm Silver (EFCO, USA)' },
  { value: 'Terra Cotta (OBE)', label: 'Terra Cotta (OBE)' },
  { value: 'Summer Yellow (KAW)', label: 'Summer Yellow (KAW)' },
];

export const ndhmdDoorCloserOptions = [
  { label: 'Surface Mounted (standard)', value: 'Surface Mounted (standard)' },
  { label: 'Surface Mounted (heavy duty)', value: 'Surface Mounted (heavy duty)' },
  {
    label: 'Surface Mounted + Drop Plate (standard)',
    value: 'Surface Mounted + Drop Plate (standard)',
  },
  {
    label: 'Surface Mounted + Drop Plate (heavy duty)',
    value: 'Surface Mounted + Drop Plate (heavy duty)',
  },
  { label: 'Surface Mounted (other see notes)', value: 'Surface Mounted (other see notes)' },
  { label: 'Concealed Overhead (standard)', value: 'Concealed Overhead (standard)' },
  {
    label: 'Concealed Overhead 90 (No-Hold Open)',
    value: 'Concealed Overhead 90 (No-Hold Open)',
  },
  { label: 'Concealed Overhead 90 (Hold Open)', value: 'Concealed Overhead 90 (Hold Open)' },
  {
    label: 'Concealed Overhead 105 (No-Hold Open)',
    value: 'Concealed Overhead 105 (No-Hold Open)',
  },
  {
    label: 'Concealed Overhead 105 (Hold Open)',
    value: 'Concealed Overhead 105 (Hold Open)',
  },
  {
    label: 'Concealed Overhead (other see notes)',
    value: 'Concealed Overhead (other see notes)',
  },
  { label: 'Floor Closer (standard)', value: 'Floor Closer (standard)' },
  { label: 'Floor Closer (Non-Hold Open)', value: 'Floor Closer (Non-Hold Open)' },
  { label: 'Floor Closer (Hold Open)', value: 'Floor Closer (Hold Open)' },
  { label: 'Floor Closer (other see notes)', value: 'Floor Closer (other see notes)' },
  { label: 'Automatic - Concealed', value: 'Automatic - Concealed' },
  { label: 'Automatic - Surface Mounted', value: 'Automatic - Surface Mounted' },
  { label: 'Automatic - Floor', value: 'Automatic - Floor' },
  { label: 'Automatic - Slider (By Pass)', value: 'Automatic - Slider (By Pass)' },
  { label: 'Automatic - Slider (By Part)', value: 'Automatic - Slider (By Part)' },
  {
    label: 'Automatic - Left.Concealed + Right.Standard Closer',
    value: 'Automatic - Left.Concealed + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Concealed + Left.Standard Closer',
    value: 'Automatic - Right.Concealed + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
    value: 'Automatic - Left.Surface Mounted + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
    value: 'Automatic - Right.Surface Mounted + Left.Standard Closer',
  },
  {
    label: 'Automatic - Left.Floor + Right.Standard Closer',
    value: 'Automatic - Left.Floor + Right.Standard Closer',
  },
  {
    label: 'Automatic - Right.Floor + Left.Standard Closer',
    value: 'Automatic - Right.Floor + Left.Standard Closer',
  },
  { label: 'Automatic: Slider (By Pass)', value: 'Automatic: Slider (By Pass)' },
  { label: 'Automatic: Slider (By Part)', value: 'Automatic: Slider (By Part)' },
  { label: 'Automatic - PAIR - Concealed', value: 'Automatic - PAIR - Concealed' },
  {
    label: 'Automatic - PAIR - Surface Mounted',
    value: 'Automatic - PAIR - Surface Mounted',
  },
  { label: 'Automatic - PAIR - Floor', value: 'Automatic - PAIR - Floor' },
  {
    label: 'Automatic - PAIR - Slider (By Pass)',
    value: 'Automatic - PAIR - Slider (By Pass)',
  },
  {
    label: 'Automatic - PAIR - Slider (By Part)',
    value: 'Automatic - PAIR - Slider (By Part)',
  },
  { label: 'Automatic (other see notes)', value: 'Automatic (other see notes)' },
  { label: 'Standard (per door type)', value: 'Standard (per door type)' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
  { label: 'No Closer ', value: 'No Closer ' },
];

export const ndhmdRoomTypeOptions = [
  {
    value: 'Apartment/Condo - Building Main Entrance',
    label: 'Apartment/Condo - Building Main Entrance',
  },
  { value: 'Apartment/Condo - Unit Entrance', label: 'Apartment/Condo - Unit Entrance' },
  { value: 'Apartment/Condo - Unit Bedroom', label: 'Apartment/Condo - Unit Bedroom' },
  { value: 'Apartment/Condo - Bathroom', label: 'Apartment/Condo - Bathroom' },
  { value: 'Apartment/Condo - Closet', label: 'Apartment/Condo - Closet' },
  { value: 'Apartment/Condo - Common Stairwell', label: 'Apartment/Condo - Common Stairwell' },
  {
    value: 'Apartment/Condo - Building Mechanical Room',
    label: 'Apartment/Condo - Building Mechanical Room',
  },
  {
    value: 'Apartment/Condo - Research (see notes)',
    label: 'Apartment/Condo - Research (see notes)',
  },
  { value: 'Apartment/Condo - Other (see notes)', label: 'Apartment/Condo - Other (see notes)' },
  { value: 'Office - Main Entrance', label: 'Office - Main Entrance' },
  { value: 'Office - Individual Office', label: 'Office - Individual Office' },
  { value: 'Office - Closet', label: 'Office - Closet' },
  { value: 'Office - Toilet', label: 'Office - Toilet' },
  { value: 'Office - Stairwell', label: 'Office - Stairwell' },
  { value: 'Office - Equipment', label: 'Office - Equipment' },
  { value: 'Office - Boiler', label: 'Office - Boiler' },
  { value: 'Office - Research (see notes)', label: 'Office - Research (see notes)' },
  { value: 'Office - Other (see notes)', label: 'Office - Other (see notes)' },
  { value: 'Hotel - Building Main Entrance', label: 'Hotel - Building Main Entrance' },
  { value: 'Hotel - Unit Entrance', label: 'Hotel - Unit Entrance' },
  { value: 'Hotel - Bathroom', label: 'Hotel - Bathroom' },
  { value: 'Hotel - Closet', label: 'Hotel - Closet' },
  { value: 'Hotel - Common Stairwell', label: 'Hotel - Common Stairwell' },
  { value: 'Hotel - Common Storage & Utility', label: 'Hotel - Common Storage & Utility' },
  { value: 'Hotel - Research (see notes)', label: 'Hotel - Research (see notes)' },
  { value: 'Hotel - Other (see notes)', label: 'Hotel - Other (see notes)' },
  { value: 'Medical - Main Entrance', label: 'Medical - Main Entrance' },
  { value: 'Medical - Patient Room', label: 'Medical - Patient Room' },
  { value: 'Medical - Stairwell', label: 'Medical - Stairwell' },
  { value: 'Medical - Operating & Exam', label: 'Medical - Operating & Exam' },
  { value: 'Medical - Bathroom', label: 'Medical - Bathroom' },
  { value: 'Medical - Closet', label: 'Medical - Closet' },
  { value: 'Medical - Recreation', label: 'Medical - Recreation' },
  { value: 'Medical - Kitchen', label: 'Medical - Kitchen' },
  { value: 'Medical - Mechanical/Storage/Utility', label: 'Medical - Mechanical/Storage/Utility' },
  { value: 'Medical - Research (see notes)', label: 'Medical - Research (see notes)' },
  { value: 'Medical - Other (see notes)', label: 'Medical - Other (see notes)' },
  { value: 'School - Entrance & Exit', label: 'School - Entrance & Exit' },
  { value: 'School - Classroom', label: 'School - Classroom' },
  { value: 'School - Toilet', label: 'School - Toilet' },
  { value: 'School - Gymnasium', label: 'School - Gymnasium' },
  { value: 'School - Cafeteria', label: 'School - Cafeteria' },
  { value: 'School - Stairwell', label: 'School - Stairwell' },
  { value: 'School - Closet', label: 'School - Closet' },
  { value: 'School - Research (see notes)', label: 'School - Research (see notes)' },
  { value: 'School - Other (see notes)', label: 'School - Other (see notes)' },
  { value: 'Industrial - Entrance & Exit', label: 'Industrial - Entrance & Exit' },
  { value: 'Industrial - Office', label: 'Industrial - Office' },
  { value: 'Industrial - Production', label: 'Industrial - Production' },
  { value: 'Industrial - Toilet', label: 'Industrial - Toilet' },
  { value: 'Industrial - Tool', label: 'Industrial - Tool' },
  { value: 'Industrial - Trucking', label: 'Industrial - Trucking' },
  { value: 'Industrial - Monorail', label: 'Industrial - Monorail' },
  { value: 'Industrial - Research (see notes)', label: 'Industrial - Research (see notes)' },
  { value: 'Industrial - Other (see notes)', label: 'Industrial - Other (see notes)' },
  { value: 'Dormitory - Building Main Entrance', label: 'Dormitory - Building Main Entrance' },
  { value: 'Dormitory - Unit Entrance', label: 'Dormitory - Unit Entrance' },
  { value: 'Dormitory - Unit Bedroom', label: 'Dormitory - Unit Bedroom' },
  { value: 'Dormitory - Bathroom', label: 'Dormitory - Bathroom' },
  { value: 'Dormitory - Closet', label: 'Dormitory - Closet' },
  { value: 'Dormitory - Common Stairwell', label: 'Dormitory - Common Stairwell' },
  { value: 'Dormitory - Research (see notes)', label: 'Dormitory - Research (see notes)' },
  { value: 'Dormitory - Other (see notes)', label: 'Dormitory - Other (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdLocksetFunctionOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  { value: 'LEVER - Entry', label: 'LEVER - Entry' },
  { value: 'LEVER - Passage Set', label: 'LEVER - Passage Set' },
  { value: 'LEVER - Privacy Set', label: 'LEVER - Privacy Set' },
  { value: 'LEVER - Storeroom Locks', label: 'LEVER - Storeroom Locks' },
  { value: 'LEVER - Office Locks', label: 'LEVER - Office Locks' },
  { value: 'LEVER - Classroom Lock', label: 'LEVER - Classroom Lock' },
  { value: 'LEVER - Classroom Security Lock', label: 'LEVER - Classroom Security Lock' },
  { value: 'LEVER - Electrified – Fail Safe', label: 'LEVER - Electrified – Fail Safe' },
  { value: 'LEVER - Electrified – Fail Secure', label: 'LEVER - Electrified – Fail Secure' },
  { value: 'LEVER - Dummy', label: 'LEVER - Dummy' },
  { value: 'LEVER HANDLE + MORTISE LOCK - Entry', label: 'LEVER HANDLE + MORTISE LOCK - Entry' },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Passage Set',
    label: 'LEVER HANDLE + MORTISE LOCK - Passage Set',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Privacy Set',
    label: 'LEVER HANDLE + MORTISE LOCK - Privacy Set',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Storeroom Locks',
    label: 'LEVER HANDLE + MORTISE LOCK - Storeroom Locks',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Office Locks',
    label: 'LEVER HANDLE + MORTISE LOCK - Office Locks',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Classroom Locks',
    label: 'LEVER HANDLE + MORTISE LOCK - Classroom Locks',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Classroom Security Locks',
    label: 'LEVER HANDLE + MORTISE LOCK - Classroom Security Locks',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Electrified – Fail Safe',
    label: 'LEVER HANDLE + MORTISE LOCK - Electrified – Fail Safe',
  },
  {
    value: 'LEVER HANDLE + MORTISE LOCK - Electrified – Fail Secure',
    label: 'LEVER HANDLE + MORTISE LOCK - Electrified – Fail Secure',
  },
  { value: 'LEVER HANDLE + MORTISE LOCK – Dummy', label: 'LEVER HANDLE + MORTISE LOCK – Dummy' },
  { value: 'KNOB - Entry', label: 'KNOB - Entry' },
  { value: 'KNOB - Passage Set', label: 'KNOB - Passage Set' },
  { value: 'KNOB - Privacy Set', label: 'KNOB - Privacy Set' },
  { value: 'KNOB - Storeroom Lock', label: 'KNOB - Storeroom Lock' },
  { value: 'KNOB - Office Locks', label: 'KNOB - Office Locks' },
  { value: 'KNOB - Classroom Lock', label: 'KNOB - Classroom Lock' },
  { value: 'KNOB - Classroom Security Lock', label: 'KNOB - Classroom Security Lock' },
  { value: 'KNOB - Electrified – Fail Safe', label: 'KNOB - Electrified – Fail Safe' },
  { value: 'KNOB - Electrified – Fail Secure', label: 'KNOB - Electrified – Fail Secure' },
  { value: 'KNOB – Dummy', label: 'KNOB – Dummy' },
  { value: 'KNOB HANDLE + MORTISE LOCK - Entry', label: 'KNOB HANDLE + MORTISE LOCK - Entry' },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Passage Set',
    label: 'KNOB HANDLE + MORTISE LOCK - Passage Set',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Privacy Set',
    label: 'KNOB HANDLE + MORTISE LOCK - Privacy Set',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Storeroom Lock',
    label: 'KNOB HANDLE + MORTISE LOCK - Storeroom Lock',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Office Lock',
    label: 'KNOB HANDLE + MORTISE LOCK - Office Lock',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Classroom Lock',
    label: 'KNOB HANDLE + MORTISE LOCK - Classroom Lock',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Classroom Security Lock',
    label: 'KNOB HANDLE + MORTISE LOCK - Classroom Security Lock',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Electrified – Fail Safe',
    label: 'KNOB HANDLE + MORTISE LOCK - Electrified – Fail Safe',
  },
  {
    value: 'KNOB HANDLE + MORTISE LOCK - Electrified – Fail Secure',
    label: 'KNOB HANDLE + MORTISE LOCK - Electrified – Fail Secure',
  },
  { value: 'KNOB HANDLE + MORTISE LOCK - Dummy', label: 'KNOB HANDLE + MORTISE LOCK - Dummy' },
  { value: 'Push/Pull Handle ONLY', label: 'Push/Pull Handle ONLY' },
  { value: 'C Pull with Plate', label: 'C Pull with Plate' },
];

export const ndhmdDeadBoltOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'KEYED INSIDE ONLY', label: 'KEYED INSIDE ONLY' },
  { value: 'KEYED OUTSIDE ONLY', label: 'KEYED OUTSIDE ONLY' },
  { value: 'KEYED INSIDE + OUTSIDE', label: 'KEYED INSIDE + OUTSIDE' },
  { value: 'THUMB TURN INSIDE + KEY OUTSIDE', label: 'THUMB TURN INSIDE + KEY OUTSIDE' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdDoorSweepWeatherStrippingOptions = [
  { value: 'Bottom Sweep', label: 'Bottom Sweep' },
  { value: 'Bottom Sweep + Astragal', label: 'Bottom Sweep + Astragal' },
  { value: 'Bottom Sweep + Frame Perimeter', label: 'Bottom Sweep + Frame Perimeter' },
  { value: 'Bottom Neoprene  Sweep', label: 'Bottom Neoprene  Sweep' },
  { value: 'Bottom Vinyl Sweep', label: 'Bottom Vinyl Sweep' },
  { value: 'Bottom Brush Sweep', label: 'Bottom Brush Sweep' },
  { value: 'Bottom Neoprene Sweep (fire rated)', label: 'Bottom Neoprene Sweep (fire rated)' },
  { value: 'Vinyl Sweep (fire rated)', label: 'Vinyl Sweep (fire rated)' },
  { value: 'Bottom Brush Sweep (fire rated)', label: 'Bottom Brush Sweep (fire rated)' },
  { value: 'Nylon Brush Astragal Kit', label: 'Nylon Brush Astragal Kit' },
  {
    value: 'Nylon Brush Astragal Kit (fire rated)',
    label: 'Nylon Brush Astragal Kit (fire rated)',
  },
  { value: 'Silicone Astragal Kit', label: 'Silicone Astragal Kit' },
  { value: 'Silicone Astragal Kit (fire rated)', label: 'Silicone Astragal Kit (fire rated)' },
  {
    value: 'Screw On Perimeter Brush (door frame)',
    label: 'Screw On Perimeter Brush (door frame)',
  },
  { value: 'Screw On Perimeter Bulb', label: 'Screw On Perimeter Bulb' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Research', label: 'Research' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdFrameProfileOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'Case Open', label: 'Case Open' },
  { value: 'Case Open w/ Drywall Returns', label: 'Case Open w/ Drywall Returns' },
  { value: 'Rabbet - Double (EQUAL)', label: 'Rabbet - Double (EQUAL)' },
  { value: 'Rabbet - Single (EQUAL)', label: 'Rabbet - Single (EQUAL)' },
  {
    value: 'Rabbet - Single Rabbet w/ Drywall Returns (EQUAL)',
    label: 'Rabbet - Single Rabbet w/ Drywall Returns (EQUAL)',
  },
  {
    value: 'Rabbet -Double  w/ Dry Wall Returns (EQUAL)',
    label: 'Rabbet -Double  w/ Dry Wall Returns (EQUAL)',
  },
  { value: 'Rabbet - Double (UN-EQUAL)', label: 'Rabbet - Double (UN-EQUAL)' },
  { value: 'Rabbet - Single (UN-EQUAL)', label: 'Rabbet - Single (UN-EQUAL)' },
  {
    value: 'Rabbet - Single Rabbet w/ Drywall Returns (UN-EQUAL)',
    label: 'Rabbet - Single Rabbet w/ Drywall Returns (UN-EQUAL)',
  },
  {
    value: 'Rabbet -Double  w/ Dry Wall Returns (UN-EQUAL)',
    label: 'Rabbet -Double  w/ Dry Wall Returns (UN-EQUAL)',
  },
  { value: 'Double Egress', label: 'Double Egress' },
  { value: 'Mullion', label: 'Mullion' },
  { value: 'Shadow Line', label: 'Shadow Line' },
  { value: 'Sub-Buck', label: 'Sub-Buck' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdFrameThicknessOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: '3 1/2 - THROAT', label: '3 1/2 - THROAT' },
  { value: '3 3/4 - THROAT', label: '3 3/4 - THROAT' },
  { value: '4 3/4 - THROAT', label: '4 3/4 - THROAT' },
  { value: '4 5/8 - THROAT', label: '4 5/8 - THROAT' },
  { value: '4 7/8 - THROAT', label: '4 7/8 - THROAT' },
  { value: '5 1/4 - THROAT', label: '5 1/4 - THROAT' },
  { value: '5 3/4 - THROAT', label: '5 3/4 - THROAT' },
  { value: '6 1/4 - THROAT', label: '6 1/4 - THROAT' },
  { value: '6 3/4 - THROAT', label: '6 3/4 - THROAT' },
  { value: '7 1/4 - THROAT ', label: '7 1/4 - THROAT ' },
  { value: '7 3/4 - THROAT', label: '7 3/4 - THROAT' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdFrameFabricationOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'Drywall - Knock Down', label: 'Drywall - Knock Down' },
  { value: 'Drywall - Welded', label: 'Drywall - Welded' },
  { value: 'Masonry - Knock Down', label: 'Masonry - Knock Down' },
  { value: 'Masonry  - Welded', label: 'Masonry  - Welded' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const ndhmdFrameInstallTypeOptions = [
  { value: 'Standard', label: 'Standard' },
  { value: 'Block Wall - Existing', label: 'Block Wall - Existing' },
  { value: 'Block Wall - To be Built', label: 'Block Wall - To be Built' },
  { value: 'Block Wall - Butt Frame', label: 'Block Wall - Butt Frame' },
  { value: 'Block Wall - Flush Frame', label: 'Block Wall - Flush Frame' },
  { value: 'Wrapped Wall Frame - Existing', label: 'Wrapped Wall Frame - Existing' },
  { value: 'Wrapped Wall Frame - To be Built', label: 'Wrapped Wall Frame - To be Built' },
  { value: 'Unknown (see notes)', label: 'Unknown (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const pdFinishB = [
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Custom', label: 'Custom' },

  { value: 'Clear Anodized (Standard  .4 mils)', label: 'Clear Anodized (Standard  .4 mils)' },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Almond (TW)', label: 'Almond (TW)' },
  { value: 'Antique White (EFCO)', label: 'Antique White (EFCO)' },
  {
    value: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
    label: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
  },
  { value: 'Colonial White (OBE, YKK)', label: 'Colonial White (OBE, YKK)' },
  { value: 'Crème (TW)', label: 'Crème (TW)' },
  { value: 'Herring Bone (EFCO)', label: 'Herring Bone (EFCO)' },
  { value: 'Milk White (KAW)', label: 'Milk White (KAW)' },
  { value: 'Stone White (USA)', label: 'Stone White (USA)' },
  { value: 'White (EFCO, TW)', label: 'White (EFCO, TW)' },
  { value: 'Beige (OBE, YKK)', label: 'Beige (OBE, YKK)' },
  { value: 'Seawolf Beige(YKK)', label: 'Seawolf Beige(YKK)' },
  { value: 'Black (OBE, YKK, KAW, EFCO, USA, TW)', label: 'Black (OBE, YKK, KAW, EFCO, USA, TW)' },
  { value: 'Deep Blue (KAW)', label: 'Deep Blue (KAW)' },
  { value: 'Blue Sky (EFCO)', label: 'Blue Sky (EFCO)' },
  { value: 'Legacy Blue (EFCO)', label: 'Legacy Blue (EFCO)' },
  { value: 'Medium Blue (OBE, KAW, TW)', label: 'Medium Blue (OBE, KAW, TW)' },
  { value: 'Midnight Blue (KAW, EFCO)', label: 'Midnight Blue (KAW, EFCO)' },
  { value: 'Military Blue (OBE, KAW, USA)', label: 'Military Blue (OBE, KAW, USA)' },
  { value: 'Regal Blue (OBE, USA)', label: 'Regal Blue (OBE, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Bronze (THW)', label: 'Bronze (THW)' },
  { value: 'Classic Bronze (YKK, KAW, USA)', label: 'Classic Bronze (YKK, KAW, USA)' },
  { value: 'Light Bronze (USA)', label: 'Light Bronze (USA)' },
  { value: 'Medium Bronze (KAW, USA, THW)', label: 'Medium Bronze (KAW, USA, THW)' },
  { value: 'Brown (OBE)', label: 'Brown (OBE)' },
  { value: 'Java Brown (KAW)', label: 'Java Brown (KAW)' },
  { value: 'Light Brown (EFCO)', label: 'Light Brown (EFCO)' },
  { value: 'Mesa Brown (EFCO)', label: 'Mesa Brown (EFCO)' },
  { value: 'Mineral Brown (EFCO)', label: 'Mineral Brown (EFCO)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Aged Copper (OBE, EFCO, USA)', label: 'Aged Copper (OBE, EFCO, USA)' },
  { value: 'Copper Penny (OBE)', label: 'Copper Penny (OBE)' },
  { value: 'Champagne (EFCO, USA)', label: 'Champagne (EFCO, USA)' },
  { value: 'Champagne Gold (OBE)', label: 'Champagne Gold (OBE)' },
  { value: 'Gold (USA)', label: 'Gold (USA)' },
  { value: 'Atlantic Gray (KAW)', label: 'Atlantic Gray (KAW)' },
  { value: 'Charcoal (YKK, KAW, USA)', label: 'Charcoal (YKK, KAW, USA)' },
  { value: 'Charcoal Gray (OBE)', label: 'Charcoal Gray (OBE)' },
  { value: 'Dark Gray (TW)', label: 'Dark Gray (TW)' },
  { value: 'Dove Gray (KAW)', label: 'Dove Gray (KAW)' },
  { value: 'Fashion Gray (OBE)', label: 'Fashion Gray (OBE)' },
  { value: 'Grey (EFCO)', label: 'Grey (EFCO)' },
  { value: 'Gun Barrel (EFCO)', label: 'Gun Barrel (EFCO)' },
  { value: 'Light Gray (KAW, TW)', label: 'Light Gray (KAW, TW)' },
  { value: 'Night Hawk Gray (KAW)', label: 'Night Hawk Gray (KAW)' },
  { value: 'Smoke Gray (KAW)', label: 'Smoke Gray (KAW)' },
  { value: 'Stone Gray (USA)', label: 'Stone Gray (USA)' },
  { value: 'Dark Green (KAW, TW)', label: 'Dark Green (KAW, TW)' },
  { value: 'Hartford Green (YKK, KAW, EFCO, USA)', label: 'Hartford Green (YKK, KAW, EFCO, USA)' },
  { value: 'Interstate Green (OBE, KAW)', label: 'Interstate Green (OBE, KAW)' },
  { value: 'Light Green (KAW, TW)', label: 'Light Green (KAW, TW)' },
  { value: 'Sea Spray (EFCO, USA)', label: 'Sea Spray (EFCO, USA)' },
  { value: 'Teal (USA)', label: 'Teal (USA)' },
  { value: 'Ivory (KAW)', label: 'Ivory (KAW)' },
  { value: 'Ivy (EFCO, USA)', label: 'Ivy (EFCO, USA)' },
  { value: 'Dark Ivy (KAW)', label: 'Dark Ivy (KAW)' },
  { value: 'Pewter (OBE)', label: 'Pewter (OBE)' },
  { value: 'Portland Stone (OBE)', label: 'Portland Stone (OBE)' },
  { value: 'Pueblo Tan (OBE, EFCO, USA)', label: 'Pueblo Tan (OBE, EFCO, USA)' },
  { value: 'Brick Red (KAW)', label: 'Brick Red (KAW)' },
  { value: 'Claret Red (KAW)', label: 'Claret Red (KAW)' },
  { value: 'Colonial Red (YKK)', label: 'Colonial Red (YKK)' },
  { value: 'Regal Red (EFCO)', label: 'Regal Red (EFCO)' },
  { value: 'Rust Red (TW)', label: 'Rust Red (TW)' },
  { value: 'Redwood (OBE, KAW, EFCO, USA)', label: 'Redwood (OBE, KAW, EFCO, USA)' },
  { value: 'Sandstone (OBE, YKK, KAW, USA, TW)', label: 'Sandstone (OBE, YKK, KAW, USA, TW)' },
  { value: 'Sea Wolf (KAW)', label: 'Sea Wolf (KAW)' },
  { value: 'Bright Silver (OBE)', label: 'Bright Silver (OBE)' },
  { value: 'Warm Silver (EFCO, USA)', label: 'Warm Silver (EFCO, USA)' },
  { value: 'Terra Cotta (OBE)', label: 'Terra Cotta (OBE)' },
  { value: 'Summer Yellow (KAW)', label: 'Summer Yellow (KAW)' },
];
export const pdGlassThickness = [
  { value: '3/16" LAMI', label: '3/16" LAMI' },
  { value: '1/4" LAMI', label: '1/4" LAMI' },
  { value: '3/8" LAMI', label: '3/8" LAMI' },
  { value: '5/8" LAMI', label: '5/8" LAMI' },
  { value: 'LAMI (SPECIAL)', label: 'LAMI (SPECIAL)' },
  { value: '3/16" TEMPERED', label: '3/16" TEMPERED' },
  { value: '1/4" TEMPERED', label: '1/4" TEMPERED' },
  { value: '3/8" TEMPERED', label: '3/8" TEMPERED' },
  { value: '5/8" TEMPERED', label: '5/8" TEMPERED' },
  { value: 'TEMPERED (SPECIAL)', label: 'TEMPERED (SPECIAL)' },
  { value: 'IGU (3/16" TEMPERED LITES)', label: 'IGU (3/16" TEMPERED LITES)' },
  { value: 'IGU (1/4" TEMPERED LITES)', label: 'IGU (1/4" TEMPERED LITES)' },
  { value: 'IGU (3/8" TEMPERED LITES)', label: 'IGU (3/8" TEMPERED LITES)' },
  { value: 'IGU (1/2" TEMPERED LITES)', label: 'IGU (1/2" TEMPERED LITES)' },
  { value: 'IGU (SPECIAL)', label: 'IGU (SPECIAL)' },
  { value: 'IGU (3/16" TEMPERED/LAMI LITES)', label: 'IGU (3/16" TEMPERED/LAMI LITES)' },
  { value: 'IGU (1/4" TEMPERED/LAMI LITES)', label: 'IGU (1/4" TEMPERED/LAMI LITES)' },
  { value: 'IGU (3/8" TEMPERED/LAMI LITES)', label: 'IGU (3/8" TEMPERED/LAMI LITES)' },
  { value: 'IGU (1/2" TEMPERED/LAMI LITES)', label: 'IGU (1/2" TEMPERED/LAMI LITES)' },
  { value: 'IGU TEMPERED/LAMI (SPECIAL)', label: 'IGU TEMPERED/LAMI (SPECIAL)' },
  { value: 'DOORS = 3/16" LAMI, WINDOWS = IGU', label: 'DOORS = 3/16" LAMI, WINDOWS = IGU' },
  { value: 'DOORS = 1/4" LAMI, WINDOWS = IGU', label: 'DOORS = 1/4" LAMI, WINDOWS = IGU' },
  { value: 'DOORS = 3/8" LAMI,  WINDOWS = IGU', label: 'DOORS = 3/8" LAMI,  WINDOWS = IGU' },
  { value: 'DOORS = 5/8" LAMI, WINDOWS = IGU', label: 'DOORS = 5/8" LAMI, WINDOWS = IGU' },
  {
    value: 'DOORS = LAMI (SPECIAL), WINDOWS = IGU (SPECIAL)',
    label: 'DOORS = LAMI (SPECIAL), WINDOWS = IGU (SPECIAL)',
  },
  {
    value: 'DOORS = 3/16" TEMPERED, WINDOWS = IGU',
    label: 'DOORS = 3/16" TEMPERED, WINDOWS = IGU',
  },
  { value: 'DOORS = 1/4" TEMPERED, WINDOWS = IGU', label: 'DOORS = 1/4" TEMPERED, WINDOWS = IGU' },
  {
    value: 'DOORS = 3/8" TEMPERED,  WINDOWS = IGU',
    label: 'DOORS = 3/8" TEMPERED,  WINDOWS = IGU',
  },
  { value: 'DOORS = 5/8" TEMPERED, WINDOWS = IGU', label: 'DOORS = 5/8" TEMPERED, WINDOWS = IGU' },
  {
    value: 'DOORS = TEMPERED (SPECIAL), WINDOWS = IGU (SPECIAL)',
    label: 'DOORS = TEMPERED (SPECIAL), WINDOWS = IGU (SPECIAL)',
  },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];
export const pdGlassColor = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Gray', label: 'Gray ' },
  { value: 'Clear MIRROR', label: 'Clear MIRROR' },
  { value: 'Clear MIRROR w/SafetyBacking', label: 'Clear MIRROR w/SafetyBacking' },
  { value: 'Gray MIRROR', label: 'Gray MIRROR' },
  { value: 'Gray MIRROR w/SafetyBacking', label: 'Gray MIRROR w/SafetyBacking' },
  { value: 'Bronze MIRROR', label: 'Bronze MIRROR' },
  { value: 'Bronze MIRROR w/SafetyBacking', label: 'Bronze MIRROR w/SafetyBacking' },
  { value: 'MirrorPane (2WAY)', label: 'MirrorPane (2WAY)' },
  { value: 'MirrorView  (low ambient)', label: 'MirrorView  (low ambient)' },
  { value: 'MirrorView50/50 (high ambient)', label: 'MirrorView50/50 (high ambient)' },
  { value: 'Low Iron ', label: 'Low Iron' },
  { value: 'Starfire ', label: 'Starfire ' },
  { value: 'Optiwhite', label: 'Optiwhite' },
  { value: 'UltraClear', label: 'UltraClear' },
  { value: 'ExtraClear', label: 'ExtraClear' },
  { value: 'Acuity ', label: 'Acuity ' },
  { value: 'OptiGray (light)', label: 'OptiGray (light)' },
  { value: 'CrystalGray (light)', label: 'CrystalGray (light)' },
  { value: 'Majestic Grey (light)', label: 'Majestic Grey (light)' },
  { value: 'Graylite2 (black)', label: 'Graylite2 (black)' },
  { value: 'SuperGray (black)', label: 'SuperGray (black)' },
  { value: 'Midnight Gray (black) ', label: 'CleaMidnight Gray (black)' },
  { value: 'White', label: 'White' },
  { value: 'Acid/Satin Etch', label: 'Acid/Satin Etch' },
  { value: 'Acid/Satin Low Iron', label: 'Acid/Satin Low Iron' },
  {
    value: 'Acid/Satin Low Iron (Double Sided)',
    label: 'Acid/Satin Low Iron (Double Sided)',
  },
  { value: 'Green ', label: 'Green ' },
  { value: 'Solexia ', label: 'Solexia ' },
  { value: 'Atlantica', label: 'Atlantica' },
  { value: 'EverGreen', label: 'EverGreen' },
  { value: 'Pure Green ', label: 'Pure Green' },
  { value: 'Forrest Green ', label: 'Forrest Green' },
  { value: 'Blue ', label: 'Blue ' },
  { value: 'BlueGreen', label: 'BlueGreen' },
  { value: 'Azuria ', label: 'Azuria ' },
  { value: 'Pacifica ', label: 'Pacifica ' },
  { value: 'SolarBlue', label: 'SolarBlue' },
  { value: 'OptiBlue ', label: 'OptiBlue ' },
  { value: 'ArticBlue', label: 'ArticBlue' },
  { value: 'GraphiteBlue ', label: 'GraphiteBlue ' },
  { value: 'CrystalBlue', label: 'CrystalBlue' },
  { value: 'Sky Blue ', label: 'Sky Blue' },
  { value: 'Midnight Blue ', label: 'Midnight Blue' },
  { value: 'Pure Blue ', label: 'Pure Blue' },
  { value: 'Gold', label: 'Gold' },
  { value: 'SunsetGold', label: 'SunsetGold' },
  { value: '1-CLEAR', label: '1-CLEAR' },
  { value: '2-GREEN', label: '2-GREEN' },
  { value: '3-GRAY', label: '3-GRAY' },
  { value: '4-BRONZE', label: '4-BRONZE' },
  { value: '6-BLUE.GREEN', label: '6-BLUE.GREEN' },
  { value: '7-AZURIA', label: '7-AZURIA' },
  { value: '13-STARFIRE (low iron)', label: '13-STARFIRE (low iron)' },
  { value: '18- OPTIBLUE', label: '18- OPTIBLUE' },
  { value: '19-CRYSTALGRAY', label: '19-CRYSTALGRAY' },
  { value: '24-OPTIWHITE (low iron)', label: '24-OPTIWHITE (low iron)' },
  { value: '26-SOLARBLUE', label: '26-SOLARBLUE' },
  { value: '27-PACIFICA', label: '27-PACIFICA' },
  { value: '30-OPTIGRAY', label: '30-OPTIGRAY' },
  { value: '31-ULTRACLEAR (LOW IRON)', label: '31-ULTRACLEAR (LOW IRON)' },
  { value: '32-CRYSTALBLUE', label: '32-CRYSTALBLUE' },
  { value: '33-MAJESTICGREY', label: '33-MAJESTICGREY' },
  { value: '35- Pure Mid Iron', label: '35- Pure Mid Iron' },
  { value: '5-BLUE (NOT AVAILABLE) ', label: '5-BLUE (NOT AVAILABLE)' },
  { value: '8-EVERGREEN (NOT AVAILABLE) ', label: '8-EVERGREEN (NOT AVAILABLE)' },
  { value: '29-GRAPHITE (NOT AVAILABLE) ', label: '29-GRAPHITE (NOT AVAILABLE)' },
  { value: 'FireLite (20-90MIN)', label: 'FireLite (20-90MIN)' },
  { value: 'FireLitePlus (20-180MIN)', label: 'FireLitePlus (20-180MIN)' },
  { value: 'FireliteNT (20-180MIN)', label: 'FireliteNT (20-180MIN)' },
  { value: 'FireliteIGU (20-180MIN)', label: 'FireliteIGU (20-180MIN)' },
  { value: 'Wire - SQUARE Polished ', label: 'Wire - SQUARE Polished' },
  { value: 'Wire - SQUARE Patterned ', label: 'Wire - SQUARE Patterned' },
  { value: 'Wire - DIAMOND Polished ', label: 'Wire - DIAMOND Polished' },
  { value: 'Wire - DIAMOND Patterned ', label: 'Wire - DIAMOND Patterned' },
  { value: 'Wirelite (FIRE 45Min) ', label: 'Wirelite (FIRE 45Min)' },
  { value: 'Wirelite (FIRE 20-90Min) ', label: 'Wirelite (FIRE 20-90Min)' },
  { value: 'Altdeutsch K', label: 'Altdeutsch K' },
  { value: 'Antique', label: 'Antique' },
  { value: 'Aqualite', label: 'Aqualite' },
  { value: 'Aquatex', label: 'Aquatex' },
  { value: 'Arctic', label: 'Arctic' },
  { value: 'Artico', label: 'Artico' },
  { value: 'Austral ', label: 'Austral' },
  { value: 'Autumn', label: 'Autumn' },
  { value: 'Bamboo', label: 'Bamboo' },
  { value: 'Bubbles', label: 'Bubbles' },
  { value: 'Cast', label: 'Cast' },
  { value: 'Chantilly ', label: 'Chantilly' },
  { value: 'Charcoal Sticks ', label: 'Charcoal Sticks' },
  { value: 'Chinchilla', label: 'Chinchilla' },
  { value: 'Clear Antique', label: 'Clear Antique' },
  { value: 'Contora ', label: 'Contora' },
  { value: 'Corduroy', label: 'Corduroy' },
  { value: 'Cotswold ', label: 'Cotswold' },
  { value: 'Cross Reeded 1/2"', label: 'Cross Reeded 1/2"' },
  { value: 'Cross Reeded Small', label: 'Cross Reeded Small' },
  { value: 'Delta Frost', label: 'Delta Frost' },
  { value: 'Digital ', label: 'Digital' },
  { value: 'Everglade ', label: 'Everglade' },
  { value: 'Flemish', label: 'Flemish' },
  { value: 'Florielle ', label: 'Florielle' },
  { value: 'Frost Etch 100', label: 'Frost Etch 100' },
  { value: 'Frost Etch 20', label: 'Frost Etch 20' },
  { value: 'Glacier', label: 'Glacier' },
  { value: 'Glue Chip', label: 'Glue Chip' },
  { value: 'Master Carre', label: 'Master Carre' },
  { value: 'Master Ligne', label: 'Master Ligne' },
  { value: 'Master Point', label: 'Master Point' },
  { value: 'Master Ray', label: 'Master Ray' },
  { value: 'Mayflower', label: 'Mayflower' },
  { value: 'Minister', label: 'Minister' },
  { value: 'Monumental', label: 'Monumental' },
  { value: 'Morisco', label: 'Morisco' },
  { value: 'Oak ', label: 'Oak' },
  { value: 'Pattern 516', label: 'Pattern 516' },
  { value: 'Pattern 62', label: 'Pattern 62' },
  { value: 'Pelerine', label: 'Pelerine' },
  { value: 'Rain', label: 'Rain' },
  { value: 'Rayado', label: 'Rayado' },
  { value: 'Redded 1/2"', label: 'Redded 1/2"' },
  { value: 'Reeded', label: 'Reeded' },
  { value: 'Seedy', label: 'Seedy' },
  { value: 'Seedy Import', label: 'Seedy Import' },
  { value: 'Seedy Marine', label: 'Seedy Marine' },
  { value: 'Skytech ', label: 'Skytech' },
  { value: 'Soft Hammered', label: 'Soft Hammered' },
  { value: 'Sparkle', label: 'Sparkle' },
  { value: 'Spraylite', label: 'Spraylite' },
  { value: 'Stipolite', label: 'Stipolite' },
  { value: 'Sycamore ', label: 'Sycamore' },
  { value: 'Taffeta', label: 'Taffeta' },
  { value: 'Textured Flutes', label: 'Textured Flutes' },
  { value: 'Thin Cross Reeded 1/8"', label: 'Thin Cross Reeded 1/8"' },
  { value: 'Thin Reeded 1/8"', label: 'Thin Reeded 1/8"' },
  { value: 'Thin Ribbed', label: 'Thin Ribbed' },
  { value: 'Warwick', label: 'Warwick' },
  { value: 'Waterwave', label: 'Waterwave' },
  { value: 'Winterlake', label: 'Winterlake' },
  { value: 'Yacare', label: 'Yacare' },
  { value: 'Azzurro (Antique Mirror)', label: 'Azzurro (Antique Mirror)' },
  { value: 'Biranco.E.Nero (Antique Mirror)', label: 'Clear' },
  { value: 'Byzantine (Antique Mirror)', label: 'Byzantine (Antique Mirror)' },
  { value: 'Cometa (Antique Mirror)', label: 'Cometa (Antique Mirror)' },
  { value: 'Golden (Antique Mirror)', label: 'Golden (Antique Mirror)' },
  { value: 'Grigio Argento (Antique Mirror)', label: 'Grigio Argento (Antique Mirror)' },
  { value: 'Luna (Antique Mirror)', label: 'Luna (Antique Mirror)' },
  { value: 'Marbled (Antique Mirror)', label: 'Marbled (Antique Mirror)' },
  { value: 'Marbled Bronze (Antique Mirror)', label: 'Marbled Bronze (Antique Mirror)' },
  { value: 'Mercury (Antique Mirror)', label: 'Mercury (Antique Mirror)' },
  { value: 'Meterora (Antique Mirror)', label: 'Meterora (Antique Mirror)' },
  { value: 'Policromo (Antique Mirror)', label: 'Policromo (Antique Mirror)' },
  { value: 'Saturno (Antique Mirror)', label: 'Saturno (Antique Mirror)' },
  { value: 'Speckled (Antique Mirror)', label: 'Speckled (Antique Mirror)' },
  { value: 'Tuscana (Antique Mirror)', label: 'Tuscana (Antique Mirror)' },
  { value: 'Vintage (Antique Mirror)', label: 'Vintage (Antique Mirror)' },
  { value: 'Vintage Bronze (Antique Mirror)', label: 'Vintage Bronze (Antique Mirror)' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdGlassCoatingOptions = [
  { value: 'Low-E', label: 'Low-E' },
  { value: 'Solarban60', label: 'Solarban60' },
  { value: 'SolarbanZ50', label: 'SolarbanZ50' },
  { value: 'Solarban67', label: 'Solarban67' },
  { value: 'Solarban70', label: 'Solarban70' },
  { value: 'Solarban72', label: 'Solarban72' },
  { value: 'SolarbanZ75', label: 'SolarbanZ75' },
  { value: 'Solarban90', label: 'Solarban90' },
  { value: 'SolarbanR100', label: 'SolarbanR100' },
  { value: 'Sungate400', label: 'Sungate400' },
  { value: 'SolarCool', label: 'SolarCool' },
  { value: 'VistaCool', label: 'VistaCool' },
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VRE-4725', label: 'VRE-4725' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-30', label: 'VUE-30' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-39', label: 'VLE-39' },
  { value: 'VT-08 (RETIRED)', label: 'VT-08 (RETIRED)' },
  { value: 'VT-14 (RETIRED)', label: 'VT-14 (RETIRED)' },
  { value: 'VT-20  (RETIRED)', label: 'VT-20  (RETIRED)' },
  { value: 'VT-24 (RETIRED)', label: 'VT-24 (RETIRED)' },
  { value: 'VT-30 (RETIRED)', label: 'VT-30 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VA-08 (RETIRED)', label: 'VA-08 (RETIRED)' },
  { value: 'VA-14 (RETIRED)', label: 'VA-14 (RETIRED)' },
  { value: 'VA-20 (RETIRED)', label: 'VA-20 (RETIRED)' },
  { value: 'VA-22 (RETIRED)', label: 'VA-22 (RETIRED)' },
  { value: 'VA-24 (RETIRED)', label: 'VA-24 (RETIRED)' },
  { value: 'VA-30 (RETIRED)', label: 'VA-30 (RETIRED)' },
  { value: 'VA-35 (RETIRED)', label: 'VA-35 (RETIRED)' },
  { value: 'VA-40 (RETIRED)', label: 'VA-40 (RETIRED)' },
  { value: 'VB-08 (RETIRED)', label: 'VB-08 (RETIRED)' },
  { value: 'VB-14 (RETIRED)', label: 'VB-14 (RETIRED)' },
  { value: 'VB-20 (RETIRED)', label: 'VB-20 (RETIRED)' },
  { value: 'VB-24 (RETIRED)', label: 'VB-24 (RETIRED)' },
  { value: 'VB-30 (RETIRED)', label: 'VB-30 (RETIRED)' },
  { value: 'VB-35 (RETIRED)', label: 'VB-35 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VC-08 (RETIRED)', label: 'VC-08 (RETIRED)' },
  { value: 'VC-14 (RETIRED)', label: 'VC-14 (RETIRED)' },
  { value: 'VC-20 (RETIRED)', label: 'VC-20 (RETIRED)' },
  { value: 'VC-24 (RETIRED)', label: 'VC-24 (RETIRED)' },
  { value: 'VC-30 (RETIRED)', label: 'VC-30 (RETIRED)' },
  { value: 'VC-35 (RETIRED)', label: 'VC-35 (RETIRED)' },
  { value: 'VC-40 (RETIRED)', label: 'VC-40 (RETIRED)' },
  { value: 'VG-08 (RETIRED)', label: 'VG-08 (RETIRED)' },
  { value: 'VG-14 (RETIRED)', label: 'VG-14 (RETIRED)' },
  { value: 'VG-20 (RETIRED)', label: 'VG-20 (RETIRED)' },
  { value: 'VG-24 (RETIRED)', label: 'VG-24 (RETIRED)' },
  { value: 'VG-30 (RETIRED)', label: 'VG-30 (RETIRED)' },
  { value: 'VG-35 (RETIRED)', label: 'VG-35 (RETIRED)' },
  { value: 'VG-40 (RETIRED)', label: 'VG-40 (RETIRED)' },
  { value: 'VW-08 (RETIRED)', label: 'VW-08 (RETIRED)' },
  { value: 'VW-14 (RETIRED)', label: 'VW-14 (RETIRED)' },
  { value: 'VW-20 (RETIRED)', label: 'VW-20 (RETIRED)' },
  { value: 'VW-24 (RETIRED)', label: 'VW-24 (RETIRED)' },
  { value: 'VW-30 (RETIRED)', label: 'VW-30 (RETIRED)' },
  { value: 'VW-35 (RETIRED)', label: 'VW-35 (RETIRED)' },
  { value: 'VW-40 (RETIRED)', label: 'VW-40 (RETIRED)' },
  { value: 'VSE-30 (RETIRED)', label: 'VSE-30 (RETIRED)' },
  { value: 'Activ', label: 'Activ' },
  { value: 'Eclipse', label: 'Eclipse' },
  { value: 'EclipseAdvantage ', label: 'EclipseAdvantage ' },
  { value: 'EnergyAdvantage ', label: 'EnergyAdvantage ' },
  { value: 'SolarE', label: 'SolarE' },
  { value: 'AG43', label: 'AG43' },
  { value: 'AG50', label: 'AG50' },
  { value: 'SN75', label: 'SN75' },
  { value: 'SN75HT', label: 'SN75HT' },
  { value: 'SN70', label: 'SN70' },
  { value: 'SN70/35', label: 'SN70/35' },
  { value: 'SN70/37', label: 'SN70/37' },
  { value: 'SN68', label: 'SN68' },
  { value: 'SN63', label: 'SN63' },
  { value: 'SN63HT', label: 'SN63HT' },
  { value: 'SN51/28', label: 'SN51/28' },
  { value: 'SN40/23', label: 'SN40/23' },
  { value: 'SN29/18', label: 'SN29/18' },
  { value: 'SNL68', label: 'SNL68' },
  { value: 'SNR43', label: 'SNR43' },
  { value: 'SNX50', label: 'SNX50' },
  { value: 'SNX51/23', label: 'SNX51/23' },
  { value: 'SNX60', label: 'SNX60' },
  { value: 'SNX62/27', label: 'SNX62/27' },
  { value: 'SNX-L62/34', label: 'SNX-L62/34' },
  { value: 'HP LightBlue62/52', label: 'HP LightBlue62/52' },
  { value: 'HP Neutral60/40 ', label: 'HP Neutral60/40 ' },
  { value: 'HP Neutral50/32', label: 'HP Neutral50/32' },
  { value: 'HP Neutral41/33', label: 'HP Neutral41/33' },
  { value: 'Neutral78/65', label: 'Neutral78/65' },
  { value: 'Neutral50', label: 'Neutral50' },
  { value: 'Neutral40', label: 'Neutral40' },
  { value: 'HP Silver43/31', label: 'HP Silver43/31' },
  { value: 'HP RoyalBlue41/29', label: 'HP RoyalBlue41/29' },
  { value: 'HP Amber41/29', label: 'HP Amber41/29' },
  { value: 'HP BrightGreen40/29', label: 'HP BrightGreen40/29' },
  { value: 'HP Bronze40/27', label: 'HP Bronze40/27' },
  { value: 'HP Silver35/26', label: 'HP Silver35/26' },
  { value: 'SolarBronze20', label: 'SolarBronze20' },
  { value: 'SolarGray20', label: 'SolarGray20' },
  { value: 'SolarGold20', label: 'SolarGold20' },
  { value: 'SolarBrightGreen20', label: 'SolarBrightGreen20' },
  { value: 'HD Silver70', label: 'HD Silver70' },
  { value: 'HD Neutral67', label: 'HD Neutral67' },
  { value: 'HD Diamond66', label: 'HD Diamond66' },
  { value: 'HD LightBlue52', label: 'HD LightBlue52' },
  { value: 'HD RoyalBlue20', label: 'HD RoyalBlue20' },
  { value: 'HD SilverGray32', label: 'HD SilverGray32' },
  { value: 'HD Silver20', label: 'HD Silver20' },
  { value: 'HD Silver10', label: 'HD Silver10' },
  { value: 'ClimaGuard53/23', label: 'ClimaGuard53/23' },
  { value: 'ClimaGuard55/27', label: 'ClimaGuard55/27' },
  { value: 'ClimaGuard62/27', label: 'ClimaGuard62/27' },
  { value: 'ClimaGuard70/36', label: 'ClimaGuard70/36' },
  { value: 'ClimaGuard72/57', label: 'ClimaGuard72/57' },
  { value: 'ClimaGuard80/70', label: 'ClimaGuard80/70' },
  { value: 'ClimaGuardiS-20', label: 'ClimaGuardiS-20' },
  { value: 'N70/38 (SB60)', label: 'N70/38 (SB60)' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'R43/28', label: 'R43/28' },
  { value: 'R47/31', label: 'R47/31' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'LoĒ-i81', label: 'LoĒ-i81' },
  { value: 'LoĒ-i88', label: 'LoĒ-i88' },
  { value: 'LoĒ-x89', label: 'LoĒ-x89' },
  { value: 'LoĒ-180', label: 'LoĒ-180' },
  { value: 'LoĒ2-270', label: 'LoĒ2-270' },
  { value: 'LoĒ2-272', label: 'LoĒ2-272' },
  { value: 'LoĒ3-340', label: 'LoĒ3-340' },
  { value: 'LoĒ3-366', label: 'LoĒ3-366' },
  { value: 'ShowerGuard', label: 'ShowerGuard' },
  { value: 'EnduroShield', label: 'EnduroShield' },
  { value: 'Black SPANDREL (PLK, VIR) ', label: 'Black SPANDREL (PLK, VIR) ' },
  { value: 'Blue Frost SPANDREL (VIR) ', label: 'Blue Frost SPANDREL (VIR) ' },
  { value: 'Blue SPANDREL (VIR) ', label: 'Blue SPANDREL (VIR) ' },
  { value: 'Bronze SPANDREL (VIR) ', label: 'Bronze SPANDREL (VIR) ' },
  { value: 'Bronze Tone SPANDREL (PLK) ', label: 'Bronze Tone SPANDREL (PLK) ' },
  { value: 'Cactus Green SPANDREL (PLK) ', label: 'Cactus Green SPANDREL (PLK) ' },
  { value: 'Charcoal SPANDREL  (OBE) ', label: 'Charcoal SPANDREL  (OBE) ' },
  { value: 'Dark Bronze SPANDREL (VIR) ', label: 'Dark Bronze SPANDREL (VIR) ' },
  { value: 'Dark Gray  SPANDREL (VIR)', label: 'Dark Gray  SPANDREL (VIR)' },
  { value: 'Etch White SPANDREL (PLK) ', label: 'Etch White SPANDREL (PLK) ' },
  { value: 'EverGreen SPANDREL (OBE, VIR) ', label: 'EverGreen SPANDREL (OBE, VIR) ' },
  { value: 'Fog Gray  SPANDREL (VIR)', label: 'Fog Gray  SPANDREL (VIR)' },
  { value: 'Ford Blue SPANDREL (OBE) ', label: 'Ford Blue SPANDREL (OBE) ' },
  { value: 'Graphite SPANDREL (PLK) ', label: 'Graphite SPANDREL (PLK) ' },
  { value: 'Gray Black SPANDREL (OBE) ', label: 'Gray Black SPANDREL (OBE) ' },
  { value: 'Gray SPANDREL (VIR, PLK) ', label: 'Gray SPANDREL (VIR, PLK) ' },
  { value: 'Harmonic Blue SPANDREL (PLK) ', label: 'Harmonic Blue SPANDREL (PLK) ' },
  { value: 'Harmony Bronze SPANDREL', label: 'Harmony Bronze SPANDREL' },
  {
    value: 'High-Opacity White SPANDREL (VIR) ',
    label: 'High-Opacity White SPANDREL (VIR) ',
  },
  { value: 'Lava Bronze SPANDREL (OBE) ', label: 'Lava Bronze SPANDREL (OBE) ' },
  { value: 'Medium Gray SPANDREL (VIR) ', label: 'Medium Gray SPANDREL (VIR) ' },
  { value: 'Opaque White  SPANDREL (PLK)', label: 'Opaque White  SPANDREL (PLK)' },
  { value: 'Sage Green SPANDREL  (VIR)', label: 'Sage Green SPANDREL  (VIR)' },
  {
    value: 'Simulated Etch White SPANDREL  (PLK) ',
    label: 'Simulated Etch White SPANDREL  (PLK) ',
  },
  { value: 'SolarBronze SPANDREL  (OBE)', label: 'SolarBronze SPANDREL  (OBE)' },
  { value: 'SolarGray SPANDREL (OBE) ', label: 'SolarGray SPANDREL (OBE) ' },
  { value: 'Solex SPANDREL (OBE) ', label: 'Solex SPANDREL (OBE) ' },
  { value: 'Subdued Bronze SPANDREL (VIR) ', label: 'Subdued Bronze SPANDREL (VIR) ' },
  { value: 'Subdued Gray SPANDREL (VIR) ', label: 'Subdued Gray SPANDREL (VIR) ' },
  { value: 'Warm Gray SPANDREL (OBE, VIR) ', label: 'Warm Gray SPANDREL (OBE, VIR) ' },
  { value: 'White SPANDREL (OBE) ', label: 'White SPANDREL (OBE) ' },
  {
    value: 'Simulated Acid-Etch SPANDREL (VIR) ',
    label: 'Simulated Acid-Etch SPANDREL (VIR) ',
  },
  {
    value: 'Simulated Sandblast SPANDREL (VIR)',
    label: 'Simulated Sandblast SPANDREL (VIR)',
  },
  { value: 'Spice  SPANDREL (VIR)', label: 'Spice  SPANDREL (VIR)' },
  { value: 'Yellow Moon SPANDREL (VIR) ', label: 'Yellow Moon SPANDREL (VIR) ' },
  { value: '20%= 1/8 LINES (OBE)', label: '20%= 1/8 LINES (OBE)' },
  { value: '20%= 1/8 DOTS (OBE)', label: '20%= 1/8 DOTS (OBE)' },
  { value: '20%= 1/8 HOLES (OBE)', label: '20%= 1/8 HOLES (OBE)' },
  { value: '40%= 1/8 DOTS (OBE)', label: '40%= 1/8 DOTS (OBE)' },
  { value: '40%= 1/8 HOLES (OBE)', label: '40%= 1/8 HOLES (OBE)' },
  { value: '50%= 1/8 LINES (OBE)', label: '50%= 1/8 LINES (OBE)' },
  { value: '60%= 1/8 DOTS (OBE)', label: '60%= 1/8 DOTS (OBE)' },
  { value: '60%= 1/8 HOLES (OBE)', label: '60%= 1/8 HOLES (OBE)' },
  { value: '1/2 LINES ON 1” CENTERS  (OBE)', label: '1/2 LINES ON 1” CENTERS  (OBE)' },
  { value: '20%= 1/8” LINES: 2256  (VIR)', label: '20%= 1/8” LINES: 2256  (VIR)' },
  { value: '20%= 1/8” DOTS: 5065  (VIR)', label: '20%= 1/8” DOTS: 5065  (VIR)' },
  { value: '30%= 1/8” LINES:2973 (VIR)', label: '30%= 1/8” LINES:2973 (VIR)' },
  { value: '30%= 1/8” DOTS: 5959 (VIR)', label: '30%= 1/8” DOTS: 5959 (VIR)' },
  { value: '40%= 1/8” LINES : 2030 (VIR)', label: '40%= 1/8” LINES : 2030 (VIR)' },
  { value: '40%= 1/8” DOTS : 5006 (VIR)', label: '40%= 1/8” DOTS : 5006 (VIR)' },
  { value: '40%= PATTERN: 6017 (VIR)', label: '40%= PATTERN: 6017 (VIR)' },
  { value: '50%= 1/8” LINES: 2002 (VIR)', label: '50%= 1/8” LINES: 2002 (VIR)' },
  { value: '50%= 1" LINES: 2013 (VIR)', label: '50%= 1" LINES: 2013 (VIR)' },
  { value: '50%= 1/2” LINES: 2032 (VIR)', label: '50%= 1/2” LINES: 2032 (VIR)' },
  { value: '50%= 1/4” LINES: 2050 (VIR)', label: '50%= 1/4” LINES: 2050 (VIR)' },
  { value: '50%= 1/4” DOTS: 5960 (VIR)', label: '50%= 1/4” DOTS: 5960 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= Pattern: 6019 (VIR)', label: '60%= Pattern: 6019 (VIR)' },
  { value: '70%= Pattern: 6015 (VIR)', label: '70%= Pattern: 6015 (VIR)' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdHardwareFinishOptions = [
  { value: 'Match Frame', label: 'Match Frame' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research ', label: 'Research ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  { value: 'Satin Nickel', label: 'Satin Nickel' },
  { value: 'White  ', label: 'White  ' },
  { value: 'Black', label: 'Black' },
  { value: 'Light Gray', label: 'Light Gray' },
  { value: 'Tan', label: 'Tan' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Antique Brass', label: 'Antique Brass' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdGlassInstallationOptions = [
  { value: 'Pre-Glazed ', label: 'Pre-Glazed ' },
  { value: 'Loose', label: 'Loose' },
  { value: 'Frame Only (glass by others)', label: 'Frame Only (glass by others)' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdGridsTypeOptions = [
  { value: 'NONE', label: 'NONE' },
  { value: 'See Grid Selector', label: 'See Grid Selector' },
  { value: 'Traditional', label: 'Traditional' },
  { value: 'Top Row', label: 'Top Row' },
  { value: 'Equally Divided (Horizontal)', label: 'Equally Divided (Horizontal)' },
  { value: 'Equally Divided (Vertical)', label: 'Equally Divided (Vertical)' },
  { value: 'Perimeter', label: 'Perimeter' },
  { value: 'Queen Anne', label: 'Queen Anne' },
  { value: 'SDL - Traditional ', label: 'SDL - Traditional ' },
  { value: 'SDL - Top Row', label: 'SDL - Top Row' },
  { value: 'SDL - Equally Divided (Horizontal)', label: 'SDL - Equally Divided (Horizontal)' },
  { value: 'SDL - Equally Divided (Vertical)', label: 'SDL - Equally Divided (Vertical)' },
  { value: 'SDL - Perimeter ', label: 'SDL - Perimeter ' },
  { value: 'SDL - Queen Anne', label: 'SDL - Queen Anne' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research ', label: 'Research ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdScreensOptions = [
  { value: 'None', label: 'None' },
  { value: 'Half ', label: 'Half ' },
  { value: 'Full', label: 'Full' },
  { value: 'Flex-Screen', label: 'Flex-Screen' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes) ', label: 'Research (see notes) ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdDoorHardwareOptions = [
  { value: 'Pull Handle (Interior & Exterior)', label: 'Pull Handle (Interior & Exterior)' },
  { value: 'Thumbutrn (Interior)', label: 'Thumbutrn (Interior)' },
  { value: 'No Keyed Cylinder (Exterior)', label: 'No Keyed Cylinder (Exterior)' },
  { value: 'Keyed Cylinder (Exterior)', label: 'Keyed Cylinder (Exterior)' },
];
export const pdExteriorTrimOptions = [
  { label: 'Trim to be Installed by others', value: 'Trim to be Installed by others' },
  { label: 'Standard', value: 'Standard' },
  { label: 'Research (see notes)', value: 'Research (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
  { label: 'Brickmould', value: 'Brickmould' },
  { label: 'Brickmould + Sill', value: 'Brickmould + Sill' },
  { label: 'Casing - Flat', value: 'Casing - Flat' },
  { label: 'Casing - Picture Frame Style', value: 'Casing - Picture Frame Style' },
  { label: 'Casing - Traditional', value: 'Casing - Traditional' },
  { label: 'Casing + Crown + Sill', value: 'Casing + Crown + Sill' },
  { label: 'Casing + Mission Sill', value: 'Casing + Mission Sill' },
  {
    label: 'Casing + Rosettes + Traditional Sill',
    value: 'Casing + Rosettes + Traditional Sill',
  },
  { label: 'Casing + Sill', value: 'Casing + Sill' },
  { label: 'Colonial', value: 'Colonial' },
  { label: 'Craftsman - Beaded&Bullnose', value: 'Craftsman - Beaded&Bullnose' },
  { label: 'Craftsman - Bullnose', value: 'Craftsman - Bullnose' },
  { label: 'Craftsman - Classical', value: 'Craftsman - Classical' },
  { label: 'Craftsman - Classical', value: 'Craftsman - Classical' },
  { label: 'Craftsman - Classical', value: 'Craftsman - Classical' },
  { label: 'Craftsman - S4S', value: 'Craftsman - S4S' },
  {
    label: 'Craftsman with 1 1/4" Fillet + Casing Cap',
    value: 'Craftsman with 1 1/4" Fillet + Casing Cap',
  },
  { label: 'Craftsmen', value: 'Craftsmen' },
  { label: 'Craftsmen Lintel + Mission Sill', value: 'Craftsmen Lintel + Mission Sill' },
  { label: 'Craftsmen with 1 1/4" Fillet', value: 'Craftsmen with 1 1/4" Fillet' },
  { label: 'Flat', value: 'Flat' },
  { label: 'Mission Lintel + Mission Sill', value: 'Mission Lintel + Mission Sill' },
  { label: 'Provincial', value: 'Provincial' },
  { label: 'Ranch', value: 'Ranch' },
  { label: 'Traditional Sill', value: 'Traditional Sill' },
];
export const pdInteriorTrimOptions = [
  { value: 'Trim to be Installed by others', label: 'Trim to be Installed by others' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  { value: 'Brickmould', label: 'Brickmould' },
  { value: 'Brickmould + Sill', label: 'Brickmould + Sill' },
  { value: 'Casing - Flat', label: 'Casing - Flat' },
  { value: 'Casing - Picture Frame Style', label: 'Casing - Picture Frame Style' },
  { value: 'Casing - Traditional', label: 'Casing - Traditional' },
  { value: 'Casing + Crown + Sill', label: 'Casing + Crown + Sill' },
  { value: 'Casing + Mission Sill', label: 'Casing + Mission Sill' },
  {
    value: 'Casing + Rosettes + Traditional Sill',
    label: 'Casing + Rosettes + Traditional Sill',
  },
  { value: 'Casing + Sill', label: 'Casing + Sill' },
  { value: 'Colonial', label: 'Colonial' },
  { value: 'Craftsman - Beaded&Bullnose', label: 'Craftsman - Beaded&Bullnose' },
  { value: 'Craftsman - Bullnose', label: 'Craftsman - Bullnose' },
  { value: 'Craftsman - Classical', label: 'Craftsman - Classical' },
  { value: 'Craftsman - Classical', label: 'Craftsman - Classical' },
  { value: 'Craftsman - Classical', label: 'Craftsman - Classical' },
  { value: 'Craftsman - S4S', label: 'Craftsman - S4S' },
  {
    value: 'Craftsman with 1 1/4" Fillet + Casing Cap',
    label: 'Craftsman with 1 1/4" Fillet + Casing Cap',
  },
  { value: 'Craftsmen', label: 'Craftsmen' },
  { value: 'Craftsmen Lintel + Mission Sill', label: 'Craftsmen Lintel + Mission Sill' },
  { value: 'Craftsmen with 1 1/4" Fillet', label: 'Craftsmen with 1 1/4" Fillet' },
  { value: 'Flat', label: 'Flat' },
  { value: 'Mission Lintel + Mission Sill', label: 'Mission Lintel + Mission Sill' },
  { value: 'Provincial', label: 'Provincial' },
  { value: 'Ranch', label: 'Ranch' },
  { value: 'Traditional Sill', label: 'Traditional Sill' },
];
export const pdGrids = [
  { value: 'NONE', label: 'NONE' },
  {
    value: 'See Grid Selector',
    label: 'See Grid Selector',
  },
  {
    value: '2 Lite A (1V) Verticle (Top+Bottom Sash: 2,2)',
    label: '2 Lite A (1V) Verticle (Top+Bottom Sash: 2,2)',
  },
  {
    value: '2 Lite B (1H) Horizontal (Top+Bottom Sash: 2,2)',
    label: '2 Lite B (1H) Horizontal (Top+Bottom Sash: 2,2)',
  },
  {
    value: '4 Lite (1H,1V) (Top+Bottom Sash: 4,4)',
    label: '4 Lite (1H,1V) (Top+Bottom Sash: 4,4)',
  },
  {
    value: '6 Lite A (1H,2V) (Top+Bottom Sash: 6,6)',
    label: '6 Lite A (1H,2V) (Top+Bottom Sash: 6,6)',
  },
  {
    value: '6 Lite B (2H,1V) (Top+Bottom Sash: 6,6)',
    label: '6 Lite B (2H,1V) (Top+Bottom Sash: 6,6)',
  },
  {
    value: '9 Lite (3H,3V) (Top+Bottom Sash: 9,9)',
    label: '9 Lite (3H,3V) (Top+Bottom Sash: 9,9)',
  },
  {
    value: '2 Lite A (1V) Verticle (Top Sash Only: 2,0)',
    label: '2 Lite A (1V) Verticle (Top Sash Only: 2,0)',
  },
  {
    value: '2 Lite B (1H) Horizontal (Top Sash Only: 2,0)',
    label: '2 Lite B (1H) Horizontal (Top Sash Only: 2,0)',
  },
  {
    value: '4 Lite (1H,1V) (Top Sash Only: 4,0)',
    label: '4 Lite (1H,1V) (Top Sash Only: 4,0)',
  },
  {
    value: '6 Lite A (1H,2V) (Top Sash Only: 6,0)',
    label: '6 Lite A (1H,2V) (Top Sash Only: 6,0)',
  },
  {
    value: '6 Lite B (2H,1V) (Top Sash Only: 6,0)',
    label: '6 Lite B (2H,1V) (Top Sash Only: 6,0)',
  },
  {
    value: '9 Lite (3H,3V) (Top Sash Only: 9,0)',
    label: '9 Lite (3H,3V) (Top Sash Only: 9,0)',
  },
  { value: 'Traditional', label: 'Traditional' },
  { value: 'Top Row', label: 'Top Row' },
  { value: 'Equally Divided (Horizontal)', label: 'Equally Divided (Horizontal)' },
  { value: 'Equally Divided (Vertical)', label: 'Equally Divided (Vertical)' },
  { value: 'Perimeter', label: 'Perimeter' },
  { value: 'Queen Anne', label: 'Queen Anne' },
  { value: 'SDL - Traditional ', label: 'SDL - Traditional ' },
  { value: 'SDL - Top Row', label: 'SDL - Top Row' },
  {
    value: 'SDL - Equally Divided (Horizontal)',
    label: 'SDL - Equally Divided (Horizontal)',
  },
  { value: 'SDL - Equally Divided (Vertical)', label: 'SDL - Equally Divided (Vertical)' },
  { value: 'SDL - Perimeter ', label: 'SDL - Perimeter ' },
  { value: 'SDL - Queen Anne', label: 'SDL - Queen Anne' },
  {
    value: 'SDL - See Grid Selector',
    label: 'SDL - See Grid Selector',
  },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research ', label: 'Research ' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdGlassTypeOptions = [
  { value: 'IGU ', label: 'IGU ' },
  { value: 'IGU LAMI', label: 'IGU LAMI' },
  { value: '1/8 ', label: '1/8 ' },
  { value: '3/16', label: '3/16' },
  { value: '1/4 ', label: '1/4 ' },
  { value: '5/16', label: '5/16' },
  { value: '3/8 ', label: '3/8 ' },
  { value: '7/16', label: '7/16' },
  { value: '1/2 ', label: '1/2 ' },
  { value: '5/8 ', label: '5/8 ' },
  { value: '3/4 ', label: '3/4 ' },
  { value: 'Standard ', label: 'Standard ' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdFrameFinishInteriorOptions = [
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Custom', label: 'Custom' },
  {
    value: 'Clear Anodized (Standard  .4 mils)',
    label: 'Clear Anodized (Standard  .4 mils)',
  },
  { value: 'Dark Bronze Anodized', label: 'Dark Bronze Anodized' },
  { value: 'Black Anodized', label: 'Black Anodized' },
  { value: 'Clear Anodized ( .7 mils)', label: 'Clear Anodized ( .7 mils)' },
  { value: 'Champagne Anodized', label: 'Champagne Anodized' },
  { value: 'Light Bronze Anodized', label: 'Light Bronze Anodized' },
  { value: 'Medium Bronze Anodized', label: 'Medium Bronze Anodized' },
  { value: 'Almond (TW)', label: 'Almond (TW)' },
  { value: 'Antique White (EFCO)', label: 'Antique White (EFCO)' },
  {
    value: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
    label: 'Bone White (OBE, YKK, KAW, EFCO, USA, TW)',
  },
  { value: 'Colonial White (OBE, YKK)', label: 'Colonial White (OBE, YKK)' },
  { value: 'Crème (TW)', label: 'Crème (TW)' },
  { value: 'Herring Bone (EFCO)', label: 'Herring Bone (EFCO)' },
  { value: 'Milk White (KAW)', label: 'Milk White (KAW)' },
  { value: 'Stone White (USA)', label: 'Stone White (USA)' },
  { value: 'White (EFCO, TW)', label: 'White (EFCO, TW)' },
  { value: 'Beige (OBE, YKK)', label: 'Beige (OBE, YKK)' },
  { value: 'Seawolf Beige(YKK)', label: 'Seawolf Beige(YKK)' },
  {
    value: 'Black (OBE, YKK, KAW, EFCO, USA, TW)',
    label: 'Black (OBE, YKK, KAW, EFCO, USA, TW)',
  },
  { value: 'Deep Blue (KAW)', label: 'Deep Blue (KAW)' },
  { value: 'Blue Sky (EFCO)', label: 'Blue Sky (EFCO)' },
  { value: 'Legacy Blue (EFCO)', label: 'Legacy Blue (EFCO)' },
  { value: 'Medium Blue (OBE, KAW, TW)', label: 'Medium Blue (OBE, KAW, TW)' },
  { value: 'Midnight Blue (KAW, EFCO)', label: 'Midnight Blue (KAW, EFCO)' },
  { value: 'Military Blue (OBE, KAW, USA)', label: 'Military Blue (OBE, KAW, USA)' },
  { value: 'Regal Blue (OBE, USA)', label: 'Regal Blue (OBE, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Boysenberry (OBE, KAW, EFCO, USA)', label: 'Boysenberry (OBE, KAW, EFCO, USA)' },
  { value: 'Bronze (THW)', label: 'Bronze (THW)' },
  { value: 'Classic Bronze (YKK, KAW, USA)', label: 'Classic Bronze (YKK, KAW, USA)' },
  { value: 'Light Bronze (USA)', label: 'Light Bronze (USA)' },
  { value: 'Medium Bronze (KAW, USA, THW)', label: 'Medium Bronze (KAW, USA, THW)' },
  { value: 'Brown (OBE)', label: 'Brown (OBE)' },
  { value: 'Java Brown (KAW)', label: 'Java Brown (KAW)' },
  { value: 'Light Brown (EFCO)', label: 'Light Brown (EFCO)' },
  { value: 'Mesa Brown (EFCO)', label: 'Mesa Brown (EFCO)' },
  { value: 'Mineral Brown (EFCO)', label: 'Mineral Brown (EFCO)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Sage Brown (OBE)', label: 'Sage Brown (OBE)' },
  { value: 'Aged Copper (OBE, EFCO, USA)', label: 'Aged Copper (OBE, EFCO, USA)' },
  { value: 'Copper Penny (OBE)', label: 'Copper Penny (OBE)' },
  { value: 'Champagne (EFCO, USA)', label: 'Champagne (EFCO, USA)' },
  { value: 'Champagne Gold (OBE)', label: 'Champagne Gold (OBE)' },
  { value: 'Gold (USA)', label: 'Gold (USA)' },
  { value: 'Atlantic Gray (KAW)', label: 'Atlantic Gray (KAW)' },
  { value: 'Charcoal (YKK, KAW, USA)', label: 'Charcoal (YKK, KAW, USA)' },
  { value: 'Charcoal Gray (OBE)', label: 'Charcoal Gray (OBE)' },
  { value: 'Dark Gray (TW)', label: 'Dark Gray (TW)' },
  { value: 'Dove Gray (KAW)', label: 'Dove Gray (KAW)' },
  { value: 'Fashion Gray (OBE)', label: 'Fashion Gray (OBE)' },
  { value: 'Grey (EFCO)', label: 'Grey (EFCO)' },
  { value: 'Gun Barrel (EFCO)', label: 'Gun Barrel (EFCO)' },
  { value: 'Light Gray (KAW, TW)', label: 'Light Gray (KAW, TW)' },
  { value: 'Night Hawk Gray (KAW)', label: 'Night Hawk Gray (KAW)' },
  { value: 'Smoke Gray (KAW)', label: 'Smoke Gray (KAW)' },
  { value: 'Stone Gray (USA)', label: 'Stone Gray (USA)' },
  { value: 'Dark Green (KAW, TW)', label: 'Dark Green (KAW, TW)' },
  {
    value: 'Hartford Green (YKK, KAW, EFCO, USA)',
    label: 'Hartford Green (YKK, KAW, EFCO, USA)',
  },
  { value: 'Interstate Green (OBE, KAW)', label: 'Interstate Green (OBE, KAW)' },
  { value: 'Light Green (KAW, TW)', label: 'Light Green (KAW, TW)' },
  { value: 'Sea Spray (EFCO, USA)', label: 'Sea Spray (EFCO, USA)' },
  { value: 'Teal (USA)', label: 'Teal (USA)' },
  { value: 'Ivory (KAW)', label: 'Ivory (KAW)' },
  { value: 'Ivy (EFCO, USA)', label: 'Ivy (EFCO, USA)' },
  { value: 'Dark Ivy (KAW)', label: 'Dark Ivy (KAW)' },
  { value: 'Pewter (OBE)', label: 'Pewter (OBE)' },
  { value: 'Portland Stone (OBE)', label: 'Portland Stone (OBE)' },
  { value: 'Pueblo Tan (OBE, EFCO, USA)', label: 'Pueblo Tan (OBE, EFCO, USA)' },
  { value: 'Brick Red (KAW)', label: 'Brick Red (KAW)' },
  { value: 'Claret Red (KAW)', label: 'Claret Red (KAW)' },
  { value: 'Colonial Red (YKK)', label: 'Colonial Red (YKK)' },
  { value: 'Regal Red (EFCO)', label: 'Regal Red (EFCO)' },
  { value: 'Rust Red (TW)', label: 'Rust Red (TW)' },
  { value: 'Redwood (OBE, KAW, EFCO, USA)', label: 'Redwood (OBE, KAW, EFCO, USA)' },
  {
    value: 'Sandstone (OBE, YKK, KAW, USA, TW)',
    label: 'Sandstone (OBE, YKK, KAW, USA, TW)',
  },
  { value: 'Sea Wolf (KAW)', label: 'Sea Wolf (KAW)' },
  { value: 'Bright Silver (OBE)', label: 'Bright Silver (OBE)' },
  { value: 'Warm Silver (EFCO, USA)', label: 'Warm Silver (EFCO, USA)' },
  { value: 'Terra Cotta (OBE)', label: 'Terra Cotta (OBE)' },
  { value: 'Summer Yellow (KAW)', label: 'Summer Yellow (KAW)' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const pdFrameFinishExteriorOptions = [...pdFrameFinishInteriorOptions];

export const pdHardwareColor = [
  { value: 'Satin Nickel', label: 'Satin Nickel' },
  { value: 'White', label: 'White' },
  { value: 'Black', label: 'Black' },
  { value: 'Light Gray', label: 'Light Gray' },
  { value: 'Tan', label: 'Tan' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Antique Brass', label: 'Antique Brass' },
  { value: 'Match Frame', label: 'Match Frame' },
  { value: 'Other', label: 'Other' },
];

export const pdHoleSize = [
  { value: 'PANIC - FIGURE 8', label: 'PANIC - FIGURE 8' },
  { value: 'COUNTER SINK', label: 'COUNTER SINK' },
  { value: 'NOTCH', label: 'NOTCH' },
  { value: '1/16', label: '1/16' },
  { value: '1/8', label: '1/8' },
  { value: '3/16', label: '3/16' },
  { value: '1/4', label: '1/4' },
  { value: '5/16', label: '5/16' },
  { value: '3/8', label: '3/8' },
  { value: '7/16', label: '7/16' },
  { value: '1/2', label: '1/2' },
  { value: '9/16', label: '9/16' },
  { value: '5/8', label: '5/8' },
  { value: '11/16', label: '11/16' },
  { value: '3/4', label: '3/4' },
  { value: '13/16', label: '13/16' },
  { value: '7/8', label: '7/8' },
  { value: '15/16', label: '15/16' },
  { value: '1', label: '1' },
  { value: '1  1/16', label: '1  1/16' },
  { value: '1  1/8', label: '1  1/8' },
  { value: '1  3/16', label: '1  3/16' },
  { value: '1  1/4', label: '1  1/4' },
  { value: '1  5/16', label: '1  5/16' },
  { value: '1  3/8', label: '1  3/8' },
  { value: '1  7/16', label: '1  7/16' },
  { value: '1  9/16', label: '1  9/16' },
  { value: '1  5/8', label: '1  5/8' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1  3/4', label: '1  3/4' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1  7/8', label: '1  7/8' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '2', label: '2' },
  { value: '2  1/16', label: '2  1/16' },
  { value: '2  1/8', label: '2  1/8' },
  { value: '2  3/16', label: '2  3/16' },
  { value: '2  1/4', label: '2  1/4' },
  { value: '2  5/16', label: '2  5/16' },
  { value: '2  3/8', label: '2  3/8' },
  { value: '2  7/16', label: '2  7/16' },
  { value: '2  1/2', label: '2  1/2' },
  { value: '2  9/16', label: '2  9/16' },
  { value: '2  5/8', label: '2  5/8' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2  3/4', label: '2  3/4' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2  7/8', label: '2  7/8' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '3', label: '3' },
  { value: '3  1/16', label: '3  1/16' },
  { value: '3  1/8', label: '3  1/8' },
  { value: '3  3/16', label: '3  3/16' },
  { value: '3  1/4', label: '3  1/4' },
  { value: '3  5/16', label: '3  5/16' },
  { value: '3  3/8 ', label: '3  3/8 ' },
  { value: '3  7/16', label: '3  7/16' },
  { value: '3  1/2 ', label: '3  1/2 ' },
  { value: '3  9/16', label: '3  9/16' },
  { value: '3  5/8 ', label: '3  5/8 ' },
  { value: '3 11/16', label: '3 11/16' },
  { value: '3  3/4', label: '3  3/4' },
  { value: '3  3/4', label: '3  3/4' },
  { value: '3 13/16', label: '3 13/16' },
  { value: '3  7/8', label: '3  7/8' },
  { value: '3 15/16', label: '3 15/16' },
  { value: '4', label: '4' },
  { value: '4  1/16', label: '4  1/16' },
  { value: '4  1/8', label: '4  1/8' },
  { value: '4  3/16', label: '4  3/16' },
  { value: '4  1/4', label: '4  1/4' },
  { value: '4  5/16', label: '4  5/16' },
  { value: '4  3/8', label: '4  3/8' },
  { value: '4  7/16', label: '4  7/16' },
  { value: '4  1/2', label: '4  1/2' },
  { value: '4  9/16', label: '4  9/16' },
  { value: '4  5/8', label: '4  5/8' },
  { value: '4 11/16', label: '4 11/16' },
  { value: '4  3/4', label: '4  3/4' },
  { value: '4 13/16', label: '4 13/16' },
  { value: '4  7/8', label: '4  7/8' },
  { value: '4 15/16', label: '4 15/16' },
  { value: '5', label: '5' },
];

export const transomOption = [
  { value: 'GAP', label: 'GAP' },
  { value: 'ABOVE DOOR ONLY', label: 'ABOVE DOOR ONLY' },
  { value: 'FULL OPENING', label: 'FULL OPENING' },
  { value: 'Full Span LEFT of Door(s)', label: 'Full Span LEFT of Door(s)' },
  { value: 'Full Span RIGHT of Door(s)', label: 'Full Span RIGHT of Door(s)' },
  { value: 'OTHER (see notes)', label: 'OTHER (see notes)' },
];

export const filmsTypeOptions = [
  { label: 'Black Out', value: 'Black Out' },
  { label: 'White Out', value: 'White Out' },
  { label: 'White Matte  (2MM)', value: 'White Matte  (2MM)' },
  { label: 'Dusted Crystal', value: 'Dusted Crystal' },
  { label: 'Reflective - Silver 20', value: 'Reflective - Silver 20' },
  { label: 'Reflective - Silver 35', value: 'Reflective - Silver 35' },
  { label: 'Reflective - Silver 50', value: 'Reflective - Silver 50' },
  { label: 'Reflective - Silver Matte 20', value: 'Reflective - Silver Matte 20' },
  { label: 'Neutral - 20', value: 'Neutral - 20' },
  { label: 'Neutral - 35', value: 'Neutral - 35' },
  { label: 'Neutral - 50', value: 'Neutral - 50' },
  { label: 'Neutral - 65', value: 'Neutral - 65' },
  { label: 'Neutral - 70', value: 'Neutral - 70' },
  { label: 'Dual Reflective - Opti 05', value: 'Dual Reflective - Opti 05' },
  { label: 'Dual Reflective - Opti 15', value: 'Dual Reflective - Opti 15' },
  { label: 'SECURITY - Clear (7MM)', value: 'SECURITY - Clear (7MM)' },
  { label: 'SECURITY - Clear (4MM)', value: 'SECURITY - Clear (4MM)' },
  { label: 'SECURITY - Clear (8MM)', value: 'SECURITY - Clear (8MM)' },
  { label: 'SECURITY - Clear (11MM)', value: 'SECURITY - Clear (11MM)' },
  { label: 'SECURITY - Clear (12MM)', value: 'SECURITY - Clear (12MM)' },
  { label: 'SECURITY - Clear (15MM)', value: 'SECURITY - Clear (15MM)' },
  { label: 'SECURITY - Anti Graffiti Xtra (6MM)', value: 'SECURITY - Anti Graffiti Xtra (6MM)' },
  { label: 'Neutral 35 (6MM)', value: 'Neutral 35 (6MM)' },
  { label: 'Neutral 35 (10MM)', value: 'Neutral 35 (10MM)' },
  { label: 'Neutral 50 (6MM)', value: 'Neutral 50 (6MM)' },
  { label: 'Neutral 50 (10MM)', value: 'Neutral 50 (10MM)' },
  { label: 'Silver 20 (4MM)', value: 'Silver 20 (4MM)' },
  { label: 'Silver 20 (9MM)', value: 'Silver 20 (9MM)' },
  { label: 'Silver 20 (12MM)', value: 'Silver 20 (12MM)' },
  { label: 'Gradient - WHITE', value: 'Gradient - WHITE' },
  { label: 'Gradient - BLACK', value: 'Gradient - BLACK' },
  { label: 'Gradient - OTHER', value: 'Gradient - OTHER' },
  { label: 'Privacy', value: 'Privacy' },
  { label: 'Privacy - One Way', value: 'Privacy - One Way' },
  { label: 'Pattern', value: 'Pattern' },
  { label: 'Texture', value: 'Texture' },
  { label: 'Safety', value: 'Safety' },
  { label: 'Vinyl', value: 'Vinyl' },
  { label: 'Bird Safety Film', value: 'Bird Safety Film' },
  { label: 'Dots', value: 'Dots' },
  { label: 'Stripes', value: 'Stripes' },
  { label: 'Sqaures', value: 'Sqaures' },
  { label: 'Rice paper', value: 'Rice paper' },
  { label: 'Natural', value: 'Natural' },
  { label: 'Reeded', value: 'Reeded' },
  { label: 'Decorative', value: 'Decorative' },
  { label: 'Auto - 5% (LIMO)', value: 'Auto - 5% (LIMO)' },
  { label: 'Auto - 10%', value: 'Auto - 10%' },
  { label: 'Auto - 15%', value: 'Auto - 15%' },
  { label: 'Auto - 20%', value: 'Auto - 20%' },
  { label: 'Auto - 30%', value: 'Auto - 30%' },
  { label: 'Auto - 35%', value: 'Auto - 35%' },
  { label: 'Auto - 50%', value: 'Auto - 50%' },
  { label: 'Auto - Black Reflective', value: 'Auto - Black Reflective' },
  { label: 'Auto - Blue Reflective', value: 'Auto - Blue Reflective' },
  { label: 'Auto - Bronze Reflective', value: 'Auto - Bronze Reflective' },
  { label: 'Auto - Charcoal Reflective', value: 'Auto - Charcoal Reflective' },
  { label: 'Auto - Copper Reflective', value: 'Auto - Copper Reflective' },
  { label: 'Auto - Dual Reflective', value: 'Auto - Dual Reflective' },
  { label: 'Auto - Gold Reflective', value: 'Auto - Gold Reflective' },
  { label: 'Auto - Green Reflective', value: 'Auto - Green Reflective' },
  { label: 'Auto - Silver Reflective', value: 'Auto - Silver Reflective' },
  { label: 'Auto - Titanium Reflective', value: 'Auto - Titanium Reflective' },
  { label: 'Auto - Gradient (Dark to Light)', value: 'Auto - Gradient (Dark to Light)' },
  { label: 'Auto - Gradient  (Light to Dark)', value: 'Auto - Gradient  (Light to Dark)' },
  { label: 'Auto - Gradient (Black to Clear)', value: 'Auto - Gradient (Black to Clear)' },
  { label: 'Auto - Gradient (Clear to Black)', value: 'Auto - Gradient (Clear to Black)' },
  { label: 'Auto - Gradient (Fade-Out Effect)', value: 'Auto - Gradient (Fade-Out Effect)' },
  { label: 'TBD', value: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Custom (see notes)', value: 'Custom (see notes)' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const filmsBrandOptions = [
  { label: '3M', value: '3M' },
  { label: 'Armorcoat', value: 'Armorcoat' },
  { label: 'Avery', value: 'Avery' },
  { label: 'Eastman ', value: 'Eastman ' },
  { label: 'Formula One', value: 'Formula One' },
  { label: 'Hanita ', value: 'Hanita ' },
  { label: 'Huper Optik', value: 'Huper Optik' },
  { label: 'Johnson ', value: 'Johnson ' },
  { label: 'Lintec', value: 'Lintec' },
  { label: 'Llumar', value: 'Llumar' },
  { label: 'Madico', value: 'Madico' },
  { label: 'Panorama', value: 'Panorama' },
  { label: 'Solamatrix', value: 'Solamatrix' },
  { label: 'Solar Gard', value: 'Solar Gard' },
  { label: 'Sunscape', value: 'Sunscape' },
  { label: 'Suntek', value: 'Suntek' },
  { label: 'Velocr', value: 'Velocr' },
  { label: 'Vista', value: 'Vista' },
  { label: 'XPEL', value: 'XPEL' },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];
export const filmsFrameTypeOptions = [
  { label: 'Aluminum', value: 'Aluminum' },
  { label: 'Wood', value: 'Wood' },
  { label: 'Vinyl', value: 'Vinyl' },
  { label: 'Steel', value: 'Steel' },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const filmsGlassTypeOptions = [
  { label: 'Single Pane', value: 'Single Pane' },
  { label: 'IGU', value: 'IGU' },
  { label: 'Laminated', value: 'Laminated ' },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];
export const filmsGlassThicknessOptions = [
  { label: '1/16"', value: '1/16"' },
  { label: '1/8"', value: '1/8"' },
  { label: '3/16"', value: '3/16"' },
  { label: '1/4', value: '1/4' },
  { label: '3/8"', value: '3/8"' },
  { label: '1/2"', value: '1/2"' },
  { label: '5/8"', value: '5/8"' },
  { label: '3/4"', value: '3/4"' },
  { label: '1"', value: '1"' },
  { label: '1/2" IGU', value: '1/2" IGU' },
  { label: '9/16" IGU', value: '9/16" IGU' },
  { label: '5/8" IGU', value: '5/8" IGU' },
  { label: '3/4" IGU', value: '3/4" IGU' },
  { label: '7/8" IGU', value: '7/8" IGU' },
  { label: '1" IGU', value: '1" IGU' },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];
export const filmsTemperedOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Research', label: 'Research' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const filmsLiftEquipmentNeededOptions = [
  { label: 'None, Walk Up, Interior', value: 'None, Walk Up, Interior' },
  { label: 'None, Walk Up, Exterior', value: 'None, Walk Up, Exterior' },
  {
    label: 'Lift Equip Needed (see equipment list)',
    value: 'Lift Equip Needed (see equipment list)',
  },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research (see notes) ', value: 'Research (see notes) ' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const filmsInstallLocationOptions = [
  { label: "GLAZIER'S SHOP", value: "GLAZIER'S SHOP" },
  { label: 'JOBSITE', value: 'JOBSITE' },
  { value: 'TBD', label: 'TBD' },
  { label: 'Research', value: 'Research' },
  { label: 'Other (see notes)', value: 'Other (see notes)' },
];

export const filmsAppliedSideOptions = [
  { values: 'Either (not sided)', label: 'Either (not sided)' },
  { values: 'Non-Coated/Treated', label: 'Non-Coated/Treated' },
  { values: 'Reflective', label: 'Reflective' },
  { values: 'Treaded Side', label: 'Treaded Side' },
  { values: 'Smooth Side', label: 'Smooth Side' },
  { values: 'Etch Side', label: 'Etch Side' },
  { values: '# 1 (Inside)', label: '# 1 (Inside)' },
  { values: '# 2 (Inside)', label: '# 2 (Inside)' },
  { values: '# 4 (Inside)', label: '# 4 (Inside)' },
  { values: '# 1 (Outside)', label: '# 1 (Outside)' },
  { values: '# 2 (Outside)', label: '# 2 (Outside)' },
  { values: '# 4 (Outside)', label: '# 4 (Outside)' },
  { values: '# OTHER  (see notes)', label: '# OTHER  (see notes)' },
  { values: 'TBD', label: 'TBD' },
  { values: 'Research', label: 'Research' },
  { values: 'Other (see notes)', label: 'Other (see notes)' },
];
export const filmsSpliceAcceptableOptions = [
  { value: 'Yes - Horizontal', label: 'Yes - Horizontal' },
  { value: 'Yes - Verticle', label: 'Yes - Verticle' },
  { value: 'No', label: 'No' },
  { value: 'Maybe', label: 'Maybe' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Research', label: 'Research' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const filmsRemovalRequiredOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Research', label: 'Research' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
export const filmsWetsealFilmToFrameOptions = [
  { value: 'Yes - Interior', label: 'Yes - Interior' },
  { value: 'Yes - Exterior', label: 'Yes - Exterior' },
  { value: 'Yes - Both', label: 'Yes - Both' },
  { value: 'TBD', label: 'TBD' },
  { value: 'Research', label: 'Research' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];
