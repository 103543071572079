const type_1_rh_gable = 'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/1_CIRCLE.png';

const type_2_lh_gable =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/2_CIRCLE+_HALF(A).png';

const type_3_rh_angle_corner =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/3_CIRCLE_HALF(B).jpg';

const type_4_lh_angle_corner =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/3_POINT_LINE.jpg';

const type_5_lh_trapezoid = 'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/4_OVAL.jpg';

const type_6_rh_trapezoid =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/5_ELIPSE.jpg';

const type_7_rh_irregular_angle_crn =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/6_ARCH_QUARTER(LEFT).jpg';

const type_8_lh_irregular_angle_crn =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/7_ARCH_QUARTER(RIGHT).jpg';

const type_9_lh_angle_corner_offset =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/8_CURVED_CYLINDER.png';

const type_10_rh_angle_corner_offset =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/9_CURVED_DOUBLE_TANGENT.png';

const type_11_o_s_left_up_out =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/10_CURVED_LEFT_TANGENT.png';

const type_12_o_s_right_up_out =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/11_CURVED_RIGHT_TANGENT.png';

const type_13_o_s_right_up_in =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/12_GABLE_ARCH_A.jpg';

const type_14_o_s_right_down_out =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/13_GABLE_ARCH_B.jpg';

const type_15_o_s_left_down_out =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/14_GABLE_(LEFT).jpg';

const type_16_o_s_left_up_in =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/15_GABLE_(RIGHT).png';

const type_19_irregular_octagon =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/16_PARALLELOGRAM_A.png';

const type_20_equilateral_triangle =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/17_PARALLELOGRAM_B.png';

const type_21_regular_pentagon =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/18_PENTAGON_LEFT.png';

const type_22_regular_hexagon =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/19_PENTAGON_RIGHT.png';

const type_24_regular_octagon =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/20_ROUNDER_CORNERS.png';

const type_25_rh_triangle =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/21_TRAPEZIUM_SPECIAL_1.jpg';

const type_26_lh_triangle =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/22_TRAPEZIUM_SPECIAL_2.jpg';

const type_37_rh_dog_house =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/23_TRAPEZIUM_SPECIAL_3.jpg';

const type_38_lh_dog_house =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/24_TRAPEZIUM_SPECIAL_4.jpg';

const type_39_lh_irreg_trapezoid =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/25_TRAPEZIUM_SPECIAL_5.jpg';

const type_40_rh_irreg_trapezoid =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/26_TRAPEZIUM_SPECIAL_6.jpg';

const type_45_rh_right_triangle =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/27_TRAPEZIUM_SPECIAL_7.jpg';

const type_46 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/28_TRAPEZIUM_SPECIAL_8.jpg';

const type_47 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/29_TRAPEZOID_ISOSCELES.jpg';

const type_48 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/30_TRAPEZOID_OBSTUSE(LEFT).jpg';

const type_49 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/31_TRAPEZOID_OBSTUSE(RIGHT).jpg';

const type_50 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/32_TRAPEZOID_SCALENE_(LEFT).png';

const type_51 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/33_TRAPEZOID_SCALENE_(RIGHT).jpg';

const type_52 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/34_TRIANGLE_ACUTE_A(LEFT).jpg';

const type_53 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/35_TRIANGLE_ACUTE_A(RIGHT).png';

const type_54 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/36_TRIANGLE_ACUTE_B(LEFT).jpg';

const type_55 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/37_TRIANGLE_ACUTE_B(RIGHT).png';

const type_56 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/38_TRIANGLE_EQUILATERAL.png';

const type_57 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/39_TRIANGLE_ISOCELES.png';

const type_58 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/40_TRIANGLE_RIGHT(LEFT).png';

const type_59 =
  'https://sag-assets-media.s3.amazonaws.com/blueprints/SHAPE/41_TRIANGLE_RIGHT(RIGHT).png';

import {
  GLASSSIZESA,
  GLASSSIZESB,
  GLASSSIZESC,
  GLASSSIZESD,
  GLASSSIZESE,
  GLASSSIZESF,
  GLASSSIZESG,
  GLASSSIZESH,
  gtExteriorLite,
  gtInteriorLite,
  gtOverThick,
  gtexThick,
  gtexTreatment,
  gtexColor,
  gtexCoatingFamily,
  gtexCoating,
  gtSpacerColor,
  gtinThick,
  gtinTreatment,
  gtinColor,
  gtinCoatingFamily,
  gtinCoating,
  gpHolesType,
  gpHolesExtSize,
  gpHolesIntSize,
  gpHolesStartX,
  gpHolesStartY,
  gpHolesLocationX,
  gpHolesLocationY,
  gpHolesNotes,
  gpGridsThickness,
  gpGridsColor,
  gpGridsColorCustom,
  gpOffsetLite,
  gpOffsetFill,
  gpOffsetSide,
  gpOffsetOffset,
  gpOffsetNotes,
} from './inputs';

export const ServiceTypes = [
  { label: 'Replace', value: 'replace' },
  { label: 'All New', value: 'all_new' },
  { label: 'Removal Only', value: 'removal_only' },
  { label: 'Removal/Reinstall Same Day', value: 'R/RSD' },
  { label: 'Removal/Reinstall Different Day', value: 'rrdd' },
  { label: 'Deliver Only', value: 'deliver_only' },
  { label: 'Install from Customer Stock', value: 'install_stock' },
];

export const Shapes = [
  { value: 'rectangular_square', label: 'Rectangular/Square' },
  {
    label: 'Circle',
    value: '1_Circle',
  },
  {
    label: 'Circle Half (A)',
    value: '2_CIRCLE_HALF(A)',
  },
  { value: '3_CIRCLE_HALF(B)', label: 'Circle Half (B)' },
  { value: '4_POINT_LINE', label: 'Point Line' },
  { value: '5_OVAL', label: 'Oval' },
  { value: '6_ELIPSE', label: 'Elipse' },
  { value: '7_ARCH_QUARTER(LEFT)', label: 'Arch Quarter (Left)' },
  { value: '8_ARCH_QUARTER(RIGHT)', label: 'Arch Quarter (Right' },
  { value: '9_CURVED_CYLINDER', label: 'Curved Cylinder' },
  { value: '10_CURVED_DOUBLE_TANGENT', label: 'Curved Double Tangent' },
  { value: '11_CURVED_LEFT_TANGENT', label: 'Curved Left Tangent' },
  { value: '12_CURVED_RIGHT_TANGENT', label: 'Curved Rigth Tangent' },
  { value: '13_GABLE_ARCH_A', label: 'Gable Arch A' },
  { value: '14_GABLE_ARCH_B', label: 'Gable Arch B' },
  { value: '15_GABLE_(LEFT)', label: 'Gable (Left)' },
  { value: '16_GABLE_(RIGHT)', label: 'Gable (Right)' },
  { value: '17_PARALLELOGRAM_A', label: 'Parallelogram A' },
  { value: '18_PARALLELOGRAM_B', label: 'Parallelogram B' },
  { value: '19_PENTAGON_LEFT', label: 'Pentagon Left' },
  { value: '20_PENTAGON_RIGHT', label: 'Pentagon Right' },
  { value: '21_ROUNDER_CORNERS', label: 'Rounder Corners' },
  { value: '22_TRAPEZIUM_SPECIAL_1.', label: 'Trapezium Special 1.' },
  { value: '23_TRAPEZIUM_SPECIAL_2', label: 'Trapezium Special 2' },
  { value: '24_TRAPEZIUM_SPECIAL_3', label: 'Trapezium Special 3' },
  { value: '25_TRAPEZIUM_SPECIAL_4', label: 'Trapezium Special 4' },
  { value: '26_TRAPEZIUM_SPECIAL_5', label: 'Trapezium Special 5' },
  { value: '27_TRAPEZIUM_SPECIAL_6', label: 'Trapezium Special 6' },
  { value: '28_TRAPEZIUM_SPECIAL_7', label: 'Trapezium Special 7' },
  { value: '29_TRAPEZIUM_SPECIAL_8', label: 'Trapezium Special 8' },
  { value: '30_TRAPEZOID_ISOSCELES', label: 'Trapezoid Isosceles' },
  { value: '31_TRAPEZOID_OBSTUSE(LEFT)', label: 'Trapezoid Obstuse (Left)' },
  { value: '32_TRAPEZOID_OBSTUSE(RIGHT)', label: 'Trapezoid Obstuse (Right)' },
  { value: '33_TRAPEZOID_SCALENE_(LEFT)', label: 'Trapezoid Scalene (Left)' },
  { value: '34_TRAPEZOID_SCALENE_(RIGHT)', label: 'Trapezoid Scalene (Right)' },
  { value: '35_TRIANGLE_ACUTE_A(LEFT)', label: 'Triangle Acute A (Left)' },
  { value: '36_TRIANGLE_ACUTE_A(RIGHT)', label: 'Triangle Acute A (Right)' },
  { value: '37_TRIANGLE_ACUTE_B(LEFT)', label: 'Triangle Acute B (Left)' },
  { value: '38_TRIANGLE_ACUTE_B(RIGHT)', label: 'Triangle Acute B (Right)' },
  { value: '39_TRIANGLE_EQUILATERAL', label: 'Triangle Equilateral' },
  { value: '40_TRIANGLE_ISOCELES', label: 'Triangle Isoceles' },
  { value: '41_TRIANGLE_RIGHT(LEFT)', label: 'Triangle Right (Left)' },
  { value: '42_TRIANGLE_RIGHT(RIGHT)', label: 'Triangle Right (Right)' },
];

export const ShapeOptions = {
  '1_Circle': {
    picture: type_1_rh_gable,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '2_CIRCLE_HALF(A)': {
    picture: type_2_lh_gable,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '3_CIRCLE_HALF(B)': {
    picture: type_3_rh_angle_corner,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '4_POINT_LINE': {
    picture: type_4_lh_angle_corner,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '5_OVAL': {
    picture: type_5_lh_trapezoid,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '6_ELIPSE': {
    picture: type_6_rh_trapezoid,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '7_ARCH_QUARTER(LEFT)': {
    picture: type_7_rh_irregular_angle_crn,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '8_ARCH_QUARTER(RIGHT)': {
    picture: type_8_lh_irregular_angle_crn,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '9_CURVED_CYLINDER': {
    picture: type_9_lh_angle_corner_offset,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '10_CURVED_DOUBLE_TANGENT': {
    picture: type_10_rh_angle_corner_offset,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '11_CURVED_LEFT_TANGENT': {
    picture: type_11_o_s_left_up_out,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '12_CURVED_RIGHT_TANGENT': {
    picture: type_12_o_s_right_up_out,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '13_GABLE_ARCH_A': {
    picture: type_13_o_s_right_up_in,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '14_GABLE_ARCH_B': {
    picture: type_14_o_s_right_down_out,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '15_GABLE_(LEFT)': {
    picture: type_15_o_s_left_down_out,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '16_GABLE_(RIGHT)': {
    picture: type_16_o_s_left_up_in,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESD,
      GLASSSIZESF,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '17_PARALLELOGRAM_A': {
    picture: type_19_irregular_octagon,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '18_PARALLELOGRAM_B': {
    picture: type_20_equilateral_triangle,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '19_PENTAGON_LEFT': {
    picture: type_21_regular_pentagon,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '20_PENTAGON_RIGHT': {
    picture: type_22_regular_hexagon,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '21_ROUNDER_CORNERS': {
    picture: type_24_regular_octagon,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '22_TRAPEZIUM_SPECIAL_1': {
    picture: type_25_rh_triangle,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '23_TRAPEZIUM_SPECIAL_2': {
    picture: type_26_lh_triangle,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '24_TRAPEZIUM_SPECIAL_3': {
    picture: type_37_rh_dog_house,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '25_TRAPEZIUM_SPECIAL_4': {
    picture: type_38_lh_dog_house,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '26_TRAPEZIUM_SPECIAL_5': {
    picture: type_39_lh_irreg_trapezoid,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '27_TRAPEZIUM_SPECIAL_6': {
    picture: type_40_rh_irreg_trapezoid,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '28_TRAPEZIUM_SPECIAL_7': {
    picture: type_45_rh_right_triangle,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '29_TRAPEZIUM_SPECIAL_8': {
    picture: type_46,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '30_TRAPEZOID_ISOSCELES': {
    picture: type_47,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '31_TRAPEZOID_OBSTUSE(LEFT)': {
    picture: type_48,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '32_TRAPEZOID_OBSTUSE(RIGHT)': {
    picture: type_49,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '33_TRAPEZOID_SCALENE_(LEFT)': {
    picture: type_50,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '34_TRAPEZOID_SCALENE_(RIGHT)': {
    picture: type_51,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '35_TRIANGLE_ACUTE_A(LEFT)': {
    picture: type_52,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '36_TRIANGLE_ACUTE_A(RIGHT)': {
    picture: type_53,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '37_TRIANGLE_ACUTE_B(LEFT)': {
    picture: type_54,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '38_TRIANGLE_ACUTE_B(RIGHT)': {
    picture: type_55,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '39_TRIANGLE_EQUILATERAL': {
    picture: type_56,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '40_TRIANGLE_ISOCELES': {
    picture: type_57,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '41_TRIANGLE_RIGHT(LEFT)': {
    picture: type_58,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
  '42_TRIANGLE_RIGHT(RIGHT)': {
    picture: type_59,
    fields: [
      GLASSSIZESA,
      GLASSSIZESB,
      GLASSSIZESC,
      GLASSSIZESD,
      GLASSSIZESE,
      GLASSSIZESF,
      GLASSSIZESG,
      GLASSSIZESH,
    ],
  },
};

/* -------- */
export const GlassTypes = [
  { value: 'igu-window', label: 'IGU (Window)' },
  { value: 'igu-door-lite', label: 'IGU (Door Lite)' },
  { value: 'igu-sky-lite-temp-or-lami', label: 'IGU (Sky Lite) *Temp/Lami' },
  { value: 'igu-lami-temp-lami', label: 'IGU (LAMI) *Temp/Lami' },
  { value: 'igu-spandrel', label: 'IGU (Spandrel)' },
  { value: 'igu-patio-door-or-panel', label: 'IGU (Patio Door/Panel)' },
  { value: 'IGU (VIRACON)', label: 'IGU (VIRACON)' },
  { value: 'lami-door-lite', label: 'LAMI (Door Lite)' },
  { value: 'lami-window', label: 'LAMI (Window)' },
  { value: 'lami-sky-lite', label: 'LAMI (Sky Lite)' },
  { value: 'PLATE (Spandrel)', label: 'PLATE (Spandrel)' },
  { value: 'plate-window', label: 'PLATE (Window)' },
  { value: 'mirror-', label: 'Mirror' },
  { value: 'plexi-', label: 'Plexi' },
  { value: 'tabletops-', label: 'Table Tops' },
  { value: 'lexan-', label: 'Lexan' },
  { value: 'bullet-proof-single-panel', label: 'Bullet Proof (Single Panel)' },
  { value: 'bullet-proof-igu', label: 'Bullet Proof (IGU)' },
  { value: 'back-painted-glass', label: 'Back Painted Glass' },
  { value: 'curved-single-pane', label: 'Curved (Single Pane)' },
  { value: 'curved-igu', label: 'Curved (IGU)' },
  { value: 'curved-lami', label: 'Curved (LAMI)' },
  { value: 'Glass-Floor', label: ' Glass Floor ' },
  { value: 'Glass-Step', label: ' Glass Step ' },
  { value: 'Glass-Handrail', label: ' Glass Handrail' },
];

export const GlassTypesFields = {
  'igu-window': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
  },
  'igu-door-lite': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
  },
  'igu-sky-lite-temp-or-lami': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
  },
  'igu-lami-temp-lami': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
    /*fields: [
      gtOverThick,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtSpacerColor,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
    ],*/
  },
  'igu-spandrel': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
    /*fields: [
      gtOverThick,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtSpacerColor,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
    ],*/
  },
  'igu-patio-door-or-panel': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
    /*fields: [
      gtOverThick,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtSpacerColor,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
    ],*/
  },
  'IGU (VIRACON)': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
  },
  'lami-door-lite': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'lami-window': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'lami-sky-lite': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'PLATE (Spandrel)': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'plate-window': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'mirror-': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'plexi-': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'tabletops-': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'lexan-': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'bullet-proof-single-panel': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'bullet-proof-igu': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
    /*fields: [
      gtOverThick,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtSpacerColor,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
    ],*/
  },
  'back-painted-glass': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'curved-single-pane': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'curved-igu': {
    fields: [
      gtOverThick,
      gtExteriorLite,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtexCoatingFamily,
      gtSpacerColor,
      gtInteriorLite,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
      gtinCoatingFamily,
    ],
    /*fields: [
      gtOverThick,
      gtexThick,
      gtexTreatment,
      gtexColor,
      gtexCoating,
      gtSpacerColor,
      gtinThick,
      gtinTreatment,
      gtinColor,
      gtinCoating,
    ],*/
  },
  'curved-lami': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'Glass-Floor': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'Glass-Step': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
  'Glass-Handrail': {
    fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating, gtexCoatingFamily],
    //fields: [gtexThick, gtexTreatment, gtexColor, gtexCoating],
  },
};
/* ------------ */
export const SetTypes = [
  { value: 'storefront_door_beads_1_over_4', label: 'Storefront Door Beads, 1/4' },
  { value: 'storefront_door_beads_1', label: 'Storefront Door Beads, 1' },
  { value: 'screw_beads_metal', label: 'Screw Beads Metal' },
  { value: 'snap-beads-aluminium-interior', label: 'Snap Beads, Aluminium, Interior' },
  { value: 'snap-beads-vinyl-interior', label: 'Snap Beads,Vinyl, Interior ' },
  { value: 'snap-beads-aluminium_exterior', label: 'Snap Beads, Aluminium, Exterior ' },
  { value: 'snap_beads_vinyl_exterior', label: 'Snap Beads, Vinyl, Exterior ' },
  { value: 'snap_beads_wood_interior', label: 'Snap Beads, Wood, Interior ' },
  { value: 'snap_beads_wood_exterior', label: 'Snap Beads, Wood, Exterior ' },
  { value: 'flush_1_over_4_top_exterior', label: 'Flush 1/4, Top, Exterior ' },
  { value: 'flush_1_over_4_top_interior', label: 'Flush 1/4, Top, Interior' },
  { value: 'flush_1_over_4_bottom_exterior', label: 'Flush 1/4, Bottom, Exterior' },
  { value: 'flush_1_over_4_bottom_interior', label: 'Flush 1/4, Bottom, Interior' },
  { value: 'flush_1_over_4_left_right_exterior', label: 'Flush 1/4, Left/Right, Exterior' },
  { value: 'flush_1_over_4_left_right_interior', label: 'Flush 1/4, Left/Right, Interior' },
  { value: 'flush_igu_top_exterior', label: 'Flush (IGU), Top, Exterior' },
  { value: 'flush_igu_top_interior', label: 'Flush (IGU), Top, Interior' },
  { value: 'flush_igu_bottom_exterior', label: 'Flush (IGU), Bottom, Exterior' },
  { value: 'flush_igu_bottom_interior', label: 'Flush (IGU), Bottom, Interior' },
  { value: 'flush_igu_left_right_exterior', label: 'Flush (IGU), Left/Right, Exterior' },
  { value: 'flush_igu_left_right_interior', label: 'Flush (IGU), Left/Right, Interior' },
  {
    value: 'wrap_around_window_vinyl_single_pane',
    label: 'Wrap Around *WINDOW* Vinyl (Single Pane)',
  },
  {
    value: 'wrap_around_window_aluminum_single_pane',
    label: 'Wrap Around *WINDOW* Aluminum (Single Pane)',
  },
  {
    value: 'wrap_around_window_wood_single_pane',
    label: 'Wrap Around *WINDOW* Wood (Single Pane)',
  },
  { value: 'wrap_around_window_vinyl_igu', label: 'Wrap Around *WINDOW* Vinyl (IGU) ' },
  { value: 'wrap_around_window_aluminum_igu', label: 'Wrap Around *WINDOW* Aluminum (IGU)' },
  { value: 'wrap_around_window_wood_igu', label: 'Wrap Around *WINDOW* Wood (IGU)' },
  { value: 'wrap_around_patio_door_single_pane', label: 'Wrap Around *PATIO DOOR*  (Single Pane)' },
  { value: 'wrap_around_patio_door_igu', label: 'Wrap Around *PATIO DOOR*  (IGU)' },
  {
    value: 'curtain_wall_all_sides_captured_exterior',
    label: 'Curtain Wall, All Sides Captured, Exterior',
  },
  {
    value: 'curtain_wall_all_sides_captured_interior',
    label: 'Curtain Wall, All Sides Captured, Interior',
  },
  { value: 'curtain_wall_butt_glazed_left_only', label: 'Curtain Wall, Butt Glazed, Left Only ' },
  { value: 'curtain_wall_butt_glazed_right_only', label: 'Curtain Wall, Butt Glazed, Right Only ' },
  { value: 'curtain_wall_butt_glazed_left_right', label: 'Curtain Wall, Butt Glazed, Left/Right' },
  { value: 'curtain_wall_butt_glazed_top_only', label: 'Curtain Wall, Butt Glazed, Top Only ' },
  {
    value: 'curtain_wall_butt_glazed_bottom_only',
    label: 'Curtain Wall, Butt Glazed, Bottom Only',
  },
  { value: 'curtain_wall_butt_glazed_top_bottom', label: 'Curtain Wall, Butt Glazed, Top/Bottom ' },
  {
    value: 'structurally_glazed_all_sides_butt_glazed',
    label: 'Structurally Glazed, 4 Sided Butt Glazed ',
  },
  { value: 'zipper_wall', label: 'Zipper wall ' },
  { value: 'seamless_mullion', label: 'Seamless Mullion ' },
  { value: 'screw_beads', label: 'Screw Beads ' },
  { value: 'u_channel_b_t', label: 'U-channel, Bottom (1x1), Top (2x1)' },
  { value: 'sash_and_division_bar', label: 'Sash & Division Bar ' },
  { value: 'sash_single_pane', label: 'Sash (Single Pane) ' },
  { value: 'sagh_igu', label: 'Sash (IGU)' },
  { value: 'lug_sash', label: 'Lug Sash' },
  { value: 'hack_and_glaze_steel_interior', label: 'Hack & Glaze, Steel, Interior' },
  { value: 'hack_and_glaze_steel_exterior', label: 'Hack & Glaze, Steel, Exterior' },
  { value: 'hack_and_glaze_wood_interior', label: 'Hack & Glaze, Wood, Interior' },
  { value: 'hack_and_glaze_wood_exterior', label: 'Hack & Glaze, Wood, Exterior' },
  { value: 'table_top', label: 'Table Top' },
  { value: 'stand_Off', label: 'Stand Off’s' },
  { value: 'lay_in_canopy', label: 'Lay In (Canopy)' },
  { value: 'handrail', label: 'Handrail ' },
  { value: 'vision_kit_all_new', label: 'Vision Kit (ALL NEW)' },
  { value: 'vision_kit_glass_only', label: 'Vision Kit (GLASS ONLY)' },
  { value: 'j_channel_b', label: 'J-Channel (BOTTOM)' },
  { value: 'j_channel_t', label: 'J-Channel (TOP)' },
  { value: 'j_channel_t_b', label: 'J-Channel (TOP/BOTTOM)' },
  { value: 'j_channel_t_b_l_r', label: 'J-Channel (T/B/L/R)' },
  { value: 'l_channel_b', label: 'L-Channel (BOTTOM) ' },
  { value: 'l_channel_t', label: 'L-Channel (TOP)' },
  { value: 'l_channel_t_b', label: 'L-Channel (TOP/BOTTOM)' },
  { value: 'l_channel_t_b_l_r', label: 'L-Channel (T/B/L/R)' },
  { value: 'caulk_mastic_only', label: 'Caulk/Mastic Only' },
];

/* FABRICATION */
export const FabHoles = [
  gpHolesType,
  gpHolesExtSize,
  gpHolesIntSize,
  gpHolesStartX,
  gpHolesStartY,
  gpHolesLocationX,
  gpHolesLocationY,
  gpHolesNotes,
];

export const FabGrids = [gpGridsThickness, gpGridsColor, gpGridsColorCustom];

export const FabOffset = [gpOffsetLite, gpOffsetFill, gpOffsetSide, gpOffsetOffset, gpOffsetNotes];
