export const glassOverallThickness = [
  { value: '1', label: '1' },
  { value: '7/8', label: '7/8' },
  { value: '3/4', label: '3/4' },
  { value: '5/8', label: '5/8' },
  { value: '1/2', label: '1/2' },
  { value: '7/16', label: '7/16' },
  { value: '9/16', label: '9/16' },
  { value: '3/8', label: '3/8' },
  { value: '5/16', label: '5/16' },
  { value: '1/4', label: '1/4' },
  { value: '3/16', label: '3/16' },
  { value: '1/8', label: '1/8' },
  { value: '1/16', label: '1/16' },
  { value: '6', label: '6' },
  { value: '5 15/16', label: '5 15/16' },
  { value: '5 7/8', label: '5 7/8' },
  { value: '5 13/16', label: '5 13/16' },
  { value: '5 3/4', label: '5 3/4' },
  { value: '5 11/16', label: '5 11/16' },
  { value: '5 5/8', label: '5 5/8' },
  { value: '5 9/16', label: '5 9/16' },
  { value: '5 1/2', label: '5 1/2' },
  { value: '5 7/16', label: '5 7/16' },
  { value: '5 3/8', label: '5 3/8' },
  { value: '5 5/16', label: '5 5/16' },
  { value: '5 1/4', label: '5 1/4' },
  { value: '5 3/16', label: '5 3/16' },
  { value: '5 1/8', label: '5 1/8' },
  { value: '5 1/16', label: '5 1/16' },
  { value: '5', label: '5' },
  { value: '4 15/16', label: '4 15/16' },
  { value: '4 7/8', label: '4 7/8' },
  { value: '4 13/16', label: '4 13/16' },
  { value: '4 3/4', label: '4 3/4' },
  { value: '4 11/16', label: '4 11/16' },
  { value: '4 5/8', label: '4 5/8' },
  { value: '4 9/16', label: '4 9/16' },
  { value: '4 1/2', label: '4 1/2' },
  { value: '4 7/16', label: '4 7/16' },
  { value: '4 3/8', label: '4 3/8' },
  { value: '4 5/16', label: '4 5/16' },
  { value: '4 1/4', label: '4 1/4' },
  { value: '4 3/16', label: '4 3/16' },
  { value: '4 1/8', label: '4 1/8' },
  { value: '4 1/16', label: '4 1/16' },
  { value: '4', label: '4' },
  { value: '3 15/16', label: '3 15/16' },
  { value: '3 7/8', label: '3 7/8' },
  { value: '3 13/16', label: '3 13/16' },
  { value: '3 3/4', label: '3 3/4' },
  { value: '3 11/16', label: '3 11/16' },
  { value: '3 5/8', label: '3 5/8' },
  { value: '3 9/16', label: '3 9/16' },
  { value: '3 1/2', label: '3 1/2' },
  { value: '3 7/16', label: '3 7/16' },
  { value: '3 3/8', label: '3 3/8' },
  { value: '3 5/16', label: '3 5/16' },
  { value: '3 1/4', label: '3 1/4' },
  { value: '3 3/16', label: '3 3/16' },
  { value: '3 1/8', label: '3 1/8' },
  { value: '3 1/16', label: '3 1/16' },
  { value: '3', label: '3' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '2 7/8', label: '2 7/8' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2 3/4', label: '2 3/4' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2 5/8', label: '2 5/8' },
  { value: '2 9/16', label: '2 9/16' },
  { value: '2 1/2', label: '2 1/2' },
  { value: '2 7/16', label: '2 7/16' },
  { value: '2 3/8', label: '2 3/8' },
  { value: '2 5/16', label: '2 5/16' },
  { value: '2 1/4', label: '2 1/4' },
  { value: '2 3/16', label: '2 3/16' },
  { value: '2 1/8', label: '2 1/8' },
  { value: '2 1/16', label: '2 1/16' },
  { value: '2', label: '2' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '1 7/8', label: '1 7/8' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1 3/4', label: '1 3/4' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1 5/8', label: '1 5/8' },
  { value: '1 9/16', label: '1 9/16' },
  { value: '1 1/2', label: '1 1/2' },
  { value: '1 7/16', label: '1 7/16' },
  { value: '1 3/8', label: '1 3/8' },
  { value: '1 5/16', label: '1 5/16' },
  { value: '1 1/4', label: '1 1/4' },
  { value: '1 3/16', label: '1 3/16' },
  { value: '1 1/8', label: '1 1/8' },
  { value: '1 1/16', label: '1 1/16' },
  { value: '15/16', label: '15/16' },
  { value: '13/16', label: '13/16' },
  { value: '11/16', label: '11/16' },
];

export const elThickness = [
  { value: '1/16"', label: '1/16"' },
  { value: '1/8"', label: '1/8"' },
  { value: '3/16"', label: '3/16"' },
  { value: '1/4"', label: '1/4"' },
  { value: '3/8"', label: '3/8"' },
  { value: '1/2"', label: '1/2"' },
  { value: '5/8"', label: '5/8"' },
  { value: '3/4"', label: '3/4"' },
  { value: '1/4" LAMI (1/8, 015, 1/8)', label: '1/4" LAMI (1/8, 015, 1/8)' },
  { value: '1/4" LAMI  (1/8, 030,1/8)', label: '1/4" LAMI  (1/8, 030,1/8)' },
  { value: '5/16" LAMI  (1/8, 060, 1/8)', label: '5/16" LAMI  (1/8, 060, 1/8)' },
  { value: '5/16" LAMI (1/8, 090 ,1/8)', label: '5/16" LAMI (1/8, 090 ,1/8)' },
  { value: '3/8" LAMI (3/16, 030, 3/16)', label: '3/8" LAMI (3/16, 030, 3/16)' },
  { value: '3/8" LAMI (1/4, 030, 1/8)', label: '3/8" LAMI (1/4, 030, 1/8)' },
  { value: '3/8" LAMI (1/4, 060, 1/8)', label: '3/8" LAMI (1/4, 060, 1/8)' },
  { value: '7/16" LAMI (3/16, 060, 3/16)', label: '7/16" LAMI (3/16, 060, 3/16)' },
  { value: '7/16" LAMI (3/16, 090, 3/16)', label: '7/16" LAMI (3/16, 090, 3/16)' },
  { value: '1/2" LAMI (1/4, 030, 1/4)', label: '1/2" LAMI (1/4, 030, 1/4)' },
  { value: '1/2" LAMI (1/4, 060, 1/4)', label: '1/2" LAMI (1/4, 060, 1/4)' },
  { value: '9/16" LAMI (1/4, 060,1/4)', label: '9/16" LAMI (1/4, 060,1/4)' },
  { value: '9/16" LAMI (1/4, 090, 1/4)', label: '9/16" LAMI (1/4, 090, 1/4)' },
  { value: '5/8" LAMI (3/8, 030, 1/4)', label: '5/8" LAMI (3/8, 030, 1/4)' },
  { value: '3/4" LAMI (1/2, 060, 1/4)', label: '3/4" LAMI (1/2, 060, 1/4)' },
  { value: '13/16" LAMI (3/8, 060, 3/8)', label: '13/16" LAMI (3/8, 060, 3/8)' },
  { value: '13/16" LAMI (3/8, 090, 3/8)', label: '13/16" LAMI (3/8, 090, 3/8)' },
];
export const elTreatment = [
  { value: 'Annealed', label: 'Annealed' },
  { value: 'Lami', label: 'Lami' },
  { value: 'Tempered', label: 'Tempered' },
  { value: 'H.S.', label: 'H.S.' },
  { value: 'Tempered_Lami', label: 'Tempered/Lami' },
];

export const elColor = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Gray', label: 'Gray ' },
  { value: 'Clear MIRROR', label: 'Clear MIRROR' },
  { value: 'Clear MIRROR w/SafetyBacking', label: 'Clear MIRROR w/SafetyBacking' },
  { value: 'Gray MIRROR', label: 'Gray MIRROR' },
  { value: 'Gray MIRROR w/SafetyBacking', label: 'Gray MIRROR w/SafetyBacking' },
  { value: 'Bronze MIRROR', label: 'Bronze MIRROR' },
  { value: 'Bronze MIRROR w/SafetyBacking', label: 'Bronze MIRROR w/SafetyBacking' },
  { value: 'MirrorPane (2WAY)', label: 'MirrorPane (2WAY)' },
  { value: 'MirrorView  (low ambient)', label: 'MirrorView  (low ambient)' },
  { value: 'MirrorView50/50 (high ambient)', label: 'MirrorView50/50 (high ambient)' },
  { value: 'Low Iron ', label: 'Low Iron' },
  { value: 'Starfire ', label: 'Starfire ' },
  { value: 'Optiwhite', label: 'Optiwhite' },
  { value: 'UltraClear', label: 'UltraClear' },
  { value: 'ExtraClear', label: 'ExtraClear' },
  { value: 'Acuity ', label: 'Acuity ' },
  { value: 'OptiGray (light)', label: 'OptiGray (light)' },
  { value: 'CrystalGray (light)', label: 'CrystalGray (light)' },
  { value: 'Majestic Grey (light)', label: 'Majestic Grey (light)' },
  { value: 'Graylite2 (black)', label: 'Graylite2 (black)' },
  { value: 'SuperGray (black)', label: 'SuperGray (black)' },
  { value: 'Midnight Gray (black) ', label: 'CleaMidnight Gray (black)' },
  { value: 'White', label: 'White' },
  { value: 'Acid/Satin Etch', label: 'Acid/Satin Etch' },
  { value: 'Acid/Satin Low Iron', label: 'Acid/Satin Low Iron' },
  { value: 'Acid/Satin Low Iron (Double Sided)', label: 'Acid/Satin Low Iron (Double Sided)' },
  { value: 'Green ', label: 'Green ' },
  { value: 'Solexia ', label: 'Solexia ' },
  { value: 'Atlantica', label: 'Atlantica' },
  { value: 'EverGreen', label: 'EverGreen' },
  { value: 'Pure Green ', label: 'Pure Green' },
  { value: 'Forrest Green ', label: 'Forrest Green' },
  { value: 'Blue ', label: 'Blue ' },
  { value: 'BlueGreen', label: 'BlueGreen' },
  { value: 'Azuria ', label: 'Azuria ' },
  { value: 'Pacifica ', label: 'Pacifica ' },
  { value: 'SolarBlue', label: 'SolarBlue' },
  { value: 'OptiBlue ', label: 'OptiBlue ' },
  { value: 'ArticBlue', label: 'ArticBlue' },
  { value: 'GraphiteBlue ', label: 'GraphiteBlue ' },
  { value: 'CrystalBlue', label: 'CrystalBlue' },
  { value: 'Sky Blue ', label: 'Sky Blue' },
  { value: 'Midnight Blue ', label: 'Midnight Blue' },
  { value: 'Pure Blue ', label: 'Pure Blue' },
  { value: 'Gold', label: 'Gold' },
  { value: 'SunsetGold', label: 'SunsetGold' },
  { value: '1-CLEAR', label: '1-CLEAR' },
  { value: '2-GREEN', label: '2-GREEN' },
  { value: '3-GRAY', label: '3-GRAY' },
  { value: '4-BRONZE', label: '4-BRONZE' },
  { value: '6-BLUE.GREEN', label: '6-BLUE.GREEN' },
  { value: '7-AZURIA', label: '7-AZURIA' },
  { value: '13-STARFIRE (low iron)', label: '13-STARFIRE (low iron)' },
  { value: '18- OPTIBLUE', label: '18- OPTIBLUE' },
  { value: '19-CRYSTALGRAY', label: '19-CRYSTALGRAY' },
  { value: '24-OPTIWHITE (low iron)', label: '24-OPTIWHITE (low iron)' },
  { value: '26-SOLARBLUE', label: '26-SOLARBLUE' },
  { value: '27-PACIFICA', label: '27-PACIFICA' },
  { value: '30-OPTIGRAY', label: '30-OPTIGRAY' },
  { value: '31-ULTRACLEAR (LOW IRON)', label: '31-ULTRACLEAR (LOW IRON)' },
  { value: '32-CRYSTALBLUE', label: '32-CRYSTALBLUE' },
  { value: '33-MAJESTICGREY', label: '33-MAJESTICGREY' },
  { value: '35- Pure Mid Iron', label: '35- Pure Mid Iron' },
  { value: '5-BLUE (NOT AVAILABLE) ', label: '5-BLUE (NOT AVAILABLE)' },
  { value: '8-EVERGREEN (NOT AVAILABLE) ', label: '8-EVERGREEN (NOT AVAILABLE)' },
  { value: '29-GRAPHITE (NOT AVAILABLE) ', label: '29-GRAPHITE (NOT AVAILABLE)' },
  { value: 'FireLite (20-90MIN)', label: 'FireLite (20-90MIN)' },
  { value: 'FireLitePlus (20-180MIN)', label: 'FireLitePlus (20-180MIN)' },
  { value: 'FireliteNT (20-180MIN)', label: 'FireliteNT (20-180MIN)' },
  { value: 'FireliteIGU (20-180MIN)', label: 'FireliteIGU (20-180MIN)' },
  { value: 'Wire - SQUARE Polished ', label: 'Wire - SQUARE Polished' },
  { value: 'Wire - SQUARE Patterned ', label: 'Wire - SQUARE Patterned' },
  { value: 'Wire - DIAMOND Polished ', label: 'Wire - DIAMOND Polished' },
  { value: 'Wire - DIAMOND Patterned ', label: 'Wire - DIAMOND Patterned' },
  { value: 'Wirelite (FIRE 45Min) ', label: 'Wirelite (FIRE 45Min)' },
  { value: 'Wirelite (FIRE 20-90Min) ', label: 'Wirelite (FIRE 20-90Min)' },
  { value: 'Altdeutsch K', label: 'Altdeutsch K' },
  { value: 'Antique', label: 'Antique' },
  { value: 'Aqualite', label: 'Aqualite' },
  { value: 'Aquatex', label: 'Aquatex' },
  { value: 'Arctic', label: 'Arctic' },
  { value: 'Artico', label: 'Artico' },
  { value: 'Austral ', label: 'Austral' },
  { value: 'Autumn', label: 'Autumn' },
  { value: 'Bamboo', label: 'Bamboo' },
  { value: 'Bubbles', label: 'Bubbles' },
  { value: 'Cast', label: 'Cast' },
  { value: 'Chantilly ', label: 'Chantilly' },
  { value: 'Charcoal Sticks ', label: 'Charcoal Sticks' },
  { value: 'Chinchilla', label: 'Chinchilla' },
  { value: 'Clear Antique', label: 'Clear Antique' },
  { value: 'Contora ', label: 'Contora' },
  { value: 'Corduroy', label: 'Corduroy' },
  { value: 'Cotswold ', label: 'Cotswold' },
  { value: 'Cross Reeded 1/2"', label: 'Cross Reeded 1/2"' },
  { value: 'Cross Reeded Small', label: 'Cross Reeded Small' },
  { value: 'Delta Frost', label: 'Delta Frost' },
  { value: 'Digital ', label: 'Digital' },
  { value: 'Everglade ', label: 'Everglade' },
  { value: 'Flemish', label: 'Flemish' },
  { value: 'Florielle ', label: 'Florielle' },
  { value: 'Frost Etch 100', label: 'Frost Etch 100' },
  { value: 'Frost Etch 20', label: 'Frost Etch 20' },
  { value: 'Glacier', label: 'Glacier' },
  { value: 'Glue Chip', label: 'Glue Chip' },
  { value: 'Master Carre', label: 'Master Carre' },
  { value: 'Master Ligne', label: 'Master Ligne' },
  { value: 'Master Point', label: 'Master Point' },
  { value: 'Master Ray', label: 'Master Ray' },
  { value: 'Mayflower', label: 'Mayflower' },
  { value: 'Minister', label: 'Minister' },
  { value: 'Monumental', label: 'Monumental' },
  { value: 'Morisco', label: 'Morisco' },
  { value: 'Oak ', label: 'Oak' },
  { value: 'Pattern 516', label: 'Pattern 516' },
  { value: 'Pattern 62', label: 'Pattern 62' },
  { value: 'Pelerine', label: 'Pelerine' },
  { value: 'Rain', label: 'Rain' },
  { value: 'Rayado', label: 'Rayado' },
  { value: 'Redded 1/2"', label: 'Redded 1/2"' },
  { value: 'Reeded', label: 'Reeded' },
  { value: 'Seedy', label: 'Seedy' },
  { value: 'Seedy Import', label: 'Seedy Import' },
  { value: 'Seedy Marine', label: 'Seedy Marine' },
  { value: 'Skytech ', label: 'Skytech' },
  { value: 'Soft Hammered', label: 'Soft Hammered' },
  { value: 'Sparkle', label: 'Sparkle' },
  { value: 'Spraylite', label: 'Spraylite' },
  { value: 'Stipolite', label: 'Stipolite' },
  { value: 'Sycamore ', label: 'Sycamore' },
  { value: 'Taffeta', label: 'Taffeta' },
  { value: 'Textured Flutes', label: 'Textured Flutes' },
  { value: 'Thin Cross Reeded 1/8"', label: 'Thin Cross Reeded 1/8"' },
  { value: 'Thin Reeded 1/8"', label: 'Thin Reeded 1/8"' },
  { value: 'Thin Ribbed', label: 'Thin Ribbed' },
  { value: 'Warwick', label: 'Warwick' },
  { value: 'Waterwave', label: 'Waterwave' },
  { value: 'Winterlake', label: 'Winterlake' },
  { value: 'Yacare', label: 'Yacare' },
  { value: 'Azzurro (Antique Mirror)', label: 'Azzurro (Antique Mirror)' },
  { value: 'Biranco.E.Nero (Antique Mirror)', label: 'Clear' },
  { value: 'Byzantine (Antique Mirror)', label: 'Byzantine (Antique Mirror)' },
  { value: 'Cometa (Antique Mirror)', label: 'Cometa (Antique Mirror)' },
  { value: 'Golden (Antique Mirror)', label: 'Golden (Antique Mirror)' },
  { value: 'Grigio Argento (Antique Mirror)', label: 'Grigio Argento (Antique Mirror)' },
  { value: 'Luna (Antique Mirror)', label: 'Luna (Antique Mirror)' },
  { value: 'Marbled (Antique Mirror)', label: 'Marbled (Antique Mirror)' },
  { value: 'Marbled Bronze (Antique Mirror)', label: 'Marbled Bronze (Antique Mirror)' },
  { value: 'Mercury (Antique Mirror)', label: 'Mercury (Antique Mirror)' },
  { value: 'Meterora (Antique Mirror)', label: 'Meterora (Antique Mirror)' },
  { value: 'Policromo (Antique Mirror)', label: 'Policromo (Antique Mirror)' },
  { value: 'Saturno (Antique Mirror)', label: 'Saturno (Antique Mirror)' },
  { value: 'Speckled (Antique Mirror)', label: 'Speckled (Antique Mirror)' },
  { value: 'Tuscana (Antique Mirror)', label: 'Tuscana (Antique Mirror)' },
  { value: 'Vintage (Antique Mirror)', label: 'Vintage (Antique Mirror)' },
  { value: 'Vintage Bronze (Antique Mirror)', label: 'Vintage Bronze (Antique Mirror)' },
];

export const elCoatingFamily = [
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-30', label: 'VUE-30 ' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-39', label: 'VLE-39' },
];

export const elSide = [
  { value: 'NA', label: 'NA' },
  { value: '#1', label: '#1' },
  { value: '#2', label: '#2' },
  { value: '#3', label: '#3' },
  { value: '#4', label: '#4' },
  { value: '#5', label: '#5' },
  { value: '#6', label: '#6' },
];

export const elCoating = [
  { value: 'Low-E', label: 'Low-E' },
  { value: 'Solarban60', label: 'Solarban60' },
  { value: 'SolarbanZ50', label: 'SolarbanZ50' },
  { value: 'Solarban67', label: 'Solarban67' },
  { value: 'Solarban70', label: 'Solarban70' },
  { value: 'Solarban72', label: 'Solarban72' },
  { value: 'SolarbanZ75', label: 'SolarbanZ75' },
  { value: 'Solarban90', label: 'Solarban90' },
  { value: 'SolarbanR100', label: 'SolarbanR100' },
  { value: 'Sungate400', label: 'Sungate400' },
  { value: 'SolarCool', label: 'SolarCool' },
  { value: 'VistaCool', label: 'VistaCool' },
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VRE-4725', label: 'VRE-4725' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-30', label: 'VUE-30' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-39', label: 'VLE-39' },
  { value: 'VT-08 (RETIRED)', label: 'VT-08 (RETIRED)' },
  { value: 'VT-14 (RETIRED)', label: 'VT-14 (RETIRED)' },
  { value: 'VT-20  (RETIRED)', label: 'VT-20  (RETIRED)' },
  { value: 'VT-24 (RETIRED)', label: 'VT-24 (RETIRED)' },
  { value: 'VT-30 (RETIRED)', label: 'VT-30 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VA-08 (RETIRED)', label: 'VA-08 (RETIRED)' },
  { value: 'VA-14 (RETIRED)', label: 'VA-14 (RETIRED)' },
  { value: 'VA-20 (RETIRED)', label: 'VA-20 (RETIRED)' },
  { value: 'VA-22 (RETIRED)', label: 'VA-22 (RETIRED)' },
  { value: 'VA-24 (RETIRED)', label: 'VA-24 (RETIRED)' },
  { value: 'VA-30 (RETIRED)', label: 'VA-30 (RETIRED)' },
  { value: 'VA-35 (RETIRED)', label: 'VA-35 (RETIRED)' },
  { value: 'VA-40 (RETIRED)', label: 'VA-40 (RETIRED)' },
  { value: 'VB-08 (RETIRED)', label: 'VB-08 (RETIRED)' },
  { value: 'VB-14 (RETIRED)', label: 'VB-14 (RETIRED)' },
  { value: 'VB-20 (RETIRED)', label: 'VB-20 (RETIRED)' },
  { value: 'VB-24 (RETIRED)', label: 'VB-24 (RETIRED)' },
  { value: 'VB-30 (RETIRED)', label: 'VB-30 (RETIRED)' },
  { value: 'VB-35 (RETIRED)', label: 'VB-35 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VC-08 (RETIRED)', label: 'VC-08 (RETIRED)' },
  { value: 'VC-14 (RETIRED)', label: 'VC-14 (RETIRED)' },
  { value: 'VC-20 (RETIRED)', label: 'VC-20 (RETIRED)' },
  { value: 'VC-24 (RETIRED)', label: 'VC-24 (RETIRED)' },
  { value: 'VC-30 (RETIRED)', label: 'VC-30 (RETIRED)' },
  { value: 'VC-35 (RETIRED)', label: 'VC-35 (RETIRED)' },
  { value: 'VC-40 (RETIRED)', label: 'VC-40 (RETIRED)' },
  { value: 'VG-08 (RETIRED)', label: 'VG-08 (RETIRED)' },
  { value: 'VG-14 (RETIRED)', label: 'VG-14 (RETIRED)' },
  { value: 'VG-20 (RETIRED)', label: 'VG-20 (RETIRED)' },
  { value: 'VG-24 (RETIRED)', label: 'VG-24 (RETIRED)' },
  { value: 'VG-30 (RETIRED)', label: 'VG-30 (RETIRED)' },
  { value: 'VG-35 (RETIRED)', label: 'VG-35 (RETIRED)' },
  { value: 'VG-40 (RETIRED)', label: 'VG-40 (RETIRED)' },
  { value: 'VW-08 (RETIRED)', label: 'VW-08 (RETIRED)' },
  { value: 'VW-14 (RETIRED)', label: 'VW-14 (RETIRED)' },
  { value: 'VW-20 (RETIRED)', label: 'VW-20 (RETIRED)' },
  { value: 'VW-24 (RETIRED)', label: 'VW-24 (RETIRED)' },
  { value: 'VW-30 (RETIRED)', label: 'VW-30 (RETIRED)' },
  { value: 'VW-35 (RETIRED)', label: 'VW-35 (RETIRED)' },
  { value: 'VW-40 (RETIRED)', label: 'VW-40 (RETIRED)' },
  { value: 'VSE-30 (RETIRED)', label: 'VSE-30 (RETIRED)' },
  { value: 'Activ', label: 'Activ' },
  { value: 'Eclipse', label: 'Eclipse' },
  { value: 'EclipseAdvantage ', label: 'EclipseAdvantage ' },
  { value: 'EnergyAdvantage ', label: 'EnergyAdvantage ' },
  { value: 'SolarE', label: 'SolarE' },
  { value: 'AG43', label: 'AG43' },
  { value: 'AG50', label: 'AG50' },
  { value: 'SN75', label: 'SN75' },
  { value: 'SN75HT', label: 'SN75HT' },
  { value: 'SN70', label: 'SN70' },
  { value: 'SN70/35', label: 'SN70/35' },
  { value: 'SN70/37', label: 'SN70/37' },
  { value: 'SN68', label: 'SN68' },
  { value: 'SN63', label: 'SN63' },
  { value: 'SN63HT', label: 'SN63HT' },
  { value: 'SN51/28', label: 'SN51/28' },
  { value: 'SN40/23', label: 'SN40/23' },
  { value: 'SN29/18', label: 'SN29/18' },
  { value: 'SNL68', label: 'SNL68' },
  { value: 'SNR43', label: 'SNR43' },
  { value: 'SNX50', label: 'SNX50' },
  { value: 'SNX51/23', label: 'SNX51/23' },
  { value: 'SNX60', label: 'SNX60' },
  { value: 'SNX62/27', label: 'SNX62/27' },
  { value: 'SNX-L62/34', label: 'SNX-L62/34' },
  { value: 'HP LightBlue62/52', label: 'HP LightBlue62/52' },
  { value: 'HP Neutral60/40 ', label: 'HP Neutral60/40 ' },
  { value: 'HP Neutral50/32', label: 'HP Neutral50/32' },
  { value: 'HP Neutral41/33', label: 'HP Neutral41/33' },
  { value: 'Neutral78/65', label: 'Neutral78/65' },
  { value: 'Neutral50', label: 'Neutral50' },
  { value: 'Neutral40', label: 'Neutral40' },
  { value: 'HP Silver43/31', label: 'HP Silver43/31' },
  { value: 'HP RoyalBlue41/29', label: 'HP RoyalBlue41/29' },
  { value: 'HP Amber41/29', label: 'HP Amber41/29' },
  { value: 'HP BrightGreen40/29', label: 'HP BrightGreen40/29' },
  { value: 'HP Bronze40/27', label: 'HP Bronze40/27' },
  { value: 'HP Silver35/26', label: 'HP Silver35/26' },
  { value: 'SolarBronze20', label: 'SolarBronze20' },
  { value: 'SolarGray20', label: 'SolarGray20' },
  { value: 'SolarGold20', label: 'SolarGold20' },
  { value: 'SolarBrightGreen20', label: 'SolarBrightGreen20' },
  { value: 'HD Silver70', label: 'HD Silver70' },
  { value: 'HD Neutral67', label: 'HD Neutral67' },
  { value: 'HD Diamond66', label: 'HD Diamond66' },
  { value: 'HD LightBlue52', label: 'HD LightBlue52' },
  { value: 'HD RoyalBlue20', label: 'HD RoyalBlue20' },
  { value: 'HD SilverGray32', label: 'HD SilverGray32' },
  { value: 'HD Silver20', label: 'HD Silver20' },
  { value: 'HD Silver10', label: 'HD Silver10' },
  { value: 'ClimaGuard53/23', label: 'ClimaGuard53/23' },
  { value: 'ClimaGuard55/27', label: 'ClimaGuard55/27' },
  { value: 'ClimaGuard62/27', label: 'ClimaGuard62/27' },
  { value: 'ClimaGuard70/36', label: 'ClimaGuard70/36' },
  { value: 'ClimaGuard72/57', label: 'ClimaGuard72/57' },
  { value: 'ClimaGuard80/70', label: 'ClimaGuard80/70' },
  { value: 'ClimaGuardiS-20', label: 'ClimaGuardiS-20' },
  { value: 'N70/38 (SB60)', label: 'N70/38 (SB60)' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'R43/28', label: 'R43/28' },
  { value: 'R47/31', label: 'R47/31' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'LoĒ-i81', label: 'LoĒ-i81' },
  { value: 'LoĒ-i88', label: 'LoĒ-i88' },
  { value: 'LoĒ-x89', label: 'LoĒ-x89' },
  { value: 'LoĒ-180', label: 'LoĒ-180' },
  { value: 'LoĒ2-270', label: 'LoĒ2-270' },
  { value: 'LoĒ2-272', label: 'LoĒ2-272' },
  { value: 'LoĒ3-340', label: 'LoĒ3-340' },
  { value: 'LoĒ3-366', label: 'LoĒ3-366' },
  { value: 'ShowerGuard', label: 'ShowerGuard' },
  { value: 'EnduroShield', label: 'EnduroShield' },
  { value: 'Black SPANDREL (PLK, VIR) ', label: 'Black SPANDREL (PLK, VIR) ' },
  { value: 'Blue Frost SPANDREL (VIR) ', label: 'Blue Frost SPANDREL (VIR) ' },
  { value: 'Blue SPANDREL (VIR) ', label: 'Blue SPANDREL (VIR) ' },
  { value: 'Bronze SPANDREL (VIR) ', label: 'Bronze SPANDREL (VIR) ' },
  { value: 'Bronze Tone SPANDREL (PLK) ', label: 'Bronze Tone SPANDREL (PLK) ' },
  { value: 'Cactus Green SPANDREL (PLK) ', label: 'Cactus Green SPANDREL (PLK) ' },
  { value: 'Charcoal SPANDREL  (OBE) ', label: 'Charcoal SPANDREL  (OBE) ' },
  { value: 'Dark Bronze SPANDREL (VIR) ', label: 'Dark Bronze SPANDREL (VIR) ' },
  { value: 'Dark Gray  SPANDREL (VIR)', label: 'Dark Gray  SPANDREL (VIR)' },
  { value: 'Etch White SPANDREL (PLK) ', label: 'Etch White SPANDREL (PLK) ' },
  { value: 'EverGreen SPANDREL (OBE, VIR) ', label: 'EverGreen SPANDREL (OBE, VIR) ' },
  { value: 'Fog Gray  SPANDREL (VIR)', label: 'Fog Gray  SPANDREL (VIR)' },
  { value: 'Ford Blue SPANDREL (OBE) ', label: 'Ford Blue SPANDREL (OBE) ' },
  { value: 'Graphite SPANDREL (PLK) ', label: 'Graphite SPANDREL (PLK) ' },
  { value: 'Gray Black SPANDREL (OBE) ', label: 'Gray Black SPANDREL (OBE) ' },
  { value: 'Gray SPANDREL (VIR, PLK) ', label: 'Gray SPANDREL (VIR, PLK) ' },
  { value: 'Harmonic Blue SPANDREL (PLK) ', label: 'Harmonic Blue SPANDREL (PLK) ' },
  { value: 'Harmony Bronze SPANDREL', label: 'Harmony Bronze SPANDREL' },
  { value: 'High-Opacity White SPANDREL (VIR) ', label: 'High-Opacity White SPANDREL (VIR) ' },
  { value: 'Lava Bronze SPANDREL (OBE) ', label: 'Lava Bronze SPANDREL (OBE) ' },
  { value: 'Medium Gray SPANDREL (VIR) ', label: 'Medium Gray SPANDREL (VIR) ' },
  { value: 'Opaque White  SPANDREL (PLK)', label: 'Opaque White  SPANDREL (PLK)' },
  { value: 'Sage Green SPANDREL  (VIR)', label: 'Sage Green SPANDREL  (VIR)' },
  {
    value: 'Simulated Etch White SPANDREL  (PLK) ',
    label: 'Simulated Etch White SPANDREL  (PLK) ',
  },
  { value: 'SolarBronze SPANDREL  (OBE)', label: 'SolarBronze SPANDREL  (OBE)' },
  { value: 'SolarGray SPANDREL (OBE) ', label: 'SolarGray SPANDREL (OBE) ' },
  { value: 'Solex SPANDREL (OBE) ', label: 'Solex SPANDREL (OBE) ' },
  { value: 'Subdued Bronze SPANDREL (VIR) ', label: 'Subdued Bronze SPANDREL (VIR) ' },
  { value: 'Subdued Gray SPANDREL (VIR) ', label: 'Subdued Gray SPANDREL (VIR) ' },
  { value: 'Warm Gray SPANDREL (OBE, VIR) ', label: 'Warm Gray SPANDREL (OBE, VIR) ' },
  { value: 'White SPANDREL (OBE) ', label: 'White SPANDREL (OBE) ' },
  { value: 'Simulated Acid-Etch SPANDREL (VIR) ', label: 'Simulated Acid-Etch SPANDREL (VIR) ' },
  { value: 'Simulated Sandblast SPANDREL (VIR)', label: 'Simulated Sandblast SPANDREL (VIR)' },
  { value: 'Spice  SPANDREL (VIR)', label: 'Spice  SPANDREL (VIR)' },
  { value: 'Yellow Moon SPANDREL (VIR) ', label: 'Yellow Moon SPANDREL (VIR) ' },
  { value: '20%= 1/8 LINES (OBE)', label: '20%= 1/8 LINES (OBE)' },
  { value: '20%= 1/8 DOTS (OBE)', label: '20%= 1/8 DOTS (OBE)' },
  { value: '20%= 1/8 HOLES (OBE)', label: '20%= 1/8 HOLES (OBE)' },
  { value: '40%= 1/8 DOTS (OBE)', label: '40%= 1/8 DOTS (OBE)' },
  { value: '40%= 1/8 HOLES (OBE)', label: '40%= 1/8 HOLES (OBE)' },
  { value: '50%= 1/8 LINES (OBE)', label: '50%= 1/8 LINES (OBE)' },
  { value: '60%= 1/8 DOTS (OBE)', label: '60%= 1/8 DOTS (OBE)' },
  { value: '60%= 1/8 HOLES (OBE)', label: '60%= 1/8 HOLES (OBE)' },
  { value: '1/2 LINES ON 1” CENTERS  (OBE)', label: '1/2 LINES ON 1” CENTERS  (OBE)' },
  { value: '20%= 1/8” LINES: 2256  (VIR)', label: '20%= 1/8” LINES: 2256  (VIR)' },
  { value: '20%= 1/8” DOTS: 5065  (VIR)', label: '20%= 1/8” DOTS: 5065  (VIR)' },
  { value: '30%= 1/8” LINES:2973 (VIR)', label: '30%= 1/8” LINES:2973 (VIR)' },
  { value: '30%= 1/8” DOTS: 5959 (VIR)', label: '30%= 1/8” DOTS: 5959 (VIR)' },
  { value: '40%= 1/8” LINES : 2030 (VIR)', label: '40%= 1/8” LINES : 2030 (VIR)' },
  { value: '40%= 1/8” DOTS : 5006 (VIR)', label: '40%= 1/8” DOTS : 5006 (VIR)' },
  { value: '40%= PATTERN: 6017 (VIR)', label: '40%= PATTERN: 6017 (VIR)' },
  { value: '50%= 1/8” LINES: 2002 (VIR)', label: '50%= 1/8” LINES: 2002 (VIR)' },
  { value: '50%= 1" LINES: 2013 (VIR)', label: '50%= 1" LINES: 2013 (VIR)' },
  { value: '50%= 1/2” LINES: 2032 (VIR)', label: '50%= 1/2” LINES: 2032 (VIR)' },
  { value: '50%= 1/4” LINES: 2050 (VIR)', label: '50%= 1/4” LINES: 2050 (VIR)' },
  { value: '50%= 1/4” DOTS: 5960 (VIR)', label: '50%= 1/4” DOTS: 5960 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= Pattern: 6019 (VIR)', label: '60%= Pattern: 6019 (VIR)' },
  { value: '70%= Pattern: 6015 (VIR)', label: '70%= Pattern: 6015 (VIR)' },
];

export const glassSpacerColor = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Black', label: 'Black' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Clear (Low Profile)', label: 'Clear (Low Profile)' },
  { value: 'Black (Low Profile)', label: 'Black (Low Profile)' },
  { value: 'Bronze (Low Profile)', label: 'Bronze (Low Profile)' },
  { value: 'Mill - Aluminum', label: 'Mill - Aluminum' },
  { value: 'Mill - Stainless Steel', label: 'Mill - Stainless Steel' },
  { value: 'Black - Aluminum', label: 'Black - Aluminum' },
  { value: 'Black - Stainless Steel', label: 'Black - Stainless Steel' },
  { value: 'Black - Warm Edge', label: 'Black - Warm Edge' },
  { value: 'VTS - Warm Edge (BLACK)', label: 'VTS - Warm Edge (BLACK)' },
  { value: 'K4SBK - Warm Edge (BLACK)', label: 'K4SBK - Warm Edge (BLACK)' },
  { value: 'Super Spacer', label: 'Super Spacer' },
  { value: 'Super Spacer - Premium Enhanced', label: 'Super Spacer - Premium Enhanced' },
  {
    value: 'Super Spacer -Super Spacer - Premium Enhanced+',
    label: 'Super Spacer -Super Spacer - Premium Enhanced+',
  },
  { value: 'Super Spacer -T-Spacer', label: 'Super Spacer -T-Spacer' },
  { value: 'Super Spacer -TriSeal', label: 'Super Spacer -TriSeal' },
];

export const extraValues = {
  storefront_door_beads_1_over_4: { width: '5/8', height: '5/8' },
  storefront_door_beads_1: { width: '7/8', height: '7/8' },
  screw_beads_metal: { width: '3/4', height: '3/4' },
  'snap-beads-aluminium-interior': { width: '3/4', height: '3/4' },
  'snap-beads-vinyl-interior': { width: '3/4', height: '3/4' },
  'snap-beads-aluminium_exterior': { width: '3/4', height: '3/4' },
  snap_beads_vinyl_exterior: { width: '3/4', height: '3/4' },
  snap_beads_wood_interior: { width: '3/4', height: '3/4' },
  snap_beads_wood_exterior: { width: '3/4', height: '3/4' },
  flush_1_over_4_top_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_top_interior: { width: '5/8', height: '5/8' },
  flush_1_over_4_bottom_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_bottom_interior: { width: '5/8', height: '5/8' },
  flush_1_over_4_left_right_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_left_right_interior: { width: '5/8', height: '5/8' },
  flush_igu_top_exterior: { width: '7/8', height: '7/8' },
  flush_igu_top_interior: { width: '7/8', height: '7/8' },
  flush_igu_bottom_exterior: { width: '7/8', height: '7/8' },
  flush_igu_bottom_interior: { width: '7/8', height: '7/8' },
  flush_igu_left_right_exterior: { width: '7/8', height: '7/8' },
  flush_igu_left_right_interior: { width: '7/8', height: '7/8' },
  wrap_around_window_vinyl_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_aluminum_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_wood_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_vinyl_igu: { width: '7/8', height: '7/8' },
  wrap_around_window_aluminum_igu: { width: '7/8', height: '7/8' },
  wrap_around_window_wood_igu: { width: '7/8', height: '7/8' },
  wrap_around_patio_door_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_patio_door_igu: { width: '1', height: '1' },
  curtain_wall_all_sides_captured_exterior: { width: '1', height: '1' },
  curtain_wall_all_sides_captured_interior: { width: '1', height: '1' },
  curtain_wall_butt_glazed_left_only: { width: '1 1/2', height: '1' },
  curtain_wall_butt_glazed_right_only: { width: '1 1/2', height: '1' },
  curtain_wall_butt_glazed_left_right: { width: '2', height: '1' },
  curtain_wall_butt_glazed_top_only: { width: '1', height: '1 1/2' },
  curtain_wall_butt_glazed_bottom_only: { width: '1', height: '1 1/2' },
  curtain_wall_butt_glazed_top_bottom: { width: '1', height: '2' },
  structurally_glazed_all_sides_butt_glazed: { width: '2', height: '2' },
  zipper_wall: { width: '3/4', height: '3/4' },
  u_channel_b_t: { width: '0', height: '1 1/4' },
  seamless_mullion: { width: '7/8', height: '7/8' },
  screw_beads: { width: '3/4', height: '3/4' },
  sash_and_division_bar: { width: '3/4', height: '3/4' },
  sash_single_pane: { width: '5/8', height: '5/8' },
  sagh_igu: { width: '7/8', height: '7/8' },
  lug_sash: { width: '3/4', height: '3/4' },
  hack_and_glaze_steel_interior: { width: '4', height: '4' },
  hack_and_glaze_steel_exterior: { width: '4', height: '4' },
  hack_and_glaze_wood_interior: { width: '4', height: '4' },
  hack_and_glaze_wood_exterior: { width: '4', height: '4' },
  table_top: { width: '0', height: '0' },
  handrail: { width: '0', height: '0' },
  stand_Off: { width: '0', height: '0' },
  lay_in_canopy: { width: '0', height: '0' },
  vision_kit_all_new: { width: '0', height: '0' },
  vision_kit_glass_only: { width: '3/4', height: '3/4' },
  j_channel_b: { width: '0', height: '0' },
  j_channel_t: { width: '0', height: '0' },
  j_channel_t_b: { width: '0', height: '0' },
  j_channel_t_b_l_r: { width: '0', height: '0' },
  l_channel_b: { width: '0', height: '0' },
  l_channel_t: { width: '0', height: '0' },
  l_channel_t_b: { width: '0', height: '0' },
  l_channel_t_b_l_r: { width: '0', height: '0' },
  caulk_mastic_only: { width: '0', height: '0' },
};

export const extraMeasurments = [
  { extra_height: '0', extra_width: '0', value: '0', label: '0' },
  { extra_height: '1/16', extra_width: '1/16', value: '1/16', label: '1/16' },
  { extra_height: '1/8', extra_width: '1/8', value: '1/8', label: '1/8' },
  { extra_height: '3/16', extra_width: '3/16', value: '3/16', label: '3/16' },
  { extra_height: '1/4', extra_width: '1/4', value: '1/4', label: '1/4' },
  { extra_height: '5/16', extra_width: '5/16', value: '5/16', label: '5/16' },
  { extra_height: '3/8', extra_width: '3/8', value: '3/8', label: '3/8' },
  { extra_height: '7/16', extra_width: '7/16', value: '7/16', label: '7/16' },
  { extra_height: '1/2', extra_width: '1/2', value: '1/2', label: '1/2' },
  { extra_height: '9/16', extra_width: '9/16', value: '9/16', label: '9/16' },
  { extra_height: '5/8', extra_width: '5/8', value: '5/8', label: '5/8' },
  { extra_height: '11/16', extra_width: '11/16', value: '11/16', label: '11/16' },
  { extra_height: '3/4', extra_width: '3/4', value: '3/4', label: '3/4' },
  { extra_height: '13/16', extra_width: '13/16', value: '13/16', label: '13/16' },
  { extra_height: '7/8', extra_width: '7/8', value: '7/8', label: '7/8' },
  { extra_height: '15/16', extra_width: '15/16', value: '15/16', label: '15/16' },
  { extra_height: '1', extra_width: '1', value: '1', label: '1' },
  { extra_height: '1 1/16', extra_width: '1 1/16', value: '1 1/16', label: '1 1/16' },
  { extra_height: '1 1/8', extra_width: '1 1/8', value: '1 1/8', label: '1 1/8' },
  { extra_height: '1 3/16', extra_width: '1 3/16', value: '1 3/16', label: '1 3/16' },
  { extra_height: '1 1/4', extra_width: '1 1/4', value: '1 1/4', label: '1 1/4' },
  { extra_height: '1 5/16', extra_width: '1 5/16', value: '1 5/16', label: '1 5/16' },
  { extra_height: '1 3/8', extra_width: '1 3/8', value: '1 3/8', label: '1 3/8' },
  { extra_height: '1 7/16', extra_width: '1 7/16', value: '1 7/16', label: '1 7/16' },
  { extra_height: '1 1/2', extra_width: '1 1/2', value: '1 1/2', label: '1 1/2' },
  { extra_height: '1 9/16', extra_width: '1 9/16', value: '1 9/16', label: '1 9/16' },
  { extra_height: '1 5/8', extra_width: '1 5/8', value: '1 5/8', label: '1 5/8' },
  { extra_height: '1 11/16', extra_width: '1 11/16', value: '1 11/16', label: '1 11/16' },
  { extra_height: '1 3/4', extra_width: '1 3/4', value: '1 3/4', label: '1 3/4' },
  { extra_height: '1 13/16', extra_width: '1 13/16', value: '1 13/16', label: '1 13/16' },
  { extra_height: '1 7/8', extra_width: '1 7/8', value: '1 7/8', label: '1 7/8' },
  { extra_height: '1 15/16', extra_width: '1 15/16', value: '1 15/16', label: '1 15/16' },
  { extra_height: '2', extra_width: '2', value: '2', label: '2' },
  { extra_height: '2 1/16', extra_width: '2 1/16', value: '2 1/16', label: '2 1/16' },
  { extra_height: '2 1/8', extra_width: '2 1/8', value: '2 1/8', label: '2 1/8' },
  { extra_height: '2 3/16', extra_width: '2 3/16', value: '2 3/16', label: '2 3/16' },
  { extra_height: '2 1/4', extra_width: '2 1/4', value: '2 1/4', label: '2 1/4' },
  { extra_height: '2 5/16', extra_width: '2 5/16', value: '2 5/16', label: '2 5/16' },
  { extra_height: '2 3/8', extra_width: '2 3/8', value: '2 3/8', label: '2 3/8' },
  { extra_height: '2 7/16', extra_width: '2 7/16', value: '2 7/16', label: '2 7/16' },
  { extra_height: '2 1/2', extra_width: '2 1/2', value: '2 1/2', label: '2 1/2' },
  { extra_height: '2 9/16', extra_width: '2 9/16', value: '2 9/16', label: '2 9/16' },
  { extra_height: '2 5/8', extra_width: '2 5/8', value: '2 5/8', label: '2 5/8' },
  { extra_height: '2 11/16', extra_width: '2 11/16', value: '2 11/16', label: '2 11/16' },
  { extra_height: '2 3/4', extra_width: '2 3/4', value: '2 3/4', label: '2 3/4' },
  { extra_height: '2 13/16', extra_width: '2 13/16', value: '2 13/16', label: '2 13/16' },
  { extra_height: '2 7/8', extra_width: '2 7/8', value: '2 7/8', label: '2 7/8' },
  { extra_height: '2 15/16', extra_width: '2 15/16', value: '2 15/16', label: '2 15/16' },
  { extra_height: '3', extra_width: '3', value: '3', label: '3' },
  { extra_height: '3 1/16', extra_width: '3 1/16', value: '3 1/16', label: '3 1/16' },
  { extra_height: '3 1/8', extra_width: '3 1/8', value: '3 1/8', label: '3 1/8' },
  { extra_height: '3 3/16', extra_width: '3 3/16', value: '3 3/16', label: '3 3/16' },
  { extra_height: '3 1/4', extra_width: '3 1/4', value: '3 1/4', label: '3 1/4' },
  { extra_height: '3 5/16', extra_width: '3 5/16', value: '3 5/16', label: '3 5/16' },
  { extra_height: '3 3/8', extra_width: '3 3/8', value: '3 3/8', label: '3 3/8' },
  { extra_height: '3 7/16', extra_width: '3 7/16', value: '3 7/16', label: '3 7/16' },
  { extra_height: '3 1/2', extra_width: '3 1/2', value: '3 1/2', label: '3 1/2' },
  { extra_height: '3 9/16', extra_width: '3 9/16', value: '3 9/16', label: '3 9/16' },
  { extra_height: '3 5/8', extra_width: '3 5/8', value: '3 5/8', label: '3 5/8' },
  { extra_height: '3 11/16', extra_width: '3 11/16', value: '3 11/16', label: '3 11/16' },
  { extra_height: '3 3/4', extra_width: '3 3/4', value: '3 3/4', label: '3 3/4' },
  { extra_height: '3 13/16', extra_width: '3 13/16', value: '3 13/16', label: '3 13/16' },
  { extra_height: '3 7/8', extra_width: '3 7/8', value: '3 7/8', label: '3 7/8' },
  { extra_height: '3 15/16', extra_width: '3 15/16', value: '3 15/16', label: '3 15/16' },
  { extra_height: '4', extra_width: '4', value: '4', label: '4' },
  { extra_height: '4 1/16', extra_width: '4 1/16', value: '4 1/16', label: '4 1/16' },
  { extra_height: '4 1/8', extra_width: '4 1/8', value: '4 1/8', label: '4 1/8' },
  { extra_height: '4 3/16', extra_width: '4 3/16', value: '4 3/16', label: '4 3/16' },
  { extra_height: '4 1/4', extra_width: '4 1/4', value: '4 1/4', label: '4 1/4' },
  { extra_height: '4 5/16', extra_width: '4 5/16', value: '4 5/16', label: '4 5/16' },
  { extra_height: '4 3/8', extra_width: '4 3/8', value: '4 3/8', label: '4 3/8' },
  { extra_height: '4 7/16', extra_width: '4 7/16', value: '4 7/16', label: '4 7/16' },
  { extra_height: '4 1/2', extra_width: '4 1/2', value: '4 1/2', label: '4 1/2' },
  { extra_height: '4 9/16', extra_width: '4 9/16', value: '4 9/16', label: '4 9/16' },
  { extra_height: '4 5/8', extra_width: '4 5/8', value: '4 5/8', label: '4 5/8' },
  { extra_height: '4 11/16', extra_width: '4 11/16', value: '4 11/16', label: '4 11/16' },
  { extra_height: '4 3/4', extra_width: '4 3/4', value: '4 3/4', label: '4 3/4' },
  { extra_height: '4 13/16', extra_width: '4 13/16', value: '4 13/16', label: '4 13/16' },
  { extra_height: '4 7/8', extra_width: '4 7/8', value: '4 7/8', label: '4 7/8' },
  { extra_height: '4 15/16', extra_width: '4 15/16', value: '4 15/16', label: '4 15/16' },
  { extra_height: '5', extra_width: '5', value: '5', label: '5' },
  { extra_height: '5 1/16', extra_width: '5 1/16', value: '5 1/16', label: '5 1/16' },
  { extra_height: '5 1/8', extra_width: '5 1/8', value: '5 1/8', label: '5 1/8' },
  { extra_height: '5 3/16', extra_width: '5 3/16', value: '5 3/16', label: '5 3/16' },
  { extra_height: '5 1/4', extra_width: '5 1/4', value: '5 1/4', label: '5 1/4' },
  { extra_height: '5 5/16', extra_width: '5 5/16', value: '5 5/16', label: '5 5/16' },
  { extra_height: '5 3/8', extra_width: '5 3/8', value: '5 3/8', label: '5 3/8' },
  { extra_height: '5 7/16', extra_width: '5 7/16', value: '5 7/16', label: '5 7/16' },
  { extra_height: '5 1/2', extra_width: '5 1/2', value: '5 1/2', label: '5 1/2' },
  { extra_height: '5 9/16', extra_width: '5 9/16', value: '5 9/16', label: '5 9/16' },
  { extra_height: '5 5/8', extra_width: '5 5/8', value: '5 5/8', label: '5 5/8' },
  { extra_height: '5 11/16', extra_width: '5 11/16', value: '5 11/16', label: '5 11/16' },
  { extra_height: '5 3/4', extra_width: '5 3/4', value: '5 3/4', label: '5 3/4' },
  { extra_height: '5 13/16', extra_width: '5 13/16', value: '5 13/16', label: '5 13/16' },
  { extra_height: '5 7/8', extra_width: '5 7/8', value: '5 7/8', label: '5 7/8' },
  { extra_height: '5 15/16', extra_width: '5 15/16', value: '5 15/16', label: '5 15/16' },
  { extra_height: '6', extra_width: '6', value: '6', label: '6' },
];
/* FABRICATION */

export const HoleType = [
  { value: 'Rectangle Square', label: 'Rectangle/Square' },
  { value: 'Rectangle Square (Rounded Corners)', label: 'Rectangle/Square (Rounded Corners)' },
  { value: 'Circle', label: 'Circle' },
  { value: 'csc', label: 'Counter Sunk (Circle)' },
];

export const XLocationStart = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
];

export const YLocationStart = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
];

export const GridThickness = [
  { value: '3/16" FLAT', label: '3/16" FLAT' },
  { value: '3/8" FLAT', label: '3/8" FLAT' },
  { value: '7/16" FLAT', label: '7/16" FLAT' },
  { value: '1/2" FLAT', label: '1/2" FLAT' },
  { value: '9/16" FLAT"', label: '9/16" FLAT"' },
  { value: '5/8" FLAT', label: '5/8" FLAT' },
  { value: '3/4" FLAT"', label: '3/4" FLAT"' },
  { value: '1" FLAT', label: '1" FLAT' },
  { value: '11/16" CONTOUR', label: '11/16" CONTOUR' },
  { value: '1" CONTOUR', label: '1" CONTOUR' },
  { value: '5.5MM FLAT', label: '5.5MM FLAT' },
  { value: '8MM FLAT', label: '8MM FLAT' },
  { value: 'SLIMLINE  FLAT', label: 'SLIMLINE  FLAT' },
  { value: 'OTHER', label: 'OTHER' },
  { value: 'RESEARCH', label: 'RESEARCH' },
];

export const GridColor = [
  { value: 'White', label: 'White' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'Green', label: 'Green' },
  { value: 'Tan', label: 'Tan' },
  { value: 'Mill (Clear Anodized)', label: 'Mill (Clear Anodized)' },
  { value: 'Champagne', label: 'Champagne' },
  { value: 'Cherry', label: 'Cherry' },
  { value: 'Earthtone', label: 'Earthtone' },
  { value: 'White (bright)', label: 'White (bright)' },
  { value: '2 TONE: Bronze/White', label: '2 TONE: Bronze/White' },
  { value: '2 TONE: Champagne/White', label: '2 TONE: Champagne/White' },
  { value: '2 TONE: Cherry/Tan', label: '2 TONE: Cherry/Tan' },
  { value: '2 TONE: Cherry/White', label: '2 TONE: Cherry/White' },
  { value: '2 TONE: Dark Oak/Tan', label: '2 TONE: Dark Oak/Tan' },
  { value: '2 TONE: Dark Oak/White', label: '2 TONE: Dark Oak/White' },
  { value: '2 TONE: Light Oak/Tan', label: '2 TONE: Light Oak/Tan' },
  { value: '2 TONE: Light Oak/White', label: '2 TONE: Light Oak/White' },
  { value: '2 TONE: Tan/White', label: '2 TONE: Tan/White' },
  { value: '2 TONE: White/Champagne', label: '2 TONE: White/Champagne' },
  { value: 'SDL - Clear', label: 'SDL - Clear' },
  { value: 'SDL - Black', label: 'SDL - Black' },
  { value: 'SDL - Bronze', label: 'SDL - Bronze' },
  { value: 'SDL - Clear (low profile)', label: 'SDL - Clear (low profile)' },
  { value: 'SDL - Black (low profile)', label: 'SDL - Black (low profile)' },
  { value: 'SDL - Bronze (low profile)', label: 'SDL - Bronze (low profile)' },
  { value: 'SDL - Mill - Aluminum', label: 'SDL - Mill - Aluminum' },
  { value: 'SDL - Mill - Stainless Steel', label: 'SDL - Mill - Stainless Steel' },
  { value: 'SDL - Black - Aluminum', label: 'SDL - Black - Aluminum' },
  { value: 'SDL - Black - Stainless Steel', label: 'SDL - Black - Stainless Steel' },
  {
    value: 'Custom',
    label: 'Custom',
  },
  { value: 'RESEARCH', label: 'RESEARCH' },
];

export const OffsetLite = [
  { value: 'Exterior (interior lite smaller)', label: 'Exterior (interior lite smaller)' },
  { value: 'Interior (exterior lite smaller)', label: 'Interior (exterior lite smaller)' },
];

export const OffsetFill = [
  { value: '90', label: '90' },
  { value: 'Normal ', label: 'Normal ' },
  { value: 'Buttered ', label: 'Buttered ' },
];

export const OffsetSide = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left ', label: 'Left' },
  { value: 'Right ', label: 'Right' },
];

export const OffsetOffset = [
  { value: '1/6', label: '1/6' },
  { value: '1/8', label: '1/8' },
  { value: '3/16', label: '3/16' },
  { value: '1/4', label: '1/4' },
  { value: '5/16', label: '5/16' },
  { value: '3/8', label: '3/8' },
  { value: '7/16', label: '7/16' },
  { value: '1/2', label: '1/2' },
  { value: '9/16', label: '9/16' },
  { value: '5/8', label: '5/8' },
  { value: '11/16', label: '11/16' },
  { value: '3/4', label: '3/4' },
  { value: '13/16', label: '13/16' },
  { value: '7/8', label: '7/8' },
  { value: '15/16', label: '15/16' },
  { value: '1', label: '1' },
  { value: '1 1/6', label: '1 1/6' },
  { value: '1 1/8', label: '1 1/8' },
  { value: '1 3/16', label: '1 3/16' },
  { value: '1 1/4', label: '1 1/4' },
  { value: '1 5/16', label: '1 5/16' },
  { value: '1 3/8', label: '1 3/8' },
  { value: '1 7/16', label: '1 7/16' },
  { value: '1 1/2', label: '1 1/2' },
  { value: '1 9/16', label: '1 9/16' },
  { value: '1 5/8', label: '1 5/8' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1 3/4', label: '1 3/4' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1 7/8', label: '1 7/8' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '2', label: '2' },
  { value: '2 1/6', label: '2 1/6' },
  { value: '2 1/8', label: '2 1/8' },
  { value: '2 3/16', label: '2 3/16' },
  { value: '2 1/4', label: '2 1/4' },
  { value: '2 5/16', label: '2 5/16' },
  { value: '2 3/8', label: '2 3/8' },
  { value: '2 7/16', label: '2 7/16' },
  { value: '2 1/2', label: '2 1/2' },
  { value: '2 9/16', label: '2 9/16' },
  { value: '2 5/8', label: '2 5/8' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2 3/4', label: '2 3/4' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2 7/8', label: '2 7/8' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '3', label: '3' },
];
