import { placeholder } from 'modernizr';
import {
  detailsOnly,
  paintFinish,
  doorCloser,
  topPivot,
  patchFilling,
  topInsert,
  headerDoorStop,
  doorGlassType,
  doorHandles,
  panicDevice,
  edgeSealWeatherstrip,
  bottomInsert,
  bottomPivot,
  floorCloser,
  threshold,
  patchLock,
  bottomRail,
  bottomArm,
  topPivotArm,
  topInsertArm,
  bottomPivotArm,
  bottomPivotTopBottomRails,
  floorCloserTopBottomRails,
  topRail,
  sideRails,
  typeOfSet,
  holeLocation,
  holeSize,
  patchFittingManufacturer,
  bottomPatchLockManufacturer,
  /* topInsertArmBrokenGlass ,*/
  glassColorThicknessBrokenGlass,
  /* STOREFRONT OPTIONS */
  brandStorefront,
  cocCloserStorefront,
  topPivotFrame,
  topPivotDoor,
  surfaceMountedCloser,
  offsetArmCenterHung,
  dropPlate,
  offsetArmPivotsButt,
  typeOfGlass,
  handlesStorefront,
  panicDeviceStorefront,
  weatherStrippingStorefront,
  bottomPivotStorefront,
  spindleStorefront,
  typeofBeads,
  thumbturn,
  keyCylinder,
  lockStorefront,
  pushPaddle,
  topFlushBolt,
  bottomFlushBolt,
  floorCloserStorefront,
  topPivotOffset,
  intermediatePivot,
  bottomPivootOffset,
  offsetArmContinuousHinge,
  continuousHinge,
  /* HollowMetalDoors */
  hmdBrand,
  hmdCocCloser,
  hmdSurfaceMountedCloser,
  hmdOffsetArm,
  hmdPanicDeviceRim,
  hmdPanicDeviceVerticalRods,
  hmdWeatherStripping,
  hmdBottomArm,
  hmdFloorCloser,
  hmdThreshold,
  hmdLock,
  hmdKnobLock,
  hmdLeverLock,
  hmdTopFlushBolt,
  hmdBottomFlushBolt,
  hmdContinuousHinge,
  /* OP4-6 */
  hmdTopHinge,
  hmdIntermediateHinge,
  hmdBottomHinge,
  /* SHOWER DOOR */
  sdGlassColorThickness,
  sdPolishedEdges,
  sdHoleSize,
  /* NEW DOORS */
  anglesOption,
  /* ndgdDoorLayout, */
  ndgdDoorFrameStyle,
  ndgdDoorAction,
  ndgdMeasurements,
  ndgdDoorHeader,
  ndgdDoorTransom,
  ndgdGlassTypeShower,
  ndgdDoorGlassCoating,
  ndgdDoorHardwareFinish,
  ndgdDoorHandle,
  ndgdFixedPanelSecuring,
  ndgdCornerJoints,
  ndgdLevel,
  ndgdDoorStyle,
  ndgdDoorHand,
  ndgdGlassType,
  /* ndgdTopRailType, */
  ndgdRailType,
  /* ndgdBottomRailType, */
  ndgdMetalFinish,
  ndgdClosers,
  ndgdLocks,
  ndgdHandles,
  ndgdHeader,
  ndgdThreshold,
  ndgdSideLightHeadCondition,
  ndgdSidelightSillCondition,
  ndgdImageHandles,
  ndadOffsetArm,
  ndadDoorStyle,
  ndadLine,
  ndadFrameSystem,
  ndadFinish,
  ndadHangingHardware,
  ndadThreshold,
  ndadDoorCloser,
  ndadLock,
  ndadPanicDevice,
  ndadHandles,
  ndadMidPanel,
  ndadBottomRail,
  ndadDoorSweeps,
  ndhmdDoorTypeOptions,
  ndhmdDoorActionOptions,
  ndhmdDoorPanelOptions,
  ndhmdHingeSizeOptions,
  ndhmdLouverOptions,
  ndhmdDoorFrameFinishOptions,
  ndhmdDoorCloserOptions,
  ndhmdRoomTypeOptions,
  ndhmdLocksetFunctionOptions,
  ndhmdDeadBoltOptions,
  ndhmdDoorSweepWeatherStrippingOptions,
  ndhmdFrameProfileOptions,
  ndhmdFrameThicknessOptions,
  ndhmdFrameFabricationOptions,
  ndhmdFrameInstallTypeOptions,
  ndhmdHingeHeight,
  ndhmdDoorThickness,
  ndhmdContinuousHinge,
  ndhmdDoorHand,
  ndhmdFireRating,
  ndhmdVisionKit,
  ndhmdLockset,
  ndhmdPanicDevice,
  ndhmdDoorCloser,
  ndhmdPullSet,
  ndhmdKickPlate,
  ndgdTopPatch,
  ndgdBottomPatch,
  pdFrameType,
  pdFramingDepth,
  pdThermallyBroken,
  pdFrame,
  pdFrameReceptor,
  pdAssembly,
  pdFinishA,
  pdFinishB,
  pdGlassThickness,
  pdGlassColor,
  pdGrids,
  pdHardwareColor,
  pdHoleSize,
  pdDoorHardwareOptions,
  pdExteriorTrimOptions,
  pdInteriorTrimOptions,
  pdGlassInstallationOptions,
  pdHardwareFinishOptions,
  pdGlassCoatingOptions,
  pdFrameFinishInteriorOptions,
  pdFrameFinishExteriorOptions,
  pdTypeOptions,
  pdGlassTypeOptions,
  transomOption,
  h_onsite,
  filmsTypeOptions,
  filmsBrandOptions,
  filmsGlassThicknessOptions,
  filmsGlassTypeOptions,
  filmsInstallLocationOptions,
  filmsLiftEquipmentNeededOptions,
  filmsTemperedOptions,
  filmsFrameTypeOptions,
  pdScreensOptions,
  filmsRemovalRequiredOptions,
  filmsWetsealFilmToFrameOptions,
  filmsSpliceAcceptableOptions,
  filmsAppliedSideOptions,
  pdGridsTypeOptions,
} from './doorTypeOptions';

import {
  glassOverallThickness,
  elThickness,
  elTreatment,
  elColor,
  elSide,
  /* elCoatingFamily, */
  elCoating,
  glassSpacerColor,
  HoleType,
  XLocationStart,
  YLocationStart,
  GridThickness,
  GridColor,
  OffsetLite,
  OffsetFill,
  OffsetOffset,
  OffsetSide,
  extraMeasurments,
} from './glassTypeOptions';
/* -----------------------------------------------GLASS SIZES */

export const legL = { label: 'Leg (L)', type: 'text', name: 'legL' };
export const GLASSSIZESA = {
  label: 'A',
  type: 'glass',
  name: 'Adlo',
  name2: 'A_dlo',
  name3: 'Ago',
  name4: 'Anotes',
  glasCheckName: 'ACheck',
  glasCheck2Name: 'ACheck2',
  options: extraMeasurments,
};
export const GLASSSIZESB = {
  label: 'B',
  type: 'glass',
  name: 'Bdlo',
  name2: 'B_dlo',
  name3: 'Bgo',
  name4: 'Bnotes',
  glasCheckName: 'BCheck',
  glasCheck2Name: 'BCheck2',
  options: extraMeasurments,
};
export const GLASSSIZESC = {
  label: 'C',
  type: 'glass',
  name: 'Cdlo',
  name2: 'C_dlo',
  name3: 'Cgo',
  name4: 'Cnotes',
  glasCheckName: 'CCheck',
  glasCheck2Name: 'CCheck2',
  options: extraMeasurments,
};
export const GLASSSIZESD = {
  label: 'D',
  type: 'glass',
  name: 'Ddlo',
  name2: 'D_dlo',
  name3: 'Dgo',
  name4: 'Dnotes',
  glasCheckName: 'DCheck',
  glasCheck2Name: 'DCheck2',
  options: extraMeasurments,
};
export const GLASSSIZESE = {
  label: 'E',
  type: 'glass',
  name: 'Edlo',
  name2: 'E_dlo',
  name3: 'Ego',
  name4: 'Enotes',
  glasCheckName: 'ECheck',
  glasCheck2Name: 'ECheck2',
  options: extraMeasurments,
};
export const GLASSSIZESF = {
  label: 'F',
  type: 'glass',
  name: 'Fdlo',
  name2: 'F_dlo',
  name3: 'Fgo',
  name4: 'Fnotes',
  glasCheckName: 'FCheck',
  glasCheck2Name: 'FCheck2',
  options: extraMeasurments,
};
export const GLASSSIZESG = {
  label: 'G',
  type: 'glass',
  name: 'Gdlo',
  name2: 'G_dlo',
  name3: 'Ggo',
  name4: 'Gnotes',
  glasCheckName: 'GCheck',
  glasCheck2Name: 'GCheck2',
  options: extraMeasurments,
};
export const GLASSSIZESH = {
  label: 'H',
  type: 'glass',
  name: 'Hdlo',
  name2: 'H_dlo',
  name3: 'Hgo',
  name4: 'Hnotes',
  glasCheckName: 'HCheck',
  glasCheck2Name: 'HCheck2',
  options: extraMeasurments,
};
export const rightL = { label: 'Right Leg (RL)', type: 'text', name: 'rightLeg' };
export const leftO = { label: 'Left Offset (LO)', type: 'text', name: 'leftOffset' };
export const rightO = { label: 'Right Offset (RO)', type: 'text', name: 'rightOffset' };
export const topO = { label: 'Top Offset (TO)', type: 'text', name: 'topOffset' };
export const widthB = { label: 'B - Width', type: 'text', name: 'widthB' };
export const widthW = { label: 'Width (W)', type: 'text', name: 'widthW' };
export const baseB = { label: 'Base (B)', type: 'text', name: 'baseB' };
export const topT = { label: 'Top (T)', type: 'text', name: 'topT' };
export const heightH = { label: 'Height (H)', type: 'text', name: 'heightH' };
export const sideS = { label: 'Side (S)', type: 'text', name: 'sideS' };
export const rightS = { label: 'Right Side (S)', type: 'text', name: 'rightS' };
export const leftS = { label: 'Left Side (S)', type: 'text', name: 'leftS' };
export const diameter = { label: 'Diameter (D)', type: 'text', name: 'diameter' };
export const radius = { label: 'Radius (R)', type: 'text', name: 'radius' };
export const radiusOne = { label: 'Radius 1 (R1)', type: 'text', name: 'radiusOne' };
export const radiusTwo = { label: 'Radius 2 (R2)', type: 'text', name: 'radiusTwo' };
export const legnthL = { label: 'Length (L)', type: 'text', name: 'lengthL' };

/* -----------------------------------------------GLASS TYPES */
export const gtExteriorLite = {
  label: 'Exterior Lite',
  type: 'label',
  colSize: '12',
};

export const gtInteriorLite = {
  label: 'Interior Lite',
  type: 'label',
  colSize: '12',
};

export const gtOverThick = {
  label: 'Overall Thickness',
  name: 'overallthickness',
  type: 'select',
  options: glassOverallThickness,
  colSize: '12',
  isDoubtful: true,
};
export const gtexThick = {
  label: 'Thickness',
  name: 'exthick',
  type: 'select',
  options: elThickness,
  isDoubtful: true,
};
export const gtexTreatment = {
  label: 'Treatment',
  name: 'extreat',
  type: 'select',
  options: elTreatment,
  isDoubtful: true,
};
export const gtexColor = {
  label: 'Color',
  name: 'excolor',
  type: 'select',
  options: elColor,
  isDoubtful: true,
};
export const gtexCoatingFamily = {
  label: 'Side',
  name: 'excoatingf',
  type: 'select',
  options: elSide,
  isDoubtful: true,
};
export const gtexCoating = {
  label: 'Coating',
  name: 'excoating',
  type: 'select',
  options: elCoating,
  isDoubtful: true,
};
export const gtSpacerColor = {
  label: 'Spacer Color',
  name: 'spacercolor',
  type: 'select',
  options: glassSpacerColor,
  colSize: '12',
  isDoubtful: true,
};
export const gtinThick = {
  label: 'Thickness',
  name: 'inthick',
  type: 'select',
  options: elThickness,
  isDoubtful: true,
};
export const gtinTreatment = {
  label: 'Treatment',
  name: 'intreat',
  type: 'select',
  options: elTreatment,
  isDoubtful: true,
};
export const gtinColor = {
  label: 'Color',
  name: 'incolor',
  type: 'select',
  options: elColor,
  isDoubtful: true,
};
export const gtinCoatingFamily = {
  label: 'Side',
  name: 'incoatingf',
  type: 'select',
  options: elSide,
  isDoubtful: true,
};
export const gtinCoating = {
  label: 'Coating',
  name: 'incoating',
  type: 'select',
  options: elCoating,
  isDoubtful: true,
};
/* FABRICATION */

export const gpPolishedEdges = {
  label: 'Polished Edges',
  name: 'polishedEdges',
  type: 'text',
};
export const gpPattern = {
  label: 'Pattern',
  name: 'pattern',
  type: 'text',
};
export const gpHolesType = {
  label: 'Hole Type',
  name: 'holetype',
  type: 'select',
  options: HoleType,
};
export const gpHolesExtSize = {
  label: 'Exterior Size',
  name: 'extSize',
  type: 'number',
};
export const gpHolesIntSize = {
  label: 'Interior Size',
  name: 'intSize',
  type: 'number',
};
export const gpHolesStartX = {
  label: 'X Location Starting Point',
  name: 'xlocationSP',
  type: 'select',
  options: XLocationStart,
};
export const gpHolesStartY = {
  label: 'Y Location Starting Point',
  name: 'ylocationSP',
  type: 'select',
  options: YLocationStart,
};
export const gpHolesLocationX = {
  label: 'X Location',
  name: 'locationx',
  type: 'number',
};
export const gpHolesLocationY = {
  label: 'Y Location',
  name: 'locationy',
  type: 'number',
};
export const gpHolesNotes = {
  label: 'Notes',
  name: 'holesnotes',
  type: 'text',
};
export const gpGridsThickness = {
  label: 'Grid Thickness',
  name: 'gridthick',
  type: 'select',
  options: GridThickness,
};
export const gpGridsColor = {
  label: 'Grids Color',
  name: 'gridcolor',
  type: 'select',
  options: GridColor,
};
export const gpGridsColorCustom = {
  label: 'Grids Custom Color',
  name: 'gridcolorcustom',
  type: 'text',
};
export const gpGridsHorizontal = {
  label: 'Horizontal Grids',
  name: 'horizontalgrids',
  type: 'number',
};
export const gpGridsVertical = {
  label: 'Vertical Grids',
  name: 'verticalgrids',
  type: 'number',
};
export const gpOffsetLite = {
  label: 'Offset Lite',
  name: 'offsetlite',
  type: 'select',
  options: OffsetLite,
};
export const gpOffsetFill = {
  label: 'Offset Fill',
  name: 'offsetfill',
  type: 'select',
  options: OffsetFill,
};
export const gpOffsetSide = {
  label: 'Offset Side',
  name: 'offsetside',
  type: 'select',
  options: OffsetSide,
};
export const gpOffsetOffset = {
  label: 'Offset',
  name: 'offsetoffset',
  type: 'select',
  options: OffsetOffset,
};
export const gpOffsetNotes = {
  label: 'Notes',
  name: 'offsetnotes',
  type: 'text',
};
/* ----------Extra field Doors Repair ----------- */
export const partFieldEmpty = {
  label: '',
  name: 'partFieldEmpty',
  type: '',
  placeholder: '',
};
export const partRepairExtraOne = {
  label: 'Extra #1',
  name: 'partRepairExtraOne',
  type: 'text',
  placeholder: 'Extra #1',
};
export const partRepairExtraOneNote = {
  label: 'Notes',
  name: 'partRepairExtraOneNote',
  type: 'text',
  placeholder: 'Notes #1',
};

export const partRepairExtraTwo = {
  label: 'Extra #2',
  name: 'partRepairExtraTwo',
  type: 'text',
  placeholder: 'Extra #2',
};

export const partRepairExtraTwoNote = {
  label: 'Notes',
  name: 'partRepairExtraTwoNote',
  type: 'text',
  placeholder: 'Notes #2',
};

export const partRepairExtraThree = {
  label: 'Extra #3',
  name: 'partRepairExtraThree',
  type: 'text',
  placeholder: 'Extra #3',
};

export const partRepairExtraThreeNote = {
  label: 'Notes',
  name: 'partRepairExtraThreeNote',
  type: 'text',
  placeholder: 'Notes #3',
};

export const partRepairExtraFour = {
  label: 'Extra #4',
  name: 'partRepairExtraFour',
  type: 'text',
  placeholder: 'Extra #4',
};

export const partRepairExtraFourNote = {
  label: 'Notes',
  name: 'partRepairExtraFourNote',
  type: 'text',
  placeholder: 'Notes #4',
};

export const partRepairExtraFive = {
  label: 'Extra #5',
  name: 'partRepairExtraFive',
  type: 'text',
  placeholder: 'Extra #5',
};
export const partRepairExtraFiveNote = {
  label: 'Notes',
  name: 'partRepairExtraFiveNote',
  type: 'text',
  placeholder: 'Notes #5',
};

/* ---------------------------------------------REPAIR DOORS */
/* ALL GLASS DOOR - PARTS REPAIR*/
export const locationDoor = { label: 'Location', name: 'locationDoor', type: 'text' };
export const finishA = {
  label: 'A - Finish',
  name: 'finishA',
  type: 'select',
  options: paintFinish,
};
export const finishANotes = {
  label: '',
  name: 'finishANotes',
  type: 'text',
  placeholder: 'Notes',
};

export const doorCloserB = {
  label: 'B - Door Closer',
  name: 'doorCloserB',
  type: 'select',
  options: doorCloser,
  details: detailsOnly,
};
export const doorCloserBNotes = {
  label: '',
  name: 'doorCloserBNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const topPivotC = {
  label: 'C - Top Pivot',
  name: 'topPivotC',
  type: 'select',
  options: topPivot,
  details: detailsOnly,
};
export const topPivotCNotes = {
  label: '',
  name: 'topPivotCNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const topPivotArmC = {
  label: 'C - Top Pivot/Arm',
  name: 'topPivotArmC',
  type: 'select',
  options: topPivotArm,
  details: detailsOnly,
};
export const topPivotArmCNotes = {
  label: '',
  name: 'topPivotArmCNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const patchFillingD = {
  label: 'D - Patch Fitting',
  name: 'patchFittingD',
  type: 'select',
  options: patchFilling,
  details: detailsOnly,
};
export const patchFillingDNotes = {
  label: '',
  name: 'patchFillingDNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const topInsertE = {
  label: 'E - Top Insert',
  name: 'topPivotE',
  type: 'select',
  options: topInsert,
  details: detailsOnly,
};
export const topInsertENotes = {
  label: '',
  name: 'topPivotENotes',
  type: 'text',
  placeholder: 'Notes',
};

export const topInsertArmE = {
  label: 'E - Top Insert/Arm',
  name: 'topInsertArmE',
  type: 'select',
  options: topInsertArm,
  details: detailsOnly,
};
export const topInsertArmENotes = {
  label: '',
  name: 'topInsertArmENotes',
  type: 'text',
  placeholder: 'Notes',
};
export const headerDoorStopF = {
  label: 'F - Header Door Stop',
  name: 'headerDoorStopF',
  type: 'select',
  options: headerDoorStop,
  details: detailsOnly,
};
export const headerDoorStopFNotes = {
  label: '',
  name: 'headerDoorStopFNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const typeOfGlassG = {
  label: 'G - Type of Glass',
  name: 'typeOfGlassG',
  type: 'select',
  options: doorGlassType,
  details: detailsOnly,
};
export const typeOfGlassGNotes = {
  label: '',
  name: 'typeOfGlassGNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const handlesH = {
  label: 'H - Handles',
  name: 'handlesH',
  type: 'select',
  options: doorHandles,
  details: detailsOnly,
};
export const handlesHNotes = {
  label: '',
  name: 'handlesHNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const panicDeviceI = {
  label: 'I - Panic Device',
  name: 'panicDeviceI',
  type: 'select',
  options: panicDevice,
  details: detailsOnly,
};
export const panicDeviceINotes = {
  label: '',
  name: 'panicDeviceINotes',
  type: 'text',
  placeholder: 'Notes',
};
export const edgeSealWeatherstripJ = {
  label: 'J - Edge Seal Weatherstrip',
  name: 'edgeSealWeatherstripJ',
  type: 'select',
  options: edgeSealWeatherstrip,
  details: detailsOnly,
};
export const edgeSealWeatherstripJNotes = {
  label: '',
  name: 'edgeSealWeatherstripJNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottomPivotArmK = {
  label: 'K - Bottom Pivot/Arm',
  name: 'bottomPivotArmK',
  type: 'select',
  options: bottomPivotArm,
  details: detailsOnly,
};
export const bottomPivotArmKNotes = {
  label: '',
  name: 'bottomPivotArmKNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottomInsertK = {
  label: 'K - Bottom Insert',
  name: 'bottomInsertK',
  type: 'select',
  options: bottomInsert,
  details: detailsOnly,
};
export const bottomInsertKNotes = {
  label: '',
  name: 'bottomInsertKNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottomPivotL = {
  label: 'L - Bottom Pivot',
  name: 'bottompivotL',
  type: 'select',
  options: bottomPivot,
  details: detailsOnly,
};
export const bottomPivotLNotes = {
  label: '',
  name: 'bottompivotLNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottomPivotLTopBottomRails = {
  label: 'L - Bottom Pivot',
  name: 'bottompivotL',
  type: 'select',
  options: bottomPivotTopBottomRails,
  details: detailsOnly,
};
export const bottomPivotLTopBottomRailsNotes = {
  label: '',
  name: 'bottompivotLNotes',
  placeholder: 'Notes',
  type: 'text',
};
export const floorDoorCloserM = {
  label: 'M - Floor Door Closer',
  name: 'floorDoorCloserM',
  type: 'select',
  options: floorCloser,
  details: detailsOnly,
};
export const floorDoorCloserMNotes = {
  label: '',
  name: 'floorDoorCloserMNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const floorDoorCloserMTopBottomRails = {
  label: 'M - Floor Door Closer',
  name: 'floorDoorCloserM',
  type: 'select',
  options: floorCloserTopBottomRails,
  details: detailsOnly,
};
export const floorDoorCloserMTopBottomRailsNotes = {
  label: '',
  name: 'floorDoorCloserMTopBottomRailsNotes',
  placeholder: 'Notes',
  type: 'text',
};
export const thresholdNAllGlass = {
  label: 'N - Threshold',
  name: 'thresholdNAllGlass',
  type: 'select',
  options: threshold,
  details: detailsOnly,
};
export const thresholdNAllGlassNotes = {
  label: '',
  name: 'thresholdNAllGlassNotes',
  placeholder: 'Notes',
  type: 'text',
};

export const thresholdN = {
  label: 'N - Threshold',
  name: 'thresholdN',
  type: 'select',
  options: threshold,
  details: detailsOnly,
};
export const thresholdNNotes = {
  label: '',
  name: 'thresholdNNotes',
  placeholder: 'Notes',
  type: 'text',
};
export const thresholdNStoreFront = {
  label: 'N - Threshold',
  name: 'thresholdNStoreFront',
  type: 'select',
  options: threshold,
  details: detailsOnly,
};
export const thresholdNNotesStoreFront = {
  label: '',
  name: 'thresholdNNotesStoreFront',
  placeholder: 'Notes',
  type: 'text',
};
export const bottomRailO = {
  label: 'O - Bottom Rail',
  name: 'bottomRailO',
  type: 'select',
  options: bottomRail,
  details: detailsOnly,
};
export const bottomRailONotes = {
  label: '',
  name: 'bottomRailONotes',
  placeholder: 'Notes',
  type: 'text',
};
export const topRailO = {
  label: 'O - Top Rail',
  name: 'topRailO',
  type: 'select',
  options: topRail,
  details: detailsOnly,
};
export const topRailONotes = {
  label: '',
  name: 'topRailONotes',
  type: 'text',
};

export const patchLockP = {
  label: 'P - Patch Lock',
  name: 'patchLockP',
  type: 'select',
  options: patchLock,
  details: detailsOnly,
};
export const patchLockPNotes = {
  label: '',
  name: 'patchLockPNotes',
  placeholder: 'Notes',
  type: 'text',
};

export const bottomArmQ = {
  label: 'Q - Bottom Arm',
  name: 'bottomArmQ',
  type: 'select',
  options: bottomArm,
  details: detailsOnly,
};
export const bottomArmQNotes = {
  label: '',
  name: 'bottomArmQNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottomRailR = {
  label: 'R - Bottom Rail',
  name: 'bottomRailR',
  type: 'select',
  options: bottomRail,
  details: detailsOnly,
};
export const bottomRailRNotes = {
  label: '',
  name: 'bottomRailRNotes',
  placeholder: 'Notes',
  type: 'text',
};
export const sideRailsS = {
  label: 'S - Side Rails',
  name: 'sideRailsS',
  type: 'select',
  options: sideRails,
  details: detailsOnly,
};
export const sideRailsSNotes = {
  label: '',
  name: 'sideRailsSNotes',
  placeholder: 'Notes',
  type: 'text',
};

/* ALL GLASS DOOR - BROKEN GLASS */
export const BGA = {
  label: 'A',
  name: 'BGA',
  name2: 'BGAnotesA',
  type: 'BGA',
};
export const BGB = {
  label: 'B',
  name: 'BGB',
  name2: 'BGAnotesB',
  type: 'BGA',
};
export const BGC = {
  label: 'C',
  name: 'BGC',
  name2: 'BGAnotesC',
  type: 'BGA',
};
export const BGD = {
  label: 'D',
  name: 'BGD',
  name2: 'BGAnotesD',
  type: 'BGA',
};
export const BGE = {
  label: 'E',
  name: 'BGE',
  name2: 'BGAnotesE',
  type: 'BGA',
};
export const BGF = {
  label: 'F',
  name: 'BGF',
  name2: 'BGAnotesF',
  type: 'BGA',
};
export const BGG = {
  label: 'G',
  name: 'BGG',
  name2: 'BGAnotesG',
  type: 'BGA',
};
export const BGH = {
  label: 'H',
  name: 'BGH',
  name2: 'BGAnotesH',
  type: 'BGA',
};
export const BGI = {
  label: 'I',
  name: 'BGI',
  name2: 'BGAnotesI',
  type: 'BGA',
};
export const BGJ = {
  label: 'J',
  name: 'BGJ',
  name2: 'BGAnotesJ',
  type: 'BGA',
};
export const BGK = {
  label: 'K',
  name: 'BGK',
  name2: 'BGAnotesK',
  type: 'BGA',
  options: pdHoleSize,
};
export const BGL = {
  label: 'L',
  name: 'BGL',
  name2: 'BGAnotesL',
  type: 'BGA',
};
export const BGLOCATION = {
  label: 'Location',
  name: 'BGLocation',
  name2: 'BGLocationNotes',
  type: 'BGA',
};
export const BGType = {
  label: 'Glass Type',
  name: 'BGType',
  name2: 'BGTypeNotes',
  type: 'BGA2',
  options: ndgdGlassTypeShower,
};

export const BGCLOATING = {
  label: 'Glass Coating',
  name: 'glassCoating',
  name2: 'noteGlassCoating',
  type: 'BGA2',
  options: ndgdDoorGlassCoating,
};

export const BGHO = {
  label: 'Hardware Onsite',
  name: 'hardwareOnsite',
  name2: 'noteGhardwareOnsite',
  type: 'BGA2',
  options: h_onsite,
};

export const BGWS = {
  label: 'Weather Striping & Seals Onsite',
  name: 'weatherOnsite',
  name2: 'noteWeatherOnsite',
  type: 'BGA2',
  options: h_onsite,
};

export const BGMetal = {
  label: 'Metal Finish',
  name: 'BGMetal',
  name2: 'BGMetalNotes',
  type: 'BGA2',
  options: ndgdMetalFinish,
};
export const BGPatchF = {
  label: 'Patch Fitting Manufacturer(A,BP,F)',
  name: 'BGPatchF',
  name2: 'BGPatchFNote',
  type: 'BGA2',
  options: patchFittingManufacturer,
};
export const BGPatchL = {
  label: 'Patch Lock Manufacturer(F)',
  name: 'BGPatchL',
  name2: 'BGPatchLNote',
  type: 'BGA2',
  options: patchLock,
};
export const typeSet = {
  label: 'Set',
  name: 'BGSet',
  name2: 'BGSetNote',
  type: 'BGA2',
  options: typeOfSet,
};

export const widthGenA = {
  label: 'Width - Height',
  type: 'WxH',
  name: 'widthGenA',
  name2: 'heightGenA',
};
export const heightGenB = { label: 'B - Glass Height', type: 'number', name: 'heightGenB' };
export const glassColorThicknessCD = {
  label: 'C/D - Glass Color/Thickness',
  name: 'glassColorThicknessCD',
  type: 'select',
  options: glassColorThicknessBrokenGlass,
};
export const totalGlassDoorHeightE = {
  label: 'E - Total Glass Door Height',
  name: 'totalGlassDoorHeightE',
  type: 'number',
};
export const dayliteF = { label: 'F - Daylite', name: 'dayliteF', type: 'number' };
export const topShoeSizeG = { label: 'G - Top Shoe Size', name: 'topShoeSizeG', type: 'number' };
export const topShoePocket = {
  label: 'H - Top Shoe Pocket',
  name: 'topShoePocket',
  type: 'number',
};
export const bottomShoeSizeI = {
  label: 'I - Bottom Shoe Size',
  name: 'bottomShoeSizeI',
  type: 'number',
};
export const bottomShoePocketJ = {
  label: 'J - Bottom Shoe Pocket',
  name: 'bottomShoePocketJ',
  type: 'number',
};
export const doorOpeningK = { label: 'K - Door Opening', name: 'doorOpeningK', type: 'number' };
export const typeOfSetL = {
  label: 'L - Type of Set',
  name: 'typeOfSetL',
  type: 'select',
  options: typeOfSet,
};
export const holeLocationN = {
  label: 'N - Hole Location',
  name: 'holeLocationN',
  type: 'select',
  options: holeLocation,
};
export const holeSizeO = {
  label: 'O - Hole Size',
  name: 'holeSizeO',
  type: 'select',
  options: holeSize,
};
export const patchFittingManufacturerP = {
  label: 'P - Patch Fitting Manufacturer',
  name: 'patchFittingManufacturerP',
  type: 'select',
  options: patchFittingManufacturer,
};
export const bottomPatchLockManufacturerQ = {
  label: 'Q - Patch Fitting Manufacturer',
  name: 'bottomPatchLockManufacturerQ',
  type: 'select',
  options: bottomPatchLockManufacturer,
};
export const lockNotchWidthR = {
  label: 'R - Lock Notch Width',
  type: 'number',
  name: 'lockNotchWidthR',
};
export const lockNotchHeightS = {
  label: 'S - Lock Notch Height',
  type: 'number',
  name: 'lockNotchHeightS',
};
export const sideRailSizeT = { label: 'T - Side Rail Size', name: 'sideRailSizeT', type: 'number' };
export const sideRailPocketU = {
  label: 'U - Side Rail Pocket',
  name: 'sideRailPocket',
  type: 'number',
};

/* STOREFRONT DOOR - PARTS REPAIR */
export const widthGen = { label: 'Width', type: 'number', name: 'widthGen' };
export const heightGen = { label: 'Height', type: 'number', name: 'heightGen' };
export const brandASF = {
  label: 'A - Brand',
  name: 'brandASF',
  type: 'select',
  options: brandStorefront,
};
export const brandANotesSF = {
  label: '',
  placeholder: 'Notes',
  name: 'brandANotesSF',
  type: 'text',
};
export const cocCloser = {
  label: 'B - COC Closer',
  name: 'cocCloser',
  type: 'select',
  options: cocCloserStorefront,
  details: detailsOnly,
};
export const cocCloserNotes = {
  label: '',
  name: 'cocCloserNotes',
  placeholder: 'Notes',
  type: 'text',
};

export const topPivotFrameCOne = {
  label: 'C1 - Top Pivot Frame',
  name: 'topPivotFrameCOne',
  type: 'select',
  options: topPivotFrame,
  details: detailsOnly,
};
export const topPivotFrameCOneNotes = {
  label: '',
  name: 'topPivotFrameCOneNotes',
  placeholder: 'Notes',
  type: 'text',
};
export const topPivotDoorCTwo = {
  label: 'C2 - Top Pivot Door',
  name: 'topPivotDoorCTwo',
  type: 'select',
  options: topPivotDoor,
  details: detailsOnly,
};
export const topPivotDoorCTwoNotes = {
  label: '',
  name: 'topPivotDoorCTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const surfaceMountedCloserD = {
  label: 'D - Surface Mounted Closer',
  name: 'surfaceMountedCloserD',
  type: 'select',
  options: surfaceMountedCloser,
  details: detailsOnly,
};
export const surfaceMountedCloserDNotes = {
  label: '',
  name: 'surfaceMountedCloserDNotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'D - Surface Mounted Closer Notes',
};
export const typeOfGlassGStorefront = {
  label: 'G - Type of Glass',
  name: 'typeOfGlassGStorefront',
  type: 'select',
  options: typeOfGlass,
  details: detailsOnly,
};
export const typeOfGlassGStorefrontNotes = {
  label: '',
  name: 'typeOfGlassGStorefrontNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const offsetArmE = {
  label: 'E - Offset Arm',
  name: 'offsetArm',
  type: 'select',
  options: offsetArmCenterHung,
  details: detailsOnly,
};
export const offsetArmENotes = {
  label: '',
  name: 'offsetArmNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const offsetArmEPivotButt = {
  label: 'E - Offset Arm',
  name: 'offsetArm',
  type: 'select',
  options: offsetArmPivotsButt,
  details: detailsOnly,
};
export const offsetArmEPivotButtNotes = {
  label: '',
  name: 'offsetArmEPivotButtNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const offsetArmEContinuousHinge = {
  label: 'E - Offset Arm',
  name: 'offsetArm',
  type: 'select',
  options: offsetArmContinuousHinge,
  details: detailsOnly,
};
export const offsetArmEContinuousHingeNotes = {
  label: '',
  name: 'offsetArmEContinuousHingeNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const dropPlateF = {
  label: 'F - Drop Plate',
  name: 'dropPlate',
  type: 'select',
  options: dropPlate,
  details: detailsOnly,
};
export const dropPlateFNotes = {
  label: '',
  name: 'dropPlateFNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const bottompivotKStoreFront = {
  label: 'K - Bottom Pivot',
  name: 'bottompivotKStoreFront',
  type: 'select',
  options: bottomPivotStorefront,
  details: detailsOnly,
};

export const bottomPivotKNotesStoreFront = {
  label: '',
  name: 'bottomPivotKNotesStoreFront',
  type: 'text',
  placeholder: 'Notes',
};
export const spindleL = {
  label: 'L - Spindle',
  name: 'spindleL',
  type: 'select',
  options: spindleStorefront,
  details: detailsOnly,
};
export const spindleLNotes = {
  label: '',
  name: 'spindleLNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const floorcloserMStorefront = {
  label: 'M - Floor Closer',
  name: 'floorcloserMStorefront',
  type: 'select',
  options: floorCloserStorefront,
  details: detailsOnly,
};
export const floorcloserMStorefrontNotes = {
  label: '',
  name: 'floorcloserMStorefrontNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const typeOfBeadsTSF = {
  label: 'T - Type of Beads',
  name: 'typeOfBeadsTSF',
  type: 'select',
  options: typeofBeads,
  details: detailsOnly,
};
export const typeOfBeadsTNotesSF = {
  label: '',
  name: 'typeOfBeadsTNotesSF',
  type: 'text',
  placeholder: 'Notes',
};
export const thumbturnU = {
  label: 'U - Thumbturn',
  name: 'thumbturnU',
  type: 'select',
  options: thumbturn,
  details: detailsOnly,
};
export const thumbturnUNotes = {
  label: '',
  name: 'thumbturnUNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const keyCylinderV = {
  label: 'V - Key Cylinder',
  name: 'keyCylinderV',
  type: 'select',
  options: keyCylinder,
  details: detailsOnly,
};
export const keyCylinderVNotes = {
  label: '',
  name: 'keyCylinderVNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const lockW = {
  label: 'W - Lock',
  name: 'lockW',
  type: 'select',
  options: lockStorefront,
  details: detailsOnly,
};
export const lockWNotes = {
  label: '',
  name: 'lockWNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const pushPaddleX = {
  label: 'X - Push Paddle',
  name: 'pushPaddleX',
  type: 'select',
  options: pushPaddle,
  details: detailsOnly,
};
export const pushPaddleXNotes = {
  label: '',
  name: 'pushPaddleXNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const topFlushBoltYOne = {
  label: 'Y1- Top Flush Bolt',
  name: 'topFlushBoltYOne',
  type: 'select',
  options: topFlushBolt,
  details: detailsOnly,
};
export const topFlushBoltYOneNotes = {
  label: '',
  name: 'topFlushBoltYOneNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const bottomFlushBoltYTwo = {
  label: 'Y2 - Bottom Flush Bolt',
  name: 'bottomFlushBoltYTwo',
  type: 'select',
  options: bottomFlushBolt,
  details: detailsOnly,
};
export const bottomFlushBoltYTwoNotes = {
  label: '',
  name: 'bottomFlushBoltYTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const topPivotOffsetZOne = {
  label: 'Z1 - Top Pivot Offset',
  name: 'topPivotOffsetZOne',
  type: 'select',
  options: topPivotOffset,
  details: detailsOnly,
};
export const topPivotOffsetZOneNotes = {
  label: '',
  name: 'topPivotOffsetZOneNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const intermediatePivotZTwo = {
  label: 'Z2 - Intermediate Pivot',
  name: 'intermediatePivotZTwo',
  type: 'select',
  options: intermediatePivot,
  details: detailsOnly,
};
export const intermediatePivotZTwoNotes = {
  label: '',
  name: 'intermediatePivotZTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const bottomPivotOffsetZThree = {
  label: 'Z3 - Bottom Pivot',
  name: 'bottomPivotOffsetZThree',
  type: 'select',
  options: bottomPivootOffset,
  details: detailsOnly,
};
export const bottomPivotOffsetZThreeNotes = {
  label: '',
  name: 'bottomPivotOffsetZThreeNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const continuousHingeZ = {
  label: 'Z - Continuous Hinge',
  name: 'continuousHingeZ',
  type: 'select',
  options: continuousHinge,
  details: detailsOnly,
};
export const continuousHingeZNotes = {
  label: '',
  name: 'continuousHingeZNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const handlesHStorefront = {
  label: 'H - Handles',
  name: 'handlesHStorefront',
  type: 'select',
  options: handlesStorefront,
  details: detailsOnly,
};
export const handlesHStorefrontNotes = {
  label: '',
  name: 'handlesHStorefrontNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const panicDeviceIStorefront = {
  label: 'I - Panic Device',
  name: 'panicDeviceIStorefront',
  type: 'select',
  options: panicDeviceStorefront,
  details: detailsOnly,
};
export const panicDeviceIStorefrontNotes = {
  label: '',
  name: 'panicDeviceIStorefrontNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const weatherStrippingJ = {
  label: 'J - Weather Stripping',
  name: 'weatherStrippingJ',
  type: 'select',
  options: weatherStrippingStorefront,
  details: detailsOnly,
};
export const weatherStrippingJNotes = {
  label: '',
  name: 'weatherStrippingJNotes',
  type: 'text',
  placeholder: 'Notes',
};

/* HOLLOW METAL DOOR - PARTS REPAIR */
export const hmdA = {
  label: 'A - Brand',
  name: 'hmdbrandA',
  type: 'select',
  options: hmdBrand,
};
export const hmdANotes = {
  label: '',
  name: 'hmdbrandANotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'Notes',
};

export const hmdB = {
  label: 'B - COC Closer',
  name: 'hmdcocCloserB',
  type: 'select',
  options: hmdCocCloser,
  details: detailsOnly,
};
export const hmdBNotes = {
  label: '',
  name: 'hmdcocCloserBNotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'Notes',
};

export const hmdD = {
  label: 'D - Surface Mounted Closer',
  name: 'hmdsurfaceMountedCloserD',
  type: 'select',
  options: hmdSurfaceMountedCloser,
  details: detailsOnly,
};
export const hmdDNotes = {
  label: '',
  name: 'hmdsurfaceMountedCloserDNotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'Notes',
};
export const hmdE = {
  label: 'E - Offset Arm',
  name: 'hmdoffsetArmE',
  type: 'select',
  options: hmdOffsetArm,
  details: detailsOnly,
};
export const hmdENotes = {
  label: '',
  name: 'hmdoffsetArmENotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'Notes',
};

export const hmdI = {
  label: 'I - Panic Device - Rim',
  name: 'hmdpanicDeviceRimI',
  type: 'select',
  options: hmdPanicDeviceRim,
  details: detailsOnly,
};
export const hmdINotes = {
  label: '',
  name: 'hmdpanicDeviceRimINotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdIOne = {
  label: 'I1 - Panic Device - Vertical Rods',
  name: 'hmdpanicDeviceVerticalRodsIOne',
  type: 'select',
  options: hmdPanicDeviceVerticalRods,
  details: detailsOnly,
};
export const hmdIOneNotes = {
  label: '',
  name: 'hmdpanicDeviceVerticalRodsIOneNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdJ = {
  label: 'J - Weather Stripping',
  name: 'hmdweatherStrippingJ',
  type: 'select',
  options: hmdWeatherStripping,
  details: detailsOnly,
};

export const hmdJNotes = {
  label: '',
  name: 'hmdweatherStrippingJNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdL = {
  label: 'L - Bottom Arm',
  name: 'hmdbottomArmL',
  type: 'select',
  options: hmdBottomArm,
  details: detailsOnly,
};
export const hmdLNotes = {
  label: '',
  name: 'hmdbottomArmLNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdM = {
  label: 'M - Floor Closer',
  name: 'hmdfloorCloserM',
  type: 'select',
  options: hmdFloorCloser,
  details: detailsOnly,
};
export const hmdMNotes = {
  label: '',
  name: 'hmdfloorCloserMNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdN = {
  label: 'N - Threshold',
  name: 'hmdthresholdN',
  type: 'select',
  options: hmdThreshold,
  details: detailsOnly,
};
export const hmdNNotes = {
  name: 'hmdthresholdNNotes',
  type: 'text',
  placeholder: 'Notes',

  label: '',
  placeholder: 'Notes',
};
export const hmdW = {
  label: 'W - Lock',
  name: 'hmdlockW',
  type: 'select',
  options: hmdLock,
  details: detailsOnly,
};
export const hmdWNotes = {
  label: '',
  name: 'hmdlockWNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const hmdWOne = {
  label: 'W1 - Knob Lock',
  name: 'hmdknobLockWOne',
  type: 'select',
  options: hmdKnobLock,
  details: detailsOnly,
};
export const hmdWOneNotes = {
  label: '',
  name: 'hmdknobLockWOneNotes',
  type: 'text',
  placeholder: 'Notes',

  placeholder: 'Notes',
};

export const hmdWTwo = {
  label: 'W2 - Lever Lock',
  name: 'hmdleverLockWTwo',
  type: 'select',
  options: hmdLeverLock,
  details: detailsOnly,
};

export const hmdWTwoNotes = {
  label: '',
  name: 'hmdleverLockWTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const hmdYOne = {
  label: 'Y1 - Top Flush Bolt',
  name: 'hmdtopFlushBoltYOne',
  type: 'select',
  options: hmdTopFlushBolt,
  details: detailsOnly,
};
export const hmdYOneNotes = {
  label: '',
  name: 'hmdtopFlushBoltYOneNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdYTwo = {
  label: 'Y2 - Bottom Flush Bolt',
  name: 'hmdbottomFlushBoltYTwo',
  type: 'select',
  options: hmdBottomFlushBolt,
  details: detailsOnly,
};
export const hmdYTwoNotes = {
  label: '',
  name: 'hmdbottomFlushBoltYTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdZ = {
  label: 'Z - Continuous Hinge',
  name: 'hmdcontinuousHingeZ',
  type: 'select',
  options: hmdContinuousHinge,
  details: detailsOnly,
};
export const hmdZNotes = {
  label: '',
  name: 'hmdcontinuousHingeZNotes',
  type: 'text',
  placeholder: 'Notes',
};
/* OP2 */
export const hmdZOne = {
  label: 'Z1 - Top Hinge',
  name: 'hmdtopHingeZOne',
  type: 'select',
  options: hmdTopHinge,
  details: detailsOnly,
};
export const hmdZOneNotes = {
  label: '',
  name: 'hmdtopHingeZOneNotes',
  type: 'text',
  placeholder: 'Notes',
};

export const hmdZTwo = {
  label: 'Z2 - Intermediate Hinge',
  name: 'hmdintermediateHingeZTwo',
  type: 'select',
  options: hmdIntermediateHinge,
  details: detailsOnly,
};
export const hmdZTwoNotes = {
  label: '',
  name: 'hmdintermediateHingeZTwoNotes',
  type: 'text',
  placeholder: 'Notes',
};
export const hmdZThree = {
  label: 'Z3 - Bottom Hinge',
  name: 'hmdbottomHingeZThree',
  type: 'select',
  options: hmdBottomHinge,
  details: detailsOnly,
};
export const hmdZThreeNotes = {
  label: '',
  name: 'hmdbottomHingeZThreeNotes',
  type: 'text',
  placeholder: 'Notes',
};

/* SHOWER DOOR - BROKEN GLASS */
export const sdglassColor = {
  label: 'Glass Color/Thickness',
  name: 'glassColorThicknessShowerDoor',
  type: 'select',
  options: sdGlassColorThickness,
};
export const sdpolished = {
  label: 'Polished Edges',
  name: 'polishedEdges',
  type: 'select',
  options: sdPolishedEdges,
};
export const sdheighttLeftA = {
  label: 'A - Width - Height',
  type: 'WxH',
  name: 'heighttLeftA',
  name2: 'widthttLeftA',
};
export const sdheightRightDiffA = {
  label: 'C - Height Right-Different from A',
  type: 'number',
  name: 'heightRightDiffA',
};
export const sdtopDoorCLHingeD = {
  label: 'D - Top of Door to CL of Hinge',
  name: 'topDoorCLHingeD',
  type: 'number',
};
export const sdtopDoorCLHingeDM = {
  label: 'D-M - Top of Door to CL of Hinge - 3 Hinge',
  name: 'topDoorCLHingeDM',
  type: 'number',
};
export const sdbottomDoorCLHingeE = {
  label: 'E - Bottom of Door to CL of Hinge',
  name: 'bottomDoorCLHingeE',
  type: 'number',
};
export const sdholeSizeG = {
  label: 'G - Hole Size',
  name: 'holeSizeG',
  type: 'select',
  options: sdHoleSize,
};
/* HANDLES */
export const rdHandleA = {
  label: 'Handle A',
  name: 'rpHandleA',
  name2: 'holesizeA',
  name3: 'notesA',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleB = {
  label: 'Handle B',
  name: 'rpHandleB',
  name2: 'holesizeB',
  name3: 'notesB',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleC = {
  label: 'Handle C',
  name: 'rpHandleC',
  name2: 'holesizeC',
  name3: 'notesC',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleD = {
  label: 'Handle D',
  name: 'rpHandleD',
  name2: 'holesizeD',
  name3: 'notesD',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleE = {
  label: 'Handle E',
  name: 'rpHandleE',
  name2: 'holesizeE',
  name3: 'notesE',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleF = {
  label: 'Handle F',
  name: 'rpHandleF',
  name2: 'holesizeF',
  name3: 'notesF',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleG = {
  label: 'Handle G',
  name: 'rpHandleG',
  name2: 'holesizeG',
  name3: 'notesG',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleH = {
  label: 'Handle H',
  name: 'rpHandleH',
  name2: 'holesizeH',
  name3: 'notesH',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const rdHandleI = {
  label: 'Handle I',
  name: 'rpHandleI',
  name2: 'holesizeI',
  name3: 'notesI',
  type: 'ndhandle',
  options: pdHoleSize,
};

/* ----------------------------------NEW DOORS  */
/* GLASS DOORS */

export const gdLayout = {
  label: 'Layout',
  name: 'layout',
  type: 'button',
  options: [true, false],
};

export const gdFS = {
  label: 'Frame Style',
  name: 'frameStyle',
  type: 'select',
  options: ndgdDoorFrameStyle,
};
export const gpNoteFrameStyle = {
  label: 'Notes',
  name: 'noteFrameStyle',
  type: 'text',
};

export const gdDA = {
  label: 'Door Action',
  name: 'doorAction',
  type: 'select',
  options: ndgdDoorAction,
};
export const gpNoteDoorAction = {
  label: 'Notes',
  name: 'noteDoorAction',
  type: 'text',
};

export const gdheader = {
  label: 'Header',
  name: 'header',
  type: 'select',
  options: ndgdHeader,
};

export const gdheaderShower = {
  label: 'Header',
  name: 'header',
  type: 'select',
  options: ndgdDoorHeader,
};

export const gpNoteheader = {
  label: 'Notes',
  name: 'noteheader',
  type: 'text',
};

export const gdTransom = {
  label: 'Transom',
  name: 'transom',
  type: 'select',
  options: ndgdDoorTransom,
};

export const gpNoteTransom = {
  label: 'Notes',
  name: 'noteTransom',
  type: 'text',
};

export const gdGlassType = {
  label: 'Glass Type',
  name: 'glassType',
  type: 'select',
  options: ndgdGlassTypeShower,
};

export const gpNoteGlassType = {
  label: 'Notes',
  name: 'noteGlassType',
  type: 'text',
};

export const gpNoteClosers = {
  label: 'Notes',
  name: 'noteClosers',
  type: 'text',
};

export const gpNoteHandles = {
  label: 'Notes',
  name: 'noteHandles',
  type: 'text',
};

export const gpNoteLocks = {
  label: 'Notes',
  name: 'noteLocks',
  type: 'text',
};

export const gpNoteThreshold = {
  label: 'Notes',
  name: 'noteThreshold',
  type: 'text',
};

export const gdGlassCoating = {
  label: 'Glass Coating',
  name: 'glassCoating',
  type: 'select',
  options: ndgdDoorGlassCoating,
};

export const gpNoteGlassCoating = {
  label: 'Notes',
  name: 'noteGlassCoating',
  type: 'text',
};

export const gdHardwareFinish = {
  label: 'Metal Finish',
  name: 'hardwareFinish',
  type: 'select',
  options: ndgdMetalFinish,
};

export const gdShowerHardwareFinish = {
  label: 'Metal Finish',
  name: 'hardwareFinish',
  type: 'select',
  options: ndgdDoorHardwareFinish,
};

export const gpNoteHardwareFinish = {
  label: 'Notes',
  name: 'noteHardwareFinish',
  type: 'text',
};

export const gpNoteAdH = {
  label: 'Notes',
  name: 'gpNoteDoorStyle',
  type: 'text',
};

export const gpNoteBottomRail = {
  label: 'Notes',
  name: 'gpNoteBottomRail',
  type: 'text',
};

export const gpNoteTopRail = {
  label: 'Notes',
  name: 'noteTopRail',
  type: 'text',
};

export const gpNoteFrameSystem = {
  label: 'Notes',
  name: 'gpNoteFrameSystem',
  type: 'text',
};

export const gpNoteDoorHand = {
  label: 'Notes',
  name: 'gpNoteDoorHand',
  type: 'text',
};

export const gpNoteHangingHardware = {
  label: 'Notes',
  name: 'gpNoteHangingHardware',
  type: 'text',
};

export const gpNoteDoorCloser = {
  label: 'Notes',
  name: 'gpNoteDoorCloser',
  type: 'text',
};

export const gpLookNote = {
  label: 'Notes',
  name: 'gpLookNote',
  type: 'text',
};

export const gpPanicDeviceNote = {
  label: 'Notes',
  name: 'gpPanicDeviceNote',
  type: 'text',
};

export const gpMidPanelNote = {
  label: 'Notes',
  name: 'gpMidPanelNote',
  type: 'text',
};

export const gpWeatherStrippingNote = {
  label: 'Notes',
  name: 'gpWeatherStrippingNote',
  type: 'text',
};

export const gdHandle = {
  label: 'Handle',
  name: 'handle',
  type: 'select',
  options: ndgdDoorHandle,
};

export const gpNoteHandle = {
  label: 'Notes',
  name: 'noteHandle',
  type: 'text',
};

export const ndhmdWS = {
  label: 'Door Sweeps',
  name: 'hmdweatherStrippingJ',
  type: 'select',
  options: ndhmdDoorSweepWeatherStrippingOptions,
};

export const gdFixedPanelSecuring = {
  label: 'Fixed Panel Securing',
  name: 'fixedPanelSecuring',
  type: 'select',
  options: ndgdFixedPanelSecuring,
};

export const gpNoteFixedPanelSecuring = {
  label: 'Notes',
  name: 'noteFixedPanelSecuring',
  type: 'text',
};

export const nulle = {
  type: 'null',
};

export const gpNoteLevel = {
  label: 'Notes',
  name: 'noteLevel',
  type: 'text',
};

export const gpDoorWidth = {
  label: 'Door Width (if different from standard)',
  name: 'doorWidth',
  type: 'text',
};

export const gpDoorHeight = {
  label: 'Door Height (if not full height)',
  name: 'doorHeight',
  type: 'text',
};

export const gpTransomHeight = {
  label: 'Transom Height',
  name: 'transomHeight',
  type: 'text',
};

export const gpMeasurements = {
  label: 'MEASUREMENTS',
  name: 'gpMeasurements',
  type: 'select',
  options: ndgdMeasurements,
};

export const gpGlassCoating = {
  label: 'Glass Coating',
  name: 'gpGlassCoating',
  type: 'select',
  options: pdGlassCoatingOptions,
};
export const gpGlassCoatingNotes = {
  label: 'Notes',
  name: 'gpGlassCoatingNotes',
  type: 'text',
};

export const gpA = {
  label: 'A',
  name: 'gpA',
  name2: 'gpA_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpAOutage = {
  label: 'Outage',
  name: 'gpAOutage',
  name2: 'gpANote',
  type: 'outage',
};

export const gpB = {
  label: 'B',
  name: 'gpB',
  name2: 'gpB_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpBOutage = {
  label: 'Outage',
  name: 'gpBOutage',
  name2: 'gpBNote',
  type: 'outage',
};

export const gpC = {
  label: 'C',
  name: 'gpC',
  name2: 'gpC_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpCOutage = {
  label: 'Outage',
  name: 'gpCOutage',
  name2: 'gpCNote',
  type: 'outage',
};

export const gpD = {
  label: 'D',
  name: 'gpD',
  name2: 'gpD_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpDOutage = {
  label: 'Outage',
  name: 'gpDOutage',
  name2: 'gpDNote',
  type: 'outage',
};

export const gpE = {
  label: 'E',
  name: 'gpE',
  name2: 'gpE_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpEOutage = {
  label: 'Outage',
  name: 'gpEOutage',
  name2: 'gpENote',
  type: 'outage',
};

export const gpF = {
  label: 'F',
  name: 'gpF',
  name2: 'gpF_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpFOutage = {
  label: 'Outage',
  name: 'gpFOutage',
  name2: 'gpFNote',
  type: 'outage',
};

export const gpG = {
  label: 'G',
  name: 'gpG',
  name2: 'gpG_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpGOutage = {
  label: 'Outage',
  name: 'gpGOutage',
  name2: 'gpGNote',
  type: 'outage',
};

export const gpH = {
  label: 'H',
  name: 'gpH',
  name2: 'gpH_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpHOutage = {
  label: 'Outage',
  name: 'gpHOutage',
  name2: 'gpHNote',
  type: 'outage',
};

export const gpI = {
  label: 'I',
  name: 'gpI',
  name2: 'gpI_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpIOutage = {
  label: 'Outage',
  name: 'gpIOutage',
  name2: 'gpINote',
  type: 'outage',
};

export const gpJ = {
  label: 'J',
  name: 'gpJ',
  name2: 'gpJ_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpJOutage = {
  label: 'Outage',
  name: 'gpJOutage',
  name2: 'gpJNote',
  type: 'outage',
};

export const gpK = {
  label: 'K',
  name: 'gpK',
  name2: 'gpK_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpKOutage = {
  label: 'Outage',
  name: 'gpKOutage',
  name2: 'gpKNote',
  type: 'outage',
};

export const gpL = {
  label: 'L',
  name: 'gpL',
  name2: 'gpL_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpLOutage = {
  label: 'Outage',
  name: 'gpLOutage',
  name2: 'gpLNote',
  type: 'outage',
};

export const gpM = {
  label: 'M',
  name: 'gpM',
  name2: 'gpM_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpMOutage = {
  label: 'Outage',
  name: 'gpMOutage',
  name2: 'gpMNote',
  type: 'outage',
};

export const gpN = {
  label: 'N',
  name: 'gpN',
  name2: 'gpN_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpNOutage = {
  label: 'Outage',
  name: 'gpNOutage',
  name2: 'gpNNote',
  type: 'outage',
};

export const gpO = {
  label: 'O',
  name: 'gpO',
  name2: 'gpO_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpOOutage = {
  label: 'Outage',
  name: 'gpOOutage',
  name2: 'gpONote',
  type: 'outage',
};

export const gpP = {
  label: 'P',
  name: 'gpP',
  name2: 'gpP_Angle',
  type: 'text',
  angles: ndgdLevel,
};

export const gpPOutage = {
  label: 'Outage',
  name: 'gpPOutage',
  name2: 'gpPNote',
  type: 'outage',
};

export const doorWidthAluminium = {
  label: 'Operable Panel Width (if different from standard)',
  name: 'doorWidthAluminium',
  type: 'text',
};

export const doorHeightAluminium = {
  label: 'Operable Panel Height (if not full height)',
  name: 'doorHeightAluminium',
  type: 'text',
};

export const transomType = {
  label: 'TRANSOM TYPE  (if transom)',
  name: 'transomType',
  type: 'select',
  options: transomOption,
};

export const transomHeightAluminium = {
  label: 'TRANSOM HEIGHT (if transom)',
  name: 'transomHeightAluminium',
  type: 'text',
};

export const panelsWide1 = {
  label: '# of Panels WIDE (Left of of Operable Panel) ',
  name: 'panelsWide1',
  type: 'text',
};

export const panelsWide2 = {
  label: '# of Panels HIGH (Left of of Operable Panel)',
  name: 'panelsWide2',
  type: 'text',
};
export const panelsWide3 = {
  label: '# of Panels WIDE (Right of of Operable Panel)',
  name: 'panelsWide3',
  type: 'text',
};
export const panelsWide4 = {
  label: '# of Panels HIGH (Right of of Operable Panel)',
  name: 'panelsWide4',
  type: 'text',
};

export const gpAngle1 = {
  label: 'Angle #1',
  name: 'gpAngle1',
  type: 'select',
  options: anglesOption,
};

export const gpAngle1Joints = {
  label: 'Joints',
  name: 'gpAngle1Joints',
  name2: 'gpAngle1Note',
  type: 'joints',
  options: ndgdCornerJoints,
};

export const gpAngle2 = {
  label: 'Angle #2',
  name: 'gpAngle2',
  type: 'select',
  options: anglesOption,
};

export const gpAngle2Joints = {
  label: 'Joints',
  name: 'gpAngle2Joints',
  name2: 'gpAngle2Note',
  type: 'joints',
  options: ndgdCornerJoints,
};

export const gpAngle3 = {
  label: 'Angle #3',
  name: 'gpAngle3',
  type: 'select',
  options: anglesOption,
};

export const gpAngle3Joints = {
  label: 'Joints',
  name: 'gpAngle3Joints',
  name2: 'gpAngle3Note',
  type: 'joints',
  options: ndgdCornerJoints,
};

export const gpAngle4 = {
  label: 'Angle #4',
  name: 'gpAngle4',
  type: 'select',
  options: anglesOption,
};

export const gpAngle4Joints = {
  label: 'Joints',
  name: 'gpAngle4Joints',
  name2: 'gpAngle4Note',
  type: 'joints',
  options: ndgdCornerJoints,
};

export const gpAngle5 = {
  label: 'Angle #5',
  name: 'gpAngle5',
  type: 'select',
  options: anglesOption,
};

export const gpAngle5Joints = {
  label: 'Joints',
  name: 'gpAngle5Joints',
  name2: 'gpAngle5Note',
  type: 'joints',
  options: ndgdCornerJoints,
};

export const gdLevel = {
  label: 'Level',
  name: 'level',
  type: 'select',
  options: ndgdLevel,
};
export const gdA = {
  label: 'Door Style',
  name: 'doorstyleA',
  type: 'select',
  options: ndgdDoorStyle,
};
export const gdBW = {
  label: 'B - Rough Opening Width',
  name: 'roughOpeningWB',
  name2: 'roughOpeningHB',
  type: 'WxH',
};
export const gdCW = {
  label: 'C - Door Opening',
  name: 'doorOpeningWC',
  name2: 'doorOpeningHC',
  type: 'WxH',
};

export const gdDW = {
  label: 'D - Door Size Width',
  name: 'doorsizeWD',
  name2: 'doorsizeHD',
  type: 'WxH',
};

export const gdE = {
  label: 'E - Door Hand',
  name: 'doorhandE',
  type: 'select',
  options: ndgdDoorHand,
};
export const gdF = {
  label: 'F - Glass Type',
  name: 'glassTypeF',
  type: 'select',
  options: ndgdGlassType,
};
/* TYPE A/F */
export const gdGT = {
  label: 'G - Top Patch',
  name: 'toppatchG',
  type: 'select',
  options: ndgdTopPatch,
};
export const gdGB = {
  label: 'G - Bottom Patch',
  name: 'bottompatchG',
  type: 'select',
  options: ndgdBottomPatch,
};
/* TYPE BP/P/WP */
export const gdG = {
  label: 'Top Rail Size',
  name: 'toprailsizeG',
  type: 'number',
};
export const gdH = {
  label: 'Bottom Rail Size',
  name: 'bottomrailsizeG',
  type: 'number',
};
/* ----- */
export const gdGTwo = {
  label: 'TOP RAIL (if rails: P, BP, WP)',
  name: 'toprailtypeG',
  type: 'select',
  options: ndgdRailType,
};
export const gdHTwo = {
  label: 'BOTTOM RAIL (if rails: P, BP, WP)',
  name: 'bottomrailtypeG',
  type: 'select',
  options: ndgdRailType,
};

/* TYPE VARIABLE END */
export const gdI = {
  label: 'I - Metal Finish',
  name: 'metalfinishI',
  type: 'select',
  options: ndgdMetalFinish,
};
export const gdJ = {
  label: 'Door Closer',
  name: 'closersJ',
  type: 'select',
  options: ndgdClosers,
};
export const gdK = {
  label: 'Locks',
  name: 'locksK',
  type: 'select',
  options: ndgdLocks,
};
export const gdL = {
  label: 'Handles',
  name: 'handlesL',
  type: 'select',
  options: ndgdHandles,
};
export const gdM = {
  label: 'M - Header',
  name: 'headerM',
  type: 'select',
  options: ndgdHeader,
};
export const gdN = {
  label: 'Threshold',
  name: 'thresholdN',
  type: 'select',
  options: ndgdThreshold,
};
export const gdO = {
  label: 'Sidelight Head Condition',
  name: 'sideheadconditionO',
  type: 'select',
  options: ndgdSideLightHeadCondition,
};
export const gdP = {
  label: 'P - Sidelight Sill Condition',
  name: 'sidesillconditionO',
  type: 'select',
  options: ndgdSidelightSillCondition,
};

export const gdQT = {
  label: 'Sidelight Head *TOP* Condition (if sidelites)',
  name: 'sidelitesconfigQ',
  type: 'select',
  options: ndgdSideLightHeadCondition,
};

export const gdQB = {
  label: 'Sidelight Sil *BOTTOM* Condition (if sidelites)',
  name: 'sidelitesconfigB',
  type: 'select',
  options: ndgdSidelightSillCondition,
};

export const gpNoteSidelitesTop = {
  label: 'Notes',
  name: 'noteSidelites',
  type: 'text',
};

export const gpNoteSidelitesB = {
  label: 'Notes',
  name: 'noteSidelitesB',
  type: 'text',
};

export const gdHandles = {
  label: 'Handles',
  name: 'glassdoorhandles',
  type: 'select',
  options: ndgdImageHandles,
};

/* ALUMINUM DOORS */
export const adA = {
  label: 'A/B - Door Opening',
  name: 'dooropeningWA',
  name2: 'dooropeningHB',
  type: 'WxH',
};

export const adC = {
  label: 'C/D - Rough Opening',
  name: 'roughopeningWC',
  name2: 'roughopeningHD',
  type: 'WxH',
};

export const adE = {
  label: 'Door Action',
  name: 'doorActionE',
  type: 'select',
  options: ndhmdDoorActionOptions,
};
export const adENote = {
  label: 'Notes',
  name: 'doorActionENote',
  type: 'text',
};
export const adEOne = {
  label: 'E1 - Offset Arm',
  name: 'offsetarmEONE',
  type: 'select',
  options: ndadOffsetArm,
};
export const adF = {
  label: 'F - Door Width',
  name: 'doorWF',
  type: 'number',
};
export const adG = {
  label: 'G - Door Height',
  name: 'doorHG',
  type: 'number',
};
export const adH = {
  label: 'Door Style',
  name: 'doorstyleH',
  type: 'select',
  options: ndadDoorStyle,
};
export const adI = {
  label: 'I - Glass on Aluminum Doors',
  name: 'glass',
  type: 'select',
  options: ndadLine,
};
export const adJ = {
  label: 'Frame System',
  name: 'framesystemJ',
  type: 'select',
  options: ndadFrameSystem,
};
export const adK = {
  label: 'Metal Finish',
  name: 'finishK',
  type: 'select',
  options: ndadFinish,
};
export const adL = {
  label: 'Hanging Hardware',
  name: 'hanginghardwareL',
  type: 'select',
  options: ndadHangingHardware,
};
export const adM = {
  label: 'M - Top of the door to the center line of Intermediate',
  name: 'topdoorcenterindermediateM',
  type: 'number',
};
export const adN = {
  label: 'N - Threshold',
  name: 'thresholdN',
  type: 'select',
  options: ndadThreshold,
};
export const adO = {
  label: 'Door Closer',
  name: 'doorcloserO',
  type: 'select',
  options: ndadDoorCloser,
};
export const adOP = {
  label: 'Handles',
  name: 'handlesOP',
  type: 'select',
  options: ndgdDoorHandle,
};
export const adOPNoteHandles = {
  label: 'Notes',
  name: 'handlesOPNote',
  type: 'text',
};
export const adP = {
  label: 'Lock',
  name: 'lockP',
  type: 'select',
  options: ndadLock,
};
export const adQ = {
  label: 'Panic Device',
  name: 'panicdeviceQ',
  type: 'select',
  options: ndadPanicDevice,
};
export const adR = {
  label: 'R - Top of the door to the center line of Lock/panic',
  name: 'topdoorcenterlockpanicR',
  type: 'number',
};
export const adS = {
  label: 'S - Handles',
  name: 'handlesS',
  type: 'select',
  options: ndadHandles,
};
export const adT = {
  label: 'T - Top of the door to the center line of Top pull',
  name: 'topdoorcentertoppullT',
  type: 'number',
};
export const adU = {
  label: 'Mid Panel *if required',
  name: 'midpanelU',
  type: 'select',
  options: ndadMidPanel,
};
export const adW = {
  label: 'Bottom Rail *if different from standard',
  name: 'bottomrailW',
  type: 'select',
  options: ndadBottomRail,
};
export const adV = {
  label: 'V - Top of the door to the center line of Mid panel',
  name: 'topdoorcentermidpanelV',
  type: 'number',
};
export const adX = {
  label: 'X - Door Sweeps',
  name: 'doorsweepsX',
  type: 'select',
  options: ndadDoorSweeps,
};

/* HOLLOW METAL DOORS  */
export const ndhmdA = {
  label: 'A/B - Door Outside Side',
  name: 'doorWA',
  name2: 'doorHB',
  type: 'WxH',
};
export const ndhmdC = {
  label: 'C - Top of the door to top hinge #1',
  name: 'topofdoortophinge_1',
  type: 'text',
};
export const ndhmdD = {
  label: 'D - Top of the door to top hinge #2',
  name: 'topofdoortophinge_2',
  type: 'text',
};
export const ndhmdE = {
  label: 'E - Top of the door to top hinge #3',
  name: 'topofdoortophinge_3',
  type: 'text',
};
export const ndhmdF = {
  label: 'F - Top of the door to top hinge #4',
  name: 'topofdoortophinge_4',
  type: 'text',
};
export const ndhmdG = {
  label: 'G - Top of the doors to Center line of the dead bolt',
  name: 'topofdoorscenterlinedeadbolt',
  type: 'text',
};
export const ndhmdH = {
  label: 'H - Top of the doors to Center line of the lock',
  name: 'topofdoorscenterlinelock',
  type: 'text',
};
export const ndhmdI = {
  label: 'I - Lock Backset',
  name: 'lockbacksetI',
  type: 'text',
};

export const ndhmdDoorType = {
  label: 'Door Type',
  name: 'ndhmdDoorType',
  type: 'select',
  options: ndhmdDoorTypeOptions,
};

export const ndhmdDoorTypeNote = {
  label: 'Notes',
  name: 'ndhmdDoorTypeNote',
  type: 'text',
};

export const ndhmdDoorAction = {
  label: 'Door Action',
  name: 'ndhmdDoorAction',
  type: 'select',
  options: ndhmdDoorActionOptions,
};

export const ndhmdDoorActionNote = {
  label: 'Notes',
  name: 'ndhmdDoorActionNote',
  type: 'text',
};

export const ndhmdDoorPanel = {
  label: 'Door Panel Layout',
  name: 'ndhmdDoorPanel',
  type: 'select',
  options: ndhmdDoorPanelOptions,
};

export const ndhmdDoorPanelNote = {
  label: 'Notes',
  name: 'ndhmdDoorPanelNote',
  type: 'text',
};

export const ndhmdHingeSize = {
  label: 'Hinge Size',
  name: 'ndhmdHingeSize',
  type: 'select',
  options: ndhmdHingeSizeOptions,
};

export const ndhmdHingeSizeNote = {
  label: 'Notes',
  name: 'ndhmdHingeSizeNote',
  type: 'text',
};

export const ndhmdLouver = {
  label: 'Louver',
  name: 'ndhmdLouver',
  type: 'select',
  options: ndhmdLouverOptions,
};

export const ndhmdLouverNote = {
  label: 'Notes',
  name: 'ndhmdLouverNote',
  type: 'text',
};

export const ndhmdDoorFrameFinish = {
  label: 'Door/Frame Finish',
  name: 'ndhmdDoorFrameFinish',
  type: 'select',
  options: ndhmdDoorFrameFinishOptions,
};

export const ndhmdDoorFrameFinishNote = {
  label: 'Notes',
  name: 'ndhmdDoorFrameFinishNote',
  type: 'text',
};

export const ndhmdDoorHardwareFinish = {
  label: 'Hardware Finish',
  name: 'ndhmdDoorHardwareFinish',
  type: 'select',
  options: ndadFinish,
};

export const ndhmdDoorHardwareFinishNote = {
  label: 'Notes',
  name: 'ndhmdDoorHardwareFinishNote',
  type: 'text',
};

export const ndhmdDoorClosers = {
  label: 'Door Closer',
  name: 'ndhmdDoorClosers',
  type: 'select',
  options: ndhmdDoorCloserOptions,
};

export const ndhmdDoorCloserNote = {
  label: 'Notes',
  name: 'ndhmdDoorCloserNote',
  type: 'text',
};

export const ndhmdRoomType = {
  label: 'Room Type',
  name: 'ndhmdRoomType',
  type: 'select',
  options: ndhmdRoomTypeOptions,
};

export const ndhmdRoomTypeNote = {
  label: 'Notes',
  name: 'ndhmdRoomTypeNote',
  type: 'text',
};

export const ndhmdLocksetFunction = {
  label: 'Lockset + Function',
  name: 'ndhmdLocksetFunction',
  type: 'select',
  options: ndhmdLocksetFunctionOptions,
};

export const ndhmdLocksetFunctionNote = {
  label: 'Notes',
  name: 'ndhmdLocksetFunctionNote',
  type: 'text',
};

export const ndhmdDeadBolt = {
  label: 'Dead Bolt',
  name: 'ndhmdDeadBolt',
  type: 'select',
  options: ndhmdDeadBoltOptions,
};

export const ndhmdDeadBoltNote = {
  label: 'Notes',
  name: 'ndhmdDeadBoltNote',
  type: 'text',
};

export const ndhmdDoorSweepWeatherStripping = {
  label: 'Door Sweep + Weather Stripping',
  name: 'ndhmdDoorSweepWeatherStripping',
  type: 'select',
  options: ndhmdDoorSweepWeatherStrippingOptions,
};

export const ndhmdDoorSweepWeatherStrippingNote = {
  label: 'Notes',
  name: 'ndhmdDoorSweepWeatherStrippingNote',
  type: 'text',
};

export const ndhmdFrameProfile = {
  label: 'Frame Profile',
  name: 'ndhmdFrameProfile',
  type: 'select',
  options: ndhmdFrameProfileOptions,
};

export const ndhmdFrameProfileNote = {
  label: 'Notes',
  name: 'ndhmdFrameProfileNote',
  type: 'text',
};

export const ndhmdFrameThickness = {
  label: 'Frame Thickness',
  name: 'ndhmdFrameThickness',
  type: 'select',
  options: ndhmdFrameThicknessOptions,
};

export const ndhmdFrameThicknessNote = {
  label: 'Notes',
  name: 'ndhmdFrameThicknessNote',
  type: 'text',
};

export const ndhmdFrameFabrication = {
  label: 'Frame Fabrication',
  name: 'ndhmdFrameFabrication',
  type: 'select',
  options: ndhmdFrameFabricationOptions,
};

export const ndhmdFrameFabricationNote = {
  label: 'Notes',
  name: 'ndhmdFrameFabricationNote',
  type: 'text',
};

export const ndhmdFrameInstallType = {
  label: 'Frame Install Type',
  name: 'ndhmdFrameInstallType',
  type: 'select',
  options: ndhmdFrameInstallTypeOptions,
};

export const ndhmdFrameInstallTypeNote = {
  label: 'Notes',
  name: 'ndhmdFrameInstallTypeNote',
  type: 'text',
};

export const ndhmdJ = {
  label: 'Hinge Height',
  name: 'hingeheightJ',
  type: 'select',
  options: ndhmdHingeHeight,
};

export const ndhmdJNote = {
  label: 'Notes',
  name: 'ndhmdJNote',
  type: 'text',
};

export const ndhmdK = {
  label: 'Door Thickness',
  name: 'doorthickK',
  type: 'select',
  options: ndhmdDoorThickness,
};

export const ndhmdKNote = {
  label: 'Notes',
  name: 'ndhmdKNote',
  type: 'text',
};

export const ndhmdL = {
  label: 'Continuous Hinge',
  name: 'contHingeL',
  type: 'select',
  options: ndhmdContinuousHinge,
};

export const ndhmdLNote = {
  label: 'Notes',
  name: 'ndhmdLNote',
  type: 'text',
};

export const ndhmdM = {
  label: 'Door Hand',
  name: 'doorhandM',
  type: 'select',
  options: ndhmdDoorHand,
};

export const ndhmdMNote = {
  label: 'Notes',
  name: 'ndhmdMNote',
  type: 'text',
};

export const ndhmdN = {
  label: 'Fire Rating',
  name: 'fireratingN',
  type: 'select',
  options: ndhmdFireRating,
};

export const ndhmdNNote = {
  label: 'Notes',
  name: 'ndhmdNNote',
  type: 'text',
};

export const ndhmdO = {
  label: 'Vision Kit',
  name: 'visionkitO',
  type: 'select',
  options: ndhmdVisionKit,
};

export const ndhmdONote = {
  label: 'Notes',
  name: 'ndhmdONote',
  type: 'text',
};
export const ndhmdP = {
  label: 'Lockset',
  name: 'locksetP',
  type: 'select',
  options: ndhmdLockset,
};

export const ndhmdPNote = {
  label: 'Notes',
  name: 'ndhmdPNote',
  type: 'text',
};
export const ndhmdQ = {
  label: 'Dead Bolt',
  name: 'deadboltButtonQ',
  type: 'button',
  options: ['No', 'Yes'],
};

export const ndhmdR = {
  label: 'Panic Device',
  name: 'panicdeviceR',
  type: 'select',
  options: ndhmdPanicDevice,
};

export const ndhmdRNote = {
  label: 'Notes',
  name: 'ndhmdRNote',
  type: 'text',
};
export const ndhmdS = {
  label: 'Door Closer',
  name: 'doorcloserS',
  type: 'select',
  options: ndhmdDoorCloser,
};

export const ndhmdSNote = {
  label: 'Notes',
  name: 'ndhmdSNote',
  type: 'text',
};
export const ndhmdT = {
  label: 'Pull Set',
  name: 'pullset',
  type: 'select',
  options: ndhmdPullSet,
};

export const ndhmdTNote = {
  label: 'Notes',
  name: 'ndhmdTNote',
  type: 'text',
};

export const ndhmdU = {
  label: 'Latch Guard',
  name: 'latchguardButtonU',
  type: 'button',
  options: ['No', 'Yes'],
};

export const ndhmdV = {
  label: 'Kick Plate',
  name: 'kickplate',
  type: 'select',
  options: ndhmdKickPlate,
};

export const ndhmdVNote = {
  label: 'Notes',
  name: 'ndhmdVNote',
  type: 'text',
};

export const ndhmdW = {
  label: 'Door Sweep',
  name: 'doorsweepButtonW',
  type: 'button',
  options: ['No', 'Yes'],
};
export const ndhmdX = {
  label: 'Drip Cap (FRAME)',
  name: 'dripcapButtonX',
  type: 'button',
  options: ['No', 'Yes'],
};
export const ndhmdY = {
  label: 'Door Viewer',
  name: 'doorviewerButtonY',
  type: 'button',
  options: ['No', 'Yes'],
};
/* OP 3-4 */
export const ndhmdopA = {
  label: 'A - Wall type',
  name: 'walltypeA',
  type: 'text',
};
export const ndhmdopB = {
  label: 'B - Rought Opening Width - Bottom',
  name: 'roughopeningB',
  type: 'text',
};
export const ndhmdopC = {
  label: 'C - Rought Opening Width - Middle',
  name: 'roughopeningC',
  type: 'text',
};
export const ndhmdopD = {
  label: 'D - Rought Opening Width - Top',
  name: 'roughopeningD',
  type: 'text',
};
export const ndhmdopE = {
  label: 'E - Rought Opening Height - Left',
  name: 'roughopeningE',
  type: 'text',
};
export const ndhmdopF = {
  label: 'F - Rought Opening Height - Right',
  name: 'roughopeningF',
  type: 'text',
};
export const ndhmdopG = {
  label: 'G - WallDepth or Thickness',
  name: 'walldepththicknessG',
  type: 'text',
};
export const ndhmdopH = {
  label: 'H - Frame Width',
  name: 'framewidth',
  type: 'text',
};
export const ndhmdZ = {
  label: 'Z - Frame Depth',
  name: 'framdepthZ',
  type: 'text',
};

/* PATIO DOOR */
export const pdOA = {
  label: 'Measurements - Opening Size',
  name: 'measureA',
  name2: 'measureB',
  type: 'WxH',
};

export const pdFA = {
  label: 'Measurements - Frame Size',
  name: 'measureFA',
  name2: 'measureFB',
  type: 'WxH',
};

export const pdDW = {
  label: 'Operable Panel Width (if different from standard)',
  name: 'doorWidthE',
  type: 'number',
};
export const pdFrameT = {
  label: 'Frame Material',
  name: 'frametype',
  type: 'select',
  options: pdFrameType,
};

export const pdFrameTNote = {
  label: 'Notes',
  name: 'pdFrameTNote',
  type: 'text',
};

export const pdFramingD = {
  label: 'Framing Depth',
  name: 'framingD',
  type: 'select',
  options: pdFramingDepth,
};

export const pdFramingDNote = {
  label: 'Notes',
  name: 'pdFramingDNote',
  type: 'text',
};
export const pdThermallyB = {
  label: 'Thermally Broken',
  name: 'thermallyB',
  type: 'select',
  options: pdThermallyBroken,
};

export const pdThermallyBNote = {
  label: 'Notes',
  name: 'pdThermallyBNote',
  type: 'text',
};

export const pdFram = {
  label: 'Frame Installation',
  name: 'frame',
  type: 'select',
  options: pdFrame,
};

export const pdFramNote = {
  label: 'Notes',
  name: 'pdFramNote',
  type: 'text',
};

export const pdFrameR = {
  label: 'Frame Receptor',
  name: 'frameR',
  type: 'select',
  options: pdFrameReceptor,
};

export const pdFrameRNote = {
  label: 'Notes',
  name: 'pdFrameRNote',
  type: 'text',
};

export const pdAssem = {
  label: 'Assembly',
  name: 'assem',
  type: 'select',
  options: pdAssembly,
};

export const pdAssemNote = {
  label: 'Notes',
  name: 'pdAssemNote',
  type: 'text',
};

export const pdLocation = {
  label: 'Location',
  name: 'pdLocation',
  type: 'text',
};
export const pdLocationNotes = {
  label: 'Notes',
  name: 'pdLocationNotes',
  type: 'text',
};
export const pdType = {
  label: 'Type',
  name: 'pdType',
  type: 'select',
  options: pdTypeOptions,
};
export const pdTypeNotes = {
  label: 'Notes',
  name: 'pdTypeNotes',
  type: 'text',
};

export const pdFrameFinishInterior = {
  label: 'Frame Finish Interior',
  name: 'pdFrameFinishInterior',
  type: 'select',
  options: pdFrameFinishInteriorOptions,
};
export const pdFrameFinishInteriorNotes = {
  label: 'Notes',
  name: 'pdFrameFinishInteriorNotes',
  type: 'text',
};
export const pdFrameFinishExterior = {
  label: 'Frame Finish (EXTERIOR, IF DIFFERENT FROM INTERIOR)',
  name: 'pdFrameFinishExterior',
  type: 'select',
  options: pdFrameFinishExteriorOptions,
};
export const pdFrameFinishExteriorNotes = {
  label: 'Notes',
  name: 'pdFrameFinishExteriorNotes',
  type: 'text',
};

export const pdGlassType = {
  label: 'Glass Type',
  name: 'pdGlassType',
  type: 'select',
  options: pdGlassTypeOptions,
};
export const pdGlassTypesNotes = {
  label: 'Notes',
  name: 'pdGlassTypesNotes',
  type: 'text',
};
export const pdGlassCoating = {
  label: 'Glass Coating',
  name: 'pdGlassCoating',
  type: 'select',
  options: pdGlassCoatingOptions,
};
export const pdGlassCoatingNotes = {
  label: 'Notes',
  name: 'pdGlassCoatingNotes',
  type: 'text',
};

export const pdDoorHardware = {
  label: 'PATIO DOOR HARDWARE OPTIONS',
  name: 'pdDoorHardware',
  type: 'select',
  options: pdDoorHardwareOptions,
};
export const pdDoorHardwareNotes = {
  label: 'Notes',
  name: 'pdDoorHardwareNotes',
  type: 'text',
};
export const pdExteriorTrim = {
  label: 'Trim (EXTERIOR)',
  name: 'pdExteriorTrim',
  type: 'select',
  options: pdExteriorTrimOptions,
};
export const pdExteriorTrimNotes = {
  label: 'Notes',
  name: 'pdExteriorTrimNotes',
  type: 'text',
};
export const pdInteriorTrim = {
  label: 'Trim (INTERIOR)',
  name: 'pdInteriorTrim',
  type: 'select',
  options: pdInteriorTrimOptions,
};
export const pdInteriorTrimNotes = {
  label: 'Notes',
  name: 'pdInteriorTrimNotes',
  type: 'text',
};
export const pdHardwareFinish = {
  label: 'Hardware Finish',
  name: 'pdHardwareFinish',
  type: 'select',
  options: pdHardwareFinishOptions,
};
export const pdHardwareFinishNotes = {
  label: 'Notes',
  name: 'pdHardwareFinishNotes',
  type: 'text',
};
export const pdScreens = {
  label: 'Screens',
  name: 'screens',
  type: 'select',
  options: pdScreensOptions,
};
export const pdScreensNotes = {
  label: 'Notes',
  name: 'pdScreensNotes',
  type: 'text',
};

export const pdExtraOne = {
  label: 'Extra #1',
  name: 'pdExtraOne',
  type: 'text',
};
export const pdExtraOneNote = {
  label: 'Notes',
  name: 'pdExtraOneNote',
  type: 'text',
};

export const pdExtraTwo = {
  label: 'Extra #2',
  name: 'pdExtraTwo',
  type: 'text',
};
export const pdExtraTwoNote = {
  label: 'Notes',
  name: 'pdExtraTwoNote',
  type: 'text',
};

export const pdExtraThree = {
  label: 'Extra #3',
  name: 'pdExtraThree',
  type: 'text',
};
export const pdExtraThreeNote = {
  label: 'Notes',
  name: 'pdExtraThreeNote',
  type: 'text',
};
export const pdExtraFour = {
  label: 'Extra #4',
  name: 'pdExtraFour',
  type: 'text',
};
export const pdExtraFourNote = {
  label: 'Notes',
  name: 'pdExtraFourNote',
  type: 'text',
};
export const pdExtraFive = {
  label: 'Extra #5',
  name: 'pdExtraFive',
  type: 'text',
};
export const pdExtraFiveNote = {
  label: 'Notes',
  name: 'pdExtraFiveNote',
  type: 'text',
};

export const pdGlassInstallation = {
  label: 'Glass Installation',
  name: 'glassInstallation',
  type: 'select',
  options: pdGlassInstallationOptions,
};
export const pdGlassInstallationNotes = {
  label: 'Notes',
  name: 'pdGlassInstallationNotes',
  type: 'text',
};
export const pdOperableSashSize = {
  label: 'OPERABLE SASH SIZE ',
  name: 'pdOperableSashSize',
  type: 'text',
};

export const pdGridsType = {
  label: 'Grids Type',
  name: 'pdGridsType',
  type: 'select',
  options: pdGridsTypeOptions,
};
export const pdGridsTypeNotes = {
  label: 'Notes',
  name: 'pdGridsTypeNotes',
  type: 'text',
};
export const pdGridsHorizontal = {
  label: 'Horizontal Grids',
  name: 'pdGridsHorizontal',
  type: 'numberInputWithButtons',
  amountCol: 12,
};
export const pdGridsVertical = {
  label: 'Vertical Grids',
  name: 'pdGridsVertical',
  type: 'numberInputWithButtons',
  amountCol: 12,
};
export const pdGridsColor = {
  label: 'Grids Color',
  name: 'pdGridsColor',
  type: 'select',
  options: GridColor,
};
export const pdGridsColorNotes = {
  label: 'Notes',
  name: 'pdGridsColorNotes',
  type: 'text',
};
export const pdThickness = {
  label: 'Grid Thickness',
  name: 'pdThickness',
  type: 'select',
  options: GridThickness,
};
export const pdThicknessNotes = {
  label: 'Notes',
  name: 'pdThicknessNotes',
  type: 'text',
};
export const pdFinA = {
  label: 'Finish - A',
  name: 'finishA',
  type: 'select',
  options: pdFinishA,
};

export const pdFinANote = {
  label: 'Notes',
  name: 'pdFinANote',
  type: 'text',
};

export const pdFinB = {
  label: 'Finish - B (if different from A)',
  name: 'finishB',
  type: 'select',
  options: pdFinishB,
};

export const pdFinBNote = {
  label: 'Notes',
  name: 'pdFinBNote',
  type: 'text',
};

export const pdGlassThick = {
  label: 'Glass Thickness',
  name: 'glassthick',
  type: 'select',
  options: pdGlassThickness,
};

export const pdGlassThickNote = {
  label: 'Notes',
  name: 'pdGlassThickNote',
  type: 'text',
};

export const pdGlassC = {
  label: 'Glass Color',
  name: 'glassC',
  type: 'select',
  options: pdGlassColor,
};

export const pdGlassCNote = {
  label: 'Notes',
  name: 'pdGlassCNote',
  type: 'text',
};

export const pdGrid = {
  label: 'Grids',
  name: 'grid',
  type: 'select',
  options: pdGrids,
};

export const pdGridNote = {
  label: 'Notes',
  name: 'pdGridNote',
  type: 'text',
};

export const pdHardwareC = {
  label: 'Hardware Color',
  name: 'hardwareC',
  type: 'select',
  options: pdHardwareColor,
};

export const pdHardwareCNote = {
  label: 'Notes',
  name: 'pdHardwareCNote',
  type: 'text',
};
export const pdWGlassColorWindows = {
  label: 'Glass Color',
  name: 'pdWGlassColorWindows',
  type: 'select',
  options: pdGlassColor,
};

export const pdWGlassColorWindowsNote = {
  label: 'Notes',
  name: 'pdWGlassColorWindowsNote',
  type: 'text',
};

export const pdWGlassThickness = {
  label: 'Glass Thickness',
  name: 'pdWGlassThickness',
  type: 'select',
  options: pdGlassThickness,
};

export const pdWGlassThicknessNote = {
  label: 'Notes',
  name: 'pdWGlassThicknessNote',
  type: 'text',
};

/* HANDLES */
export const ndHandleA = {
  label: 'Handle A',
  name: 'ndHandleA',
  name2: 'holesizeA',
  name3: 'notesA',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleB = {
  label: 'Handle B',
  name: 'ndHandleB',
  name2: 'holesizeB',
  name3: 'notesB',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleC = {
  label: 'Handle C',
  name: 'ndHandleC',
  name2: 'holesizeC',
  name3: 'notesC',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleD = {
  label: 'Handle D',
  name: 'ndHandleD',
  name2: 'holesizeD',
  name3: 'notesD',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleE = {
  label: 'Handle E',
  name: 'ndHandleE',
  name2: 'holesizeE',
  name3: 'notesE',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleF = {
  label: 'Handle F',
  name: 'ndHandleF',
  name2: 'holesizeF',
  name3: 'notesF',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleG = {
  label: 'Handle G',
  name: 'ndHandleG',
  name2: 'holesizeG',
  name3: 'notesG',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleH = {
  label: 'Handle H',
  name: 'ndHandleH',
  name2: 'holesizeH',
  name3: 'notesH',
  type: 'ndhandle',
  options: pdHoleSize,
};
export const ndHandleI = {
  label: 'Handle I',
  name: 'ndHandleI',
  name2: 'holesizeI',
  name3: 'notesI',
  type: 'ndhandle',
  options: pdHoleSize,
};

export const filmsLocation = {
  label: 'Location',
  name: 'filmsLocation',
  type: 'text',
};
export const filmsQuantity = {
  label: 'Quantity',
  name: 'filmsQuantity',
  type: 'numberInputWithButtons',
};
export const filmsSizeWidth = {
  label: 'WIDTH',
  name: 'filmsSizeWidth',
  type: 'text',
};
export const filmsSizeHeigth = {
  label: 'HEIGTH',
  name: 'filmsSizeHeigth',
  type: 'text',
};

export const filmsType = {
  label: 'Film Type',
  name: 'filmsType',
  type: 'select',
  options: filmsTypeOptions,
};
export const filmsTypeNotes = {
  label: 'Notes',
  name: 'filmsTypeNotes',
  type: 'text',
};

export const filmsBrand = {
  label: 'Film Brand (if known)',
  name: 'filmsBrand',
  type: 'select',
  options: filmsBrandOptions,
};
export const filmsBrandNotes = {
  label: 'Notes',
  name: 'filmsBrandNotes',
  type: 'text',
};
export const filmsAppliedSide = {
  label: 'Film Applied Side',
  name: 'filmsAppliedSide',
  type: 'select',
  options: filmsAppliedSideOptions,
};
export const filmsAppliedSideNotes = {
  label: 'Notes',
  name: 'filmsAppliedSideNotes',
  type: 'text',
};
export const filmsSpliceAcceptable = {
  label: 'Splice Acceptable - If Nessesary',
  name: 'filmsSpliceAcceptable',
  type: 'select',
  options: filmsSpliceAcceptableOptions,
};

export const filmsSpliceAcceptableNotes = {
  label: 'Notes',
  name: 'filmsSpliceAcceptableNotes',
  type: 'text',
};

export const filmsWetsealFilmToFrame = {
  label: 'Wetseal Film to Frame',
  name: 'filmsWetsealFilmToFrame',
  type: 'select',
  options: filmsWetsealFilmToFrameOptions,
};

export const filmsWetsealFilmToFrameNotes = {
  label: 'Notes',
  name: 'filmsWetsealFilmToFrameNotes',
  type: 'text',
};

export const filmsFrameType = {
  label: 'Frame Type',
  name: 'filmsFrameType',
  type: 'select',
  options: filmsFrameTypeOptions,
};
export const filmsFrameTypeNotes = {
  label: 'Notes',
  name: 'filmsFrameTypeNotes',
  type: 'text',
};
export const filmsGlassType = {
  label: 'Glass Type',
  name: 'filmsGlassType',
  type: 'select',
  options: filmsGlassTypeOptions,
};
export const filmsGlassTypeNotes = {
  label: 'Notes',
  name: 'filmsGlassTypeNotes',
  type: 'text',
};

export const filmsGlassThickness = {
  label: 'Glass Thickness ',
  name: 'filmsGlassThickness',
  type: 'select',
  options: filmsGlassThicknessOptions,
};
export const filmsGlassThicknessNotes = {
  label: 'Notes',
  name: 'filmsGlassThicknessNotes',
  type: 'text',
};

export const filmsTempered = {
  label: 'TEMPERED',
  name: 'filmsTempered',
  type: 'select',
  options: filmsTemperedOptions,
};
export const filmsTemperedNotes = {
  label: 'Notes',
  name: 'filmsTemperedNotes',
  type: 'text',
};
export const filmsRemovalRequired = {
  label: 'Film Removal Required?',
  name: 'filmsRemovalRequired',
  type: 'select',
  options: filmsRemovalRequiredOptions,
};
export const filmsRemovalRequiredNotes = {
  label: 'Notes',
  name: 'filmsRemovalRequiredNotes',
  type: 'text',
};
export const filmsLiftEquipmentNeeded = {
  label: 'LIFT EQUIPMENT NEEDED',
  name: 'filmsLiftEquipmentNeeded',
  type: 'select',
  options: filmsLiftEquipmentNeededOptions,
};

export const filmsLiftEquipmentNeededNotes = {
  label: 'Notes',
  name: 'filmsLiftEquipmentNeededNotes',
  type: 'text',
};
export const filmsInstallLocation = {
  label: 'INSTALL LOCATION',
  name: 'filmsInstallLocation',
  type: 'select',
  options: filmsInstallLocationOptions,
};
export const filmsInstallLocationNotes = {
  label: 'Notes',
  name: 'filmsInstallLocationNotes',
  type: 'text',
};
export const filmsExtraOne = {
  label: 'EXTRA #1',
  name: 'filmsExtraOne',
  type: 'text',
  placeholder: 'PART, EQUIPMENT, ETC',
};
export const filmsExtraOneNotes = {
  label: 'Notes',
  name: 'filmsExtraOneNotes',
  type: 'text',
};
export const filmsExtraTwo = {
  label: 'EXTRA #2',
  name: 'filmsExtraTwo',
  type: 'text',
  placeholder: 'PART, EQUIPMENT, ETC',
};
export const filmsExtraTwoNotes = {
  label: 'Notes',
  name: 'filmsExtraTwoNotes',
  type: 'text',
};

export const filmsExtraThree = {
  label: 'EXTRA #3',
  name: 'filmsExtraThree',
  type: 'text',
  placeholder: 'PART, EQUIPMENT, ETC',
};
export const filmsExtraThreeNotes = {
  label: 'Notes',
  name: 'filmsExtraThreeNotes',
  type: 'text',
};

export const filmsExtraFour = {
  label: 'EXTRA #4',
  name: 'filmsExtraFour',
  type: 'text',
  placeholder: 'PART, EQUIPMENT, ETC',
};
export const filmsExtraFourNotes = {
  label: 'Notes',
  name: 'filmsExtraFourNotes',
  type: 'text',
};

export const filmsExtraFive = {
  label: 'EXTRA #5',
  name: 'filmsExtraFive',
  type: 'text',
  placeholder: 'PART, EQUIPMENT, ETC',
};

export const filmsExtraFiveNotes = {
  label: 'Notes',
  name: 'filmsExtraFiveNotes',
  type: 'text',
};
