import React, { Component } from 'react';
import { Field, FieldArray } from 'formik';
import { Row, Col, Form, Image, Button, Modal, Alert } from 'react-bootstrap';
import FormInput from 'components/FormInputs';
import TabActions from 'components/TabActions';
import DropZone from 'components/DropZone';
import {
  DoorCategory,
  DoorCategoryTypes,
  RepairDoorsHandle,
  RepairDoorsHandleFields,
} from 'constants/repairDoors';
import { isRequired } from 'utils/rules';
import GlassRemindersView from './GlassRemindersView';
import GlassMaterialsView from './GlassMaterialsView';
import Sketch from 'components/Sketch';

const HANDLES = {
  rpHandleA: '',
  holesizeA: '',
  notesA: '',
  rpHandleB: '',
  holesizeB: '',
  notesB: '',
  rpHandleC: '',
  holesizeC: '',
  notesC: '',
  rpHandleD: '',
  holesizeD: '',
  notesD: '',
  rpHandleE: '',
  holesizeE: '',
  notesE: '',
  rpHandleF: '',
  holesizeF: '',
  notesF: '',
  rpHandleG: '',
  holesizeG: '',
  notesG: '',
  rpHandleH: '',
  holesizeH: '',
  notesH: '',
  rpHandleI: '',
  holesizeI: '',
  notesI: '',
};

const DETAILS = {
  locationDoor: '',
  finishA: '',
  widthGen: '',
  heightGen: '',
  widthGenA: '',
  heightGenA: '',
  brandA: '',
  hmdbrandA: '',
  doorCloserB: '',
  doorCloserB_detail: '',
  hmdcocCloserB: '',
  hmdcocCloserB_detail: '',
  cocCloser: '',
  cocCloser_detail: '',
  topPivotC: '',
  topPivotC_detail: '',
  topPivotArmC: '',
  topPivotArmC_detail: '',
  topPivotFrameCOne: '',
  topPivotDoorCTwo: '',
  glassColorThicknessCD: '',
  patchFittingD: '',
  patchFittingD_detail: '',
  surfaceMountedCloserD: '',
  surfaceMountedCloserD_detail: '',
  brandASF: '',
  brandANotesSF: '',
  thresholdNAllGlass: '',
  thresholdNAllGlassNotes: '',
  hmdsurfaceMountedCloserD: '',
  hmdsurfaceMountedCloserD_detail: '',
  topPivotE: '',
  topPivotE_detail: '',
  offsetArm: '',
  offsetArm_detail: '',
  topInsertArmE: '',
  topInsertArmE_detail: '',
  totalGlassDoorHeightE: '',
  hmdoffsetArmE: '',
  hmdoffsetArmE_detail: '',
  headerDoorStopF: '',
  headerDoorStopF_detail: '',
  dayliteF: '',
  dropPlate: '',
  dropPlate_detail: '',
  typeOfGlassG: '',
  typeOfGlassG_detail: '',
  topShoeSizeG: '',
  handlesH: '',
  handlesH_detail: '',
  handlesHStorefront: '',
  handlesHStorefront_detail: '',
  topShoePocket: '',
  panicDeviceI: '',
  bottomShoeSizeI: '',
  panicDeviceI_detail: '',
  panicDeviceIStorefront: '',
  panicDeviceIStorefront_detail: '',
  hmdpanicDeviceRimI: '',
  hmdpanicDeviceRimI_detail: '',
  hmdpanicDeviceVerticalRodsIOne: '',
  hmdpanicDeviceVerticalRodsIOne_detail: '',
  edgeSealWeatherstripJ: '',
  edgeSealWeatherstripJ_detail: '',
  bottomShoePocketJ: '',
  weatherStrippingJ: '',
  weatherStrippingJ_detail: '',
  hmdweatherStrippingJ: '',
  hmdweatherStrippingJ_detail: '',
  bottomPivotArmK: '',
  bottomPivotArmK_detail: '',
  bottomPivotKStoreFront: '',
  bottomPivotKNotesStoreFront: '',
  floorcloserMStorefront: '',
  floorcloserMStorefrontNotes: '',
  typeOfBeadsTSF: '',
  typeOfBeadsTNotesSF: '',
  thresholdNStoreFront: '',
  thresholdNNotesStoreFront: '',
  bottompivotK: '',
  bottomInsertK: '',
  bottomInsertK_detail: '',
  doorOpeningK: '',
  bottompivotL: '',
  bottompivotL_detail: '',
  typeOfSetL: '',
  spindleL: '',
  hmdbottomArmL: '',
  hmdbottomArmL_detail: '',
  floorDoorCloserM: '',
  floorDoorCloserM_detail: '',
  floorcloserMStorefront: '',
  hmdfloorCloserM: '',
  hmdfloorCloserM_detail: '',
  thresholdN: '',
  thresholdN_detail: '',
  holeLocationN: '',
  hmdthresholdN: '',
  hmdthresholdN_detail: '',
  bottomRailO: '',
  topRailO_detail: '',
  topRailO: '',
  holeSizeO: '',
  bottomPatchLockManufacturerQ: '',
  patchLockP: '',
  patchFittingManufacturerP: '',
  patchLockP_detail: '',
  bottomArmQ: '',
  bottomArmQ_detail: '',
  bottomRailR: '',
  bottomRailR_detail: '',
  lockNotchWidthR: '',
  sideRailsS: '',
  sideRailsS_detail: '',
  lockNotchHeightS: '',
  sideRailPocket: '',
  typeOfBeadsT: '',
  typeOfBeadsT_detail: '',
  thumbturnU: '',
  thumbturnU_detail: '',
  keyCylinderV: '',
  keyCylinderV_detail: '',
  lockW: '',
  lockW_detail: '',
  pushPaddleX: '',
  pushPaddleX_detail: '',
  hmdlockW: '',
  hmdlockW_detail: '',
  hmdknobLockWOne: '',
  hmdknobLockWOne_detail: '',
  hmdleverLockWTwo: '',
  hmdleverLockWTwo_detail: '',
  topFlushBoltYOne: '',
  topFlushBoltYOne_detail: '',
  bottomFlushBoltYTwo: '',
  bottomFlushBoltYTwo_detail: '',
  hmdtopFlushBoltYOne: '',
  hmdtopFlushBoltYOne_detail: '',
  hmdbottomFlushBoltYTwo: '',
  hmdbottomFlushBoltYTwo_detail: '',
  topPivotOffsetZOne: '',
  topPivotOffsetZOne_detail: '',
  intermediatePivotZTwo: '',
  intermediatePivotZTwo_detail: '',
  bottomPivotOffsetZThree: '',
  bottomPivotOffsetZThree_detail: '',
  hmdcontinuousHingeZ: '',
  hmdcontinuousHingeZ_detail: '',
  continuousHingeZ: '',
  continuousHingeZ_detail: '',
  hmdtopHingeZOne: '',
  hmdtopHingeZOne_detail: '',
  hmdintermediateHingeZTwo: '',
  hmdintermediateHingeZTwo_detail: '',
  hmdbottomHingeZThree: '',
  hmdbottomHingeZThree_detail: '',
  glassColorThicknessShowerDoor: '',
  polishedEdges: '',
  heighttLeftA: '',
  widthttLeftA: '',
  heightRightDiffA: '',
  topDoorCLHingeD: '',
  topDoorCLHingeDM: '',
  bottomDoorCLHingeE: '',
  holeSizeG: '',
  BGA: '',
  BGAnotesA: '',
  BGB: '',
  BGAnotesB: '',
  BGC: '',
  BGAnotesC: '',
  BGD: '',
  BGAnotesD: '',
  BGE: '',
  BGAnotesE: '',
  BGF: '',
  BGAnotesF: '',
  BGG: '',
  BGAnotesG: '',
  BGH: '',
  BGAnotesH: '',
  BGI: '',
  BGAnotesI: '',
  BGJ: '',
  BGAnotesJ: '',
  BGK: '',
  BGAnotesK: '',
  BGL: '',
  BGAnotesL: '',
  BGLocation: '',
  BGLocationText: '',
  BGLocationNotes: '',
  BGType: '',
  BGTypeNotes: '',
  glassCoating: '',
  noteGlassCoating: '',
  hardwareOnsite: '',
  noteGhardwareOnsite: '',
  hardwareOnsite: '',
  noteWeatherOnsite: '',
  BGMetal: '',
  BGMetalNotes: '',
  BGPatchF: '',
  BGPatchFNote: '',
  BGPatchL: '',
  BGPatchLNote: '',
  BGSet: '',
  BGSetNote: '',
};

const INITIAL_VALUE = {
  glassReminders: {
    solar_film: 'no',
    solar_film_option_sag: null,
    wet_seal: 'no',
    wet_seal_option: null,
    furniture: 'no',
    walls_cielings: 'no',
    walls_cielings_option: null,
    blind_remove: 'no',
    glass_elevator: 'yes',
    color_waiver: 'no',
    damage_waiver: 'no',
    disclaimers: 'no',
    lift_inside: 'no',
    lift_outside: 'no',
  },
  glassMaterials: {
    caulks: [{ amount: 0, value: '', notes: '' }],
    types: [{ amount: 0, value: '', notes: '' }],
    equipaments: [{ amount: 0, value: '', notes: '' }],
    channels: [{ amount: 0, value: '', notes: '' }],
    miscellaneous: [{ amount: 0, value: '', notes: '' }],
  },
  details: {},
  handles: HANDLES,
  images: [],
  sketchs: [],
};

export default class RepairDoorsView extends Component {
  state = { current: 0, showEraseAlert: false };

  addValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) + 1);
  };

  substractValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) - 1);
  };

  erase = remove => {
    const { current } = this.state;
    remove(current);
    this.setState({ showEraseAlert: false, current: current - 1 });
  };

  viewItem = current => {
    this.setState({ current });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderRepairDoor = (repairDoor, key, form, push, remove, errors = {}, touched = {}) => {
    const { handleChange, handleBlur, setFieldValue, token } = this.props;

    return (
      <Row className="mb-4 ml-0 mr-0 pt-5" style={{ background: '#fff' }}>
        <Col xs={12} sm={5} md={5} xl={5}>
          <Form.Group controlId={`repairDoors.${key}.category`}>
            <Form.Label>
              <h3>Category</h3>
            </Form.Label>
            <Field
              as="select"
              className={
                errors.category && touched.category
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`repairDoors.${key}.category`}
              value={repairDoor.category || ''}
              validate={isRequired}
              onChange={evt => {
                setFieldValue(`repairDoors.${key}.type`, null);
                handleChange(evt);
              }}
              onBlur={handleBlur}
              required
            >
              <option value="">Choose a type</option>
              {DoorCategory.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.category && touched.category && errors.category}
            </Form.Control.Feedback>
          </Form.Group>
          {repairDoor.category && repairDoor.type && (
            <div>
              <Image
                src={DoorCategoryTypes[repairDoor.category].fields[repairDoor.type].picture}
                style={{ margin: '20px 0px', padding: '10px' }}
                thumbnail
              />
              <Form.Group controlId={`repairDoors.${key}.instructions`}>
                <Form.Label className="input-text">Instructions</Form.Label>
                <Field
                  as="textarea"
                  className={'textarea'}
                  name={`repairDoors.${key}.instructions`}
                  value={repairDoor.instructions || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                />
              </Form.Group>
            </div>
          )}
        </Col>
        <Col xs={12} sm={7} md={7} xl={7}>
          {DoorCategoryTypes[repairDoor.category] && (
            <Form.Group controlId={`repairDoors.${key}.type`}>
              <Form.Label>
                <h3>Type</h3>
              </Form.Label>
              <Field
                as="select"
                className={
                  errors.type && touched.type
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`repairDoors.${key}.type`}
                value={repairDoor.type || ''}
                validate={isRequired}
                onChange={e => {
                  setFieldValue(`repairDoors.${key}.type`, e.target.value);
                  setFieldValue(`repairDoors.${key}.details`, DETAILS);
                  setFieldValue(`repairDoors.${key}.handles`, HANDLES);
                  setFieldValue(`repairDoors.${key}.images`, []);
                  setFieldValue(`repairDoors.${key}.sketchs`, []);
                }}
                onBlur={handleBlur}
                required
              >
                <option value="">Choose a type</option>
                {DoorCategoryTypes[repairDoor.category].types.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
              <Form.Control.Feedback type="invalid">
                {errors.type && touched.type && errors.type}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {repairDoor.category && repairDoor.type && (
            <Row>
              {repairDoor.category === 'gdn' || repairDoor.category === 'sdbg'
                ? DoorCategoryTypes[repairDoor.category].fields[repairDoor.type].fields.map(
                    ({ name, name2, name3, type, label, details, options }, index) => (
                      <Col xs={12} sm={12} md={12} xl={12} key={index}>
                        <FormInput
                          name={`repairDoors.${key}.details.${name}`}
                          name2={`repairDoors.${key}.details.${name2}`}
                          name3={`repairDoors.${key}.details.${name3}`}
                          type={type}
                          label={`${label}`}
                          details={details}
                          options={options}
                          value={repairDoor.details[name] || ''}
                          value2={repairDoor.details[name2] || ''}
                          value3={repairDoor.details[name3] || ''}
                          isInvalid={
                            errors.details && touched.details
                              ? errors.details[name] && touched.details[name]
                              : false
                          }
                          isInvalidDetails={
                            errors.details && touched.details
                              ? !!errors.details[`${name}_detail`] &&
                                touched.details[`${name}_detail`]
                              : false
                          }
                          detailName={`repairDoors.${key}.details.${name}_detail`}
                          detailValue={repairDoor.details[`${name}_detail`]}
                          error={errors.details ? errors.details[name] : null}
                          validate={isRequired}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Col>
                    ),
                  )
                : DoorCategoryTypes[repairDoor.category].fields[repairDoor.type].fields.map(
                    ({ name, name2, type, label, details, options, placeholder }, index) => (
                      <Col xs={12} sm={12} md={12} key={index}>
                        <FormInput
                          name={`repairDoors.${key}.details.${name}`}
                          name2={`repairDoors.${key}.details.${name2}`}
                          type={type}
                          label={`${label}`}
                          details={details}
                          options={options}
                          value={repairDoor.details[name] || ''}
                          value2={repairDoor.details[name2] || ''}
                          isInvalid={
                            errors.details && touched.details
                              ? errors.details[name] && touched.details[name]
                              : false
                          }
                          isInvalidDetails={
                            errors.details && touched.details
                              ? !!errors.details[`${name}_detail`] &&
                                touched.details[`${name}_detail`]
                              : false
                          }
                          detailName={`repairDoors.${key}.details.${name}_detail`}
                          detailValue={repairDoor.details[`${name}_detail`]}
                          error={errors.details ? errors.details[name] : null}
                          validate={isRequired}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={placeholder}
                        />
                      </Col>
                    ),
                  )}
            </Row>
          )}
        </Col>

        {repairDoor.category && repairDoor.type && ['gdn', 'sdbg'].includes(repairDoor.category) && (
          <Col xs={12} md={12} sm={12} xl={12}>
            <Row>
              <Col xs={12} sm={12} md={5} xl={5}>
                <Form.Group controlId={`repairDoors.${key}.handle`}>
                  <Form.Label>Handle type</Form.Label>
                  <Field
                    as="select"
                    className={
                      errors.handle && touched.handle
                        ? 'form-control is-invalid is-invalid-select'
                        : 'form-control'
                    }
                    name={`repairDoors.${key}.handle`}
                    value={repairDoor.handle || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Choose a handle</option>
                    {RepairDoorsHandle.map(({ label, value }, index) => (
                      <option value={value} key={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                </Form.Group>
                {repairDoor.handle && (
                  <Image
                    src={RepairDoorsHandleFields[repairDoor.handle].picture}
                    style={{ margin: '20px 0px', padding: '10px' }}
                    thumbnail
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={7} xl={7}>
                {repairDoor.handle && (
                  <Row>
                    {RepairDoorsHandleFields[repairDoor.handle].fields.map(
                      ({ name, name2, name3, type, label, details, options }, index) => (
                        <Col xs={12} sm={12} md={12} xl={12} key={index}>
                          <FormInput
                            name={`repairDoors.${key}.handles.${name}`}
                            name2={`repairDoors.${key}.handles.${name2}`}
                            name3={`repairDoors.${key}.handles.${name3}`}
                            type={type}
                            label={`${(10 + index).toString(36).toUpperCase()} - ${label}`}
                            details={details}
                            options={options}
                            value={repairDoor.handles[name] || ''}
                            value2={repairDoor.handles[name2] || ''}
                            value3={repairDoor.handles[name3] || ''}
                            isInvalid={
                              errors.handles && touched.handles
                                ? errors.handles[name] && touched.handles[name]
                                : false
                            }
                            isInvalidDetails={
                              errors.handles && touched.handles
                                ? !!errors.handles[`${name}_detail`] &&
                                  touched.handles[`${name}_detail`]
                                : false
                            }
                            detailName={`repairDoors.${key}.handles.${name}_detail`}
                            detailValue={repairDoor.handles[`${name}_detail`]}
                            error={errors.hanldes ? errors.hanldes[name] : null}
                            validate={isRequired}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      ),
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        )}

        {repairDoor.category && repairDoor.type && (
          <Col xs={12} md={12} sm={12} xl={6} style={{ marginTop: '40px' }}>
            <GlassRemindersView
              prefix={`repairDoors.${key}.glassReminders`}
              values={repairDoor.glassReminders}
              errors={errors.glassReminders || {}}
              handleChange={handleChange}
              touched={touched.glassReminders || {}}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </Col>
        )}

        {repairDoor.category && repairDoor.type && (
          <Col xs={12} md={12} sm={12} xl={6} style={{ marginTop: '40px' }}>
            <GlassMaterialsView
              prefix={`repairDoors.${key}.glassMaterials`}
              values={repairDoor.glassMaterials}
              errors={errors.glassMaterials || {}}
              handleChange={handleChange}
              touched={touched.glassMaterials || {}}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </Col>
        )}

        <Col xs={12} sm={12} md={12} xl={12} className="p-0">
          <h3>Images</h3>
          <DropZone
            height={300}
            token={token}
            folder="repair-doors-uploads"
            initialFiles={repairDoor.images}
            handleSuccess={({ url }, position) => {
              const images = [...repairDoor.images];
              const pos = Math.min(position, repairDoor.images.length);
              images.splice(pos, 0, url);
              setFieldValue(`repairDoors.${key}.images`, images);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `repairDoors.${key}.images`,
                repairDoor.images.filter(u => u !== url),
              )
            }
            handlePosition={(fromIndex, toIndex) => {
              const images = [...repairDoor.images];
              const element = images[fromIndex];
              images.splice(fromIndex, 1);
              images.splice(toIndex, 0, element);

              setFieldValue(`repairDoors.${key}.images`, images);
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} className="mt-5 pt-3" style={{ cursor: 'cell' }}>
          <h3>Sketches</h3>
          <Form.Label>Click below to sketch </Form.Label>
          <Sketch
            token={token}
            folder="repair-doors-sketchs"
            sketchs={repairDoor.sketchs}
            handleSuccess={({ url }) => {
              setFieldValue(`repairDoors.${key}.sketchs`, [...repairDoor.sketchs, url]);
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                `repairDoors.${key}.sketchs`,
                repairDoor.sketchs.filter(u => u !== url),
              )
            }
          />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      values: { repairDoors },
      errors,
      touched,
    } = this.props;
    const { current, showEraseAlert } = this.state;
    const repairDoorsCount = repairDoors.length;
    const repairDoor = repairDoorsCount > 0 ? repairDoors[current] : null;

    return (
      <div>
        <FieldArray
          name="repairDoors"
          render={({ form, push, remove }) => (
            <div style={{ marginBottom: '40px' }} key="repairDoors">
              <TabActions
                name={'Repair Door'}
                count={repairDoorsCount}
                current={current}
                add={() => {
                  this.setState({ current: repairDoorsCount }, () => {
                    push(INITIAL_VALUE);
                  });
                }}
                ss={() => {}}
                s={() => {
                  this.setState({ current: repairDoorsCount }, () => {
                    push({
                      ...INITIAL_VALUE,
                      type: repairDoor.type,
                      category: repairDoor.category,
                      details: repairDoor.details,
                    });
                  });
                }}
                copy={() => {
                  this.setState({ current: repairDoorsCount }, () => {
                    push({ ...repairDoor });
                  });
                }}
                erase={() => this.setState({ showEraseAlert: true })}
                viewItem={this.viewItem}
              />
              {repairDoor ? (
                this.renderRepairDoor(
                  repairDoor,
                  current,
                  form,
                  push,
                  remove,
                  errors && errors.repairDoors ? errors.repairDoors[current] : {},
                  touched && touched.repairDoors ? touched.repairDoors[current] : {},
                )
              ) : (
                <Alert variant="light" style={{ paddingTop: 20, background: '#F8FBFF' }}>
                  <Alert.Heading style={{ textAlign: 'center' }}>
                    {`There is currently no Repair door, `}
                    <Alert.Link
                      onClick={() => {
                        this.setState({ current: repairDoorsCount }, () => {
                          push(INITIAL_VALUE);
                        });
                      }}
                    >
                      Click here
                    </Alert.Link>
                    {` to create a new`}
                  </Alert.Heading>
                </Alert>
              )}
              {/* Alerts */}
              {this.renderAlert(
                'Remove repair door',
                'Are you sure that want remove the repair door?',
                showEraseAlert,
                () => this.setState({ showEraseAlert: false }),
                'Remove',
                () => this.erase(remove),
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
