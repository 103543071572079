/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';

import { Form, Col, Row } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { createEvent } from 'actions/index';
import { getHours, getMinutes, isSameDay, set } from 'date-fns';
import { EVENT_TYPE, EVENTS_TYPES } from '../../../constants';

const removeDuplicates = participants => {
  const participantsArray = participants.split(',').map(participant => participant.trim());
  const uniqueParticipants = [...new Set(participantsArray)];
  return uniqueParticipants.join(', ');
};
const removeSalesman = (participants, currentJob) => {
  if (typeof participants !== 'string') {
    return [];
  }

  const participantsArray = participants.split(',').map(participant => participant.trim());

  const customerEmail = currentJob.customer?.email || '';
  const salesmanEmail = currentJob.salesman?.email || '';
  const secondSalesmanEmail = currentJob.second_salesman?.email || '';

  const filteredParticipants = participantsArray.filter(
    participant =>
      participant !== customerEmail &&
      participant !== salesmanEmail &&
      participant !== secondSalesmanEmail,
  );

  return filteredParticipants.join(', ');
};

const formatQuoteNumber = quote_number => {
  if (!quote_number) return '';
  const [part1, part2] = [quote_number.slice(0, 3), quote_number.slice(3)];
  return `${part1.trim()} ${part2.trim()}`;
};

const FormCreateEvent = forwardRef(
  ({ refSubmit, id, currentJob, company_id, company, participants, handleClose, openCreate }) => {
    const {
      email_notification,
      email_notification_enabled,
      app_manager,
      app_manager_enabled,
    } = company;
    const [currentType, setCurrentType] = useState('');

    const currentDate = new Date();
    const dispatch = useDispatch();

    const onSubmit = values => {
      let validParticipants = values.participants;

      if (Array.isArray(validParticipants)) {
        validParticipants = validParticipants.join(', ');
      }

      validParticipants = removeDuplicates(validParticipants);
      const filteredParticipants = removeSalesman(validParticipants, currentJob);

      const data = {
        ...values,
        company_id,
        job_id: id,
        participants: filteredParticipants,
        salesman_id: currentJob?.salesman_id || null,
        second_salesman_id: currentJob?.second_salesman_id || null,
        csr_id: currentJob?.csr_id || null,
      };
      dispatch(createEvent(data));
      handleClose();
    };

    const typeMappings = {
      text_message: 'Text Message',
      email: 'Email',
      call: 'Call',
      appointment: 'Appointment',
      notes: 'Notes',
    };

    const currentTypeFormatted = typeMappings[currentType] || currentType.toUpperCase();

    const formattedQuoteNumber = formatQuoteNumber(openCreate.quote_number);

    const getDefaultParticipants = () => {
      let defaultParticipants = [
        currentJob?.customer?.email,
        currentJob?.salesman?.email,
        currentJob?.second_salesman?.email,
      ].filter(Boolean);

      if (app_manager_enabled && app_manager) {
        defaultParticipants.push(app_manager);
      }

      if (email_notification_enabled && email_notification) {
        defaultParticipants.push(email_notification);
      }

      if (
        (!app_manager_enabled || !app_manager) &&
        (!email_notification_enabled || !email_notification)
      ) {
        defaultParticipants = [...defaultParticipants, ...participants];
      }

      if (currentType === EVENTS_TYPES.appointment && currentJob?.onsite_contact_email) {
        defaultParticipants.push(currentJob.onsite_contact_email);
      }

      if (
        currentType === EVENTS_TYPES.appointment &&
        currentJob.extraContact &&
        Array.isArray(currentJob.extraContact)
      ) {
        const extraEmails = currentJob.extraContact.map(contact => contact.email).filter(Boolean);
        defaultParticipants.push(...extraEmails);
      }

      return defaultParticipants;
    };

    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={{
          type: currentType,
          name: `Glaziers Tool - ${formattedQuoteNumber} - Event - ${currentTypeFormatted}`,
          start_time: currentType === EVENTS_TYPES.appointment ? '' : currentDate,
          end_time: currentType === EVENTS_TYPES.appointment ? '' : currentDate,
          notes: '',
          participants: [getDefaultParticipants()].filter(Boolean).join(', ') || '',
          location:
            [openCreate.address_line_1_job, openCreate.address_line_2_job, openCreate.city_job]
              .filter(Boolean)
              .join(', ') || '',
        }}
        validationSchema={Yup.object().shape({
          end_time: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('End date is required'),
          }),
          location: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Location is required'),
            otherwise: Yup.string(),
          }),
          name: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Name is required'),
          }),
          participants: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string()
              .required('Participants is required')
              .test('emails', 'Please enter valid emails separated by commas', value => {
                if (!value || value.trim() === '') {
                  return false;
                }
                return value
                  .split(',')
                  .every(email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()));
              }),
            otherwise: Yup.string().required('Participants is required'),
          }),

          start_time: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Start date is required'),
          }),
          type: Yup.string().required('Appointment is required'),
        })}
      >
        {({ values, handleChange, setFieldValue }) => (
          <FormikForm>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId="type">
                  <Form.Label className="input-text">Event type</Form.Label>
                  <Field
                    as="select"
                    className="form-control"
                    name="type"
                    value={currentType}
                    onChange={event => {
                      setCurrentType(event.target.value);
                    }}
                  >
                    <option value="">Choose a event</option>
                    {EVENT_TYPE.map(({ label, value }, index) => (
                      <option value={value} key={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="type" component="div" className="errorMessage" />
                </Form.Group>
              </Col>
              {values.type === EVENTS_TYPES.appointment && (
                <>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="name">
                      <Form.Label className="input-text">Event name</Form.Label>
                      <Field
                        placeholder="Name of event"
                        name="name"
                        className="form-control"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="name" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="start_time">
                      <div>
                        <Form.Label className="input-text">Start date & time</Form.Label>
                      </div>
                      <div className="customDatePickerWidth">
                        <Field
                          name="start_time"
                          render={({ field }) => (
                            <DatePicker
                              autoComplete="off"
                              name="start_time"
                              placeholderText="Start date & time"
                              className="form-control"
                              selected={field.value}
                              value={values.start_time}
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="Pp"
                              onChange={date => {
                                const startDate = new Date(date.setMilliseconds(0));
                                setFieldValue('start_time', startDate);
                                const endDate = new Date(startDate);
                                endDate.setMinutes(endDate.getMinutes() + 15);
                                endDate.setMilliseconds(0);
                                setFieldValue('end_time', endDate);
                              }}
                              minDate={new Date()}
                              minTime={
                                isSameDay(field.value || new Date(), new Date())
                                  ? set(new Date(), {
                                      hours: getHours(new Date()),
                                      minutes: getMinutes(new Date()),
                                    })
                                  : set(new Date(), { hours: 0, minutes: 0 })
                              }
                              maxTime={new Date().setHours(23, 59, 59)}
                            />
                          )}
                        />
                      </div>
                      <ErrorMessage name="start_time" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="end_time">
                      <div>
                        <Form.Label className="input-text">End date & time</Form.Label>
                      </div>
                      <div className="customDatePickerWidth">
                        <Field
                          name="end_time"
                          render={({ field }) => (
                            <DatePicker
                              autoComplete="off"
                              name="end_time"
                              placeholderText="End date & time"
                              className="form-control"
                              selected={field.value}
                              value={values.end_time}
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="Pp"
                              onChange={date => {
                                const endDate = new Date(date.setMilliseconds(0));
                                setFieldValue('end_time', endDate);
                              }}
                              minDate={values.start_time || new Date()}
                              minTime={
                                values.start_time &&
                                values.start_time.setHours(
                                  values.start_time.getHours(),
                                  values.start_time.getMinutes(),
                                )
                              }
                              excludeTimes={[values.start_time]}
                              maxTime={new Date().setHours(23, 59, 59)}
                              disabled={!values.start_time}
                            />
                          )}
                        />
                      </div>
                      <ErrorMessage name="end_time" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12}>
                    <Form.Group controlId="participants">
                      <Form.Label className="input-text">Participant's Email</Form.Label>
                      <Field
                        placeholder="Participants"
                        name="participants"
                        value={values.participants}
                        onChange={handleChange}
                        as="textarea"
                        className="textarea"
                        rows={2}
                      />
                      <ErrorMessage name="participants" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12}>
                    <Form.Group controlId="location">
                      <Form.Label className="input-text">Location</Form.Label>
                      <Field
                        placeholder="Enter location"
                        name="location"
                        className="form-control"
                        value={values.location}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <ErrorMessage name="location" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col xs={12}>
                <Form.Group controlId="notes">
                  <Form.Label className="input-text">Notes</Form.Label>
                  <Field
                    placeholder="Enter your notes here"
                    as="textarea"
                    className="textarea"
                    name="notes"
                    value={values.notes}
                    rows={3}
                  />
                  <ErrorMessage name="notes" component="div" className="errorMessage" />
                </Form.Group>
              </Col>
            </Row>
            <button type="submit" ref={refSubmit} style={{ display: 'none' }} />
          </FormikForm>
        )}
      </Formik>
    );
  },
);

export default FormCreateEvent;
