import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, FieldArray } from 'formik';
import { isRequired } from 'utils/rules';
import DropZone from 'components/DropZone';

export default class NotesView extends Component {
  render() {
    const { values, errors, handleChange, touched, handleBlur, setFieldValue, token } = this.props;

    return (
      <Row className="mb-4 ml-0 mr-0 pt-5">
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="general_notes">
            <Form.Label>
              <h3>General Notes</h3>
            </Form.Label>
            <Field
              as="textarea"
              className={'textarea'}
              name="general_notes"
              value={values.general_notes || ''}
              // validate={isRequired}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={3}
              style={{ border: 'none' }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.general_notes && touched.general_notes && errors.general_notes}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <h3>Images</h3>
          <DropZone
            height={300}
            token={token}
            folder="glaziers-notes-uploads"
            initialFiles={values.images}
            handleSuccess={({ url }, position) => {
              const images = [...values.images]
              const pos = Math.min(position, values.images.length)
              images.splice(pos, 0, url)
              setFieldValue(`images`, images)
            }}
            handleRemove={({ url }) =>
              setFieldValue(
                'images',
                values.images.filter(u => u !== url),
              )
            }
            handlePosition={(fromIndex, toIndex) => {
              const images = [...values.images]
              var element = images[fromIndex];
              images.splice(fromIndex, 1);
              images.splice(toIndex, 0, element);

              setFieldValue(`images`, images)
            }}
          />
        </Col>
      </Row>
    );
  }
}
